import { useRef, useState } from 'react';
import { css } from '@emotion/css';
import { CopySimple } from '@phosphor-icons/react';
import { animation, flex } from '../shared-styles';
import { colors } from '../styles';
import { useTimeout } from '../use-timeout';

export const Copy = ({ value, css: cssString = '', disableCopy = false, stopPropagation = true, children }) => {
  const ref = useRef();
  const [copied, setCopied] = useState(false);
  const [copyLocation, setCopyLocation] = useState([]);

  useTimeout(
    700,
    () => {
      setCopied(false);
      setCopyLocation([]);
    },
    [copied]
  );

  const handleCopy = (e) => {
    if (disableCopy) {
      return;
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
    try {
      navigator.clipboard.writeText(value);
      setCopied(true);
      const rect = ref?.current?.getBoundingClientRect();
      setCopyLocation([rect?.left - ref?.current?.clientLeft, rect?.top - ref?.current?.clientTop]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      ref={ref}
      className={css`
        border-radius: 8px;
        padding: 4px;
        margin: -4px;
        ${disableCopy
          ? ''
          : `
            :hover {
              cursor: pointer;
            }
        `}
        ${cssString}
      `}
      onClick={handleCopy}
    >
      {copied ? (
        <div
          className={css`
            position: fixed;
            left: calc(${copyLocation[0]}px);
            top: calc(${copyLocation[1]}px);
            background-color: ${colors.purple};
            width: 40px;
            height: 40px;
            text-align: center;
            padding: 8px;
            border-radius: 50%;
            ${flex('center')}
            ${animation('fadeOut', '.7s ease forwards')}
          `}
        >
          <CopySimple color="white" />
        </div>
      ) : null}
      {children}
    </div>
  );
};
