import { css } from '@emotion/css';
import { Newspaper } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';

export const Website = ({ element }) => {
  const { attributes } = element;

  const validUrl = useMemo(() => {
    try {
      new URL(attributes?.src);
      return true;
    } catch (err) {
      return false;
    }
  }, [attributes?.src]);

  if (!validUrl) {
    return (
      <Div
        css={css`
          ${flex('jcc aic column')} background-color: ${colors.gray[100]};
          border-radius: 8px;
          height: 500px;
          width: 100%;
        `}
      >
        <Newspaper size={48} />
        {!validUrl && !!attributes.src ? (
          <Text color={colors.red[100]}>Invalid Website URL</Text>
        ) : (
          <Text styles="bold label">Website</Text>
        )}
      </Div>
    );
  }

  return <iframe {...attributes} title="Website" />;
};
