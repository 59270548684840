import { css } from '@emotion/css';
import { useMemo } from 'react';
import { Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { Tooltip } from '../../common/components/Tooltip';

export const FlagsBadge = ({ flags, properties = [], orphanedFlags = [], flagCountOverride = null }) => {
  const flagCount = useMemo(() => {
    if (flagCountOverride !== null) {
      return flagCountOverride;
    }

    let count = 0;

    const orphanedFlagsFields = orphanedFlags?.map(({ metadata }) => metadata.property_chain);
    const flaggedProperties = flags
      ?.map(({ metadata }) => metadata.property_chain)
      .filter((property) => !orphanedFlagsFields.includes(property));

    for (let item of properties) {
      const propertiesCount = flaggedProperties?.filter((property) => property === item)?.length || 0;
      count = count + propertiesCount;
    }

    if (orphanedFlags?.length) {
      return count + orphanedFlags?.length;
    }
    return count;
  }, [flags?.length, properties?.length, flagCountOverride]);

  if (!flagCount) {
    return null;
  }

  return (
    <Tooltip label={`${flagCount} field${flagCount === 1 ? '' : 's'} need${flagCount === 1 ? 's' : ''} attention`}>
      <div
        className={css`
          position: relative;
          margin-right: 8px;
        `}
      >
        <Text
          css={`
            color: white;
            font-weight: bold;
            background-color: ${colors.purple};
            ${flex('center')}
            min-width: 24px;
            padding: 0 8px;
            height: 24px;
            border-radius: 30px;
          `}
        >
          {flagCount}
        </Text>
      </div>
    </Tooltip>
  );
};
