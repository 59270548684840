import React, { useEffect, useMemo, useState } from 'react';

import {
  Cards,
  FirstAidKit,
  ChartBar,
  Gear,
  Users,
  ChatText,
  Buildings,
  SquaresFour,
  Swatches,
  CaretDown,
  CaretUp,
} from '@phosphor-icons/react';

import List from '@material-ui/core/List';
import { useLocation, matchPath } from 'react-router-dom';
import { useStore } from './store-provider/use-store';
import { css } from '@emotion/css';
import { history } from './history';
import { colors } from './shared/styles';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { ACLWrapper } from './shared/components/acl-wrapper';
import { WRITE_BUSINESS_ACCESS } from './shared/acl-constants';
import { Collapse } from './common/components/Collapse';

const flipCard = (display, adjust) => css`
  height: 100%;
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  backface-visibility: hidden;
  transition: all 0.3s;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  ${display && `transform: rotateY(${adjust}180deg);`}
`;

const container = (displayNav) => css`
  height: 100%;
  position: relative;
  background-color: #f5f7fa;
  perspective: 1000px;
  overflow-y: auto;
  .nav {
    ${flipCard(!displayNav, '')}
  }
  .dash {
    ${flipCard(displayNav, '-')}
  }
`;

const NavItem = ({ title, path, Icon, businessId, location, subMenuItem }) => {
  const isPathMatch = (path) => Boolean(matchPath(location.pathname, { path }));
  let isSubMenu = !!subMenuItem;

  const isPathMatchSubMenu = useMemo(() => {
    return isPathMatch(path) || subMenuItem?.some(({ path }) => isPathMatch(path));
  }, [location.pathname, path]);

  const [open, setOpen] = useState(isPathMatchSubMenu);
  const [parentHover, setParentHover] = useState(false);

  useEffect(() => {
    if (isPathMatchSubMenu) {
      setOpen(true);
    }
  }, [isPathMatchSubMenu]);

  const handleClick = (nextPath, isDrop = false) => {
    const pathWithId = nextPath.replace(':businessId', businessId);
    if (isSubMenu && open && !isPathMatchSubMenu && isDrop) {
      setOpen(false);
    } else {
      history.push(pathWithId);
    }
  };

  return (
    <div
      className={css`
        cursor: pointer;
      `}
    >
      <div
        onClick={() => handleClick(path, isSubMenu)}
        onMouseEnter={() => {
          setParentHover(true);
        }}
        onMouseLeave={() => {
          setParentHover(false);
        }}
        className={css`
          display: flex;

          justify-content: space-between;

          align-items: center;
          border-left: ${isPathMatch(path) && !isSubMenu ? '6px solid #25282d' : '6px solid transparent'};

          background-color: ${(isPathMatch(path) && !isSubMenu) || parentHover
            ? (!isSubMenu || !open) && '#e8edf3'
            : 'transparent'};
          ${isPathMatchSubMenu ? `cursor: default;` : `cursor: pointer;`}
          padding: 8px 0;
          :hover {
            transition: background-color 0.2s ease;
          }
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: flex-start;
            gap: 16px;
            align-items: center;
            padding: 8px 16px;
          `}
        >
          <Icon />
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              letter-spacing: 0.15px;

              color: #25282d;
            `}
          >
            {title}
          </div>
        </div>
        {isSubMenu && (
          <div
            onMouseEnter={(e) => {
              e.stopPropagation();

              setParentHover(false);
            }}
            className={css`
              width: 48px;
              height: 48px;
              border-radius: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              pointer-events: none;
            `}
          >
            {!isPathMatchSubMenu &&
              (!open ? (
                <CaretDown size={16} color={'#25282D'} weight="bold" />
              ) : (
                <CaretUp size={16} color={'#25282D'} weight="bold" />
              ))}
          </div>
        )}
      </div>

      <Collapse isOpen={isSubMenu && open}>
        {subMenuItem?.map((item) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleClick(item.path);
              }}
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                border-left: ${isPathMatch(item.path)
                  ? `6px solid #25282d; color: ${colors.black}`
                  : `6px solid transparent; color: ${colors.gray[500]}`};

                letter-spacing: 0.15px;

                padding: 16px;

                padding-left: 64px;

                background-color: ${isPathMatch(item.path) ? '#e8edf3' : 'transparent'};
                :hover {
                  transition: background-color 0.2s ease;
                  background-color: #e8edf3;
                }
              `}
            >
              {item.title}
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export const CustomMenu = (props) => {
  const {
    data: { selectedBusiness, parentBusiness },
  } = useStore();

  const businessSmartFieldsFF = useFeatureFlagPayload('business-smart-fields');

  const businessId = selectedBusiness.ID || '';
  const isManagingMultipleBusinesses = parentBusiness.Type === 'multi' || parentBusiness.Type === 'carrier';
  const location = useLocation();
  const displayNav = !location.pathname.includes('dashboard') && !location.pathname.includes('members');

  let companyKitSubMenuItems = [
    {
      title: 'Business Details',
      path: '/:businessId/business-details',
    },
    { title: 'Design Styles', path: '/:businessId/design-styles' },
    { title: 'Documents', path: '/:businessId/documents' },
  ];
  if (businessSmartFieldsFF?.value) {
    companyKitSubMenuItems.push({
      title: 'Smart Text',
      path: '/:businessId/smart-fields',
    });
  }

  return (
    <div className={container(displayNav)}>
      <div className="nav">
        <div>
          <NavItem
            location={location}
            businessId={businessId}
            title="Brite Guides"
            path={'/:businessId/courses'}
            Icon={Cards}
            subMenuItem={[
              {
                title: 'All Guides',
                path: '/:businessId/courses',
              },
              { title: 'Templates', path: '/:businessId/templates' },
            ]}
          />

          <NavItem
            location={location}
            businessId={businessId}
            title="Benefits"
            path={'/:businessId/packages'}
            Icon={FirstAidKit}
          />

          <ACLWrapper acls={['write:brite']}>
            <NavItem
              location={location}
              businessId={businessId}
              title="Messaging"
              path={'/:businessId/messaging'}
              Icon={ChatText}
            />
          </ACLWrapper>

          <NavItem
            location={location}
            businessId={businessId}
            title="Analytics"
            path={'/:businessId/analytics'}
            Icon={ChartBar}
          />

          <hr
            className={css`
              border: 0;
              border-bottom: 1px solid ${colors.gray[300]};
              background: none;
              margin-left: 24px;
              margin-right: 24px;
            `}
          />

          <NavItem
            location={location}
            businessId={businessId}
            title="Company Kit"
            path={'/:businessId/business-details'}
            Icon={Swatches}
            subMenuItem={companyKitSubMenuItems}
          />
        </div>

        <List>
          {isManagingMultipleBusinesses && (
            <NavItem
              location={location}
              businessId={businessId}
              title="Companies"
              path={'/:businessId/companies'}
              Icon={Buildings}
            />
          )}
          <ACLWrapper acls={[WRITE_BUSINESS_ACCESS]}>
            <NavItem
              location={location}
              businessId={businessId}
              title="Settings"
              path={'/:businessId/settings'}
              Icon={Gear}
            />
          </ACLWrapper>
        </List>
      </div>

      <div className="dash">
        <List>
          <NavItem
            location={location}
            businessId={businessId}
            title="Dashboard"
            path={'/dashboard'}
            Icon={SquaresFour}
          />

          <NavItem location={location} businessId={businessId} title="Members" path={'/members'} Icon={Users} />
        </List>
      </div>
    </div>
  );
};
