import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import SuccessText from '../../../Courses/success-text.svg';
import { Button, Modal } from '../../../shared/components';
import { colors } from '../../../shared/styles';

// Text messaging has error states so it is abstracted from the default LaunchSuccessModal.
export const LaunchSuccessTextMessageModal = ({ hide = () => {}, sentToPhoneNumbers = [], textingErrors = [] }) => {
  return (
    <Modal
      display
      onClose={() => hide()}
      css={`
        overflow: auto;
        min-width: 800px;
      `}
    >
      <>
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 32px;
          `}
        >
          <Button styles="icon" onClick={() => hide()}>
            <X size={24} />
          </Button>
        </div>
        <div
          className={css`
            margin: 32px;
            margin-top: 0px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img src={SuccessText} alt="Success" />
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 24px;
              line-height: 133.4%;
              margin: 32px;
              text-align: center;
              color: ${colors.black};
            `}
          >
            {textingErrors.length > 0
              ? 'Your message is sending to ' +
                (sentToPhoneNumbers.length - textingErrors?.length) +
                ' of ' +
                sentToPhoneNumbers.length +
                ' recipients.'
              : 'Your message is sending successfully.'}
          </div>
          {textingErrors.length > 0 && <h3>The following recipients had errors</h3>}
          <div
            className={css`
              margin-bottom: 24px;
              overflow-y: scroll;
              max-height: 250px;
            `}
          >
            {textingErrors.map((error) => {
              return (
                <div
                  className={css`
                    margin: 8px;
                    color: red;
                  `}
                >
                  {error.PhoneNumber}: {error.Error}
                </div>
              );
            })}
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-bottom: 32px;
            `}
          >
            <Button onClick={() => hide()}>Close</Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
