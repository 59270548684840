import { CircularProgress, MenuItem, Typography } from '@material-ui/core';
import { css } from '@emotion/css';
import { CaretDown } from '@phosphor-icons/react';
import React from 'react';
import { useQueryAPI } from '../../react-query';
import { Div, DropMenu, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { PlanTypeIcons } from '../plan-type-icons';

const productTypeContainer = css`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  max-width: 960px;
  min-width: 500px;
  align-items: center;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: calc(50% - 16px);
    max-width: calc(50% - 16px);
    margin: 8px;
    padding: 8px 16px;
    border: 1px solid #d1dae3;
    border-radius: 8px;
    height: 52px;
    :hover {
      background-color: #f5f7fa;
    }
    p {
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .main {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: calc(1 / 3);
    min-width: calc(1 / 3);
    height: 80px;
    p {
      flex-grow: unset;
    }
  }
  .check {
    position: absolute;
    right: 16px;
  }
  .selected {
    background-color: #f5f7fa;
    border: 2px solid black;
    padding: 15px;
    margin: 8px;
  }
`;

export const mainPlans = [
  {
    ProductType: 'insurance_plan',
    DisplayValue: 'Medical Plan',
  },
  {
    ProductType: 'dental_buyup',
    DisplayValue: 'Dental',
  },
  {
    ProductType: 'vision_buyup',
    DisplayValue: 'Vision',
  },
];

const mainPlanList = mainPlans.map(({ ProductType }) => ProductType);

export const handleProductTypes = (data) => {
  const list = [...data]
    .filter(({ ProductType }) => !mainPlanList.includes(ProductType))
    .filter(({ Deprecated }) => !Deprecated) // filter out any deprecated product-types so we can't create new ones
    .sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
  return [
    {
      ProductType: 'custom',
      DisplayValue: 'Custom Benefit',
    },
    ...list,
  ];
};

export const PlanType = ({ typeList, setTypeList, routeAca }) => {
  const { data: productTypes, isLoading } = useQueryAPI({
    url: `/v1/product/types`,
    select: handleProductTypes,
    defaultValue: [],
  });

  const updateProductType = (Type) => {
    if (typeList.includes(Type)) {
      const newTypes = typeList.filter((type) => type !== Type);
      setTypeList(newTypes);
    } else {
      setTypeList([...typeList, Type]);
    }
  };

  const updateTypeCount = (type, count) => {
    setTypeList((typeList) => {
      const types = typeList.filter((item) => item !== type);
      const addedTypes = `${type},`
        .repeat(count)
        .split(',')
        .filter((type) => type);
      return [...types, ...addedTypes];
    });
  };

  const CountMenu = ({ type }) => {
    const count = typeList.reduce((p, v) => p + (v === type ? 1 : 0), 0);
    return (
      <DropMenu
        listStyle={{ width: '80px' }}
        title=""
        button={
          <Text
            styles="label ai:c padh padv-sm radius bold"
            className={css`
              :hover {
                background-color: ${colors.gray[300]};
              }
            `}
          >
            <Text as="span" styles="mr-sm">
              {count}
            </Text>
            <CaretDown />
          </Text>
        }
      >
        <MenuItem value={1} onClick={() => updateTypeCount(type, 1)}>
          1
        </MenuItem>
        <MenuItem value={2} onClick={() => updateTypeCount(type, 2)}>
          2
        </MenuItem>
        <MenuItem value={3} onClick={() => updateTypeCount(type, 3)}>
          3
        </MenuItem>
        <MenuItem value={4} onClick={() => updateTypeCount(type, 4)}>
          4
        </MenuItem>
      </DropMenu>
    );
  };

  return (
    <>
      <Text styles="h2 center mb mt-xl">Select a Benefit Type</Text>
      <Text styles="mb-xl">
        Select all benefits that apply or{' '}
        <Text
          label
          bold
          as="span"
          css={`
            cursor: pointer;
            :hover {
              text-decoration: underline;
            }
          `}
          onClick={routeAca}
        >
          Import ACA
        </Text>
      </Text>
      <div className={productTypeContainer}>
        {mainPlans.map(({ DisplayValue, ProductType }) => {
          const selected = typeList.includes(ProductType);
          return (
            <div
              className={selected ? 'selected main' : 'main'}
              onClick={() => updateProductType(ProductType)}
              key={ProductType}
            >
              <Div
                css={css`
                  ${flex('aic')}
                `}
              >
                <PlanTypeIcons type={ProductType} />
                <Typography color="primary">
                  {ProductType === 'supplimental_life' ? 'Life Insurance (Basic/Supplemental)' : DisplayValue}
                </Typography>
              </Div>
              {selected && <CountMenu type={ProductType} />}
            </div>
          );
        })}
      </div>

      {isLoading && <CircularProgress />}

      <div
        className={css`
          ${productTypeContainer}
        `}
      >
        {productTypes &&
          productTypes.map(({ DisplayValue, ProductType }) => {
            const selected = typeList.includes(ProductType);
            return (
              <div
                className={selected ? 'selected' : ''}
                onClick={() => updateProductType(ProductType)}
                key={ProductType}
              >
                <Typography color="primary">
                  {ProductType === 'supplimental_life' ? 'Life Insurance (Basic/Supplemental)' : DisplayValue}
                </Typography>
                {selected && <CountMenu type={ProductType} />}
              </div>
            );
          })}
      </div>
    </>
  );
};
