import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import { CalendarBlank } from '@phosphor-icons/react';
import { CustomAxios } from '../../redux/axios/axios';
import { Box, Button, Dates, Div, Dropdown, Modal, Text } from '../../shared/components';

export const OpenEnrollmentModal = ({ hideOpenEnrollment, handleTimeFrame, guide, setGuide }) => {
  const updateOEDates = async () => {
    try {
      let course = cloneDeep(guide);
      delete course.Pages;
      await CustomAxios.put(`/v2/course/${guide.ID}`, course);
    } catch (e) {}
  };
  return (
    <Modal
      display
      onClose={() => {
        hideOpenEnrollment();
        handleTimeFrame('Last 30 days');
      }}
    >
      <Modal.Paper>
        <Modal.Body>
          <Div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
            `}
          >
            <CalendarBlank size={72} />
            <Text h2>Let's set your open enrollment dates!</Text>
            <Text
              body
              css={css`
                text-align: center;
              `}
            >
              To get the most precise insights on your analytics, we suggest adding your open enrollment dates.
            </Text>
            <Dropdown
              listMaxHeight="100vh"
              button={() => (
                <Button secondary>
                  {format(Dates.getDateFromISO(guide?.TargetingStart), 'MMM d, yyyy')}
                  {' - '}
                  {format(Dates.getDateFromISO(guide?.TargetingEnd), 'MMM d, yyyy')}
                </Button>
              )}
            >
              <Box
                css={`
                  margin: 32px;
                `}
                onClick={(e) => e.stopPropagation()}
              >
                <Dates.Range
                  start={startOfDay(Dates.getDateFromISO(guide?.TargetingStart))}
                  end={endOfDay(Dates.getDateFromISO(guide?.TargetingEnd))}
                  autoSetEnd={(start) => addDays(start, 365)}
                  setRange={(range) => {
                    let course = cloneDeep(guide);
                    course.TargetingStart = new Date(range?.start)?.toISOString();
                    course.TargetingEnd = new Date(range?.end)?.toISOString();
                    setGuide(course);
                  }}
                />
              </Box>
            </Dropdown>
          </Div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              hideOpenEnrollment();
              handleTimeFrame('Last 30 days');
            }}
          >
            Skip
          </Button>
          <Button
            primary
            disabled={!guide?.TargetingStart || !guide?.TargetingEnd}
            onClick={() => {
              hideOpenEnrollment();
              updateOEDates();
              handleTimeFrame('Open Enrollment');
            }}
          >
            Set
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
