import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { CustomAxios } from '../redux/axios/axios';

import { format } from 'date-fns';
import { ArrowLeft, Check } from '@phosphor-icons/react';

import IconButton from '@material-ui/core/IconButton';
import { cloneDeep } from 'lodash';
import { FormatPhoneNumber } from '../lib/communications/formatting';

import { TextField } from '@material-ui/core';

export const TextingHistoryDetails = ({ parentCourse, parentSetCourse, refetch }) => {
  const [course, setCourse] = useState(parentCourse);
  const [phoneNumberSearch, setPhoneNumberSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let cloned = cloneDeep(parentCourse);

        cloned.course = cloneDeep(parentCourse.course);
        cloned.deliveredCount = parentCourse.deliveredCount;
        cloned.percentage = parentCourse.percentage;

        // We have too many unique link trackers to query all at once, so we need to batch them.
        // Eventually, we should think about a different structure for linking this data on the backend, but this is a quick fix until we decide to revisit.
        let queryParamBatches = [];
        let linkCounter = 0;

        // start and endDate are required for the click analytics endpoint. We don't limit/batch these elegantly yet, so we're just going to hard code some arbitrary dates for now.
        const startDate = format(Date.now() - 360 * 24 * 60 * 60 * 1000, "yyyy-MM-dd'T'hh:mm:ss'Z'");
        const endDate = format(Date.now(), "yyyy-MM-dd'T'hh:mm:ss'Z'");

        let queryParams = `?startDate=${startDate}&endDate=${endDate}&`;
        for (let message of cloned) {
          if (message.link_tracker) {
            linkCounter++;
            queryParams = queryParams + `linkIDs=${message.link_tracker}&`;
            if (linkCounter > 700) {
              queryParamBatches.push(queryParams);
              queryParams = '?';
              linkCounter = 0;
            }
          }
        }

        // push the last batch
        if (queryParams.length > 1) {
          queryParamBatches.push(queryParams);
        }

        var promiseArray = [];
        queryParamBatches.forEach((queryParamBatch) => {
          queryParamBatch = queryParamBatch.substring(0, queryParamBatch.length - 1);
          promiseArray.push(CustomAxios.get(`/v1/analytics/${cloned.course.ID}/clicks${queryParamBatch}`));
        });

        let responses = await Promise.all(promiseArray);

        let courseActivity = [];
        responses.forEach((response) => {
          courseActivity = courseActivity.concat(response.data.Data);
        });

        let openCount = 0;
        let alreadyProcessedLinks = {};
        for (let message of cloned) {
          for (let openInfo of courseActivity) {
            if (openInfo.LinkTracker === message.link_tracker) {
              message.opened = true;
              // check if already processed
              if (alreadyProcessedLinks[message.link_tracker]) {
                continue;
              }

              // append to alreadyProcessedLinks
              alreadyProcessedLinks[message.link_tracker] = true;
              openCount = openCount + 1;
            }
          }
        }
        cloned.openCount = openCount;
        setCourse(cloned);
      } catch (e) {
        console.log('er', e);
      }
    };

    fetchData();
  }, [parentCourse]);

  const getColor = (textInfo) => {
    if (
      textInfo.status === 'failed' ||
      textInfo.status === 'undelivered' ||
      textInfo.status === 'delivery_unknown' ||
      textInfo.status === 'previously_opted_out'
    ) {
      return '#eb4e3d';
    }

    if (textInfo.status === 'delivered') {
      return '#32AA4E';
    }

    return '#25282D';
  };

  const getBgColor = (textInfo) => {
    if (
      textInfo.status === 'failed' ||
      textInfo.status === 'undelivered' ||
      textInfo.status === 'delivery_unknown' ||
      textInfo.status === 'previously_opted_out'
    ) {
      return 'rgba(235, 78, 61, 0.2)';
    }

    if (textInfo.status === 'delivered') {
      return 'rgba(61, 198, 94, 0.2)';
    }

    return '#E8EDF3';
  };

  return (
    <div
      className={css`
        margin-top: 32px;
        margin-bottom: 80px;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <div>
          <IconButton
            aria-label="back"
            color="primary"
            onClick={() => {
              parentSetCourse('');
            }}
          >
            <ArrowLeft />
          </IconButton>
        </div>
        <div
          className={css`
            font-weight: 700;
            font-size: 24px;
            line-height: 133.4%;
            /* identical to box height, or 32px */
            margin-left: 12px;
            color: #25282d;
          `}
        >
          {course.course.Name}
        </div>
      </div>
      <div
        className={css`
          display: flex;
          margin-left: 14px;
          margin-top: 40px;
        `}
      >
        <div
          className={css`
            width: 600px;
          `}
        >
          <div
            className={css`
              font-weight: 700;
              font-size: 18px;
              line-height: 175%;
              /* identical to box height, or 32px */

              letter-spacing: 0.15px;
              width: 600px;

              color: #25282d;
              margin-bottom: 16px;
            `}
          >
            Message
          </div>
          <div
            className={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              letter-spacing: 0.15px;

              color: #66737f;
            `}
          >
            {course[0].body}
          </div>

          <div
            className={css`
              font-weight: 700;
              font-size: 18px;
              line-height: 175%;
              /* identical to box height, or 32px */

              letter-spacing: 0.15px;
              margin-top: 40px;
              color: #25282d;
              margin-bottom: 16px;
            `}
          >
            Last Sent
          </div>

          <div
            className={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              letter-spacing: 0.15px;

              color: #66737f;
            `}
          >
            {format(
              new Date(
                new Date(course[0].date_created).valueOf() -
                  new Date(course[0].date_created).getTimezoneOffset() * 60 * 1000
              ),
              'MMM dd yyyy '
            )}
          </div>
        </div>
        <div
          className={css`
            margin-left: 40px;
          `}
        >
          <div
            className={css`
              font-weight: 700;
              font-size: 18px;
              line-height: 175%;
              /* identical to box height, or 32px */

              letter-spacing: 0.15px;
              margin-bottom: 16px;
              color: #25282d;
            `}
          >
            Guide Image
          </div>
          <div
            className={css`
              border: 1px solid #d1dae3;
              border-radius: 8px;
              padding: 24px;
            `}
          >
            {course.course.LogoURL && (
              <img
                alt="Guide Logo"
                className={css`
                  max-height: 80px;
                  max-width: 250px;
                `}
                src={course.course.LogoURL}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 60px;
        `}
      >
        <div
          className={css`
            border: 1px solid #d1dae3;
            border-radius: 8px;
            padding: 24px;
            padding-right: 100px;
            flex: 1;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              color: #25282d;
            `}
          >
            Attempted Messages
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 34px;
              line-height: 123.5%;
              /* identical to box height, or 42px */

              letter-spacing: 0.25px;

              color: #25282d;
            `}
          >
            {course.length}
          </div>
        </div>
        <div
          className={css`
            border: 1px solid #d1dae3;
            border-radius: 8px;
            padding: 24px;
            padding-right: 100px;
            flex: 1;
            margin-left: 32px;
            margin-right: 32px;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              color: #25282d;
            `}
          >
            Delivered Rate
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 34px;
              line-height: 123.5%;
              /* identical to box height, or 42px */

              letter-spacing: 0.25px;

              color: #25282d;
            `}
          >
            {Math.round(course.percentage)}%
          </div>
        </div>

        <div
          className={css`
            border: 1px solid #d1dae3;
            border-radius: 8px;
            padding: 24px;
            padding-right: 100px;
            flex: 1;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              color: #25282d;
            `}
          >
            Opened Guide Rate
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 34px;
              line-height: 123.5%;
              /* identical to box height, or 42px */

              letter-spacing: 0.25px;

              color: #25282d;
            `}
          >
            {course.openCount
              ? `${Math.round((course.openCount * 100) / (course.length * (course.percentage * 0.01)))}%`
              : 'no data'}
          </div>
        </div>
      </div>
      <div
        className={css`
          font-weight: 700;
          font-size: 18px;
          line-height: 175%;
          /* identical to box height, or 32px */

          letter-spacing: 0.15px;

          color: #25282d;
          margin-top: 40px;
        `}
      >
        Recipients
      </div>

      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <TextField
          variant="outlined"
          placeholder="Search Phone Number"
          style={{
            flex: 1,
            marginRight: '24px',
            border: '1px solid #d1dae3',
            borderRadius: '8px',
            marginTop: '8px',
          }}
          value={phoneNumberSearch}
          onChange={(e) => setPhoneNumberSearch(e.target.value.replace(/[^0-9]/g, ''))}
          // extract only the digits from e.target.value
        />

        <div
          className={css`
            padding-left: 24px;
            flex: 2;
          `}
        >
          {/* This div is just so that the flex spacing aligns with upper section of 3 parts */}
        </div>
      </div>

      <div
        className={css`
          overflow-y: scroll;
          max-height: 600px;
          margin-top: 16px;
        `}
      >
        {course.map((textInfo) => {
          if (phoneNumberSearch && !textInfo.to.includes(phoneNumberSearch)) {
            return null;
          }

          return (
            <div
              className={css`
                border-bottom: 1px solid #d1dae3;
                display: flex;
                align-items: center;
                padding: 16px;
                padding-left: 0px;
              `}
            >
              <div
                className={css`
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  /* identical to box height, or 150% */

                  color: #25282d;
                  padding-right: 80px;
                  width: 220px;
                `}
              >
                {FormatPhoneNumber(textInfo.to)}
              </div>
              <div
                className={css`
                  width: 180px;
                `}
              >
                <div
                  className={css`
                    background: ${getBgColor(textInfo)};
                    border-radius: 42px;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    /* identical to box height, or 171% */
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    color: ${getColor(textInfo)};
                  `}
                >
                  {textInfo.status}
                </div>
              </div>
              <div
                className={css`
                  border-radius: 42px;
                  padding-left: 16px;
                  padding-right: 16px;
                  padding-top: 4px;
                  padding-bottom: 4px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  /* identical to box height, or 171% */
                `}
              >
                {format(
                  new Date(
                    new Date(textInfo.date_updated).valueOf() -
                      new Date(textInfo.date_updated).getTimezoneOffset() * 60 * 1000
                  ),
                  'MMM dd yyyy h:mm a'
                )}
              </div>
              {textInfo.opened && (
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    margin-left: 80px;
                  `}
                >
                  <div
                    className={css`
                      margin-right: 16px;
                    `}
                  >
                    <Check size={24} />
                  </div>
                  <div
                    className={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      color: #25282d;
                    `}
                  >
                    Opened Guide
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
