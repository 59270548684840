import { css } from '@emotion/css';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { Button, Modal, Text } from '../../../shared/components';
import { useStore } from '../../../store-provider/use-store';

export const MessageTemplatesModal = ({ hideMessageTemplates, guide }) => {
  const {
    data: { selectedBusiness },
  } = useStore();

  const businessName = selectedBusiness?.Name;

  return (
    <Modal
      display
      css={`
        overflow: auto;
        min-width: 800px;
      `}
      onClose={() => {
        hideMessageTemplates();
      }}
    >
      <div
        className={css`
          height: 100vh;
          overflow: auto;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <Button
              styles="icon"
              onClick={() => {
                hideMessageTemplates();
              }}
            >
              <ArrowLeft />
            </Button>
            <Text h2>Brite Message Templates</Text>
          </div>
          <Button
            styles="icon"
            onClick={() => {
              hideMessageTemplates();
            }}
          >
            <X size={24} />
          </Button>
        </div>
        <div
          className={css`
            margin: 32px;
            margin-top: 0px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text h4>Template 1</Text>
          </div>
          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              padding-top: 16px;
              padding-left: 24px;
              padding-right: 24px;
              padding-bottom: 32px;
              margin-top: 16px;
              margin-bottom: 32px;
            `}
          >
            All {businessName} Employees,
            <br />
            <br /> Open Enrollment time is here! This is your time to make your benefits elections for the upcoming
            year. Open enrollment opens [insert start date] and closes at midnight [insert end date]. <br />
            <br />
            Click the link below to get started:
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text h4>Template 2</Text>
          </div>
          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              padding-top: 16px;
              padding-left: 24px;
              padding-right: 24px;
              padding-bottom: 32px;
              margin-top: 16px;
              margin-bottom: 32px;
            `}
          >
            <p>Hey {businessName} Employees,&nbsp;</p>
            <p>
              Open Enrollment is here! Open Enrollment is your time to learn about the benefits offered to you and to
              make your elections for the year. Open enrollment will be held from [insert start date - insert end date]
              and closing at midnight.&nbsp;
            </p>
            <p>There are two parts to our Open Enrollment this year:&nbsp;</p>
            <ul>
              <li>Get recommendations &amp; learn about the benefits (link below)</li>
              <li>Enroll in your benefits (through [insert enrollment link])</li>
            </ul>
            <p>Click the link below to get started:</p>
            <p>https://britehr.app/{guide.VanityURL}</p>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text h4>Template 3</Text>
          </div>
          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              padding-top: 16px;
              padding-left: 24px;
              padding-right: 24px;
              padding-bottom: 32px;
              margin-top: 16px;
              margin-bottom: 32px;
            `}
          >
            <p>
              Open Enrollment for {businessName} is here! Open Enrollment is your time to learn about the benefits we
              offer and make your elections for the year. *All of these benefit elections will go into effect [X/1/20XX]
              and cannot be updated until next year (*unless you have a qualified life event).
            </p>
            <p>Open Enrollment will be held from [X/X/20XX - X/X/20XX] closing at midnight.</p>
            <p>There are two parts to our Open Enrollment this year:</p>
            <ul>
              <li>
                Learn about the benefits <span style={{ color: '#4a86e8' }}>(NEW)</span>
              </li>
              <li>Elect benefits</li>
            </ul>
            <p>
              <strong>Learning about the benefits - </strong>
              <strong>https://britehr.app/{guide.VanityURL}</strong>
            </p>
            <p>
              {businessName} offers incredible employee benefits to our employees. We have incorporated a new way of
              learning how each of these benefits work, including where to download their apps, quick links to find
              providers/facilities, and even some recommendation tools to help you pick the right plan for you. Feel
              free to share this link with those that you plan to enroll on your plans as well!
            </p>
            <p>*please know that you cannot make your benefits elections in this learning tool.</p>
            <p>
              <strong>Elect Your Benefits - </strong>
              <strong>[Hyperlink HRIS Enrollment Link]</strong>
            </p>
            <p>
              Open Enrollment will be held [xx/x/20xx - xx/x/20xx] closing at midnight. During this period, log into
              [insert HRIS name] [insert URL link] to either select or waive benefits. On [xx/x/20xx] after the window
              closes, all selections will be locked-in and sent to the carriers. Please note that you must select{' '}
              <strong>"Agree"</strong> in the Enrollment Summary Screen to complete your open enrollment.
            </p>
            <p>Sincerely,</p>
            <p>[Insert Company Contact]</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
