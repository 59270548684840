import { css } from '@emotion/css';
import { Button, Div, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Placeholder } from '@phosphor-icons/react';

export const LaunchType = ({
  iconComponent = <Placeholder size={40} />,
  iconLabel = 'Placeholder',
  onClick = () => {},
}) => {
  return (
    <Div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      `}
    >
      <Div
        css={css`
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          flex-shrink: 0;
          border: 1px solid ${colors.gray[300]};
          border-radius: 50%;

          :hover {
            background: ${colors.gray[100]};
          }
        `}
        onClick={() => onClick()}
      >
        <Button
          styles="icon"
          css={css`
            :hover {
              background: none;
            }
          `}
        >
          {iconComponent}
        </Button>
      </Div>
      <Text caption color={colors.black}>
        {iconLabel}
      </Text>
    </Div>
  );
};
