import { endOfDay, format, getMonth, getYear, startOfDay, subDays } from 'date-fns';
import { css } from '@emotion/css';
import { useState, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash';
import { IconButton, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLeft, Info, UploadSimple, Trash } from '@phosphor-icons/react';
import { Tooltip } from '../../common/components/Tooltip';
import { useModal } from '../../common/hooks/useModal';
import { CustomAxios } from '../../redux/axios/axios';
import { Box, BriteLoader, Button, Dates, Div, Dropdown, Modal, Text } from '../../shared/components';
import { flex, px, animation } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useStore } from '../../store-provider/use-store';
import { createData, getDurationFromSeconds } from '../helpers';
import ShareModal from '../ShareModal';
import { TrafficTab } from './tabs/TrafficTab';
import { EmployeeCountModal } from './EmployeeCountModal';
import { OpenEnrollmentModal } from './OpenEnrollmentModal';
import { EditorGuideAnalyticsTabs } from './EditorGuideAnalyticsTabs';
import { EngagementTab } from './tabs/EngagementTab';
import { ConversionTab } from './tabs/ConversionTab';
import { fulfilledPromises } from '../../react-query/promise-manager';

const useStyles = makeStyles(() => ({
  formControl: {
    'label + &': {
      display: 'none',
    },
    minWidth: 223,
    color: colors.black,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    height: 48,
    letterSpacing: '0.15px',
    borderRadius: 8,
    '> .MuiOutlinedInput-notchedOutline': {
      '> .PrivateNotchedOutline-legend-20': {
        width: 'unset',
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
  },
  menuStyle: {
    color: colors.black,
    marginLeft: '8px',
    marginRight: '8px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: colors.gray[100],
    },
    '&.Mui-selected': {
      borderRadius: '8px',
      backgroundColor: colors.gray[100],
      '&:hover': {
        borderRadius: '8px',
        backgroundColor: colors.gray[100],
      },
    },
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    'label + &': {
      display: 'none',
    },
    '& $notchedOutline': {
      borderColor: colors.gray[300],
    },
    '&:hover $notchedOutline': {
      borderColor: colors.black,
    },
    '&$focused $notchedOutline': {
      borderColor: colors.black,
    },
    '&$focused': {
      backgroundColor: 'white',
    },
    '> .MuiOutlinedInput-notchedOutline': {
      '> .PrivateNotchedOutline-legend-20': {
        width: 'unset',
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
  },
  focused: {
    backgroundColor: 'transparent',
  },
  notchedOutline: {},
}));

const chartLegendOptions = {
  position: 'top',
  align: 'end',
  display: true,
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    boxHeight: 7,
    filter: function (item, chart) {
      // Remove legend items for datasets that have no data
      let hasEntries = false;
      let dataset = chart.datasets[item.datasetIndex];
      for (let j = 0; j < dataset.data.length; j++) {
        if (dataset.data[j] > 0) {
          hasEntries = true;
        }
      }
      return hasEntries;
    },
  },
};

const tabValues = {
  TRAFFIC_TAB: 'traffic',
  ENGAGEMENT_TAB: 'engagement',
  CONVERSION_TAB: 'conversion',
};

export const EditorGuideAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({
    Feedback: [],
    Buyups: [],
    Activity: [],
    Questions: [],
    Recommendations: [],
    Time: {
      TotalTime: 0,
    },
  });
  const [tab, setTab] = useState(tabValues.TRAFFIC_TAB);
  const [eligibleEmployee, setEligibleEmployee] = useState(0);
  const [timeFrame, setTimeFrame] = useState('Last 30 days');
  const [guide, setGuide] = useState(null);
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 30)));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [loadingPageViewsData, setLoadingPageViewsData] = useState(false);
  const [pageViewsStatistics, setPageViewsStatistics] = useState(null);
  const [pageDataRows, setPageDataRows] = useState([]);
  const [open, setOpen] = useState(false);

  // Modals
  const openEnrollmentModal = useModal();
  const employeeCountModal = useModal();
  const shareModal = useModal();

  const {
    data: { selectedBusiness },
  } = useStore();

  const { courseId } = useParams();

  const history = useHistory();

  const classes = useStyles();

  const outlinedInputClasses = useOutlinedInputStyles();

  const fetchPageViewsStatistics = async (pages, id, start = startDate, end = endDate) => {
    setLoadingPageViewsData(true);
    // use CustomAxios to get page views statistics
    try {
      const engagementResponse = await CustomAxios.get(
        `/v1/analytics/engagement/${id}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      if (!engagementResponse.data) {
        engagementResponse.data = {
          PageSessions: [],
          Questions: [],
          AveragePagesPerVisitor: 0,
          AverageTimePerVisitorSeconds: 0,
          TotalPageVisits: 0,
          TotalVisitTimeSeconds: 0,
        };
      }
      if (!engagementResponse.data.Questions) {
        engagementResponse.data.Questions = [];
      }

      let groupedByPages = groupBy(engagementResponse.data.PageSessions, function (page) {
        return page.PageID;
      });

      let largestPageVisits = 0;

      let pagesWithData = pages.map((page) => {
        let pageStats = engagementResponse.data.PageSessions.find((pageViewStats) => pageViewStats.PageID === page.ID);
        if (!pageStats) {
          pageStats = {};
        }
        pageStats.visits = groupedByPages[pageStats.PageID]?.length || 0;

        let pageSeshes = groupedByPages[pageStats.PageID] || [];
        let groupedByUser = groupBy(pageSeshes, function (sesh) {
          return sesh.UserID;
        });

        let totalSeconds = pageSeshes.reduce((n, { PageSessionDurationSeconds }) => n + PageSessionDurationSeconds, 0);
        let vis = pageStats.visits || 0;

        let averageSeconds = 0;
        if (vis > 0) {
          averageSeconds = totalSeconds / vis;
        }

        if (vis > largestPageVisits) {
          largestPageVisits = vis;
        }

        pageStats.unique = Object.keys(groupedByUser).length;
        pageStats.pageData = page;
        pageStats.average = getDurationFromSeconds(averageSeconds);
        pageStats.total = getDurationFromSeconds(totalSeconds);
        pageStats.totalSeconds = totalSeconds;
        pageStats.averageSeconds = averageSeconds;
        return pageStats;
      });

      const rows = [];
      const columnRows = [];

      for (let pageData of pagesWithData) {
        if (pageData.PageOrder) {
          rows.push(
            createData(
              pageData.PageOrder,
              pageData.pageData.Name,
              pageData.visits || 0,
              pageData.unique || 0,
              pageData.average,
              pageData.total
            )
          );
          const label = `${pageData?.PageOrder} - ${pageData?.pageData?.Name}`;
          columnRows.push([label, pageData.visits || 0]);
        }
      }

      pagesWithData.columnRows = columnRows;

      setPageDataRows(rows);
      setPageViewsStatistics({
        courseID: id,
        startDate: start,
        data: pagesWithData,
        engagementOverview: engagementResponse.data,
        largestPageVisits,
      });
    } catch (e) {
      console.log('error getting page view statistics', e);
    } finally {
      setLoadingPageViewsData(false);
    }
  };

  const getAnalytics = async (guideID, start = startDate, end = endDate) => {
    setLoading(true);
    try {
      var response;
      let enrollmentConfidenceResponse;
      response = await CustomAxios.get(
        `/v1/analytics/traffic/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      try {
        enrollmentConfidenceResponse = await CustomAxios.get(
          `/v1/bp/confidence_survey?course_id=eq.${guideID}&created_at=gte.${start.toISOString()}&created_at=lte.${end.toISOString()}`,
          {
            headers: {
              'Accept-Profile': 'brite_public',
            },
          }
        );
      } catch (e) {
        enrollmentConfidenceResponse = { data: [], error: true };
      }

      const feedbackResponse = await CustomAxios.get(
        `/v1/analytics/${guideID}/feedback?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      const feedbackCommentsResponse = await CustomAxios.get(
        `/v1/analytics/${guideID}/feedback/comments?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );
      let feedbackComments = feedbackCommentsResponse.data;
      let feedbackData = feedbackResponse.data;
      let data = response.data;
      if (!data.Activity) {
        data.Activity = [];
      }
      if (!data.Questions) {
        data.Questions = [];
      }

      if (!data.Recommendations) {
        data.Recommendations = [];
      }
      if (!data.Buyups) {
        data.Buyups = [];
      }

      let comments = [];

      if (feedbackComments) {
        comments = groupBy(feedbackComments, function (comment) {
          return comment.NpsNumber;
        });
      }
      let index = 0;

      if (!feedbackData) {
        data.Feedback = [];
      } else {
        let newFeedback = [];
        feedbackData = feedbackData.sort((a, b) => a.NpsNumber < b.NpsNumber);
        for (let i = 1; i < 6; i++) {
          if (!feedbackData[index] || i !== feedbackData[index].NpsNumber) {
            newFeedback.push({
              NpsNumber: i,
              Count: 0,
              Comments: comments[i] || [],
            });
          } else {
            newFeedback.push({
              ...feedbackData[index],
              Comments: comments[i] || [],
            });
            index = index + 1;
          }
        }

        let total = newFeedback.reduce((partialSum, b) => {
          return partialSum + b.Count;
        }, 0);

        for (let feedbackObj of newFeedback) {
          feedbackObj.percentage = Math.floor((feedbackObj.Count / total) * 100);
          feedbackObj.width = (feedbackObj.percentage / 100) * 600;
        }
        let colors = ['#25282D', '#36414C', '#66737F', '#9AA7B5', '#D1DAE3'];
        let colorIndex = 0;
        newFeedback = newFeedback.sort((a, b) => b.percentage - a.percentage);
        newFeedback[0].color = colors[colorIndex];

        if (newFeedback[0].percentage > newFeedback[1].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[1].color = colors[colorIndex];
        if (newFeedback[1].percentage > newFeedback[2].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[2].color = colors[colorIndex];
        if (newFeedback[2].percentage > newFeedback[3].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[3].color = colors[colorIndex];
        if (newFeedback[3].percentage > newFeedback[4].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[4].color = colors[colorIndex];

        data.Feedback = newFeedback;
      }
      const guide = CustomAxios.get(`/v2/course/${guideID}`);
      const guidePages = CustomAxios.get(`/v2/course/${guideID}/pages`);
      const responses = await fulfilledPromises('allSettled', {
        guide,
        guidePages,
      });
      let pages = responses?.guidePages?.data;
      if (pages.length > 0) {
        pages = sortBy(pages, ['Order']);
      } else {
        pages = [
          {
            Type: 'regular',
            Content: null,
            Name: 'Welcome',
            Order: 1,
            FrontendID: '_' + Math.random().toString(36).substr(2, 9),
            InsurancePlans: [],
            Question: null,
            IsLocked: false,
            TemplateID: '00000000-0000-0000-0000-000000000000',
          },
        ];
      }
      setGuide(responses?.guide?.data);
      setEligibleEmployee(responses.guide?.data?.EligibleEmployees || selectedBusiness?.EmployeeCount || 0);
      fetchPageViewsStatistics(pages, guideID, start, end);

      const conversionResponse = await CustomAxios.get(
        `/v1/analytics/conversion/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      data.conversion = conversionResponse.data;
      if (!data.conversion.MedicalRecommendations) {
        data.conversion.MedicalRecommendations = [];
      }

      if (enrollmentConfidenceResponse) {
        data.enrollmentConfidence = enrollmentConfidenceResponse.data || [];
      }
      setAnalytics({ ...data });
      setLoading(false);
      window?.ChurnZero?.push(['trackEvent', 'Analytics Accessed', '', 1]);
    } catch (error) {
      setLoading(false);
      setAnalytics({
        Feedback: [],
        Buyups: [],
        Activity: [],
        Questions: [],
        Recommendations: [],
        Time: {
          TotalTime: 0,
        },
      });
    }
  };

  useEffect(() => {
    getAnalytics(courseId);
  }, [courseId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const refresh = (start, end) => {
    getAnalytics(courseId, start, end);
  };

  const handleTimeFrame = (timeframe) => {
    let days = 7;
    setTimeFrame(timeframe);
    if (timeframe === 'Last 7 days') {
      days = 7;
    }
    if (timeframe === 'Last 30 days') {
      days = 30;
    }
    if (timeframe === 'Last 90 days') {
      days = 90;
    }
    if (timeframe === 'Last Year') {
      days = 365;
    }
    if (timeframe === 'Open Enrollment') {
      if (guide.TargetingEnd && guide.TargetingStart) {
        const start = startOfDay(Dates.getDateFromISO(guide.TargetingStart));
        setStartDate(start);
        const end = endOfDay(Dates.getDateFromISO(guide.TargetingEnd));
        setEndDate(end);
        refresh(start, end);
      } else {
        openEnrollmentModal.toggle();
      }
      return;
    }
    openEnrollmentModal.hide();
    const start = startOfDay(subDays(new Date(), days));
    setStartDate(start);
    const end = endOfDay(new Date());
    setEndDate(end);
    refresh(start, end);
  };

  const timeOptions = useMemo(() => {
    let options = ['Last 7 days', 'Last 30 days', 'Last 90 days', 'Last Year', 'Custom'];
    if (guide?.Type === 'openenrollment') {
      options.unshift('Open Enrollment');
    }
    return options;
  }, [guide]);

  const countCommunicationByDay = () => {
    var groups = groupBy(analytics.GuideCommunications, function (LinkSent) {
      return format(startOfDay(new Date(LinkSent.created_at)), 'yyyy-MM-dd');
    });

    // Return an array of objects by day with a CommunicationMedium, and total count for that day
    let obj = [];
    const keys = Object.keys(groups);
    for (const key of keys) {
      let emailCount = 0;
      let textCount = 0;
      let slackCount = 0;
      let teamsCount = 0;
      let pdfQRCodeCount = 0;

      for (const item of groups[key]) {
        if (item.communication_medium === 'email') {
          emailCount += 1;
        }
        if (item.communication_medium === 'text') {
          textCount += 1;
        }
        if (item.communication_medium === 'slack') {
          slackCount += 1;
        }
        if (item.communication_medium === 'teams') {
          teamsCount += 1;
        }
      }

      obj.push({
        date: key,
        emailCount,
        textCount,
        slackCount,
        teamsCount,
        pdfQRCodeCount,
      });
    }

    return obj;
  };

  let communicationAnnotations = {};
  let lineCount = 1;

  for (const item of countCommunicationByDay()) {
    let labelContent = ['Sent'];
    if (item.emailCount > 0) {
      labelContent.push(item.emailCount + ' emails');
    }
    if (item.textCount > 0) {
      labelContent.push(item.textCount + ' texts');
    }
    if (item.slackCount > 0) {
      labelContent.push(item.slackCount + ' slack messages');
    }
    if (item.teamsCount > 0) {
      labelContent.push(item.teamsCount + ' teams messages');
    }
    communicationAnnotations['line' + lineCount] = {
      type: 'line',
      xMin: item.date,
      xMax: item.date,
      borderColor: colors.purple,
      borderWidth: 2,
      borderDash: [8, 4],
      label: {
        content: labelContent,
        position: 'end',
      },
      enter({ element }) {
        element.label.options.display = true;
        return true; // force update
      },
      leave({ element }) {
        element.label.options.display = false;
        return true;
      },
    };
    lineCount++;
  }

  const getMinDate = (formatting) => format(new Date(), formatting);
  const getMaxDate = (formatting) => format(new Date(), formatting);

  const customDateRangeFormat = useMemo(() => {
    const sameYear = getYear(startDate) === getYear(endDate);
    const sameMonth = getMonth(startDate) === getMonth(endDate);
    if (sameYear && sameMonth) {
      return format(startDate, 'MMM d') + ' - ' + format(endDate, 'd, yyyy');
    } else if (sameYear) {
      return format(startDate, 'MMM d') + ' - ' + format(endDate, 'MMM d, yyyy');
    } else {
      return format(startDate, 'MMM d, yyyy') + ' - ' + format(endDate, 'MMM d, yyyy');
    }
  }, [startDate, endDate]);

  const close = () => {
    history.push(`/${selectedBusiness.ID}/courses/${guide.ID}`);
  };

  return (
    <Modal full display={true}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        {!loading && (
          <Div
            css={css`
              ${flex('space-between')} width: 100%;
              height: 96px;
              padding: 24px 40px;
              border-bottom: 1px solid ${colors.gray[300]};
              background-color: white;
              z-index: 100000000;
            `}
          >
            <Div
              css={css`
                ${flex('left')}
              `}
            >
              {close && (
                <Button styles="icon" onClick={close}>
                  <ArrowLeft />
                </Button>
              )}
              <Text
                h2
                className={css`
                  max-width: 450px;
                  padding: 0 4px;
                `}
                ellipsis
              >
                {guide?.Name}
              </Text>
            </Div>
            <Div
              css={css`
                ${flex('aic')}
              `}
            >
              <Div
                css={css`
                  ${flex('right')}
                  transition: all .2s ease;
                `}
              >
                <div
                  className={css`
                    margin-right: ${px.lg};
                    padding: 5px 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    height: 44px;
                  `}
                >
                  <div
                    className={css`
                      color: ${colors.black};
                      font-feature-settings: 'clig' off, 'liga' off;
                      font-family: Roboto;
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 700;
                      border-bottom: 2px solid ${colors.black};
                      line-height: 24px; /* 100% */
                      cursor: pointer;
                    `}
                    onClick={() => employeeCountModal.toggle()}
                  >
                    {!eligibleEmployee ? 0 : eligibleEmployee.toLocaleString()}
                  </div>
                  <div
                    className={css`
                      color: ${colors.black};
                      font-feature-settings: 'clig' off, 'liga' off;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 32px; /* 200% */
                      letter-spacing: 0.15px;
                    `}
                  >
                    Employees
                  </div>
                  <Tooltip label="The amount of people this guide was distributed to. Usually the total amount of employees for this company. This number is used in calculations.">
                    <Info size={24} color={colors.gray[400]} />
                  </Tooltip>
                </div>
              </Div>
              {guide && (
                <div
                  className={css`
                    margin-right: 8px;
                  `}
                >
                  <Tooltip label="Share Analytics">
                    <IconButton onClick={() => shareModal.toggle()}>
                      <UploadSimple />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              <Div
                css={css`
                  ${flex('right')}
                  transition: all .2s ease;
                `}
              >
                {timeFrame !== 'Custom' && (
                  <div
                    className={css`
                      margin-right: ${px.lg};
                    `}
                  >
                    {timeOptions && (
                      <Select
                        className={classes.formControl}
                        id="timeframe-select"
                        variant="outlined"
                        value={timeFrame}
                        onChange={(e) => {
                          handleTimeFrame(e.target.value);
                        }}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        input={<OutlinedInput labelWidth={223} classes={outlinedInputClasses} />}
                      >
                        {timeOptions.map((opt) => {
                          if (opt === 'Open Enrollment' && open) {
                            return (
                              <MenuItem className={classes.menuStyle} value={opt} key={opt}>
                                <div
                                  className={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;
                                  `}
                                >
                                  <div>{opt}</div>
                                  <div
                                    className={css`
                                      color: ${colors.purple};
                                      font-feature-settings: 'clig' off, 'liga' off;
                                      /* Body - Dark */
                                      font-family: Roboto;
                                      font-size: 16px;
                                      font-style: normal;
                                      font-weight: 400;
                                      line-height: 24px; /* 150% */
                                      letter-spacing: 0.15px;
                                      cursor: pointer;
                                      &:hover {
                                        color: ${colors.black};
                                      }
                                    `}
                                    onClick={(e) => {
                                      openEnrollmentModal.toggle();
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem className={classes.menuStyle} value={opt}>
                              {opt}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                )}
                {timeFrame === 'Custom' && (
                  <Box
                    flex="right"
                    css={`
                      margin-right: 16px;
                    `}
                  >
                    <Dropdown
                      button={() => <Button link>{customDateRangeFormat}</Button>}
                      popper={{ placement: 'bottom-end' }}
                      onClose={() => refresh()}
                    >
                      <Box
                        css={`
                          width: 700px;
                          padding: 32px;
                          padding-bottom: 0;
                        `}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box
                          flex="space-between"
                          css={`
                            margin-bottom: 16px;
                          `}
                        >
                          <Text h4>Custom Range</Text>
                          <Button
                            styles="icon sm"
                            onClick={() => handleTimeFrame('Last 30 days')}
                            hoverLabel="Remove Custom Range"
                          >
                            <Trash size={24} />
                          </Button>
                        </Box>
                        <Dates.Range
                          start={startDate}
                          end={endDate}
                          maxDate={endOfDay(new Date())}
                          setRange={({ start, end }) => {
                            setStartDate(startOfDay(start));
                            if (end < start) {
                              setEndDate(endOfDay(start));
                            } else {
                              setEndDate(endOfDay(end));
                            }
                          }}
                        />
                      </Box>
                    </Dropdown>
                  </Box>
                )}
              </Div>
              <Div
                css={css`
                  ${flex('right')}
                  transition: all .2s ease;
                  overflow: hidden;
                  max-width: 250px;
                `}
              >
                {close && (
                  <Button
                    className={css`
                      ${animation('fadeIn', '.2s ease')}
                      padding-left: 24px;
                      padding-right: 24px;
                      padding-top: 10px;
                      padding-bottom: 10px;
                    `}
                    styles="secondary"
                    onClick={close}
                  >
                    Close
                  </Button>
                )}
              </Div>
            </Div>
          </Div>
        )}
        <div
          className={css`
            flex: 1;
            width: 100%;
            height: 100%;
            overflow: auto;
          `}
        >
          {loading ? (
            <div
              className={css`
                display: flex;
                justify-content: center;
                margin-top: 80px;
              `}
            >
              <BriteLoader />
            </div>
          ) : (
            <div
              className={css`
                max-width: 1200px;
                margin: 56px auto;
              `}
            >
              <EditorGuideAnalyticsTabs tab={tab} setTab={setTab} tabValues={tabValues} />
              {tab === tabValues.TRAFFIC_TAB && (
                <TrafficTab
                  {...{
                    analytics,
                    eligibleEmployee,
                    startDate,
                    endDate,
                    getMinDate,
                    getMaxDate,
                    chartLegendOptions,
                    communicationAnnotations,
                  }}
                />
              )}
              {tab === tabValues.ENGAGEMENT_TAB && (
                <EngagementTab {...{ loadingPageViewsData, startDate, pageViewsStatistics, pageDataRows }} />
              )}
              {tab === tabValues.CONVERSION_TAB && (
                <ConversionTab {...{ analytics, startDate, endDate, getMinDate, getMaxDate, chartLegendOptions }} />
              )}
            </div>
          )}
        </div>
      </div>
      {openEnrollmentModal.isOpen && (
        <OpenEnrollmentModal
          hideOpenEnrollment={() => openEnrollmentModal.hide()}
          handleTimeFrame={handleTimeFrame}
          guide={guide}
          setGuide={setGuide}
        />
      )}
      {employeeCountModal.isOpen && (
        <EmployeeCountModal
          hideEmployeeCount={() => employeeCountModal.hide()}
          guide={guide}
          eligibleEmployee={eligibleEmployee}
          setEligibleEmployee={setEligibleEmployee}
        />
      )}
      {shareModal.isOpen && <ShareModal guideId={guide.ID} onClose={() => shareModal.hide()} />}
    </Modal>
  );
};
