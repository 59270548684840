import { Text } from '../../shared/components';
import { colors } from '../../shared/styles';

export const LaunchTip = ({ title = '', text = '' }) => (
  <Text caption>
    <Text as="span" caption bold color={colors.black}>
      {title}
    </Text>{' '}
    {text}
  </Text>
);
