import { Box, BriteLoader, Button, Dropdown, Input, Modal, Text } from '../shared/components';
import Plansight from '../images/plansight.png';
import { colors } from '../shared/styles';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useForm } from '../shared/use-form';
import {
  plansightEmployersResource,
  plansightPlansResource,
  plansightTypesResource,
  useResource,
} from '../react-query';
import { useSearchParams } from '../shared/use-search-params';
import { useEffect, useMemo, useState } from 'react';
import { fetchResource } from '../react-query/use-resource';
import { PRODUCT_HEADERS } from './constants';
import { CarrierLogo } from '../products/carrier-logo';
import { container } from '../shared/shared-styles';
import { Badge } from '../shared/components/badge';
import { useParams } from 'react-router-dom';
import { CustomAxios } from '../redux/axios/axios';
import { getYear } from 'date-fns';

export const fulfilledPromises = async (type, promisesObject = {}) => {
  const list = Object.entries(promisesObject);
  const promises = await Promise[type](list.map(([_, promise]) => promise));
  return list.reduce((acc, [key], index) => {
    if (promises[index].status === 'fulfilled') {
      acc[key] = promises[index]?.value;
    } else {
      acc[key] = null;
    }
    return acc;
  }, {});
};

export const ImportPlansight = () => {
  const [step, setStep] = useState('search');

  const { packageId } = useParams();
  const { removeParam } = useSearchParams();

  const form = useForm({ companyName: '', planTypes: [] });
  const [debounced_companyName] = useDebounceValue(form?.values?.companyName, 500);

  const plansightEmployers = useResource(plansightEmployersResource, {
    transform: (data) => data?.Data,
    search: {
      name: debounced_companyName,
      limit: 100,
      skip: 0,
    },
  });

  // PLAN TYPES *** *** *** *** *** *** *** *** *** *** *** ***
  const plansightPlanTypes = useResource(plansightTypesResource, {
    transform: (data) => data?.Data?.filter((item) => item?.Importable),
    enabled: !!form?.values?.company?.id,
    params: {
      employerId: form?.values?.company?.id,
    },
    search: {
      limit: 100,
      skip: 0,
    },
  });

  const selectedPlanTypes = form?.values?.planTypes?.length ? form?.values?.planTypes : plansightPlanTypes?.data;
  const planTypesList = useMemo(() => {
    const planTypes = form?.values?.planTypes || [];
    if (planTypes.length === 0) {
      return 'Select Plan Types';
    }
    return planTypes
      .map((type) => plansightPlanTypes?.data?.find((item) => item?.PlansightType === type)?.Display)
      .join(', ');
  }, [form?.values?.planTypes]);

  const updatePlanTypes = (type) => {
    const planTypes = form?.values?.planTypes || [];
    const index = planTypes.indexOf(type);
    if (index > -1) {
      planTypes.splice(index, 1);
    } else {
      planTypes.push(type);
    }
    form.merge({ planTypes });
  };

  // *** *** *** *** *** *** *** *** *** *** *** *** *** *** ***
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filteredProducts = useMemo(() => {
    if (!form?.values?.year) {
      return products;
    }
    return products?.filter((item) => {
      return getYear(new Date(item?.Details?.EffectiveDate)) === form?.values?.year;
    });
  }, [products?.length, form?.values?.years]);

  const years = useMemo(() => {
    return products?.reduce((prev, item) => {
      const year = getYear(new Date(item?.Details?.EffectiveDate));
      if (isNaN(year) || !year) {
        return prev;
      }
      return [...new Set([...prev, year])];
    }, []);
  }, [products?.length]);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      let resources = {};
      const planTypes = form?.values?.planTypes?.length
        ? form?.values?.planTypes
        : plansightPlanTypes?.data?.map((item) => item?.PlansightType) || [];
      console.log(planTypes);
      for (const type of planTypes) {
        try {
          const data = fetchResource(plansightPlansResource, {
            params: {
              employerId: form?.values?.company?.id,
              planType: type,
            },
            search: {
              limit: 100,
            },
          });
          resources[type] = data;
        } catch {}
      }
      const results = await fulfilledPromises('allSettled', resources);
      const list = Object.values(results || {}).reduce((prev, item) => {
        return [...prev, ...item?.Data];
      }, []);
      setProducts(list);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (form?.values?.company?.id && selectedPlanTypes?.length) {
      handleSearch();
    }
  }, [form?.values?.company?.id, selectedPlanTypes]);

  const toggleSelectedProducts = (product) => {
    const selected = [...selectedProducts];
    const index = selected.map((item) => item?.ID).indexOf(product?.ID);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(product);
    }
    setSelectedProducts([...selected]);
  };

  const handleCancel = () => {
    if (step === 'search') {
      removeParam('setup');
    } else {
      setStep('search');
    }
  };

  const createPlan = async (product) => {
    try {
      const { ID, ...rest } = product;
      const response = await CustomAxios.post(`v2/product`, rest);
      return CustomAxios.put(`/v1/benefitspackage/${packageId}/product/${response.data.ID}`);
    } catch {}
  };

  const createPlans = async () => {
    let list = [];
    for (const product of selectedProducts) {
      list.push(createPlan(product));
    }
    await Promise.allSettled(list);
    removeParam('setup');
  };

  return (
    <Modal display={true} onClose={() => {}} full>
      <Box
        css={`
          width: 100vw;
          height: 100vh;
        `}
      >
        <Box
          flex="space-between"
          css={`
            height: 100px;
            width: 100vw;
            padding: 32px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h1>Import From Plansight</Text>
          <Box
            flex="right"
            css={`
              gap: 16px;
            `}
          >
            <Button secondary onClick={handleCancel}>
              {step === 'search' ? 'Cancel' : 'Back'}
            </Button>

            <Button
              css={`
                position: relative;
              `}
              onClick={() => (step === 'search' ? setStep('review') : createPlans())}
              disabled={!selectedProducts?.length}
            >
              {step === 'search' ? 'Review' : 'Import'}{' '}
              {selectedProducts?.length > 0 ? <Badge>{selectedProducts?.length}</Badge> : null}
            </Button>
          </Box>
        </Box>
        {step === 'search' ? (
          <>
            <Box
              css={`
                display: grid;
                gap: 24px;
                margin: 32px auto;
                max-width: 1000px;
                border-radius: 16px;
                padding: 32px;
                background-color: ${colors.gray[100]};
              `}
            >
              <img src={Plansight} alt="plansight-logo" />
              <Text h2>Search for your plans</Text>
              <Box
                flex="left end"
                css={`
                  gap: 16px;
                `}
              >
                <Box
                  css={`
                    display: grid;
                    gap: 8px;
                  `}
                >
                  <Text label bold>
                    Company Name
                  </Text>
                  <Dropdown
                    css={`
                      border: 1px solid ${colors.gray[300]};
                      padding: 0;
                      cursor: pointer;
                    `}
                    button={({ open }) => (
                      <Box
                        flex="space-between"
                        css={`
                          width: 300px;
                          svg {
                            margin-right: 8px;
                          }
                        `}
                      >
                        <Input
                          css={`
                            flex-grow: 1;
                            border: none;
                            outline: none;
                            :hover {
                              border: none;
                              outline: none;
                            }
                          `}
                          autocomplete="off"
                          {...form?.getProperty('companyName')}
                        />
                        {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
                      </Box>
                    )}
                  >
                    <Box
                      css={`
                        min-width: 300px;
                        min-height: 48px;
                      `}
                    >
                      {plansightEmployers?.query?.isLoading ? (
                        <BriteLoader size={64} overlay={false} />
                      ) : (
                        plansightEmployers?.data?.map((item) => (
                          <Box option onClick={() => form.merge({ company: item, companyName: item?.name })}>
                            {item?.name}
                          </Box>
                        ))
                      )}
                    </Box>
                  </Dropdown>
                </Box>

                <Box
                  css={`
                    display: grid;
                    gap: 8px;
                  `}
                >
                  <Text label bold>
                    Plan Types
                  </Text>
                  <Dropdown
                    disabled={!form?.values?.company?.id || !plansightPlanTypes?.data?.length}
                    css={`
                      border-radius: 8px;
                      border: 1px solid ${colors.gray[300]};
                      height: 48px;
                      width: 300px;
                      padding: 8px;
                      cursor: pointer;
                    `}
                    flex="center"
                    button={({ open }) => (
                      <Box
                        flex="space-between"
                        css={`
                          width: 100%;
                        `}
                      >
                        <Text label>{planTypesList}</Text>
                        {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
                      </Box>
                    )}
                  >
                    <Box
                      css={`
                        width: 300px;
                      `}
                    >
                      {plansightPlanTypes?.data?.map((item) => (
                        <Box option onClick={() => updatePlanTypes(item?.PlansightType)}>
                          {item?.Display}
                        </Box>
                      ))}
                    </Box>
                  </Dropdown>
                </Box>

                <Box
                  css={`
                    display: grid;
                    gap: 8px;
                  `}
                >
                  <Text label bold>
                    Plan Year
                  </Text>
                  <Dropdown
                    disabled={!form?.values?.company?.id || !years?.length}
                    css={`
                      border-radius: 8px;
                      border: 1px solid ${colors.gray[300]};
                      height: 48px;
                      width: 300px;
                      padding: 8px;
                      cursor: pointer;
                    `}
                    flex="center"
                    button={({ open }) => (
                      <Box
                        flex="space-between"
                        css={`
                          width: 100%;
                        `}
                      >
                        <Text label>{form?.values?.year || 'Select Year'}</Text>
                        {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
                      </Box>
                    )}
                  >
                    <Box
                      css={`
                        width: 300px;
                      `}
                    >
                      <Box option onClick={() => form?.merge({ year: '' })}>
                        None
                      </Box>
                      {years?.map((year) => (
                        <Box option onClick={() => form?.merge({ year })}>
                          {year}
                        </Box>
                      ))}
                    </Box>
                  </Dropdown>
                </Box>
              </Box>
              <Box flex="right">
                <Button
                  css={`
                    width: 250px;
                  `}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box>
            </Box>
            {isLoading ? (
              <Box flex="center">
                <BriteLoader overlay={false} />
              </Box>
            ) : (
              <Box
                css={`
                  display: grid;
                  gap: 16px;
                  margin: 32px auto;
                  max-width: 1000px;
                  border-radius: 16px;
                  padding: 32px 0;
                `}
              >
                <Box flex="space-between">
                  <Text h4>{products?.length} plans found</Text>
                  <Text>Don't see your plan?</Text>
                </Box>
                {filteredProducts?.map((product) => (
                  <Box
                    css={`
                      border: 1px solid ${colors.gray[300]};
                      ${selectedProducts.map((item) => item?.ID).includes(product?.ID)
                        ? `
                          outline: 2px solid ${colors.black};
                          border: 1px solid transparent;
                        `
                        : ''}
                      border-radius: 8px;
                      padding: 0 16px;
                      ${container.hover}
                    `}
                    flex="space-between"
                    onClick={() => toggleSelectedProducts(product)}
                  >
                    <Box>
                      <Text label>{product?.ProductName}</Text>
                      <Text>{PRODUCT_HEADERS[product?.Type]}</Text>
                    </Box>

                    <CarrierLogo
                      maxWidth="120px"
                      carrierID={product?.ProviderID}
                      planCarrierName={product?.CarrierName}
                      position="center"
                    />
                  </Box>
                ))}
              </Box>
            )}
          </>
        ) : step === 'review' ? (
          <Box
            css={`
              display: grid;
              gap: 24px;
              margin: 32px auto;
              max-width: 1000px;
              padding: 32px;
            `}
          >
            <Text h2>Review your selected plans.</Text>
            <Box
              css={`
                border-top: 1px solid ${colors.gray[300]};
              `}
            >
              {selectedProducts?.map((item) => (
                <Box
                  flex="space-between"
                  css={`
                    padding: 24px;
                    border-bottom: 1px solid ${colors.gray[300]};
                  `}
                >
                  <Box>
                    <Text label>{item?.ProductName}</Text>
                    <Text>{PRODUCT_HEADERS[item?.Type]}</Text>
                  </Box>
                  <Button
                    text
                    css={`
                      color: ${colors.purple};
                      font-weight: normal;
                    `}
                    onClick={() => toggleSelectedProducts(item)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};
