import { css } from '@emotion/css';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { HandWaving, UploadSimple, X } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '../../../common/hooks/useModal';
import { FormatPhoneNumber } from '../../../lib/communications/formatting';
import { CustomAxios } from '../../../redux/axios/axios';
import { Button, Modal, Text } from '../../../shared/components';
import { MessageTemplatesModal } from './MessageTemplatesModal';
import { ImportPhoneNumbersModal } from './ImportPhoneNumbersModal';
import { LaunchSuccessTextMessageModal } from './LaunchSuccessTextMessageModal';

export const TextMessageModal = ({ hide = () => {}, guide = {}, initialMessage = '' }) => {
  const [loading, setLoading] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState(new Set());
  const [sentToPhoneNumbers, setSentToPhoneNumbers] = useState([]);
  const [messageBody, setMessageBody] = useState(initialMessage);
  const [textingErrors, setTextingErrors] = useState([]);
  const [textAgree, setTextAgree] = useState(false);
  const [textAgreeAgain, setTextAgreeAgain] = useState(false);

  const messageTemplatesModal = useModal();
  const importPhoneNumbersModal = useModal();
  const launchSuccessModal = useModal();

  const errorMessages = {
    InvalidCountryCode: "Local number appears invalid. International numbers require '+' before the country code.",
    InvalidPhoneNumber:
      'Number appears invalid. May not be textable due to incorrect digits or because it is a landline.',
  };
  const textMessageMaxLength = 450;

  const sendTextMessage = async () => {
    if (messageBody.length > textMessageMaxLength) {
      toast.error(`Message body has too many characters.`);
      return;
    }
    setLoading(true);

    const nums = [...phoneNumbers].map((n) => ({ PhoneNumber: n }));
    if (currentPhoneNumber) {
      nums.push({ PhoneNumber: currentPhoneNumber });
    }

    try {
      if (nums.length > 0) {
        const body = {
          Body: messageBody,
          Recipients: nums,
          CourseID: guide.ID,
        };

        const response = await CustomAxios.post(`/v1/sms/send`, body);
        if (response.data.SubmissionErrors && response.data.SubmissionErrors.length > 0) {
          setTextingErrors(response.data.SubmissionErrors);
        } else {
          setTextingErrors([]);
        }
      }

      setLoading(false);
      launchSuccessModal.toggle();
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (e) {
      setLoading(false);
      toast.error(`Failed to send text message.`);
      console.log(e);
    }
  };

  if (launchSuccessModal.isOpen) {
    return (
      <LaunchSuccessTextMessageModal
        hide={() => {
          hide();
          launchSuccessModal.hide();
        }}
        sentToPhoneNumbers={sentToPhoneNumbers}
        textingErrors={textingErrors}
      />
    );
  }

  return (
    <Modal
      full
      display
      onClose={() => {
        hide();
      }}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <Text h1>Text Guide Link</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button styles="icon" hoverLabel="Message Templates Help" onClick={() => messageTemplatesModal.toggle()}>
            <HandWaving />
          </Button>
          <Button
            secondary
            onClick={() => {
              hide();
              setSentToPhoneNumbers([...phoneNumbers]);
              setPhoneNumbers(new Set());
              setCurrentPhoneNumber('');
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !textAgree ||
              !textAgreeAgain ||
              loading ||
              messageBody.length > textMessageMaxLength ||
              phoneNumbers.size === 0
            }
            onClick={() => {
              sendTextMessage();
            }}
          >
            Send Message
          </Button>
        </div>
      </div>

      <div
        className={css`
          padding: 48px;
          max-width: 700px;
          margin: 0 auto;
          overflow: auto;
        `}
      >
        <div
          className={css`
            margin-bottom: 12px;
          `}
        >
          <div
            className={css`
              padding-bottom: 8px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text body>Phone Numbers {phoneNumbers.size > 0 && <span>({phoneNumbers.size})</span>}</Text>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              className={css`
                flex-grow: 1;
                width: 100%;
                margin-right: 16px;
              `}
            >
              <TextField
                style={{ width: '100%' }}
                variant="outlined"
                placeholder="Enter Phone Number"
                value={currentPhoneNumber}
                onChange={(e) => setCurrentPhoneNumber(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button
                      naked
                      onClick={() => {
                        if (currentPhoneNumber) {
                          // Check that the number is a mobile number and is valid.
                          if (isValidPhoneNumber(currentPhoneNumber) || isValidPhoneNumber('+1' + currentPhoneNumber)) {
                            // The backend will handle adding the US country code, if it is missing.
                            setPhoneNumbers((phoneNumbers) =>
                              new Set(phoneNumbers).add(currentPhoneNumber.replace(/[^\d+]/g, ''))
                            );
                            setCurrentPhoneNumber('');
                          } else if (isValidPhoneNumber('+' + currentPhoneNumber)) {
                            toast.error(errorMessages.InvalidCountryCode);
                          } else {
                            toast.error(errorMessages.InvalidPhoneNumber);
                          }
                        }
                      }}
                    >
                      Add
                    </Button>
                  ),
                }}
              />
            </div>
            <div>
              <Button
                secondary
                onClick={() => {
                  importPhoneNumbersModal.toggle();
                }}
              >
                <UploadSimple size={24} style={{ height: '40px' }} />
                Import
              </Button>
            </div>
          </div>
          <div
            className={css`
              max-height: 250px;
              overflow: auto;
              margin-bottom: 32px;
              margin-top: 8px;
              background: ${phoneNumbers.length > 0 ? '#f5f7fa' : 'fff'};
              border-radius: 8px;
              padding: ${phoneNumbers.length > 0 ? '8px' : '0px'};
            `}
          >
            {phoneNumbers &&
              [...phoneNumbers].map((phoneNumber) => {
                return (
                  <div
                    key={phoneNumber}
                    className={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}
                  >
                    <div>{FormatPhoneNumber(phoneNumber)}</div>
                    <Button
                      styles="icon"
                      onClick={() => {
                        const updatedPhoneNumbers = new Set(phoneNumbers);
                        updatedPhoneNumbers.delete(phoneNumber);
                        setPhoneNumbers(updatedPhoneNumbers);
                      }}
                    >
                      <X />
                    </Button>
                  </div>
                );
              })}
          </div>
          <div
            className={css`
              padding-bottom: 8px;
              padding-top: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text label>Message</Text>
            <Text
              label
              css={css`
                color: ${messageBody.length > textMessageMaxLength ? 'red' : 'inherit'};
              `}
            >
              {messageBody.length} / {textMessageMaxLength}
            </Text>
          </div>
          <TextField
            variant="outlined"
            multiline
            rows={4}
            style={{ width: '100%' }}
            value={messageBody}
            onChange={(e) => {
              setMessageBody(e.target.value);
            }}
          />
          <div
            className={css`
              margin-top: 4px;
              margin-bottom: 16px;
            `}
          >
            <Text helper>Guide link will be added to the end of the message automatically.</Text>
          </div>
          <FormControlLabel
            style={{ fontSize: '12px', marginBottom: '12px' }}
            control={
              <Checkbox
                checked={textAgree}
                onChange={(e) => {
                  setTextAgree(e.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <div
                className={css`
                  font-size: 12px;
                  padding-top: 8px;
                `}
              >
                By clicking here, I represent that I have the authorization of the Company to send the above text
                message and use such images and information included, and that Brite bears no responsibility for the
                content of the text message, including the content of any external links.
              </div>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={textAgreeAgain}
                onChange={(e) => {
                  setTextAgreeAgain(e.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <div
                className={css`
                  font-size: 12px;
                `}
              >
                I have read and understood the terms and conditions of the SAAS Agreement, including but not limited to
                compliance provisions under the Telephone Consumer Protection Act.
              </div>
            }
          />
        </div>
      </div>
      {messageTemplatesModal.isOpen && (
        <MessageTemplatesModal hideMessageTemplates={() => messageTemplatesModal.hide()} guide={guide} />
      )}
      {importPhoneNumbersModal.isOpen && (
        <ImportPhoneNumbersModal
          hideImportPhoneNumbers={() => importPhoneNumbersModal.hide()}
          errorMessages={errorMessages}
          phoneNumbers={phoneNumbers}
          setPhoneNumbers={setPhoneNumbers}
        />
      )}
    </Modal>
  );
};
