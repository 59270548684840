import { css } from '@emotion/css';
import { useContext, useMemo } from 'react';
import { Div } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Component } from '.';
import { ColumnResizer, Droppable, droppableContainer } from '../utility-components';
import { EditorDetailsContext } from '../provider/editor-detail-provider';

const columnContainer = ({ width, drag, disableTileDrag, isMultiHovering, hasComponentList }) => css`
  position: relative;
  display: flex;

  ${isMultiHovering &&
  `
    .multi-select {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      outline: 2px solid ${colors.purple};
      outline-offset: -1px;
      border-radius: 8px;
      z-index: 100;
    }
  `}

  .list-container {
    position: relative;
    height: 100%;
    ${!drag?.isDragging &&
    `
      :hover {
        object-fit: cover;
        border-radius: 8px;
        outline-offset: -2px;
      }
    `}
  }

  ${hasComponentList
    ? `
    justify-content: stretch;
    flex-direction: column;
  `
    : `align-items: stretch;`}

  .selected {
    object-fit: cover;
    border-radius: 8px;
    outline: 2px solid ${colors.black};
    outline-offset: -1px;
  }

  width: ${width}%;

  ${droppableContainer(drag)}
`;

export const Column = ({ editor, element, location, column, rowRef }) => {
  const [rowIdx, colIdx] = location;

  const totalColumns = column.columns?.length;
  const {
    state: { multiSelect, disableTileDrag, drag = {} },
  } = editor;

  const {
    editorDetails: { containerBounds = {} },
  } = useContext(EditorDetailsContext);

  const width = useMemo(() => {
    const hasWidth = 'width' in element;
    const value = hasWidth ? element.width || 1 : 1 / column.columns?.length;
    let width = (value * 100).toFixed(2);
    return column.isMobileStack ? 100 : width;
  }, [column.isMobileStack, element?.width, column.columns?.length]);

  const hasComponentList = element?.type === 'list';
  const displayResize =
    !drag?.isDragging && (column.mouseEntered || column.resizeIndex !== null) && colIdx !== totalColumns - 1;
  const isMultiHovering =
    multiSelect?.isSelecting &&
    rowIdx >= multiSelect?.start &&
    rowIdx <= multiSelect?.end &&
    multiSelect?.hoverIndices?.[1] === location?.[1];

  const componentProps = {
    editor,
    element,
    location,
    // Put all other props into component:
    component: {
      totalColumns: column.columns?.length,
    },
  };

  return (
    <Div
      className={columnContainer({
        width,
        drag,
        disableTileDrag,
        hasComponentList,
        isMultiHovering,
      })}
    >
      <div className="multi-select" />
      {displayResize && (
        <ColumnResizer
          rowWidth={containerBounds?.editor?.width}
          setResizeIndex={column.setResizeIndex}
          resizeIndex={column.resizeIndex}
          columns={column.columns}
          rowIdx={rowIdx}
          index={colIdx}
        />
      )}

      {hasComponentList ? (
        element?.list?.map((data, idx) => {
          if (data === null) {
            return null;
          }
          const nextIndices = [...location, idx];
          return (
            <div className="list-container" key={`${editor?.state?.syncRenderState}-${nextIndices.toString()}`}>
              {drag?.isDragging && drag?.action !== 'multi-move' && (
                <>
                  <Droppable type="top" indices={nextIndices} />
                  <Droppable type="bottom" indices={nextIndices} />
                </>
              )}
              <Component {...componentProps} element={data} location={[rowIdx, colIdx, idx]} rowRef={rowRef} />
            </div>
          );
        })
      ) : (
        <>
          <Component
            {...componentProps}
            key={`${editor?.state?.syncRenderState}-${location?.toString()}`}
            rowRef={rowRef}
          />
          {drag?.isDragging && drag?.action !== 'multi-move' && (
            <>
              <Droppable type="bottom" indices={location} />
              <Droppable type="top" indices={location} />
            </>
          )}
        </>
      )}
      {drag?.isDragging && drag?.action !== 'multi-move' && (
        <>
          <Droppable type="left" indices={location} />
          <Droppable type="right" indices={location} />
        </>
      )}
    </Div>
  );
};
