import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { DotsThree } from '@phosphor-icons/react';
import { Button } from '../shared/components';
import { Tooltip } from '../common/components/Tooltip';

export function FolderOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip label="Folder Actions">
        <Button onClick={handleClick} styles="icon sm">
          <DotsThree />
        </Button>
      </Tooltip>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={(e) => {
            props.rename(e);
            handleClose();
          }}
        >
          Rename
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            props.remove(e);
            handleClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
