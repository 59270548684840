import { css, cx } from '@emotion/css';
import { Copy } from '@phosphor-icons/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AutoSizer, MultiGrid, ScrollSync } from 'react-virtualized';
import { Button, Div, Text } from '../../../shared/components';
import { BriteLoader } from '../../../shared/components/brite-loader';
import { animation, container, flex, scrollbar } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { decode, mediaModifiers, ROW_HEIGHT } from '../media-utils';
import { productUtils } from '../../../products/configs/product-utils';
import { aiSuggestions, useRequests } from '../../../react-query';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Tooltip } from '../../../common/components/Tooltip';
import { buildHistory } from '../useMedia';

const cellContainer = css`
  ${animation('fadeIn', '.5s linear')}
  position: relative;
  width: 100%;
  height: max-content;
  padding: 0 8px;
  border-bottom: 1px solid ${colors.gray[300]};
  border-right: 1px solid ${colors.gray[300]};
  user-select: none;
  overflow: auto;
  transition: background 0.1s ease;
  background-color: white;
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: ${colors.gray[200]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray[400]};
    border-radius: 8px;
  }
`;

const spreadsheetContainerStyles = (columnWidths) => css`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  pointer-events: all;
  max-width: ${columnWidths + 8}px;
  margin: 0 auto;
  border-left: 1px solid ${colors.gray[300]};
  border-right: 1px solid ${colors.gray[300]};
  ${scrollbar.hide}

  .cell-overlay {
    pointer-events: none;
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .active-cell {
    border: 4px solid ${colors.purple};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .setup-focus {
    background-color: ${colors.lighterPurple}AA;
  }

  .tracking {
    transition: background 0.2s ease;
    background-color: ${colors.gray[100]};
  }

  .active-tracking {
    transition: background 0.2s ease;
    background-color: ${colors.lighterPurple};
  }

  .last-cell {
    transition: outline 0.2s ease;
    outline: 4px solid ${colors.purple}44;
    outline-offset: -4px;
  }
`;

function delay(t, val) {
  return new Promise((resolve) => setTimeout(resolve, t, val));
}
// TODO: Update overscanCellCount to be total merge length (minimum of like 5)
// **************************************************
// *** Control how many
// **************************************************
function overscanIndicesGetter({
  cellCount, // Number of rows or columns in the current axis
  overscanCellsCount, // Maximum number of cells to over-render in either direction
  startIndex, // Begin of range of visible cells
  stopIndex, // End of range of visible cells
}) {
  return {
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
    overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
  };
}
export const Spreadsheet = ({ media, state, focus, isLoading, displayAutoFill }) => {
  const { layout } = state;

  const aiAutofill = useFeatureFlagPayload('ai-autofill');

  const fieldHistoryRequests = useRequests(state?.fieldHistory);
  const aiSuggestionsRequests = useRequests(state?.aiSuggestions);

  const gridRef = useRef();

  const [contextMenu, setContextMenu] = useState([]);
  const [lastRenderedSheet, setLastRenderedSheet] = useState('');

  const dataByPropertyChain = useMemo(() => {
    return layout?.Layout?.Sections?.reduce((prev, item) => {
      return item?.Fields?.reduce((p, field) => {
        if (!field?.PropertyChain) {
          return p;
        }
        return {
          ...p,
          [field.PropertyChain]: field,
        };
      }, prev);
    }, {});
  }, [layout]);

  const isCurrentSheet =
    media?.state?.focus?.location?.fileId === media?.state?.mediaId &&
    media?.state?.focus?.location?.sheet === media?.state?.activeSheet;

  const scrollToCellCoords = isCurrentSheet ? decode(media?.state?.focus?.location?.cellRef) : { c: null, r: null };

  const isSetupFocus = media?.state?.focusState === 'setup-focus';
  const isFocused = media?.state?.focusState === 'focused';

  const getWorksheet = () => {
    if (focus.columns.length && isFocused) {
      return media?.state?.worksheet?.reduce((prevRows, item, rowIdx) => {
        const cols = item?.reduce((prevCol, cell, colIdx) => {
          if (colIdx === 0) {
            return [...prevCol, cell];
          }
          if (focus.columns.includes(colIdx)) {
            return [...prevCol, cell];
          }
          return prevCol;
        }, []);
        return [...prevRows, cols];
      }, []);
    }
    return media?.state?.worksheet;
  };

  const [delayedScrollUpdate, setDelayedScrollUpdate] = useState('');

  const delayedScroll = async () => {
    // Multi-Grid manages scrolling for us which is nifty,
    // except when we need to scroll back to a cell we've already
    // scrolled to (since the value hasn't changed, so we add
    // this delay so there's 10ms change in the scrolled cell.
    await delay(5);
    setDelayedScrollUpdate(media?.state?.focus?.updatedAt);
  };

  useEffect(() => {
    delayedScroll();
  }, [media?.state?.focus?.updatedAt]);

  const isSynced = !!media?.state?.focus?.action && media?.state?.focus?.updatedAt === delayedScrollUpdate;
  const scrollToColumn = isSynced && media?.state?.focus?.location?.cellRef ? scrollToCellCoords.c + 1 : undefined;
  const scrollToRow = isSynced && media?.state?.focus?.location?.cellRef ? scrollToCellCoords.r + 2 : undefined;

  const addColumn = (columnIndex) => {
    focus.setColumns((cols) => {
      if (cols.includes(columnIndex)) {
        return cols.filter((item) => item !== columnIndex);
      }
      return [...cols, columnIndex];
    });
  };

  useEffect(() => {
    if (gridRef?.current && (media?.state?.focusState?.state !== 'setup-focus' || focus.columns?.length)) {
      mediaModifiers.refreshSheet(media);
    }
  }, [media?.state?.focusState]);

  const dismissSuggestion = async () => {
    const suggestion = media?.state?.focus?.source;
    try {
      mediaModifiers.setFocus(media, {
        ...media?.state?.focus,
        sourceType: '',
      });
      const putSuggestions = aiSuggestions.utils.getPut(suggestion?.id, {
        ...suggestion,
        status: 'dismissed',
      });
      await aiSuggestionsRequests.put({
        ...putSuggestions,
        optimstic: (old) => old?.filter((item) => item?.id !== suggestion?.id),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const acceptSuggestion = (cell) => {
    const suggestion = media?.state?.focus?.source;
    productUtils.updateHistoryFromSuggestion(state, suggestion, {
      fieldHistory: fieldHistoryRequests,
      aiSuggestions: aiSuggestionsRequests,
    });
    if (suggestion?.property_chain === 'ProviderID') {
      state?.dispatch({
        type: 'set-search-term',
        data: {
          propertyChain: suggestion?.property_chain,
          value: suggestion?.suggested_value,
        },
      });
    }
    const moveToNextProperty = suggestion?.property_chain !== 'ProviderID';
    mediaModifiers.acceptInput(media, {
      value: suggestion?.suggested_value,
      propertyChain: suggestion?.property_chain,
      fieldType: state?.fieldsObject?.[suggestion?.property_chain]?.Type,
    });
    mediaModifiers.selectCell(media, cell, moveToNextProperty);
  };

  const cellToFieldHistoryMap = useMemo(() => {
    return buildHistory(media, state?.fieldHistory?.data);
  }, [
    state?.fieldHistory?.query?.isLoading,
    state?.fieldHistory?.data?.length,
    media?.state?.currentPropertyChain,
    state?.productId,
    media?.state?.mediaId,
    media?.state?.activeSheet,
  ]);
  // TODO: --------------------------------------------------
  // useMemo helped optimize the spreadsheet but may not be
  // needed.
  // return useMemo(() => {
  if (isLoading || media?.state?.isParsingWorksheet) {
    return null;
  }

  const worksheet = getWorksheet();
  const rowCount = worksheet?.length;
  const columnCount = worksheet?.[0]?.length;
  const columnWidths = worksheet?.[0]?.map(({ columnWidth }) => columnWidth);
  const cellRenderer = ({ rowIndex, columnIndex, key, style }) => {
    const cell = worksheet?.[rowIndex]?.[columnIndex];
    const columnWidth = columnWidths?.[columnIndex];
    const hasFocusSetup = isSetupFocus && focus.columns.includes(columnIndex);

    if (rowIndex === 0) {
      return (
        <Div
          key={key}
          style={{
            ...style,
            width: `${columnWidth}px`,
          }}
          css={css`
            ${flex('center')}
            ${isFocused && columnIndex > 0
              ? `
              @keyframes eye-catching-focus {
                from {
                  background-color: ${colors.purple};
                }
                to {
                  background-color: ${colors.gray[100]};
                }
              }
              animation: eye-catching-focus 1s ease forwards;
            `
              : `background-color: ${colors.gray[100]};`}

            border-right: 1px solid ${colors.gray[300]};
            border-bottom: 1px solid ${colors.gray[300]};
            transition: background-color 0.2s ease;
            ${isSetupFocus
              ? `
                border-top: 3px solid ${colors.purple};
                border-bottom: 3px solid ${colors.purple};
                :hover {
                  background-color: ${colors.gray[300]};
                  cursor: pointer;
                }
                ${
                  focus.columns.includes(columnIndex)
                    ? `
                  background-color: ${colors.purple};
                  :hover {
                    background-color: ${colors.purple};
                  }
                `
                    : ''
                }
              `
              : ''}
          `}
          onClick={() => (isSetupFocus ? addColumn(columnIndex) : null)}
        >
          <Text bold color={hasFocusSetup ? 'white' : ''}>
            {cell.ref}
          </Text>
        </Div>
      );
    } else if (columnIndex === 0) {
      return (
        <Div
          key={key}
          style={style}
          css={css`
            ${flex('center')}
            background-color: ${colors.gray[100]};
            border-right: 1px solid ${colors.gray[300]};
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text bold>{cell.index}</Text>
        </Div>
      );
    }

    const isActive = isCurrentSheet && media?.state?.focus?.location?.cellRef === cell?.ref;

    const selectedCell = isActive ? 'active-cell' : '';
    const cellHistory = cellToFieldHistoryMap?.[cell?.ref] || {};

    const trackCell =
      !hasFocusSetup && cellHistory?.isMapped ? (cellHistory?.isActiveProduct ? 'active-tracking' : 'tracking') : '';

    const propertyChain =
      cellHistory?.isMapped && cellHistory?.isActiveProduct && dataByPropertyChain
        ? dataByPropertyChain[cellHistory?.recentPropertyChain]?.DisplayValue
        : '';

    let cellWidth = columnWidth;
    if (cell?.isMerged && !cell?.merge?.isHidden) {
      for (let idx = 1; idx <= cell?.merge?.c; idx++) {
        if (columnIndex + idx < worksheet?.[0]?.length) {
          cellWidth += columnWidths?.[columnIndex + idx];
        }
      }
    }

    const cellWidthStyle = css`
      min-width: ${cellWidth}px;
      max-width: ${cellWidth}px;
      width: ${cellWidth}px;
    `;

    const handleContextMenuCell = (e) => {
      e.preventDefault();
      const bounds = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;
      setContextMenu([x, y, cell?.ref]);
    };

    const handleClickCell = (shouldMoveNextField = false) => {
      const value = cell?.value;
      mediaModifiers.acceptInput(media, {
        value,
        propertyChain: media?.state?.currentPropertyChain,
        fieldType: state?.fieldsObject?.[media?.state?.currentPropertyChain]?.Type,
      });
      mediaModifiers.selectCell(media, cell, shouldMoveNextField);
    };

    const cellEventProps = contextMenu?.length
      ? {}
      : {
          onClick: () => handleClickCell(),
          onDoubleClick: () => handleClickCell(true),
        };

    return (
      <Div
        key={key}
        style={{
          ...style,
          width: `${columnWidth}px`,
        }}
        className={cx(
          cell.merge.isHidden
            ? css`
                visible: hidden;
                pointer-events: none;
              `
            : '',
          isSetupFocus || media?.state?.overlay?.display
            ? css`
                pointer-events: none;
              `
            : ''
        )}
      >
        {aiAutofill?.value && displayAutoFill && isActive && media?.state?.focus?.sourceType === 'suggestion' ? (
          <Div
            className={cx(css`
              position: absolute;
              top: calc(100% + 8px);
              right: 0;
              z-index: 100000;
              border: 2px solid ${colors.black};
              padding: 8px 16px;
              border-radius: 8px;
              background-color: white;
              pointer-events: all;
              ${flex('left')}
            `)}
          >
            <Button
              small
              css={`
                background: ${colors.briteGradient};
                text-transform: none;
              `}
              onClick={() => acceptSuggestion(cell)}
            >
              Accept
            </Button>
            <Button
              secondary
              small
              css={`
                text-transform: none;
                margin-left: 16px;
              `}
              onClick={dismissSuggestion}
            >
              Dismiss
            </Button>
          </Div>
        ) : null}
        {cell.merge.isHidden ? null : (
          <>
            <Tooltip label={propertyChain}>
              <Div
                // ****************************************************
                // This DIV has to play nicely with the virtualization.
                // Use .cell-overlay to style cells.
                css={cx(
                  cell.style,
                  cellWidthStyle,
                  css`
                    transition: background-color 0.5s ease;
                    background-color: white;
                    ${hasFocusSetup ? `background-color: ${colors.lighterPurple}A0;` : ''}
                  `
                )}
                className={cx(trackCell, cellContainer)}
                onContextMenu={handleContextMenuCell}
                {...cellEventProps}
              >
                <Div className={cx('cell-overlay', selectedCell)} />
                <Text
                  css={`
                    max-height: calc(100% + 8px);
                    padding: 8px 0;
                  `}
                >
                  {cell.value}
                </Text>
              </Div>
            </Tooltip>
            {contextMenu?.length && contextMenu?.[2] === cell?.ref ? (
              <Div
                css={css`
                  position: absolute;
                  ${animation('fadeIn', '.2s ease')}
                  top: ${contextMenu[1]}px;
                  left: ${contextMenu[0]}px;
                  width: 200px;
                  ${container.box}
                  overflow: hidden;
                  padding: 0;
                  z-index: 1000;
                  div {
                    ${flex('space-between')}
                    ${container.hover}
                    padding: 16px;
                    svg {
                      margin-right: 8px;
                    }
                  }
                `}
              >
                <Div
                  onClick={() => {
                    navigator.clipboard.writeText(cell?.value);
                    toast.info('Copied cell value!');
                  }}
                >
                  <Text label>Copy Cell</Text>
                  <Copy size={24} />
                </Div>
              </Div>
            ) : null}
          </>
        )}
      </Div>
    );
  };
  const totalWidth = columnWidths?.reduce((p, v) => p + v, 0);

  return (
    <Div css={spreadsheetContainerStyles(totalWidth)} onClick={() => setContextMenu([])}>
      <BriteLoader isLoading={lastRenderedSheet !== media?.state?.activeSheet}>
        <Text
          h1
          css={`
            margin-top: 16px;
          `}
        >
          Loading worksheet
        </Text>
      </BriteLoader>
      <ScrollSync>
        {(scrollProps) => {
          return (
            <AutoSizer>
              {({ width, height }) => {
                const atBottomScroll = scrollProps.scrollHeight - scrollProps.scrollTop < scrollProps.clientHeight;
                return (
                  <Div>
                    <MultiGrid
                      classNameBottomRightGrid={css`
                        outline: none;
                        ::-webkit-scrollbar {
                          height: 8px;
                          width: 8px;
                          background: #e2ebf4;
                          border-top-right-radius: 16px;
                        }
                        ::-webkit-scrollbar-thumb {
                          background: ${colors.gray[400]};
                        }
                        transition: opacity 0.4s ease;
                      `}
                      ref={gridRef}
                      rowCount={rowCount}
                      columnCount={columnCount}
                      cellRenderer={cellRenderer}
                      fixedColumnCount={1}
                      fixedRowCount={1}
                      onScroll={scrollProps.onScroll}
                      onSectionRendered={() => setLastRenderedSheet(media?.state?.activeSheet)}
                      overscanIndicesGetter={overscanIndicesGetter}
                      overscanColumnCount={5}
                      overscanRowCount={5}
                      scrollToColumn={scrollToColumn}
                      scrollToRow={scrollToRow}
                      scrollToAlignment="center"
                      rowHeight={ROW_HEIGHT}
                      columnWidth={({ index }) => columnWidths?.[index]}
                      height={height}
                      width={width}
                    />
                    <Div
                      css={css`
                        transition: min-height 0.25s ease;
                        min-height: ${atBottomScroll ? `250px` : `0`};
                      `}
                    />
                  </Div>
                );
              }}
            </AutoSizer>
          );
        }}
      </ScrollSync>
    </Div>
  );
  // TODO: --------------------------------------------------
  // }, [
  //   focus.state,
  //   renderSheet,
  //   isSetupFocus,
  //   focus.columns.length,
  //   media?.state?.isParsingWorksheet,
  // ]);
  // TODO: --------------------------------------------------
};
