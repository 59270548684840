import axios from 'axios';
import errorHandler from '../../lib/stackdriver/errorHandler';
import posthog from 'posthog-js';

const url = window._env_.API_URL + `/`;

const axiosInstance = axios.create({
  baseURL: url,
  timeout: 60000,

  // the admin app can and should always bypass the cache
  headers: {
    'Cache-Control': 'no-cache',
    'X-Brite-Bypass-Cache': 'true', // the value is not important
  },
});

export class CustomAxios {
  static setInterceptors = (resetStore, auth0) => {
    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.requestErrors.push(error);
        console.requestErrors = console.requestErrors.slice(-10);
        if (!error.response) {
          errorHandler.report(`There was no response from the server for ${error.config.url}`);
        } else if (error.response.status === 401 || error.response.status === 403) {
          CustomAxios.logout(resetStore, auth0);
        }
        return Promise.reject(error);
      }
    );
  };

  static logout = (resetStore, auth0) => {
    errorHandler.report(`Auto logout user from 401 status`);

    posthog.reset();
    CustomAxios.setHeader('Authorization', undefined);
    CustomAxios.setHeader('BusinessID', undefined);
    CustomAxios.setHeader('ParentID', undefined);
    resetStore();

    if (!auth0) {
      return;
    }

    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  static setHeader = (key, value) => {
    axiosInstance.defaults.headers.common[key] = value;
  };

  static deleteHeader = (key) => {
    delete axiosInstance.defaults.headers.common[key];
  };

  static baseURL = () => {
    return axiosInstance.defaults.baseURL;
  };

  static request = (config) => {
    return axiosInstance.request(config);
  };
  static get = (url, config) => {
    if (url?.includes('undefined')) {
      // eslint-disable-next-line
      throw `Cancelling network request, UNDEFINED in url: ${url}`;
    } else {
      return axiosInstance.get(url, config);
    }
  };
  static delete = (url, config) => {
    return axiosInstance.delete(url, config);
  };
  static head = (url, config) => {
    return axiosInstance.head(url, config);
  };
  static post = (url, data, config) => {
    return axiosInstance.post(url, data, config);
  };
  static put = (url, data, config) => {
    return axiosInstance.put(url, data, config);
  };
  static patch = (url, data, config) => {
    return axiosInstance.patch(url, data, config);
  };
}
