import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { useState } from 'react';
import { Button, Modal, Text } from '../../../shared/components';

export const EnableTeamsModal = ({ hide = () => {} }) => {
  const [step, setStep] = useState(1);
  return (
    <Modal
      onClose={() => {
        hide();
      }}
      display
      css={`
        border-radius: 16px;
        padding: 32px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        `}
      >
        <div
          className={css`
            padding-bottom: 16px;
          `}
        >
          <Text h2>Connect Microsoft Teams</Text>
        </div>
        <div>
          <Button
            styles="icon"
            onClick={() => {
              hide();
            }}
          >
            <X />
          </Button>
        </div>
      </div>
      {step === 1 && (
        <>
          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              margin-bottom: 24px;
              padding: 16px;
            `}
          >
            <Text body>A Microsoft Teams Admin will be needed to complete the next step of this process.</Text>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: start;
              flex-direction: column;
            `}
          >
            <div
              className={css`
                padding-bottom: 16px;
              `}
            >
              <Text h4>Step 1:</Text>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                gap: 4px;
              `}
            >
              <Button
                onClick={() => {
                  setStep(2);
                }}
              >
                <a
                  className={css`
                    text-decoration: none;
                    color: #ffffff;
                  `}
                  href={'https://storage.cloud.google.com/brite_downloadables/Brite_MicrosoftTeamsApp.zip'}
                  download="brite_bot.zip"
                >
                  Download Application File
                </a>
              </Button>
              <Button
                styles="secondary"
                onClick={() => {
                  setStep(2);
                }}
              >
                Skip This Step
              </Button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <div>
          <div
            className={css`
              background: #f5f7fa;
              border-radius: 8px;
              margin-bottom: 24px;
              padding: 16px;
            `}
          >
            <div
              className={css`
                padding-bottom: 16px;
              `}
            >
              <Text h4>Step 2:</Text>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <Text body>
                Have a Microsoft Teams Admin upload that file for your organization in the{' '}
                <a href={'https://admin.teams.microsoft.com/policies/manage-apps'} target="_blank">
                  Teams Admin Portal
                </a>
                .
              </Text>
              <Text body>
                For more details, see this{' '}
                <a
                  href={
                    'https://learn.microsoft.com/en-us/microsoftteams/platform/concepts/deploy-and-publish/apps-upload#upload-your-app'
                  }
                  target="_blank"
                >
                  help center article
                </a>
                .
              </Text>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              secondary
              onClick={() => {
                setStep(1);
              }}
            >
              Go back
            </Button>
            <Button
              onClick={() => {
                try {
                  window.kolla.openConnector('microsoft-teams-47756');
                  window?.ChurnZero?.push(['setAttribute', 'account', 'integration-microsoft-teams', true]);
                  hide();
                } catch (e) {
                  console.error('Teams connector Kolla failure.');
                  hide();
                }
              }}
            >
              That is done, Connect.
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
