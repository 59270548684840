import { startCase } from 'lodash';
import { css } from '@emotion/css';
import { getDevicePercentage } from './utils/getDevicePercentage';
import { getDeviceColor } from './utils/getDeviceColor';

export const DeviceDonutLabel = ({ device, analytics }) => {
  return (
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        className={css`
          display: flex;

          align-items: center;
        `}
      >
        <div
          className={css`
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: ${getDeviceColor(device)};
            margin-right: 16px;
          `}
        ></div>
        <div>{startCase(device)}</div>
      </div>
      <div>{getDevicePercentage(device, analytics)}</div>
    </div>
  );
};
