import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import {
  ArrowFatLineDown,
  ArrowFatLineLeft,
  ArrowFatLineRight,
  ArrowFatLineUp,
  Chat,
  Copy,
  MagnifyingGlass,
  Trash,
} from '@phosphor-icons/react';
import { Div, Text } from '../../shared/components';
import { animation, container, flex, px } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useEvent } from '../../shared/use-event';
import { useOutsideClick } from '../../shared/use-outside-click';
import { useSearchParams } from '../../shared/use-search-params';
import {
  copyLayoutColumn,
  copyLayoutListItem,
  copyLayoutRow,
  editorUtils,
  iterateColumn,
  modifyContent,
  removeItem,
} from '../provider/utils';
import { ComponentIconMap } from './component-icon-map';
import { matchingComponents } from '../find-and-replace';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useContext } from 'react';
import { EditorDetailsContext } from '../provider/editor-detail-provider';
import * as uuid from 'uuid';

const componentNameMap = {
  textV2: 'Text',
};

const findAndReplaceComponentTypes = Object.keys(matchingComponents);

export const ContextMenu = ({ editor, element, location, contextMenu, setContextMenu, totalColumns }) => {
  const {
    dispatch,
    editorDetails: { commentByComponent, pageId },
  } = useContext(EditorDetailsContext);

  const { updateParams } = useSearchParams();
  const [rowIdx, colIdx] = location;
  const {
    state: { multiSelect, layout },
  } = editor;

  const ref = useOutsideClick(
    () => {
      setContextMenu({});
    },
    { disableRightClick: true }
  );
  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;

  const resetSelections = () => {
    editorUtils?.setProperty(editor, 'multiSelect', null);
    editorUtils?.setProperty(editor, 'selection', null);
  };

  const handleCopyComponent = () => {
    let allowCopy = element?.component !== 'text' && element?.component !== 'document';
    if (!allowCopy) {
      const docSelected = document.getSelection();
      allowCopy = docSelected?.isCollapsed;
    }
    if (allowCopy && location?.length > 1) {
      const rows =
        location?.length === 3
          ? copyLayoutListItem(layout, location)
          : location?.length === 2
          ? copyLayoutColumn(layout, location)
          : null;
      if (rows.length) {
        modifyContent?.set(editor, [], rows);
      }
    }
  };

  const handleCopyRow = () => {
    const rows = copyLayoutRow(layout, rowIdx);
    modifyContent?.set(editor, [], rows);
  };

  const handleDeleteRow = () => {
    let rows = [...layout?.rows];
    rows.splice(rowIdx, 1);
    modifyContent?.set(editor, [], rows);
    resetSelections();
  };

  const multiDeleteColumn = () => {
    let rows = [...layout?.rows];
    for (let index = multiSelect?.start; index <= multiSelect?.end; index++) {
      if (colIdx < rows?.[index]?.columns?.length) {
        const row = rows[index];
        let columns = [...row?.columns].map(({ width, ...rest }) => rest);
        columns.splice(colIdx, 1);
        rows.splice(index, 1, { ...row, columns });
      }
    }
    modifyContent?.set(editor, [], rows);
  };

  const multiCopyColumn = () => {
    let rows = [...layout?.rows];
    for (let index = multiSelect?.start; index <= multiSelect?.end; index++) {
      if (colIdx < rows?.[index]?.columns?.length) {
        const row = rows[index];
        let columns = [...row?.columns];
        let copiedColumn = cloneDeep(columns?.[colIdx]);
        iterateColumn(copiedColumn, (location, item) => {
          const [listIdx = null] = location;
          if (listIdx !== null) {
            copiedColumn.list[listIdx] = { ...item, id: uuid.v4() };
          } else {
            copiedColumn = { ...item, id: uuid.v4() };
          }
        });
        columns.splice(colIdx + 1, 0, copiedColumn);
        rows.splice(index, 1, { ...row, columns });
      }
    }
    modifyContent?.set(editor, [], rows);
  };

  const moveColumnLeft = () => {
    let rows = [...layout?.rows];
    for (let index = multiSelect?.start; index <= multiSelect?.end; index++) {
      let columns = [...rows[index]?.columns];
      if (colIdx < columns?.length) {
        const column = columns[colIdx];
        columns.splice(colIdx, 1);
        columns.splice(colIdx - 1, 0, column);
      }
      rows.splice(index, 1, { ...rows[index], columns });
    }
    modifyContent?.set(editor, [], rows);
  };

  const moveColumnRight = () => {
    let rows = [...layout?.rows];
    for (let index = multiSelect?.start; index <= multiSelect?.end; index++) {
      let columns = [...rows[index]?.columns];
      if (colIdx < columns?.length) {
        const column = columns[colIdx];
        columns.splice(colIdx, 1);
        columns.splice(colIdx + 1, 0, column);
      }
      rows.splice(index, 1, { ...rows[index], columns });
    }
    modifyContent?.set(editor, [], rows);
  };

  const moveUp = () => {
    let rows = [...layout?.rows];
    const fromIdx = multiSelect?.isSelecting ? multiSelect?.start - 1 : rowIdx - 1;
    const toIdx = multiSelect?.isSelecting ? multiSelect?.end : rowIdx;
    const copiedRow = rows[fromIdx];
    rows.splice(fromIdx, 1);
    rows.splice(toIdx, 0, copiedRow);
    modifyContent?.set(editor, [], rows);
    if (multiSelect?.isSelecting) {
      const start = multiSelect.start - 1;
      const end = multiSelect.end - 1;
      setTimeout(() => {
        const { hoverIndices, ...rest } = multiSelect;
        editorUtils?.setProperty(editor, 'multiSelect', {
          ...rest,
          start,
          end,
        });
      }, 0);
    }
  };

  const moveDown = () => {
    let rows = [...layout?.rows];
    const fromIdx = multiSelect?.isSelecting ? multiSelect?.end + 1 : rowIdx + 1;
    const toIdx = multiSelect?.isSelecting ? multiSelect?.start : rowIdx;
    const copiedRow = rows[fromIdx];
    rows.splice(fromIdx, 1);
    rows.splice(toIdx, 0, copiedRow);
    modifyContent?.set(editor, [], rows);
    if (multiSelect?.isSelecting) {
      const start = multiSelect.start + 1;
      const end = multiSelect.end + 1;
      setTimeout(() => {
        const { hoverIndices, ...rest } = multiSelect;
        editorUtils?.setProperty(editor, 'multiSelect', {
          ...rest,
          start,
          end,
        });
      }, 0);
    }
  };

  const deleteGroup = () => {
    let rows = [...layout.rows];
    const count = multiSelect?.end - multiSelect?.start + 1;
    rows.splice(multiSelect?.start, count);
    modifyContent?.set(editor, [], rows);
    setTimeout(resetSelections, 0);
  };

  const handleDeleteComponent = () => {
    const rows = removeItem(layout, location);
    modifyContent?.set(editor, [], rows, { syncRenderState: true });
    editorUtils?.setProperty(editor, 'selection', null);
  };

  const addComment = () => {
    let componentId = element.id;
    // not all components have a componentId added to them
    // so we need to add them here to connect the comment.
    if (!componentId) {
      componentId = uuid.v4();
      modifyContent?.merge(editor, location, { id: componentId });
    }

    updateParams({ mode: 'collaborating' });
    const commentId = commentByComponent?.[pageId]?.[componentId]?.length
      ? commentByComponent?.[pageId]?.[componentId][0]
      : '';
    dispatch({
      type: 'SET',
      key: 'commenting',
      payload: {
        componentId,
        commentId,
        selectType: 'component',
        selectedAt: new Date().toISOString(),
      },
    });
  };

  const resetContextMenu = () => setContextMenu({});

  useEvent('scroll', resetContextMenu, { capture: true });

  return (
    <>
      <Div
        ref={ref}
        className={css`
          ${container.box}
          padding: ${px.sm} 0;
          ${!contextMenu?.display
            ? `
            display: none;
          `
            : animation('fadeIn', '.2s ease')}
          position: fixed;
          left: ${contextMenu.x}px;
          top: ${contextMenu.y}px;
          min-width: 200px;
          width: max-content;
          z-index: 10000;
          text-transform: capitalize;
          padding: 16px;
          .menu-item {
            ${flex('left')}
            ${container.hover}
            padding: 8px;
            border-radius: 4px;
          }
        `}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          setContextMenu({});
          editorUtils?.setProperty(editor, 'selection', null);
          if (multiSelect?.isSelecting) {
            const { hoverIndices, ...rest } = multiSelect || {};
            editorUtils?.setProperty(editor, 'multiSelect', rest);
          }
        }}
      >
        {!multiSelect?.isSelecting ? (
          <>
            {collaborationFF ? (
              <Div className="menu-item" onClick={addComment}>
                <Chat size={24} color={colors.purple} />
                <Text
                  label
                  css={`
                    margin-left: 16px;
                  `}
                >
                  Comment
                </Text>
              </Div>
            ) : null}
            <Div className="menu-item" onClick={handleCopyComponent}>
              <ComponentIconMap type={element?.component} size={24} color={colors.black} weight="bold" />
              <Text styles="label ml">Duplicate {componentNameMap?.[element?.component] || element?.component}</Text>
            </Div>
            <Div className="menu-item" onClick={handleCopyRow}>
              <Copy size={24} />
              <Text styles="label ml">Duplicate Row</Text>
            </Div>
            <Div className="menu-item" onClick={moveUp} disabled={location?.[0] === 0}>
              <ArrowFatLineUp size={24} />
              <Text styles="label ml">Move Row Up</Text>
            </Div>
            <Div className="menu-item" onClick={moveDown} disabled={location?.[0] === layout?.rows?.length - 1}>
              <ArrowFatLineDown size={24} />
              <Text styles="label ml">Move Row Down</Text>
            </Div>
            <Div className="menu-item" onClick={handleDeleteComponent}>
              <ComponentIconMap type={element?.component} size={24} color={colors.black} weight="bold" />
              <Text styles="label ml">Delete {componentNameMap?.[element?.component] || element?.component}</Text>
            </Div>
            <Div className="menu-item" onClick={handleDeleteRow}>
              <Trash size={24} />
              <Text styles="label ml">Delete Row</Text>
            </Div>
            {findAndReplaceComponentTypes?.includes(element?.component) ? (
              <Div
                className="menu-item"
                onClick={(e) => {
                  e.stopPropagation();
                  updateParams({ courseModal: 'find-and-replace' });
                }}
              >
                <MagnifyingGlass size={24} />
                <Text styles="label ml">Find & Replace</Text>
              </Div>
            ) : null}
          </>
        ) : (
          <>
            <Div className="menu-item" onClick={multiCopyColumn}>
              <Copy size={24} />
              <Text styles="label ml">Duplicate Column</Text>
            </Div>
            <Div className="menu-item" onClick={moveUp} disabled={multiSelect?.start === 0}>
              <ArrowFatLineUp size={24} />
              <Text styles="label ml">Move Group Up</Text>
            </Div>
            <Div className="menu-item" onClick={moveDown} disabled={multiSelect?.end === layout?.rows?.length - 1}>
              <ArrowFatLineDown size={24} />
              <Text styles="label ml">Move Group Down</Text>
            </Div>
            <Div className="menu-item" onClick={moveColumnLeft} disabled={colIdx === 0}>
              <ArrowFatLineLeft size={24} />
              <Text styles="label ml">Move Column Left</Text>
            </Div>
            <Div className="menu-item" onClick={moveColumnRight} disabled={colIdx === totalColumns - 1}>
              <ArrowFatLineRight size={24} />
              <Text styles="label ml">Move Column Right</Text>
            </Div>
            <Div className="menu-item" onClick={multiDeleteColumn}>
              <Trash size={24} />
              <Text styles="label ml">Delete Column</Text>
            </Div>
            <Div className="menu-item" onClick={deleteGroup}>
              <Trash size={24} />
              <Text styles="label ml">Delete Group</Text>
            </Div>
            <Div
              className="menu-item"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                updateParams({ courseModal: 'save-content-block' });
              }}
            >
              <ComponentIconMap type="content-block" size={24} />
              <Text styles="label ml">Create Block</Text>
            </Div>
          </>
        )}
      </Div>
    </>
  );
};
