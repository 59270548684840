import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { Button, Div, Modal, Text } from '../shared/components';
import { flex, px } from '../shared/shared-styles';

export const ConfirmationPrompt = ({ onSave, name, hasChanges, ...modalProps }) => {
  const handleSave = () => {
    onSave();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          padding: ${px.xl};
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex('jcsb aic')}
          `}
        >
          <Text styles="h2">Are you sure?</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Text styles="mt">
          Changes you make here will be reflected throughout your entire guide
          {!hasChanges ? '.' : ' and any other guide that uses this Design Style.'}
        </Text>

        <Div
          css={css`
            ${flex('jcr aic')} margin-top: ${px.md};
          `}
        >
          <Button styles="mr text" onClick={modalProps.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Div>
      </Div>
    </Modal>
  );
};
