import { css } from '@emotion/css';
import { Cpu, Desktop, DeviceMobileCamera } from '@phosphor-icons/react';
import { Button, Div } from '../../shared/components';
import { animation, flex, shadows } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { Tooltip } from '../../common/components/Tooltip';

export const ViewOptions = ({ viewMode, devMode, setViewMode }) => {
  return (
    <Div
      css={css`
        ${flex('right')} z-index: 901;
        position: absolute;
        left: 64px;
        bottom: 32px;
        ${animation('fadeIn', '.2s ease')}
      `}
    >
      <Div
        css={css`
          ${flex('right')} border-radius: 8px;
          background-color: white;
          ${shadows.md}
          padding: 8px;
          button {
            margin: 0 4px;
          }
        `}
      >
        <Button
          styles={viewMode === 'desktop' ? 'icon-active sm' : 'icon sm'}
          hoverLabel="Desktop"
          onClick={() => setViewMode('desktop')}
        >
          <Desktop size={24} />
        </Button>
        <Button
          styles={viewMode === 'mobile' ? 'icon-active sm' : 'icon sm'}
          hoverLabel="Mobile"
          onClick={() => setViewMode('mobile')}
        >
          <DeviceMobileCamera size={24} />
        </Button>
      </Div>
      {devMode && (
        <Tooltip label="DEV MODE">
          <Cpu
            color={colors.purple}
            className={css`
              margin-left: 16px;
            `}
          />
        </Tooltip>
      )}
    </Div>
  );
};
