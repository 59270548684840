import React, { useMemo, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { CaretDown, CaretUp, CreditCard, WarningCircle, Plus, Question, Trash } from '@phosphor-icons/react';
import { css } from '@emotion/css';
import { sectionContainer } from './product-section';
import { Button, Div, Input, Select, Text, Toggle } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { FieldInput } from './field-components/field-input';
import { cloneDeep, get, set } from 'lodash';
import {
  getTierStructure,
  useDisplaySettings,
} from '../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings';
import { fieldContainerStyle } from './field-components/field';
import { useQueryAPI } from '../react-query';
import { mutliNetworkCategoryMap } from '../benefit-package/utils';
import { useSearchParams } from '../shared/use-search-params';
import posthog from 'posthog-js';
import { SelectCarrier } from '../benefit-package/select-carrier';
import { getBenefit } from '../benefit-package/creation-flow/creation-flow';
import { productModifier, productUtils } from './configs/product-utils';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useAcls } from '../shared/use-acls';
import { WRITE_BENEFITS_PACKAGE } from '../shared/acl-constants';
import { Collapse } from '../common/components/Collapse';
import { Tooltip } from '../common/components/Tooltip';

export const programDetails = {
  health_savings_account: {
    display: 'Health Savings Account',
    shortDisplay: 'HSA',
    productType: 'hsa_buyup',
    actionText: "You haven't added a Health Savings Account yet.",
  },
  flexible_spending_account: {
    display: 'Flexible Spending Account',
    shortDisplay: 'FSA',
    productType: 'fsa_buyup',
    actionText: "You haven't added a Flexible Spending Account yet.",
  },
  health_reimbursement_arrangement: {
    display: 'Health Reimbursement Arrangement',
    productType: null,
  },
};

export const programAvailabiltyByProductType = {
  health_savings_account: ['hdhp', 'epo', 'acaplans-hdhp'],
  flexible_spending_account: ['ppo', 'acaplans-ppo', 'hmo', 'epo'],
  health_reimbursement_arrangement: [], // Empty = applies to all
};

export const programList = Object.entries(programDetails);

export const SpendingAccounts = ({ state }) => {
  const costTiersFF = useFeatureFlagPayload('tier-structure');
  const { includedPrograms, businessId, packageId, product } = state;
  const [open, setOpen] = useState(false);
  const { params, updateParams, removeParam } = useSearchParams();
  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const tiers = getTierStructure({
    featureFlag: costTiersFF?.value,
    cost: product?.Cost,
    displaySettings,
  });
  const [loadingConnect, setLoadingConnect] = useState('');
  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);

  const products = useQueryAPI({
    cacheKey: ['pre-fetch-editor-resource'],
    url: `v2/benefitspackage/${packageId}/product`,
  });

  useEffect(() => {
    if (!params?.connectedProductId) {
      removeParam('isAddingConnectedProduct');
    }
  }, [params?.connectedProductId]);

  const updateProgram = (type, property, value) => {
    const data = cloneDeep(includedPrograms?.[type]);
    const nextData = set(data, property, value);
    productModifier.setIncludedPrograms(state, {
      ...includedPrograms,
      [type]: nextData,
    });
  };

  const getFieldProps = (type, property) => {
    const PropertyChain = `RawData.Details.${property}`;
    const data = includedPrograms?.[type];
    const value = get(data, PropertyChain);

    return {
      value,
      updateProperty: (property, value) => updateProgram(type, property, value),
      field: {
        PropertyChain,
        Type: 'dollar',
        productId: data?.ID,
      },
      config: {
        storeValueAs: 'number',
        roundUp: false,
        disabled: !canEdit,
      },
    };
  };

  const createAndConnectPlan = async (programType) => {
    const type = programDetails?.[programType]?.productType;
    try {
      setLoadingConnect(programType);
      const product = getBenefit(type);
      posthog.capture('BenefitsPackageSave', {
        actionType: 'brite:create_benefit:' + type,
      });
      const productId = await productUtils.createProduct(state, product);
      updateProgram(programType, 'RelatedProductID', productId);
      updateParams({
        connectedProductId: productId,
        isAddingConnectedProduct: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      products.refetch();
      setLoadingConnect('');
    }
  };

  const handleSelectCarrier = (type, value = null, _, name = null) => {
    productModifier.setIncludedPrograms(state, {
      ...includedPrograms,
      [type]: {
        ...includedPrograms?.[type],
        CarrierID: value,
        CarrierName: name,
      },
    });
  };

  const addDetail = (type) => {
    let details = [...(includedPrograms?.[type]?.TitleDescriptions || [])];
    details.push({ Title: '', Description: '' });
    updateProgram(type, 'TitleDescriptions', details);
  };

  const updateDetail = (type, idx, property, value) => {
    let details = [...(includedPrograms?.[type]?.TitleDescriptions || [])];
    details.splice(idx, 1, {
      ...details[idx],
      [property]: value,
    });
    updateProgram(type, 'TitleDescriptions', details);
  };

  const removeDetail = (type, idx) => {
    let details = [...(includedPrograms?.[type]?.TitleDescriptions || [])].filter((_, index) => index !== idx);
    updateProgram(type, 'TitleDescriptions', details);
  };

  const availablePrograms = useMemo(() => {
    return programList?.filter(([key]) => {
      const isEmpty = !programAvailabiltyByProductType?.[key]?.length;
      const hasAvailabilityByPlanType = programAvailabiltyByProductType[key].includes(product?.Details?.PlanType);
      // if the program already exists, allow them to edit/remove it,
      // but once it's gone, it's gone FOR-EVAH
      const programAlreadyExists = key in includedPrograms;
      return isEmpty || hasAvailabilityByPlanType || programAlreadyExists;
    });
  }, [product?.Details?.PlanType, includedPrograms]);

  // *** Connecting Component
  // ************************
  const ConnectSpendingAccountProduct = ({ programType }) => {
    const detail = programDetails?.[programType];
    const program = includedPrograms?.[programType];
    const programProducts = products.data?.filter(({ Type }) => Type === detail?.productType);
    const hasProductID = programProducts?.findIndex(({ ID }) => ID === program?.RelatedProductID) > -1;
    const upateProduct = (event) => updateProgram(programType, 'RelatedProductID', event.target.value);
    return (
      <>
        {!!programProducts?.length ? (
          <Div className="field">
            <Text label className="label">
              Connect Existing {programType === 'health_savings_account' ? 'HSA' : 'FSA'}
            </Text>
            <Select
              css={`
                width: 50%;
              `}
              value={program?.RelatedProductID || ''}
              onChange={upateProduct}
            >
              {!hasProductID ? (
                <MenuItem value={program?.RelatedProductID}>No connected spending account</MenuItem>
              ) : null}
              {programProducts?.map((item) => (
                <MenuItem value={item?.ID}>
                  <Div
                    css={css`
                      width: 100%;
                      ${flex('space-between')}
                    `}
                  >
                    <Text label>{item?.ProviderName || detail?.display}</Text>
                    <Text>{mutliNetworkCategoryMap[item?.MultiNetworkCategory]}</Text>
                  </Div>
                </MenuItem>
              ))}
              <MenuItem
                value={null}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  createAndConnectPlan(programType);
                }}
              >
                <Plus color={colors.purple} />
                <Text
                  css={`
                    color: ${colors.purple};
                    margin-left: 8px;
                  `}
                >
                  Add {detail?.shortDisplay}
                </Text>
              </MenuItem>
            </Select>
          </Div>
        ) : (
          <Div
            css={css`
              width: 100%;
              background-color: ${colors.gray[200]};
              border-radius: 8px;
              padding: 16px;
              margin: 16px 0;
            `}
          >
            <Div
              css={css`
                ${flex('left')}
                svg {
                  margin-right: 16px;
                  min-width: 32px;
                }
              `}
            >
              <WarningCircle />
              <Text label>{detail?.actionText}</Text>
            </Div>
            <Div
              css={css`
                margin-left: 48px;
                margin-top: 8px;
              `}
            >
              <Button onClick={() => createAndConnectPlan(programType)} disabled={loadingConnect === programType}>
                add {detail?.display}
              </Button>
            </Div>
          </Div>
        )}
      </>
    );
  };

  // SPENDING ACCOUNT COMPONENT -->>>>
  return (
    <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
      <Div
        css={css`
          ${flex('jcsb aic')} cursor: pointer;
          ${open && `padding-bottom: 16px;`}
        `}
        onClick={() => setOpen(!open)}
      >
        <Div
          css={css`
            ${flex('aic')}
          `}
        >
          <Div
            css={css`
              ${flex('aic')} min-width: 32px;
              margin-right: 8px;
            `}
          >
            {<CreditCard />}
          </Div>
          <Text styles="h3">Spending Accounts</Text>
        </Div>
        {open ? <CaretUp /> : <CaretDown />}
      </Div>

      <Collapse isOpen={open}>
        <Text
          css={`
            padding: 16px 0;
            padding-top: 0;
          `}
        >
          Turn on any account you would like to add to this medical plan
        </Text>
        {availablePrograms?.map(([programType, { display }]) => (
          <Div
            key={programType}
            css={css`
              border-radius: 16px;
              border: 1px solid ${colors.gray[300]};
              padding: 32px;
              margin: 16px 0;
              .field {
                ${fieldContainerStyle()}
                padding-left: 0;
                .field-input-component {
                  width: 50%;
                }
              }
            `}
          >
            <Div
              css={css`
                ${flex('space-between')}
              `}
            >
              <Text label bold>
                {display}
              </Text>
              <Toggle
                disabled={!canEdit}
                checked={programType in includedPrograms}
                onChange={() => canEdit && productModifier.toggleProgram(state, programType)}
              />
            </Div>
            <Collapse isOpen={programType in includedPrograms}>
              {programType === 'health_savings_account' ? (
                <Div
                  css={css`
                    margin: 8px 0;
                  `}
                >
                  <ConnectSpendingAccountProduct programType={programType} />
                  <Text
                    label
                    bold
                    css={`
                      margin: 8px 0;
                    `}
                  >
                    Monthly Contributions
                  </Text>

                  <Div className="field">
                    <Text
                      label
                      className="label"
                      css={`
                        margin: 16px 0;
                      `}
                    >
                      Employer Contribution Type
                    </Text>
                    <Select
                      disabled={!canEdit}
                      className={css`
                        width: 50%;
                      `}
                      value={
                        includedPrograms?.[programType]?.RawData?.Details?.ContributionType ||
                        'HSA - No Employer Contribution'
                      }
                      onChange={(e) => updateProgram(programType, 'RawData.Details.ContributionType', e.target.value)}
                    >
                      <MenuItem value="HSA - No Employer Contribution">No Employer Contribution</MenuItem>
                      <MenuItem value="HSA - Flat Monthly Employer Contribution">Flat Monthly Contribution</MenuItem>
                      <MenuItem value="HSA - Employer Match">Employer Match</MenuItem>
                    </Select>
                  </Div>

                  {!includedPrograms?.[programType]?.RawData?.Details?.ContributionType ||
                    (includedPrograms?.[programType]?.RawData?.Details?.ContributionType !==
                      'HSA - No Employer Contribution' &&
                      tiers?.keys?.map((key) => {
                        const contributionType =
                          includedPrograms?.[programType]?.RawData?.Details?.ContributionType || '';
                        return (
                          <Div
                            css={css`
                              ${contributionType === 'HSA - Employer Match'
                                ? `
                              border-top: 1px solid ${colors.gray[300]};
                              padding: 16px 0;
                          `
                                : ''}
                            `}
                          >
                            <Div className="field">
                              <Text label className="label">
                                {tiers?.labels?.[key]}
                              </Text>
                              <FieldInput
                                {...getFieldProps(
                                  programType,
                                  `${key + (contributionType === 'HSA - Employer Match' ? 'EmployerMonthlyMatch' : '')}`
                                )}
                              />
                            </Div>
                            {contributionType === 'HSA - Employer Match' && (
                              <Div className="field">
                                <Text label className="label">
                                  For Every $1, Up To
                                </Text>
                                <FieldInput {...getFieldProps(programType, `${key}EmployerMonthlyMatchMax`)} />
                              </Div>
                            )}
                          </Div>
                        );
                      }))}
                </Div>
              ) : programType === 'flexible_spending_account' ? (
                <ConnectSpendingAccountProduct programType={programType} />
              ) : programType === 'health_reimbursement_arrangement' ? (
                <Div
                  css={css`
                    margin: 8px 0;
                    .field {
                      ${fieldContainerStyle()}
                    }
                  `}
                >
                  <Text label bold>
                    Minimum Deductible Amount
                  </Text>

                  <Text>You must meet your portion of the deductible before the HRA will reimburse you.</Text>

                  <Div className="field">
                    <Text label>{displaySettings?.EmployeeOnly}</Text>
                    <FieldInput {...getFieldProps(programType, 'MinimumDeductibleSingle')} />
                  </Div>

                  <Div className="field">
                    <Text label>2 Party or More</Text>
                    <FieldInput {...getFieldProps(programType, 'MinimumDeductibleTwoPlusParty')} />
                  </Div>

                  <Text
                    label
                    bold
                    css={`
                      margin: 16px 0;
                    `}
                  >
                    Annual HRA Contributions
                  </Text>

                  {tiers?.keys?.map((key) => (
                    <Div className="field">
                      <Text label className="label">
                        {tiers?.labels?.[key]}
                      </Text>
                      <FieldInput {...getFieldProps(programType, key)} />
                    </Div>
                  ))}

                  <Div
                    css={css`
                      ${flex('space-between')}
                      margin-top: 32px;
                    `}
                  >
                    <Text label>Carrier</Text>
                    <SelectCarrier
                      disabled={!canEdit}
                      selectProps={{ style: { width: '50%', height: '40px' } }}
                      fieldStyle={{ style: { margin: '1px', padding: '1px' } }}
                      carrierId={includedPrograms?.[programType]?.CarrierID}
                      setCarrierId={(...args) => handleSelectCarrier(programType, ...args)}
                    />
                  </Div>

                  <Div
                    css={css`
                      ${flex('left')}
                      margin-top: 32px;
                    `}
                  >
                    <Text h4>Details</Text>
                    <Tooltip label="Enter any details you want the employee to see. These will appear on the expanded view of each card.">
                      <Question />
                    </Tooltip>
                  </Div>
                  <Div
                    css={css`
                      ${flex('aic')} box-sizing: box-content;
                      margin-top: 32px;
                    `}
                  >
                    <Text
                      styles="label bold"
                      className={css`
                        width: 45%;
                      `}
                    >
                      Title
                    </Text>
                    <Text
                      styles="label bold"
                      className={css`
                        width: 45%;
                        margin-left: 16px;
                      `}
                    >
                      Description
                    </Text>
                  </Div>
                  {!!includedPrograms?.[programType]?.TitleDescriptions?.length &&
                    includedPrograms?.[programType]?.TitleDescriptions.map((data, idx) => (
                      <Div
                        css={css`
                          ${flex('aic')}
                        `}
                      >
                        <Input
                          disabled={data.Autogenerated || !canEdit}
                          className={css`
                            width: 45%;
                            margin-right: 32px;
                          `}
                          value={data.Title}
                          onChange={(e) => updateDetail(programType, idx, 'Title', e.target.value)}
                        />
                        <Input
                          disabled={data.Autogenerated || !canEdit}
                          className={css`
                            width: 45%;
                            margin-right: 32px;
                          `}
                          value={data.Description}
                          onChange={(e) => updateDetail(programType, idx, 'Description', e.target.value)}
                        />
                        <Button
                          styles="icon"
                          onClick={() => removeDetail(programType, idx)}
                          disabled={data.Autogenerated || !canEdit}
                        >
                          <Trash size={24} />
                        </Button>
                      </Div>
                    ))}
                  <Div
                    css={css`
                      ${flex('jcr aic')} margin-top: 32px;
                    `}
                  >
                    {!includedPrograms?.[programType]?.TitleDescriptions?.length && (
                      <Text>Click 'Add Detail' to create a custom detail.</Text>
                    )}
                    <Button
                      styles="secondary"
                      className={css`
                        margin-left: auto;
                      `}
                      onClick={() => addDetail(programType)}
                    >
                      Add Detail
                    </Button>
                  </Div>
                </Div>
              ) : null}
            </Collapse>
          </Div>
        ))}
      </Collapse>
    </Div>
  );
};
