import { css } from '@emotion/css';
import { CaretRight } from '@phosphor-icons/react';
import { useMemo, useRef } from 'react';
import { Transforms } from 'slate';
import { useFocused, useSelected, useSlate } from 'slate-react';
import { Div, Text } from '../../../shared/components';
import { findNodePath, getMarkedStyles } from './slate-utils';
import { markStyleMap } from './slate-config';
import { colors } from '../../../shared/styles';
import { flex } from '../../../shared/shared-styles';
import { useEditorResource } from '../../use-editor-resource';
import { PRODUCT_HEADERS } from '../../../benefit-package/constants';
import { Tooltip } from '../../../common/components/Tooltip';

// DEFAULT COMPONENT
// *****************************************************************************

export const DefaultElement = (props) => {
  return <p {...props.atttributes}>{props.children}</p>;
};

// LINK COMPONENT
// *****************************************************************************

export const Link = (props) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <Div
      css={css`
        position: relative;
        display: inline;
        a {
          color: inherit;
          text-decoration: inherit;
          ${selected && focused
            ? `
            filter: drop-shadow(0 0 3px rgba(150, 150, 250, .55));
          `
            : ''}
        }
      `}
    >
      <Tooltip label={props?.element?.href}>
        <a
          {...props.attributes}
          href={props?.element?.href}
          onDoubleClick={() => window.open(props?.element?.href, '_blank')}
        >
          {props.children}
        </a>
      </Tooltip>
    </Div>
  );
};

// SmartField
// *****************************************************************************

const tagContainer = (hasFocus, isSuccess, isEmpty) => css`
  display: inline-flex;
  align-items: center;
  position: relative;

  border-bottom: 2px dashed ${isSuccess ? (isEmpty ? colors.yellow[100] : colors.purple) : colors.red[100]};

  :hover {
    transition: border-bottom 0.3s ease;
    border-bottom: 2px solid ${isSuccess ? (isEmpty ? colors.yellow[100] : colors.purple) : colors.red[100]};
  }

  padding: 0;
  cursor: pointer;
  margin: 1px;
  margin-bottom: 2px;
  margin-left: 0.15em;
  margin-right: 0.1em;
  caret-color: black;
  user-select: none;
  ${isEmpty || hasFocus
    ? `
      position: relative;
      ::after {
        content: '';
        position: absolute;
        top: -2px; left: -2px; right: -2px; bottom: -2px;
        border-radius: 8px;
        ${
          isEmpty
            ? `
              background-color: ${colors.gray[100]};
              opacity: .5;
            `
            : `
            background-color: ${colors.gray[200]};
            z-index: -1;
            border-bottom: 2px solid
            ${isSuccess ? (isEmpty ? colors.yellow[100] : colors.purple) : colors.red[100]};
        `
        }
        
      }
  `
    : ''}
`;

const BENEFITS_PKG = 'Benefits Package';

export const SmartField = (props) => {
  const anchor = useRef();
  const { data } = useEditorResource('liveSmartFields');
  const { data: productsList } = useEditorResource('products');

  const getBreadCrumb = (element) => {
    if (element?.dataSourceChain?.length) {
      const list = element?.dataSourceChain?.reduce((prev, item) => {
        if (item?.Type === 'product') {
          if (data?.[item?.IdentifierTag]?.Success) {
            const id = data?.[item?.IdentifierTag]?.Value;
            const product = productsList?.find(({ ID }) => ID === id);
            const type = PRODUCT_HEADERS?.[product?.Type] || 'Product';
            return [...prev, BENEFITS_PKG, type, product?.ProductName];
          }
        } else if (item?.Type === 'business' && prev?.includes(BENEFITS_PKG)) {
          return [...prev, 'Carrier'];
        } else if (item?.Type === 'business' && !prev?.length) {
          return [...prev, 'Company Kit'];
        }
        return prev;
      }, []);
      return list;
    }
  };

  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();

  const smartField = data?.[props?.element?.value] || {};

  const select = () => {
    try {
      const at = findNodePath(editor, (node) => node?.frontendId === props?.element?.frontendId);
      if (!!at) {
        Transforms.select(editor, at);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isSuccess = !('Success' in smartField) || smartField?.Success;
  const isEmpty = 'Value' in smartField && !smartField?.Value;

  const { inlineStyle, crumbs } = useMemo(() => {
    const node =
      props?.element?.children?.length > 1
        ? props?.element?.children.find(({ text }) => text === props?.element?.name)
        : props?.element?.children?.[0];
    const inlineStyle = node ? getMarkedStyles(node, false) : '';
    const crumbs = isSuccess ? getBreadCrumb(props.element, data) : [];
    return {
      inlineStyle,
      crumbs,
    };
  });

  return (
    <>
      <span {...props.attributes} contentEditable={false} style={{ userSelect: false }}>
        <Tooltip
          label={
            <Div
              css={css`
                padding: 4px;
              `}
            >
              <Text
                css={`
                  color: white;
                  font-weight: bold;
                  font-size: 0.9em;
                `}
              >
                {props.element.name}
              </Text>
              <Div
                css={css`
                  ${flex('left wrap')}
                `}
              >
                {crumbs?.map((item, idx) => (
                  <Text
                    css={`
                      color: white;
                      font-weight: normal;
                      font-size: 0.8em;
                      svg {
                        margin: 0 8px;
                      }
                    `}
                  >
                    {item}
                    {idx < crumbs.length - 1 ? <CaretRight size={8} /> : null}
                  </Text>
                ))}
              </Div>
            </Div>
          }
        >
          <span
            contentEditable={false}
            style={{ ...inlineStyle, userSelect: false }}
            className={tagContainer(selected && focused, isSuccess, isEmpty)}
            onClick={select}
            ref={anchor}
          >
            {smartField?.Success ? smartField?.Value || props.element.name : props.element.name}
          </span>
        </Tooltip>
        <span style={{ display: 'none' }}>{props.children}</span>
      </span>
    </>
  );
};

// LEAF COMPONENT
// *****************************************************************************

export const Leaf = (props) => {
  const getLeafStyle = () => {
    const mappedMarks = Object.entries(props?.leaf || {}).reduce((prev, item) => {
      const [key, value] = item;
      if (key in markStyleMap) {
        let obj = {};
        if (key === 'color') {
          obj = { color: value };
        }
        const style = markStyleMap[key]?.style(obj) || {};
        return Object.entries(style)?.reduce((p, [key, value]) => {
          if (key in prev && value) {
            return { ...p, [key]: p[key] + ' ' + value };
          }
          return { ...p, [key]: value };
        }, prev);
      }

      return prev;
    }, {});

    return mappedMarks;
  };

  const leafStyle = getLeafStyle();

  return (
    <span {...props.attributes} style={leafStyle}>
      {props.children}
    </span>
  );
};
