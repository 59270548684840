import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../../redux/axios/axios';
import { Button, Div, Input, Loader, Modal, Text } from '../../../shared/components';
import { flex } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useSearchParams } from '../../../shared/use-search-params';
import { useStateSync } from '../../../shared/use-state-sync';

export const NewBlankPageModal = ({ courseId, refetch, ...modalProps }) => {
  const { updateParams } = useSearchParams();

  const [pageType] = useStateSync('brite-editor', [modalProps.display]);
  const [pageName, setPageName] = useStateSync('', [modalProps.display]);
  const [isLoading, setIsLoading] = useState(false);

  const createPage = async () => {
    const body = {
      Type: pageType,
      Name: pageName || 'New Page',
      Content: null,
      FrontendID: '_' + Math.random().toString(36).substr(2, 9),
      IsLocked: false,
      TemplateID: '00000000-0000-0000-0000-000000000000',
      CourseID: courseId,
    };
    let pageId = '';
    try {
      setIsLoading(true);
      const { data } = await CustomAxios.post(`v2/course/${courseId}/pages`, body);
      pageId = data?.ID;
    } catch (err) {
      toast.error(`Failed to create a new page.`);
      console.warn(err);
    } finally {
      setIsLoading(false);
      refetch();
      if (pageId) {
        modalProps.onClose();
        updateParams({ pageId });
      }
    }
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            padding: 32px;
            ${flex('space-between')} width: 100%;
          `}
        >
          <Text h2>New Blank Page</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            padding: 0 32px;
          `}
        >
          <Text label bold>
            Page Name
          </Text>
          <Input
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            css={`
              width: 100%;
            `}
          />
          <Text>You can skip this and change it later</Text>
        </Div>

        <Div
          css={css`
            padding: 32px;
            ${flex('right')}
          `}
        >
          <Button
            styles="secondary"
            css={`
              margin-right: 16px;
            `}
            onClick={modalProps.onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={createPage}
            css={`
              position: relative;
            `}
          >
            <Loader
              isLoading={isLoading}
              type="three-dots"
              className={css`
                height: calc(100% - 16px);
                ${flex('center')} background-color: ${colors.gray[300]};
              `}
            />
            Add Page
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
