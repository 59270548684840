import { css } from '@emotion/css';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Box, Button, Div, Dropdown, Slider, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { useDebounce } from '../../shared/use-debounce';
import { useStateSync } from '../../shared/use-state-sync';
import { modifyContent } from '../provider/utils';
import { useEditorResource } from '../use-editor-resource';
import { ColorPicker } from './menu-components';

export const SpaceToolbar = ({ editor, element, location }) => {
  const { data: styles } = useEditorResource('styles');

  const styleObject = { ...styles?.space, ...element?.attributes?.style };
  const rawHeight = element?.attributes?.style?.minHeight || '';

  const [height, setHeight] = useStateSync(Number(rawHeight.replace(/\D/g, '') || 0), [rawHeight]);

  const updateHeight = useDebounce((value) => {
    const minHeight = `${Math.max(8, Number(value))}px`;
    modifyContent.merge(editor, location, {
      attributes: {
        style: {
          height: '100%',
          minHeight,
        },
      },
    });
  }, 25);

  const changeSpaceHeight = (value) => {
    setHeight(value);
    updateHeight(value);
  };

  const updatePageBreak = (value) => {
    modifyContent.merge(editor, location, {
      container: {
        attributes: {
          style: {
            breakAfter: value === 'page-break' ? 'page' : 'avoid',
          },
        },
      },
    });
  };

  const spaceOptions = [
    {
      label: 'Space',
      value: 'space',
    },
    {
      label: 'Page Break',
      value: 'page-break',
    },
  ];

  return (
    <Div
      css={css`
        ${flex('jcl aic')}
      `}
    >
      <Dropdown
        button={({ open }) => (
          <Button
            secondary
            css={`
              display: flex;
              justify-content: space-between;
              padding: 8px 16px;
              margin-right: 8px;
              width: 150px;
              text-transform: none;
            `}
          >
            <Text label>{element?.container?.attributes?.style?.breakAfter === 'page' ? 'Page Break' : 'Space'}</Text>
            {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
          </Button>
        )}
        popper={{ placement: 'bottom-start' }}
      >
        <Box
          css={`
            background-color: white;
            width: 150px;
          `}
        >
          {spaceOptions.map((option) => (
            <Dropdown.Item key={option.value} onClick={() => updatePageBreak(option.value)}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Box>
      </Dropdown>
      <Slider
        aria-label="Width"
        min={8}
        max={300}
        value={height || 0}
        style={{ width: '140px' }}
        valueLabelDisplay="auto"
        onChange={(e, value) => changeSpaceHeight(value)}
      />
      <ColorPicker
        item={element}
        title="Background Color"
        updateColor={(backgroundColor) =>
          modifyContent.merge(editor, location, {
            attributes: { style: { backgroundColor } },
          })
        }
        currentColor={styleObject?.backgroundColor}
      />
    </Div>
  );
};
