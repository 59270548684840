import { CustomAxios } from '../redux/axios/axios';
import { useQuery, useQueryClient } from 'react-query';
import { useForm } from '../shared/use-form';
import { useParams } from 'react-router';
import { useSearchParams } from '../shared/use-search-params';
import { useStore } from '../store-provider/use-store';

// request: {
//  url: string,
//  queryOptions: ReactQuery useQuery,
//  limit: number,
//  offset: number,
//  axios: AxiosConfig, optional,
//}

const queryFn = (request) => async () => {
  let list = [];
  const startSearch = request?.url?.includes('?') ? '&' : '?';
  let next = `${request.url}${startSearch}limit=${request.limit}&offset=${request.offset}`;
  while (!!next) {
    try {
      const response = await CustomAxios.get(next, request.axios);
      const data = response?.data?.Data || response?.data;
      list = list.concat(data);
      if ('Next' in response?.data) {
        next = response?.data?.Next;
      } else if (data?.length === request.limit) {
        next = `${request.url}${startSearch}limit=${request.limit}&offset=${request.offset + list.length}`;
      } else {
        next = '';
      }
    } catch {
      next = '';
    }
  }
  return list;
};

export const useQueryAllPages = (request = {}) => {
  const {
    data: { user },
  } = useStore();

  const queryClient = useQueryClient();

  const routerParams = useParams();
  const { params: searchParams } = useSearchParams();

  const queryKey = ['all-pages', request.url];

  const query = useQuery({
    ...request.queryOptions,
    queryFn: queryFn(request),
    queryKey,
  });

  const params = {
    ...routerParams,
    ...searchParams,
    productId: routerParams?.planId || '',
    userId: user?.ID,
  };

  const setCache = (value) => {
    queryClient.setQueryData(queryKey, value);
  };

  const { values: data, ...form } = useForm(query?.data, [query?.dataUpdatedAt]);
  return { query, data, form, setCache, params };
};
