import React, { useState } from 'react';
import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { Button, IconButton } from '@material-ui/core';
import Page0 from './page0.png';
import Page1 from './page1.png';
import Page2 from './page2.png';
import Page3 from './page3.png';
import Page4 from './page4.png';
import Page0Header from './page0header.png';
import Page1Header from './page1header.png';
import Page2Header from './page2header.png';
import Page3Header from './page3header.png';
import Page4Header from './page4header.png';
import Page5Header from './page5header.png';
import { Modal } from '../shared/components';

export const WalkThrough = ({ showWalkthrough, setShowWalkthrough }) => {
  const [page, setPage] = useState(0);
  return (
    <Modal
      css={`
        width: 700px;
        padding: 24px;
      `}
      display
      onClose={() => setShowWalkthrough(false)}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
        `}
      >
        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            /* identical to box height, or 133% */

            text-align: center;

            color: #25282d;
          `}
        >
          {page === 1 && <span>01.</span>}
          {page === 2 && <span>02.</span>}
          {page === 3 && <span>03.</span>}
          {page === 4 && <span>04.</span>}
        </div>
        <div>
          <IconButton onClick={() => setShowWalkthrough(false)}>
            <X color="#D1DAE3" />
          </IconButton>
        </div>
      </div>

      {page === 0 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page0Header} width="97px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            Hey Welcome to Brite. We’re so glad you’re here!
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            Brite is a powerful tool and we don’t want you to miss anything, so we have a few tips to help you get
            started.
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setPage(1)}>
              Let's Go
            </Button>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 56px;
            `}
          >
            <img alt="header" src={Page0} width="208px" />
          </div>
        </div>
      )}
      {page === 1 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page1Header} width="140px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            Start by adding your benefits to Brite
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            When you add your benefits your data will be dynamic. You can change it anytime and it will update across
            all of your guides.
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setPage(2)}>
              Next Tip
            </Button>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 56px;
            `}
          >
            <img alt="header" src={Page1} width="208px" />
          </div>
        </div>
      )}
      {page === 2 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page2Header} width="140px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            Then generate a guide from your benefit package
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            You can always create a guide from scratch, but when you generate one from a benefit package Brite will
            automatically generate a beautiful guide for you to share
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setPage(3)}>
              Next Tip
            </Button>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 56px;
            `}
          >
            <img alt="header" src={Page2} width="208px" />
          </div>
        </div>
      )}
      {page === 3 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page3Header} width="140px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            Tweak your guide's design with design styles
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            Design Styles are easy to create, and can be attached to any guide. When you update a Design Style the the
            changes will be reflected on any guide it’s attached to
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setPage(4)}>
              Next Tip
            </Button>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 56px;
            `}
          >
            <img alt="header" src={Page3} width="208px" />
          </div>
        </div>
      )}
      {page === 4 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page4Header} width="140px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            Using Brite’s Decision Tool will increase your employee's enrollment confidence by up to 80%
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            Brite has a simple and friendly decision tool that you can add to any guide to make the stressful decision
            of selecting a benefits a lot easier
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setPage(5)}>
              Got it
            </Button>
          </div>

          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 56px;
            `}
          >
            <img alt="header" src={Page4} width="208px" />
          </div>
        </div>
      )}
      {page === 5 && (
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img alt="header" src={Page5Header} width="140px" />
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              /* identical to box height, or 133% */

              text-align: center;

              color: #25282d;
              margin-top: 38px;
            `}
          >
            See how well your employees are engaging with Brite
          </div>
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              /* or 150% */

              text-align: center;
              letter-spacing: 0.15px;

              color: #66737f;
              margin-top: 16px;
              margin-bottom: 56px;
            `}
          >
            Brite’s powerful analytics are an awesome way to see how employees are engaging with your guides
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button variant="contained" color="primary" onClick={() => setShowWalkthrough(false)}>
              Got it
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
