import { css } from '@emotion/css';
import { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { X } from '@phosphor-icons/react';
import { Button, Modal, Text } from '../../../shared/components';

export const ImportEmailAddressesModal = ({ hideImportEmailAddresses, emailAddresses, setEmailAddresses }) => {
  const [numImportedEmailRows, setNumImportedEmailRows] = useState(0);
  const [badEmailsFromUpload, setBadEmailsFromUpload] = useState([]);

  const isValidEmail = (email) => {
    return /^\S+@\S+$/.test(email);
  };

  return (
    <Modal
      onClose={() => {
        hideImportEmailAddresses();
      }}
      display
      css={`
        overflow: auto;
        min-width: 800px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 32px;
        `}
      >
        <Text h2>Import Email Addresses</Text>
        <Button
          styles="icon"
          onClick={() => {
            hideImportEmailAddresses();
          }}
        >
          <X size={24} />
        </Button>
      </div>
      <div
        className={css`
          margin-bottom: 32px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div>
            <div
              className={css`
                margin-bottom: 16px;
              `}
            >
              Import a CSV file with a column containing the email addresses you wish to use.
            </div>
            <Importer
              defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
              restartable={false} // optional, lets user choose to upload another file when import is complete
              delimiter={','} // this struggles to autodetect if the delimiters are not consistent, so we force it to be a comma and it will read better whether or not there are commas
              processChunk={async (rows, { startIndex }) => {
                try {
                  new Promise((resolve) => {
                    let addresses = new Set(emailAddresses);
                    let badAddresses = [];
                    rows.forEach((row, index) => {
                      // Check that the number is a valid mobile phone number.
                      if (isValidEmail(row.EmailAddress)) {
                        // The backend will handle adding the US country code, if it is missing.
                        addresses.add(row.EmailAddress);
                      } else {
                        badAddresses.push({
                          EmailAddress: row.EmailAddress,
                          Error: 'Does not appear to be an email address.',
                          Row: index + 1 + startIndex,
                        });
                      }
                    });
                    setNumImportedEmailRows((numImportedEmailRows) => numImportedEmailRows + rows.length);
                    setBadEmailsFromUpload((badEmailsFromUpload) => [...badEmailsFromUpload, ...badAddresses]);
                    setEmailAddresses((emailAddresses) => new Set([...emailAddresses, ...addresses]));
                    resolve();
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              onClose={() => {
                hideImportEmailAddresses();
              }}
            >
              <ImporterField name="EmailAddress" label="Email Address" />
            </Importer>
            {badEmailsFromUpload.length > 0 && (
              <div>
                <Text
                  h4
                  css={css`
                    font-size: 16px;
                    margin-top: 16px;
                    margin-bottom: 32px;
                    text-align: center;
                  `}
                >
                  {badEmailsFromUpload.length} of {numImportedEmailRows} rows were skipped with errors.
                </Text>
                <div
                  className={css`
                    margin-bottom: 24px;
                    overflow-y: scroll;
                    max-height: 250px;
                  `}
                ></div>
                <div
                  className={css`
                    display: flex;
                    justify-content: center;
                    margin-bottom: 32px;
                  `}
                >
                  <Button
                    onClick={() => {
                      const csvOfImportErrors =
                        'data:text/csv;charset=utf-8,Row,EmailAddress,Error\r\n' +
                        badEmailsFromUpload
                          .map((e) => {
                            return [e.Row, e.EmailAddress, e.Error];
                          })
                          .join('\n');
                      const encodedUri = encodeURI(csvOfImportErrors);
                      const link = document.createElement('a');
                      link.setAttribute('href', encodedUri);
                      link.setAttribute('download', 'brite_email_import_errors.csv');
                      document.body.appendChild(link);
                      link.click();
                    }}
                  >
                    Download Errors
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
