import { useDropzone } from 'react-dropzone';
import { Box, Button, Modal, Text } from '../shared/components';
import { fileTypesMap } from '../Documents/FullscreenUploader';
import { Trash, UploadSimple } from '@phosphor-icons/react';
import { CustomAxios } from '../redux/axios/axios';
import { useMemo, useState } from 'react';
import { resizeAndUpload } from './upload-utils';

export const UploadGuideHeader = ({ refetch, course, onClose }) => {
  const [courseLogoURL, setCourseLogoURL] = useState(course?.LogoURL);
  const [file, setFile] = useState(null);

  const deleteFile = async () => {
    try {
      setCourseLogoURL('');
      await CustomAxios.put(`v2/course/${course?.ID}`, {
        ...course,
        LogoURL: '',
      });
      refetch();
    } catch {
      setCourseLogoURL(course?.LogoURL);
    } finally {
      onClose();
    }
  };

  const readFile = (acceptedFiles) => {
    if (acceptedFiles?.length === 1) {
      setFile(acceptedFiles[0]);
    }
  };

  const fileUpload = () => {
    const url = '/v1/media';
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (file.size > 1000000) {
      return resizeAndUpload(file, 1000, 1000, file.type, url, config, formData);
    } else {
      formData.append('file', file);
      return CustomAxios.post(url, formData, config);
    }
  };

  const submitFile = async () => {
    try {
      const response = await fileUpload();
      await CustomAxios.put(`v2/course/${course?.ID}`, {
        ...course,
        LogoURL: response.data,
      });
    } catch {
    } finally {
      refetch();
      onClose();
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: readFile,
    accept: [fileTypesMap.images],
    noDragEventsBubbling: true,
  });

  const fileSrc = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (courseLogoURL) {
      return courseLogoURL;
    }
    return null;
  }, [file, courseLogoURL]);

  return (
    <Modal display={true} onClose={onClose}>
      <Modal.Paper>
        <Modal.Header title="Upload Header Image" />
        <Modal.Body>
          <Text>An image header will appear in the top left corner of each page throughout your guide.</Text>
          {file || courseLogoURL ? (
            <Box
              css={`
                width: 100%;
                height: 250px;
                margin: 24px 0;
                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              `}
            >
              <img src={fileSrc} alt="file" />
            </Box>
          ) : (
            <Box
              upload
              {...getRootProps()}
              css={`
                margin: 24px 0;
              `}
            >
              {isDragActive ? (
                <UploadSimple size={64} />
              ) : (
                <>
                  <input {...getInputProps()} />
                  <Box>
                    <Text>Drag and Drop or click to upload an image</Text>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Modal.Body>
        <Modal.Actions>
          <Box
            flex="space-between"
            css={`
              width: 100%;
            `}
          >
            <Button
              hoverLabel="Remove Image"
              styles="icon sm"
              css={`
                ${courseLogoURL ? '' : 'visibility: hidden;'}
              `}
              onClick={deleteFile}
            >
              <Trash />
            </Button>
            <Box flex="right">
              <Button secondary onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={submitFile}>Save Image</Button>
            </Box>
          </Box>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
