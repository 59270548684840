import React, { useMemo } from 'react';
import { MultiChooseCompanyContainer } from './Sidebar/MultiChooseCompany';
import { css } from '@emotion/css';
import { useLocation } from 'react-router';
import { useStore } from './store-provider/use-store';
import { Button, Div, DropMenu, Text } from './shared/components';
import { container, flex } from './shared/shared-styles';
import { MegaphoneSimple } from '@phosphor-icons/react';
import { colors } from './shared/styles';
import { MenuItem } from '@material-ui/core';
import { UserSettings } from './user-settings';
import { Notifications } from './Notifications/Notifications';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { UserAvatar } from './shared/components/UserAvatar';
import { extractSearchQuery } from './react-query';

export const MultiHeader = (props) => {
  const {
    data: { isBriteUser, user, selectedBusiness, parentBusiness },
  } = useStore();

  const requestBuildsFF = useFeatureFlagPayload('request-builds');
  const editorCollaborationFF = useFeatureFlagPayload('editor-collaboration');
  const [modal, setModal] = React.useState('');

  // ************************************************
  // TODO: Remove alongside editorCollaborationFF
  const [hasUnseenReleases, setHasUnseenReleases] = React.useState(window.rnw.unseen_count > 0);
  const displayRelease = () => {
    window.rnw('show');
    setHasUnseenReleases(false);
  };
  // ************************************************

  const requestBuild = () => {
    const query = {
      name: encodeURIComponent(parentBusiness?.ParentName || parentBusiness?.Name),
      email: encodeURIComponent(user?.Email),
      firstname: encodeURIComponent(user?.FirstName),
      lastname: encodeURIComponent(user?.LastName),
      subject: encodeURIComponent(selectedBusiness?.Name),
    };
    const searchQuery = extractSearchQuery(query);
    window.open(`https://share.hsforms.com/1ocfe_278SJmEYkjOl5h9fg3ihp9?${searchQuery}`, '_blank');
  };

  const location = useLocation();

  const userDisplay = useMemo(() => {
    let displayText = '';
    if (user) {
      if (user.FirstName) {
        displayText = `${user.FirstName}`;
      }
      if (user.LastName) {
        displayText += ` ${user.LastName}`;
      }
      if (!displayText) {
        displayText = user.Email;
      }
    }
    return displayText;
  }, [user?.FirstName, user?.LastName, user?.Email]);

  if (!isBriteUser && (location.pathname.includes('dashboard') || location.pathname.includes('members'))) {
    return null;
  }

  return (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 64px;
          padding: 24px 40px;
          margin: 0;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 1;
        `}
      >
        <div
          className={css`
            ${flex('left')}
          `}
        >
          <MultiChooseCompanyContainer />
        </div>

        <Div
          css={css`
            ${flex('right')}
          `}
        >
          {editorCollaborationFF?.value ? (
            <Notifications />
          ) : (
            <Div
              css={css`
                position: relative;
              `}
            >
              <Button styles="icon" onClick={displayRelease}>
                <MegaphoneSimple />
              </Button>
              {hasUnseenReleases ? (
                <Div
                  css={css`
                    position: absolute;
                    top: 4px;
                    right: 8px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: ${colors.red[100]};
                  `}
                />
              ) : null}
            </Div>
          )}

          <DropMenu
            transformOrigin={{
              vertical: -48,
            }}
            button={
              <div
                className={css`
                  ${flex('left')}
                  border-radius: 30px;
                  ${container.hover}
                  padding: 8px;
                  padding-right: 16px;
                `}
              >
                <UserAvatar />
                <Text
                  label
                  bold
                  css={`
                    margin-left: 16px;
                  `}
                >
                  {userDisplay}
                </Text>
              </div>
            }
          >
            <MenuItem onClick={() => setModal('edit-user')}>User Settings</MenuItem>
            <MenuItem
              onClick={(e) => {
                window.open('https://britehr.app/Help-Center-Resources/1', '_blank');
              }}
            >
              Learn Brite
            </MenuItem>
            {requestBuildsFF?.value ? <MenuItem onClick={requestBuild}>Request a Build</MenuItem> : null}
            <MenuItem onClick={() => props.logout({ returnTo: window.location.origin })}>Logout</MenuItem>
          </DropMenu>
        </Div>
      </div>
      <UserSettings display={modal === 'edit-user'} onClose={() => setModal('')} />
    </>
  );
};
