import { useEffect, useState } from 'react';
import { Box, Button, Input, Modal, Select, Text } from '../shared/components';
import { shadows } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useForm } from '../shared/use-form';
import { industries, states } from '../constants';
import { Option } from '../shared/components/select';
import { Check } from '@phosphor-icons/react';
import { Checkbox } from '../shared/components/checkbox';
import { useAuth0 } from '../Auth/react-auth0-wrapper';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { BriteLoader } from '../shared/components/brite-loader';
import { Collapse } from '../common/components/Collapse';

const createBusiness = async (payload) => {
  try {
    let { Name, EmployeeCount, user, Type, IsActive, LogoURL, Industry, Domain, State, PaymentSource } = payload;

    let business = {
      Name,
      EmployeeCount: parseInt(EmployeeCount),
      IsActive,
      Type: Type || 'single',
      LogoURL,
      Industry,
      Domain,
      State,
      PaymentSource,
    };

    const response = await CustomAxios.post(`/public/v1/business`, business);

    let businessResp = response.data;

    CustomAxios.setHeader('BusinessID', businessResp.ID);
    CustomAxios.setHeader('ParentID', businessResp.ID);

    let userNew = {
      FirstName: user.firstName || '',
      LastName: user.lastName || '',
      Email: user.emailAddress,
      Type: Type + 'Admin',
      Business: {
        ID: businessResp.ID,
      },
    };

    const createUserResponse = await CustomAxios.post(`/public/v1/users`, userNew);

    let userResp = createUserResponse.data;
    let businessId = '';
    if (userResp && userResp.Business) {
      businessId = userResp.Business.ID;
    }

    await CustomAxios.post(`/v1/terms`, {
      BusinessID: businessId,
      UserID: userResp.ID,
    });

    window.location.reload();
  } catch (error) {
    toast.error('Failed to create business');
  }
};

const cardContainer = (selected) => `
  margin-bottom: 32px;
  padding: 32px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${colors.gray[300]};
  ${
    selected
      ? `
      outline: 2px solid ${colors.black};
      border: 1px solid transparent;
      `
      : ''
  }
`;

const getIsSectionComplete = (form, sectionIdx = null) => {
  const sections = [
    [form?.values?.type],
    [
      form?.values?.name,
      form?.values?.companyState,
      form?.values?.websiteURL,
      form?.values?.employeeCount >= 0,
      form?.values?.type !== 'single' || form?.values?.industry,
    ],
    [form?.values?.firstName, form?.values?.lastName, form?.values?.agree],
  ];
  return sectionIdx === null
    ? sections.every((section) => section.every((item) => item))
    : sections[sectionIdx].every((item) => item);
};

export const CreateBusinessModal = () => {
  const [loading, setLoading] = useState(false);
  const { user, logout } = useAuth0();

  const [idx, setIdx] = useState(0);

  const form = useForm({
    type: '',
    firstName: user?.given_name || '',
    lastName: user?.family_name || '',
  });

  useEffect(() => {
    if (form.values.type) {
      setIdx(1);
    }
  }, [form.values.type]);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      Name: form?.values?.name,
      IsActive: false,
      Type: form?.values?.type,
      LogoURL: '',
      Industry: form?.values?.industry,
      Domain: form?.values?.websiteURL,
      EmployeeCount: form?.values?.employeeCount,
      State: form?.values?.companyState,
      PaymentSource: form?.values?.type,
      user: {
        firstName: form?.values?.firstName,
        lastName: form?.values?.lastName,
        emailAddress: user?.email,
      },
    };
    await createBusiness(payload);
  };

  const isDisabled = !getIsSectionComplete(form);
  return (
    <Modal display={true} full>
      <BriteLoader isLoading={loading} />
      <Modal.Paper
        css={`
          width: 100vw;
          height: 100vh;
          padding: 0;
          padding-top: 32px;
        `}
      >
        <Box
          flex="space-between"
          css={`
            border-bottom: 1px solid ${colors.gray[300]};
            padding: 32px;
            padding-top: 0;
          `}
        >
          <Text h1>Setup Brite Account</Text>
          <Text label bold>
            {user?.email}
          </Text>
        </Box>
        <Modal.Body
          css={`
            padding: 32px;
            width: 100vw;
            height: calc(100vh - 200px);
            overflow-y: auto;
          `}
        >
          <BusinessType form={form} idx={idx} setIdx={setIdx} />
          {form?.values?.type === 'single' || form?.values?.type === 'multi' ? (
            <>
              <BusinessInformation form={form} idx={idx} setIdx={setIdx} />
              <YourInformation form={form} idx={idx} setIdx={setIdx} />
            </>
          ) : form?.values?.type === 'carrier' ? (
            <Box
              css={`
                margin: 64px auto;
                max-width: 700px;
              `}
            >
              <Text
                h4
                css={`
                  margin-bottom: 16px;
                `}
              >
                Coming soon! Contact us to learn more by emailing sales@britebenefits.com
              </Text>
              <Text>
                Manage how your products are presented to brokers, employers, and employees on the Brite platform.
              </Text>
            </Box>
          ) : form?.values?.type === 'other' ? (
            <Box
              css={`
                margin: 64px auto;
                max-width: 700px;
              `}
            >
              <Text h4>How can I add my user to an existing company on Brite?</Text>
              <Box
                css={`
                  margin-top: 16px;
                  border-radius: 8px;
                  border: 1px solid ${colors.gray[300]};
                  padding: 32px;
                `}
              >
                <Text label bold>
                  Contact the admin on your company's account
                </Text>
                <Text>
                  The admin can add you as a user on the company's account and try again. Make sure they use the same
                  email address that you signed up with.
                </Text>
              </Box>
              <Box
                css={`
                  margin-top: 16px;
                  border-radius: 8px;
                  border: 1px solid ${colors.gray[300]};
                  padding: 32px;
                `}
              >
                <Text label bold>
                  Contact Us
                </Text>
                <Text>Reach out to our support team at support@britebenefits.com</Text>
              </Box>
            </Box>
          ) : null}
        </Modal.Body>
      </Modal.Paper>
      <Box
        css={`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 32px;
          border-top: 1px solid ${colors.gray[300]};
          z-index: 100;
          background-color: white;
        `}
        flex="space-between"
      >
        <Box>
          <Text label bold>
            Questions?
          </Text>
          <Text>Reach out to our sales team at sales@britebenefits.com</Text>
        </Box>
        <Box flex="right">
          <Button
            secondary
            onClick={() => logout({ returnTo: window.location.origin })}
            css={`
              margin-right: 16px;
            `}
          >
            Logout
          </Button>
          <Button disabled={isDisabled} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const typeMap = {
  single: 'Employer',
  multi: 'Broker',
  carrier: 'Provider or Vendor',
  other: 'Other',
};

export const BusinessType = ({ form, idx, setIdx }) => {
  return (
    <Box
      css={`
        max-width: 700px;
        margin: 0 auto;
        background-color: white;
        border-radius: 8px;
        margin-bottom: 32px;
        ${shadows.md}
      `}
    >
      <Box
        flex="space-between"
        css={`
          padding: 32px;
          border-radius: 8px;
          cursor: pointer;
        `}
        onClick={() => setIdx(idx === 0 ? null : 0)}
      >
        <Text h2>Select what best fits your business</Text>
        <Text
          css={`
            padding: 0 8px;
            border-radius: 30px;
            background-color: ${colors.black};
            color: white;
          `}
        >
          {typeMap?.[form?.values?.type]}
        </Text>
      </Box>

      <Collapse isOpen={idx === 0}>
        <Box
          css={`
            margin: 32px 32px;
            margin-top: 16px;
            box-sizing: border-box;
            width: calc(100% - 64px);
          `}
        >
          <Box onClick={() => form.merge({ type: 'multi' })} hover css={cardContainer(form?.values?.type === 'multi')}>
            <Text h2>Broker</Text>
            <Text>
              Pick this if you are a benefit brokerage company. Manage benefits for all of the companies in your
              portfolio.
            </Text>
          </Box>
          <Box
            onClick={() => form.merge({ type: 'single' })}
            hover
            css={cardContainer(form?.values?.type === 'single')}
          >
            <Text h2>Employer</Text>
            <Text>Pick this if you are signing up an individual company.</Text>
          </Box>
          <Box
            onClick={() => form.merge({ type: 'carrier' })}
            hover
            css={cardContainer(form?.values?.type === 'carrier')}
          >
            <Text h2>Provider or Vendor</Text>
            <Text>
              Pick this to manage how your products are presented to brokers, employers, and employees on the Brite
              platform.
            </Text>
          </Box>
          <Box onClick={() => form.merge({ type: 'other' })} hover css={cardContainer(form?.values?.type === 'other')}>
            <Text h2>My company is already using Brite</Text>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export const BusinessInformation = ({ form, idx, setIdx }) => {
  const isCompleted = getIsSectionComplete(form, 1);
  return (
    <Box
      css={`
        max-width: 700px;
        margin: 0 auto;
        border-radius: 8px;
        margin-bottom: 32px;
        ${shadows.md}
        background-color: white;
      `}
    >
      <Box
        flex="space-between"
        css={`
          padding: 32px;
          border-radius: 8px;
          cursor: pointer;
        `}
        onClick={() => setIdx(idx === 1 ? null : 1)}
      >
        <Text h2>Business Information</Text>
        <Box
          flex="center"
          css={`
            ${isCompleted
              ? `
              background-color: ${colors.black};
              color: white;
              `
              : `
              background-color: ${colors.gray[400]};
              color: white;
            `}
            border-radius: 50%;
            width: 32px;
            height: 32px;
          `}
        >
          <Check color="currentColor" size={24} />
        </Box>
      </Box>

      <Collapse isOpen={idx === 1}>
        <Box
          css={`
            margin: 32px 32px;
            margin-top: 16px;
            box-sizing: border-box;
            width: calc(100% - 64px);
          `}
        >
          <Box
            flex="space-between"
            css={`
              margin-bottom: 32px;
            `}
          >
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
                margin-right: 32px;
              `}
            >
              <Text label bold>
                Company Name
              </Text>
              <Input {...form?.getProperty('name')} />
            </Box>
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
              `}
            >
              <Text label bold>
                State
              </Text>
              <Select {...form?.getProperty('companyState')}>
                {states?.map((state) => (
                  <Option value={state?.id}>{state?.label}</Option>
                ))}
              </Select>
            </Box>
          </Box>
          <Box flex="space-between">
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
                margin-right: 32px;
              `}
            >
              <Text label bold>
                Website URL
              </Text>
              <Input {...form?.getProperty('websiteURL')} />
            </Box>
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
              `}
            >
              {form?.values?.type === 'multi' ? (
                <Text label bold>
                  Companies in your Portfolio
                </Text>
              ) : form?.values?.type === 'single' ? (
                <Text label bold>
                  Employees in your Company
                </Text>
              ) : null}
              <Input type="number" {...form?.getProperty('employeeCount')} />
            </Box>
          </Box>
          {form?.values?.type === 'single' ? (
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
                margin-right: 32px;
                margin-top: 32px;
              `}
            >
              <Text label bold>
                Industry
              </Text>
              <Select {...form?.getProperty('industry')}>
                {industries?.map((industry) => (
                  <Option key={industry?.name} value={industry?.name}>
                    {industry?.name}
                  </Option>
                ))}
              </Select>
            </Box>
          ) : null}
        </Box>
      </Collapse>
    </Box>
  );
};

export const YourInformation = ({ form, idx, setIdx }) => {
  const isCompleted = getIsSectionComplete(form, 2);

  return (
    <Box
      css={`
        max-width: 700px;
        margin: 0 auto;
        border-radius: 8px;
        margin-bottom: 32px;
        background-color: white;
        ${shadows.md}
      `}
    >
      <Box
        flex="space-between"
        css={`
          padding: 32px;
          border-radius: 8px;
          cursor: pointer;
        `}
        onClick={() => setIdx(idx === 2 ? null : 2)}
      >
        <Text h2>Your Information</Text>
        <Box
          flex="center"
          css={`
            ${isCompleted
              ? `
              background-color: ${colors.black};
              color: white;
              `
              : `
              background-color: ${colors.gray[400]};
              color: white;
            `}
            border-radius: 50%;
            width: 32px;
            height: 32px;
          `}
        >
          <Check color="currentColor" size={24} />
        </Box>
      </Box>

      <Collapse isOpen={idx === 2}>
        <Box
          css={`
            margin: 32px 32px;
            margin-top: 16px;
            box-sizing: border-box;
            width: calc(100% - 64px);
          `}
        >
          <Box
            flex="space-between"
            css={`
              margin-bottom: 32px;
            `}
          >
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
                margin-right: 32px;
              `}
            >
              <Text label bold>
                First Name
              </Text>
              <Input {...form?.getProperty('firstName')} />
            </Box>
            <Box
              css={`
                flex-grow: 1;
                width: 50%;
              `}
            >
              <Text label bold>
                Last Name
              </Text>
              <Input {...form?.getProperty('lastName')} />
            </Box>
          </Box>
          <Box
            flex="left"
            css={`
              a {
                color: ${colors.purple};
              }
            `}
          >
            <Checkbox checked={!!form?.values?.agree} onChange={() => form?.merge({ agree: !form?.values?.agree })} />
            <Box
              css={`
                margin-left: 16px;
              `}
            >
              <Text>
                I agree to the{' '}
                <a href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9" target="_blank">
                  Term of Use
                </a>{' '}
                and I understand the{' '}
                <a href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9" target="_blank">
                  Privacy Policy
                </a>
              </Text>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
