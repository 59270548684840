// FORM CONFIGS
// These configs affects the product-form editor

import { MenuItem } from '@material-ui/core';
import { addYears, subDays } from 'date-fns';
import { css } from '@emotion/css';
import { Asterisk, Buildings, CheckSquare, CurrencyDollarSimple, ListPlus, MapPin } from '@phosphor-icons/react';
import { Icon } from '../../shared/components';
import { FieldInput } from '../field-components/field-input';
import { getFieldValue } from '../field-components/field-utils';
import { LayoutContainer, LayoutSelect } from '../field-components/layout-container';
import { ReactComponent as HandClicking } from '../../images/svg/hand-clicking.svg';

export const productTypeConfigs = {};

export const advancedInputFields = [
  'Details.InNetworkProfessionalServices.PrimaryCarePhysician',
  'Details.InNetworkProfessionalServices.Specialist',
  'Details.InNetworkProfessionalServices.PreventativeCare',
  'Details.InNetworkProfessionalServices.TeleMedicine',
  'Details.InNetworkProfessionalServices.InPatientHospital',
  'Details.InNetworkProfessionalServices.OutPatientSurgery',
  'Details.InNetworkProfessionalServices.MentalHealthOutpatient',
  'Details.InNetworkProfessionalServices.UrgentCare',
  'Details.InNetworkProfessionalServices.EmergencyRoom',
  'Details.InNetworkProfessionalServices.PhysicalTherapy',
  'Details.InNetworkProfessionalServices.VirtualPrimary',
  'Details.InNetworkProfessionalServices.VirtualSpecialty',
];

export const surestInputFields = [
  'Details.SurestProfessionalServices.PrimaryCare',
  'Details.SurestProfessionalServices.Specialist',
  'Details.SurestProfessionalServices.TeleMedicine',
  'Details.SurestProfessionalServices.InpatientHospital',
  'Details.SurestProfessionalServices.OutpatientSurgery',
  'Details.SurestProfessionalServices.MentalHealth',
  'Details.SurestProfessionalServices.Baby',
  'Details.SurestProfessionalServices.UrgentCare',
  'Details.SurestProfessionalServices.EmergencyRoom',
  'Details.SurestProfessionalServices.PhysicalTherapy',
  'Details.SurestProfessionalServices.Virtual',
  'Details.SurestProfessionalServices.VirtualSpecialty',
  'Details.SurestProfessionalServices.Tier1',
  'Details.SurestProfessionalServices.Tier2',
  'Details.SurestProfessionalServices.Tier3',
  'Details.SurestProfessionalServices.Tier4',
  'Details.SurestProfessionalServices.Tier5',
  'Details.SurestProfessionalServices.Tier6',
];

export const formSectionConfigs = {
  defaultConfig: () => ({
    'Premiums & Contributions': {
      Icon: CurrencyDollarSimple,
    },
    'Network Information': {
      Icon: MapPin,
    },
    'Plan Details': {
      Icon: ListPlus,
    },
    'Carrier Details': {
      Icon: Buildings,
    },
    'Custom Question': {
      Icon: CheckSquare,
    },
    'Optional Information': {
      Icon: Asterisk,
    },
    'Call to Action': {
      Icon: ({ ...props }) => <Icon SVG={HandClicking} {...props} />,
    },
  }),
  insurance_plan: ({ product }) => ({
    'Medical Plan Details': {
      isHidden: !!product?.MultiNetworkCategory && product?.MultiNetworkCategory !== 'core',
    },
    'Premiums & Contributions': {
      Icon: CurrencyDollarSimple,
      syncable: !!product?.MultiNetworkCategory && product?.MultiNetworkCategory !== 'core',
    },
    'Network Information': {
      Icon: MapPin,
    },
    'Plan Details': {
      Icon: ListPlus,
      syncable: !!product?.MultiNetworkCategory && product?.MultiNetworkCategory !== 'core',
    },
    'Carrier Details': {
      Icon: Buildings,
    },
    'Custom Question': {
      Icon: CheckSquare,
    },
    'Optional Information': {
      Icon: Asterisk,
    },
    'Call to Action': {
      Icon: ({ ...props }) => <Icon SVG={HandClicking} {...props} />,
    },
  }),
};

// Configurable properties for fieldConfigs: ./config-utils.js

export const formFieldConfigs = {
  // allProductTypes are overriden by more specific product Types
  defaultConfig: ({ product }) => {
    return {
      ProviderID: {
        hideEditLayout: true,
        needsAttention:
          (!product?.ProviderID || product?.ProviderID === '00000000-0000-0000-0000-000000000000') &&
          !product?.ProviderName,
      },
      ProviderName: {
        showSuggestion: true,
        hideField: true,
        hideEditLayout: true,
        disabled: !!product?.ProviderID && product?.ProviderID !== '00000000-0000-0000-0000-000000000000',
        needsAttention: !product?.ProviderName && !product?.ProviderID,
      },
      ProductName: { hideEditLayout: true },
    };
  },

  custom: ({ product }) => ({
    Description: {
      hideEditLayout: true,
    },
    'Question.Text': {
      needsAttention: product?.Question?.Responses?.length && !product?.Question?.Text,
    },
    CallToActionText: {
      hideEditLayout: true,
    },
    CallToActionUrl: {
      hideEditLayout: true,
    },
  }),

  insurance_plan: ({ product }) => {
    const { Details } = product;

    return {
      'Details.EffectiveDate': {
        hideEditLayout: true,
        productTrigger: (value) => {
          try {
            const nextYear = subDays(addYears(new Date(value), 1), 1).toISOString();
            return {
              'Details.DeductibleReset': nextYear,
            };
          } catch (err) {
            console.log(err);
            return {};
          }
        },
      },
      'Details.DeductibleReset': { hideEditLayout: true },
      'Details.MultiCarrierNetworkCarrierSearchUrl': {
        hideEditLayout: true,
        hideField: !Details.IsMultiCarrier,
      },
      'Details.MultiCarrierNetworkDescription': {
        hideEditLayout: true,
        hideField: !Details.IsMultiCarrier,
      },
      'Details.MultiCarrierNetworkName': {
        hideEditLayout: true,
        hideField: !Details.IsMultiCarrier,
      },
      'Details.IsMultiCarrier': {
        hideEditLayout: true,
        layoutTrigger: (value) => {
          try {
            const updates = { State: value ? 'show' : 'hide' };
            return {
              'Details.MultiCarrierApplicableStates': updates,
              'Details.MultiCarrierID': updates,
              'Details.MultiCarrierName': updates,
              'Details.MultiCarrierNetworkCarrierSearchUrl': updates,
              'Details.MultiCarrierNetworkDescription': updates,
              'Details.MultiCarrierNetworkName': updates,
            };
          } catch (err) {
            console.log(err);
            return {};
          }
        },
      },
      'Details.MultiCarrierApplicableStates': {
        hideEditLayout: true,
        hideField: !Details.IsMultiCarrier,
      },
      'Details.MultiCarrierID': {
        hideEditLayout: true,
        hideField: !Details.IsMultiCarrier,
      },
      'Details.MultiCarrierName': {
        hideEditLayout: true,
        disabled:
          !!product?.Details.MultiCarrierID &&
          product?.Details.MultiCarrierID !== '00000000-0000-0000-0000-000000000000',
        hideField: !Details.IsMultiCarrier,
      },
      'Details.NetworkName': { hideEditLayout: true },
      'Details.NetworkProviderSearchUrl': { hideEditLayout: true },
      'Details.NetworkDescription': { hideEditLayout: true },
      'Details.InNetworkPlanDesign.SingleDeductible': {
        storeValueAs: 'number',
      },
      'Details.InNetworkPlanDesign.Deductible': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.SingleOOPM': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.OOPM': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.Coinsurance': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.SingleEmbeddedDeductibleOOPM': {
        hideEditLayout: true,
      },

      'Details.InNetworkPrescriptionDrugs.PrescriptionDrugTier': {
        hideEditLayout: true,
        layoutTrigger: (value) => ({
          'Details.InNetworkPrescriptionDrugs.Tier4': {
            State:
              value === '4 Tier Structure' || value === '5 Tier Structure' || value === '6 Tier Structure'
                ? 'show'
                : 'hide',
          },
          'Details.InNetworkPrescriptionDrugs.Tier5': {
            State: value === '5 Tier Structure' || value === '6 Tier Structure' ? 'show' : 'hide',
          },
          'Details.InNetworkPrescriptionDrugs.Tier6': {
            State: value === '6 Tier Structure' ? 'show' : 'hide',
          },
        }),
      },
      'Details.PlanType': {
        hideEditLayout: true,
      },
      ApplicableStates: { hideEditLayout: true },
      MultiNetworkCategory: { hideEditLayout: true },

      // Perscription Drugs ************************************************************************************************
      'Label.PrescriptionDrugs': {
        hideEditLayout: true,
      },
      'Details.InNetworkPrescriptionDrugs.Tier4': {
        hideField: !(
          Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '4 Tier Structure' ||
          Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '5 Tier Structure' ||
          Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '6 Tier Structure'
        ),
      },
      'Details.InNetworkPrescriptionDrugs.Tier5': {
        hideField: !(
          Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '5 Tier Structure' ||
          Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier === '6 Tier Structure'
        ),
      },
      'Details.InNetworkPrescriptionDrugs.Tier6': {
        hideField: Details?.InNetworkPrescriptionDrugs?.PrescriptionDrugTier !== '6 Tier Structure',
      },

      ...surestInputFields?.reduce(
        (prev, item) => ({
          ...prev,
          [item]: {
            hideField: true,
          },
        }),
        {}
      ),
    };
  },

  dental_buyup: ({ product }) => {
    // layoutOptions
    const { Details } = product;
    return {
      ProductPlanType: {
        hideEditLayout: true,
      },
      'Details.AnnualMaximum': {
        formatOptions: {
          dollar: 'Dollar',
          percent: 'Percent',
          'text-input': 'Freeform',
        },
      },
      'Details.IndividualDeductible': {
        formatOptions: {
          dollar: 'Dollar',
          percent: 'Percent',
          'text-input': 'Freeform',
        },
      },
      'Details.FamilyDeductible': {
        formatOptions: {
          dollar: 'Dollar',
          percent: 'Percent',
          'text-input': 'Freeform',
        },
      },

      'Details.InNetworkPlanDesign.SingleDeductible': {
        storeValueAs: 'number',
      },
      'Details.InNetworkPlanDesign.Deductible': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.SingleOOPM': { storeValueAs: 'number' },
      'Details.InNetworkPlanDesign.OOPM': { storeValueAs: 'number' },
      'Details.OrthodonticsLifetimeMax': {
        hideField: !Details?.IncludesOrtho,
      },
      'Details.Children': {
        hideField: !Details?.IncludesOrtho,
      },
      'Details.Adult': {
        hideField: !Details?.IncludesOrtho,
      },
      'Details.IncludesOrtho': {
        hideEditLayout: true,
        layoutTrigger: (value) => {
          const updates = { State: value ? 'show' : 'hide' };
          return {
            'Details.OrthodonticsLifetimeMax': updates,
            'Details.Children': updates,
            'Details.Adult': updates,
          };
        },
      },
      ProductNetworkName: {
        hideEditLayout: true,
      },
      ProductNetworkProviderSearchUrl: {
        hideEditLayout: true,
      },
      ProductNetworkDescription: {
        hideEditLayout: true,
      },
    };
  },

  vision_buyup: ({ product }) => {
    return {
      'Details.EmployeeCost': {
        hideEditLayout: true,
      },

      'Details.EyeExams': {
        customFormField: (props) => <VisionFrequency {...props} frequencyPropertyChain="Details.EyeExamsFrequency" />,
      },
      'Details.EyeExamsFrequency': {
        hideField: true,
      },

      'Details.LensesBenefit': {
        customFormField: (props) => <VisionFrequency {...props} frequencyPropertyChain="Details.LensesFrequency" />,
      },
      'Details.LensesFrequency': {
        hideField: true,
      },

      'Details.ContactLenses': {
        customFormField: (props) => (
          <VisionFrequency {...props} frequencyPropertyChain="Details.ContactLensesFrequency" />
        ),
      },
      'Details.ContactLensesFrequency': {
        hideField: true,
      },

      'Details.Frames': {
        customFormField: (props) => <VisionFrequency {...props} frequencyPropertyChain="Details.FramesFrequency" />,
      },
      'Details.FramesFrequency': {
        hideField: true,
      },
    };
  },

  basic_life_v2: ({ product }) => {
    const { Details } = product;
    return {
      'Details.EmployeeBenefit': {
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          multipleOfSalary: 'Multiple Of Salary',
        },
      },

      'Details.ADDIncluded': {
        hideEditLayout: true,
      },
      'Details.SpouseDependantCoverage': {
        hideEditLayout: true,
        layoutTrigger: (value) => {
          const nextState = {
            State: value ? 'show' : 'hide',
          };
          return {
            'Details.SpouseBenefit': nextState,
            'Details.DependantBenefit': nextState,
          };
        },
      },
      'Details.SpouseBenefit': {
        hideField: !Details.SpouseDependantCoverage,
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          percentOfEmployeeElectedAmount: 'Percentage of Employee Elected Amount',
        },
      },
      'Details.DependantBenefit': {
        hideField: !Details.SpouseDependantCoverage,
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          percentOfEmployeeElectedAmount: 'Percentage of Employee Elected Amount',
        },
      },
    };
  },

  supplemental_life_v2: ({ product }) => {
    const { Details } = product;
    return {
      'Details.EmployeeMaximumCoverage': {
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          multipleOfSalary: 'Multiple Of Salary',
        },
      },
      'Details.EmployeeGuaranteeIssue': {
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          multipleOfSalary: 'Multiple Of Salary',
        },
      },
      'Details.ADDIncluded': {
        hideEditLayout: true,
      },
      'Details.SpouseDependantCoverage': {
        hideEditLayout: true,
        layoutTrigger: (value) => {
          const nextState = {
            State: value ? 'show' : 'hide',
          };
          return {
            'Details.SpouseMaximumCoverage': nextState,
            'Details.DependantMaximumCoverage': nextState,
            'Details.SpouseGuaranteeIssue': nextState,
            'Details.DependantGuaranteeIssue': nextState,
          };
        },
      },
      'Details.SpouseMaximumCoverage': {
        hideField: !Details.SpouseDependantCoverage,
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          percentOfEmployeeElectedAmount: 'Percentage of Employee Elected Amount',
        },
      },
      'Details.DependantMaximumCoverage': {
        hideField: !Details.SpouseDependantCoverage,
        layoutAlignment: 'vertical',
        formatOptions: {
          dollar: 'Fixed Amount',
          percentOfEmployeeElectedAmount: 'Percentage of Employee Elected Amount',
        },
      },
      'Details.SpouseGuaranteeIssue': {
        hideField: !Details.SpouseDependantCoverage,
        formatOptions: {
          dollar: 'Dollar',
          percent: 'Percent',
        },
      },
      'Details.DependantGuaranteeIssue': {
        hideField: !Details.SpouseDependantCoverage,
        formatOptions: {
          dollar: 'Dollar',
          percent: 'Percent',
        },
      },
    };
  },

  supplimental_life: ({ product }) => {
    const { Details } = product;
    return {
      'Details.EmployerPaidCoverage': {
        hideEditLayout: true,
        layoutTrigger: (value) => ({
          'Details.EmployerPaidCoverageAmount': {
            State: value ? 'show' : 'hide',
          },
        }),
      },
      'Details.EmployerPaidCoverageAmount': {
        hideField: !Details?.EmployerPaidCoverage,
      },

      'Details.SupplementalLifeCoverageAvailable': {
        hideEditLayout: true,
        layoutTrigger: (value) => {
          const updates = { State: value ? 'show' : 'hide' };
          return {
            'Details.TotalLifeInsuranceBenefitString': updates,
            'Details.SupplementalLifeCoverageAvailable': updates,
            'Details.GuaranteeIssueAmount': updates,
            'Details.SpouseCoverage': updates,
            'Details.SpouseDependantCoverage': updates,
            'Details.DependentCoverage': updates,
          };
        },
      },

      'Details.TotalLifeInsuranceBenefitString': {
        hideField: !Details.SupplementalLifeCoverageAvailable,
        formatOptions: {
          dollar: 'Dollar',
          'text-input': 'Freeform',
        },
      },
      'Details.ADDIncluded': {
        hideEditLayout: true,
        hideField: !Details.SupplementalLifeCoverageAvailable,
      },
      'Details.GuaranteeIssueAmount': {
        hideField: !Details.SupplementalLifeCoverageAvailable,
      },
      'Details.SpouseDependantCoverage': {
        hideEditLayout: true,
        hideField: !Details.SupplementalLifeCoverageAvailable,
        layoutTrigger: (value) => {
          const updates = { State: value ? 'show' : 'hide' };
          return {
            'Details.SpouseCoverage': updates,
            'Details.DependentCoverage': updates,
          };
        },
      },
      'Details.SpouseCoverage': {
        hideField: !(Details.SupplementalLifeCoverageAvailable && Details?.SpouseDependantCoverage),
        formatOptions: { dollar: 'Dollar', percent: 'Percent' },
      },
      'Details.DependentCoverage': {
        hideField: !(Details.SupplementalLifeCoverageAvailable && Details?.SpouseDependantCoverage),
        formatOptions: { dollar: 'Dollar', percent: 'Percent' },
      },
    };
  },
};

export const getProductNeedsAttention = (product) => {
  const defaultFieldConfigs = formFieldConfigs?.defaultConfig({ product }) || {};
  const productFieldConfig = formFieldConfigs?.[product?.Type]?.({ product }) || {};
  const fieldConfigs = {
    ...defaultFieldConfigs,
    ...productFieldConfig,
  };
  const fieldsNeedAttention = Object.values(fieldConfigs)?.reduce((prev, value) => {
    return [...prev, value.needsAttention];
  }, []);
  return fieldsNeedAttention.some((item) => item);
};

export const getSectionNeedsAttention = (product, fields) => {
  const defaultFieldConfigs = formFieldConfigs?.defaultConfig({ product }) || {};
  const productFieldConfig = formFieldConfigs?.[product?.Type]?.({ product }) || {};

  const fieldConfigs = {
    ...defaultFieldConfigs,
    ...productFieldConfig,
  };

  return fields?.some(({ PropertyChain }) => fieldConfigs?.[PropertyChain]?.needsAttention);
};

const VisionFrequency = ({ state, fieldProps, frequencyPropertyChain }) => {
  const { fieldsObject, product } = state;
  const { value, updateProperty, field, config } = fieldProps;
  const layoutProps = { state, field, config };

  const fieldConfig = fieldsObject?.[frequencyPropertyChain] || {
    PropertyChain: frequencyPropertyChain,
  };

  const frequencyValue = getFieldValue(state.product, fieldConfig, '12 months');

  // if the frequency value is different from the value in the product, update the product
  if (frequencyValue !== getFieldValue(state.product, fieldConfig)) {
    updateProperty(frequencyPropertyChain, frequencyValue);
  }

  return (
    <LayoutContainer {...layoutProps} hideFlag={true}>
      <div
        className={css`
          display: flex;
        `}
      >
        <FieldInput
          field={{ ...field, productId: product?.ID }}
          updateProperty={updateProperty}
          config={config}
          value={value}
        />
        <LayoutSelect
          value={frequencyValue}
          onChange={(e) => {
            updateProperty(frequencyPropertyChain, e.target.value);
          }}
          tabIndex="-1"
        >
          <MenuItem value={'12 months'}>12 months</MenuItem>
          <MenuItem value={'24 months'}>24 months</MenuItem>
        </LayoutSelect>
      </div>
    </LayoutContainer>
  );
};
