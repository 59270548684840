import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { DotsSix, Trash, DotsThreeVertical, BookmarkSimple, Plus } from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Div, Input, Text, DropMenu } from '../../shared/components';
import { flex, px } from '../../shared/shared-styles';
import { useStateSync } from '../../shared/use-state-sync';
import { productModifier } from '../configs/product-utils';
import { useAcls } from '../../shared/use-acls';
import { WRITE_BENEFITS_PACKAGE } from '../../shared/acl-constants';
import getUuid from 'uuid-by-string';
import { useSearchParams } from '../../shared/use-search-params';
import { Flag } from '../flags/flag';
import { needsAttention } from '../../react-query/resources/needs-attention';

const getKeyedItem = (itemString) => getUuid(itemString);

export const CustomDetails = ({ state }) => {
  const { updateParams } = useSearchParams();

  const { product, flags } = state;

  const flag = needsAttention.utils.getFlagByPropertyChain(flags, 'custom-details');

  const toggleFlag = (e) => {
    e.stopPropagation();
    updateParams({
      editFlagId: flag?.id || '',
      flagProperty: flag?.metadata?.property_chain,
    });
  };

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);

  const [customDetails, setCustomDetails] = useStateSync(
    (product?.TitleDescriptions || [])?.filter(({ Autogenerated }) => !Autogenerated),
    [product?.TitleDescriptions]
  );

  const updateTitleDescriptions = (customDetails) => {
    const autoGenerated = (product?.TitleDescriptions || [])?.filter(({ Autogenerated }) => Autogenerated);
    productModifier.setProduct(state, (data) => ({
      ...data,
      TitleDescriptions: [...autoGenerated, ...customDetails],
    }));
  };

  const addDetail = () => {
    let detail = [...customDetails];
    detail.push({ Title: '', Description: '' });
    updateTitleDescriptions(detail);
  };

  const updateDetail = (idx, property, value) => {
    const index = idx;
    let detail = [...customDetails];
    detail.splice(index, 1, {
      ...customDetails[index],
      [property]: value,
    });
    setCustomDetails(detail);
  };

  const removeDetail = (index) => {
    let detail = [...customDetails].filter((_, idx) => idx !== index);
    updateTitleDescriptions(detail);
  };

  // ****************************
  // ** Manange reordering
  // ****************************
  const ref = useRef();
  const [height, setHeight] = useState(null);
  const reorderDetails = (data) => {
    setHeight(null);
    const source = data?.source?.index;
    const destination = data?.destination?.index;
    let list = [...customDetails];
    const sourceItem = list.splice(source, 1);
    list.splice(destination, 0, ...sourceItem);
    updateTitleDescriptions(list);
  };
  const updateHeight = () => setHeight(ref.current.clientHeight);
  // ****************************

  return (
    <>
      <Text label>
        Plan details like pricing, contributions, and highlights can be added to a custom benefit and will show up in
        the benefit summary.
      </Text>
      {!!customDetails?.length && (
        <>
          <Div
            css={css`
              ${flex('aic')} box-sizing: box-content;
              margin-top: ${px.lg};
            `}
          >
            <Text
              styles="label bold"
              className={css`
                width: 45%;
              `}
            >
              Title
            </Text>
            <Text
              styles="label bold"
              className={css`
                width: 45%;
                margin-left: ${px.md};
              `}
            >
              Description
            </Text>
            <DropMenu
              anchorOrigin={{ horizontal: 'right' }}
              transformOrigin={{ horizontal: 160 }}
              button={
                <Button
                  styles="icon sm"
                  css={`
                    margin: 8px;
                    margin-right: 0;
                  `}
                >
                  <DotsThreeVertical size={24} />
                </Button>
              }
            >
              <MenuItem onClick={toggleFlag}>
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    gap: 32px;
                  `}
                >
                  <div>{flag ? 'Edit Flag' : 'Flag Field'}</div>
                  <div>
                    <BookmarkSimple size={24} />
                  </div>
                </div>
              </MenuItem>
            </DropMenu>
          </Div>

          <Div
            ref={ref}
            css={css`
              ${height === null ? '' : `min-height: ${height}px;`}
            `}
          >
            <DragDropContext onDragEnd={reorderDetails} onBeforeDragStart={updateHeight}>
              <Droppable droppableId="droppable-detail">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {customDetails.map((data, idx) => {
                      const key = getKeyedItem(idx.toString());
                      return (
                        <Draggable key={key} index={idx} draggableId={key}>
                          {(provided) => (
                            <Div
                              key={idx}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                              css={css`
                                ${flex('aic')}
                                margin: 8px 0;
                              `}
                            >
                              <span style={{ visibility: 'none' }}>{provided.placeholder}</span>
                              <Input
                                disabled={data.Autogenerated || !canEdit}
                                className={css`
                                  width: 45%;
                                  margin-right: ${px.lg};
                                `}
                                value={data.Title}
                                onChange={(e) => updateDetail(idx, 'Title', e.target.value)}
                                onBlur={() => updateTitleDescriptions(customDetails)}
                              />
                              <Input
                                disabled={data.Autogenerated || !canEdit}
                                className={css`
                                  width: 45%;
                                  margin-right: ${px.lg};
                                `}
                                value={data.Description}
                                onChange={(e) => updateDetail(idx, 'Description', e.target.value)}
                                onBlur={() => updateTitleDescriptions(customDetails)}
                              />
                              <DotsSix />
                              <Button
                                styles="icon"
                                onClick={() => removeDetail(idx)}
                                disabled={data.Autogenerated || !canEdit}
                              >
                                <Trash size={24} />
                              </Button>
                            </Div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Div>
        </>
      )}
      <Flag flag={flag} />
      <Div
        css={css`
          ${flex('left')} margin-top: 32px;
          width: 100%;
        `}
      >
        <Button disabled={!canEdit} styles="secondary" onClick={addDetail}>
          <Plus />
          Add Detail
        </Button>
      </Div>
    </>
  );
};
