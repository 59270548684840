import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Div, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { animation, container, flex, scrollbar, shadows } from '../shared/shared-styles';
import { ArrowsDownUp } from '@phosphor-icons/react';
import { useOutsideClick } from '../shared/use-outside-click';
import { cloneDeep } from 'lodash';

import Checkbox from '@material-ui/core/Checkbox';

export const FilterDropdown = ({ setFilterVal, filterVal }) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (selected) => {
    let cloned = cloneDeep(filterVal);
    const index = cloned.indexOf(selected);
    if (index === -1) {
      cloned.push(selected);
    } else {
      cloned.splice(index, 1);
    }
    setFilterVal(cloned);
  };

  const ref = useOutsideClick(() => setOpen(false));

  return (
    <Div
      css={css`
        position: relative;
        border-radius: 8px;
        height: 48px;

        ${flex('space-between')}
        padding: 16px;
        padding-top: 12px;
        padding-bottom: 12px;

        background-color: white;
        cursor: pointer;
        border: 1px solid ${colors.gray[300]};
        ${open ? '' : container.hover}
      `}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      <ArrowsDownUp size={24} />
      <Text
        h4
        ellipsis
        css={`
          flex-grow: 1;
          color: var(--700, #25282d);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 160% */
          letter-spacing: 0.46px;
          text-transform: uppercase;
          padding-left: 8px;
        `}
      >
        Filter
      </Text>

      {filterVal && filterVal.length > 0 && (
        <>
          <div
            className={css`
              border-radius: 16px;
              background: var(--Purple, #6d44fb);
              display: flex;
              padding: 6px 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
              margin-left: 8px;
            `}
          >
            <div
              className={css`
                color: var(--000, #fff);
                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 8px; /* 66.667% */
                letter-spacing: 0.15px;
              `}
            >
              {filterVal.length}
            </div>
          </div>
        </>
      )}
      {!open ? null : (
        <Div
          css={css`
            ${animation('fadeIn', '.15s ease')}
            ${shadows?.md}
            position: absolute;
            top: calc(100% + 8px);
            left: 0;
            right: 0;
            background-color: white;
            z-index: 100000000000000000;
            border-radius: 8px;
            cursor: default;
            padding: 8px 0;
            width: 240px;
          `}
          onClick={(e) => e.stopPropagation()}
        >
          <Div
            css={css`
              max-height: 48vh;
              overflow-y: auto;
              padding: 8px;
              ${scrollbar.style}
              width: 240px;
            `}
          >
            <Div
              css={css`
                ${flex('space-between')}
                ${container.hover}
                   
                    border-radius: 8px;
                padding: 4px 8px;
                &:hover {
                  background-color: ${colors.gray[100]};
                }
              `}
              onClick={() => handleSelect('openenrollment')}
            >
              <Div
                css={css`
                  flex-grow: 1;
                  padding: 8px;
                  width: 240px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Checkbox checked={filterVal.indexOf('openenrollment') > -1} color="primary" style={{ padding: 0 }} />
                <Text
                  label
                  css={`
                    flex-grow: 1;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                  `}
                >
                  Open Enrollment
                </Text>
              </Div>
            </Div>
            <Div
              css={css`
                ${flex('space-between')}
                ${container.hover}
                   
                    border-radius: 8px;
                padding: 4px 8px;
                &:hover {
                  background-color: ${colors.gray[100]};
                }
              `}
              onClick={() => handleSelect('newhire')}
            >
              <Div
                css={css`
                  flex-grow: 1;
                  padding: 8px;
                  width: 240px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Checkbox checked={filterVal.indexOf('newhire') > -1} color="primary" style={{ padding: 0 }} />
                <Text
                  label
                  css={`
                    flex-grow: 1;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                  `}
                >
                  New Hire
                </Text>
              </Div>
            </Div>
            <Div
              css={css`
                ${flex('space-between')}
                ${container.hover}
                    
                    border-radius: 8px;
                padding: 4px 8px;
                &:hover {
                  background-color: ${colors.gray[100]};
                }
              `}
              onClick={() => handleSelect('recruiting')}
            >
              <Div
                css={css`
                  flex-grow: 1;
                  padding: 8px;
                  width: 240px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Checkbox checked={filterVal.indexOf('recruiting') > -1} color="primary" style={{ padding: 0 }} />
                <Text
                  label
                  css={`
                    flex-grow: 1;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                  `}
                >
                  Recruiting
                </Text>
              </Div>
            </Div>
            <Div
              css={css`
                ${flex('space-between')}
                ${container.hover}
                   
                    border-radius: 8px;
                padding: 4px 8px;
                &:hover {
                  background-color: ${colors.gray[100]};
                }
              `}
              onClick={() => handleSelect('other')}
            >
              <Div
                css={css`
                  flex-grow: 1;
                  padding: 8px;
                  width: 240px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Checkbox checked={filterVal.indexOf('other') > -1} color="primary" style={{ padding: 0 }} />
                <Text
                  label
                  css={`
                    flex-grow: 1;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                  `}
                >
                  Other
                </Text>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  );
};
