import { css } from '@emotion/css';
import { MapPin } from '@phosphor-icons/react';
import { Button, Div, Text } from '../../../../shared/components';
import { container, flex } from '../../../../shared/shared-styles';

export const NetworkSearch = ({ state }) => {
  const openSearch = (url) => {
    window.open(url, '_blank');
  };

  const {
    IsMultiCarrier,
    MultiCarrierNetworkName,
    MultiCarrierNetworkCarrierSearchUrl,
    NetworkName,
    NetworkDescription,
    NetworkProviderSearchUrl,
  } = state?.product?.Details || {};

  const { ProductNetworkName, ProductNetworkDescription, ProductNetworkProviderSearchUrl } = state?.product || {};

  return (
    <Div
      css={css`
        ${container.box}
        width: 100%;
        padding: 24px 32px;
      `}
    >
      <Div
        css={css`
          ${flex('space-between')}
        `}
      >
        <Text
          css={`
            width: min-content;
            border-radius: 30px;
            background-color: var(--accent-color-4);
            color: var(--accent-color-1);
            padding: 4px 16px;
            margin-right: 16px;
            font-weight: bold;
          `}
        >
          Network
        </Text>
        {IsMultiCarrier ? (
          <Text label bold>
            {ProductNetworkName || NetworkName ? ProductNetworkName || NetworkName : ''}
            {MultiCarrierNetworkName ? ` | ${MultiCarrierNetworkName}` : ''}
          </Text>
        ) : ProductNetworkName || NetworkName ? (
          <Text label bold>
            {ProductNetworkName || NetworkName}
          </Text>
        ) : null}
      </Div>
      <Div
        css={css`
          padding-top: 32px;
        `}
      >
        <Div
          css={css`
            ${flex('left')}
            p {
              margin-left: 16px;
            }
          `}
        >
          <MapPin />
          <Text h2>Search for network providers</Text>
        </Div>
        <Text
          css={`
            margin: 16px 0;
          `}
        >
          {!IsMultiCarrier ? ProductNetworkDescription || NetworkDescription : ''}
        </Text>
        {!IsMultiCarrier && (
          <Button
            onClick={() => {
              openSearch(ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl);
            }}
            css={`
              background-color: var(--accent-color-1);
            `}
          >
            Search Network
          </Button>
        )}
        {IsMultiCarrier && (
          <div
            className={css`
              display: flex;
              gap: 16px;
              flex-wrap: wrap;
            `}
          >
            <Button
              onClick={() => {
                openSearch(ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl);
              }}
              css={`
                background-color: var(--accent-color-1);
              `}
            >
              Search {ProductNetworkName || NetworkName}
            </Button>
            <Button
              onClick={() => {
                openSearch(MultiCarrierNetworkCarrierSearchUrl);
              }}
              css={`
                background-color: var(--accent-color-1);
              `}
            >
              Search {MultiCarrierNetworkName}
            </Button>
          </div>
        )}
      </Div>
    </Div>
  );
};
