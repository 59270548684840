import { css } from '@emotion/css';
import { DownloadSimple, FileText, UploadSimple } from '@phosphor-icons/react';
import { ExtensionIconMapper } from '../../Documents/ExtensionIconMapper';
import { BriteLoader, Div, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useEditorResource } from '../use-editor-resource';
import { modifyContent } from '../provider/utils';
import { fileTypesMap } from '../../Documents/FullscreenUploader';
import { useState } from 'react';
import { useUploadMedia } from '../../shared/useUploadMedia';

const textareaStyle = css`
  width: 100%;
  input {
    display: inline;
    outline: none;
    border: none;
    background: none;
    appearance: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    resize: none;
    margin-left: 16px;
    font-size: 18px;
    color: ${colors.black};
  }
`;

const accept = Object.values(fileTypesMap).join(', ');

export const Document = ({ editor, element, location }) => {
  const { document = null } = element;
  const { data: styles } = useEditorResource('styles');
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);
  return (
    <div className={textareaStyle}>
      {!document ? (
        <EmptyDocument onUpdate={onUpdate} />
      ) : (
        <Div
          data-component={true}
          className={css`
            ${flex('jcl aic')}
            background-color: white;
            border: 1px solid ${colors.gray[300]};
            border-radius: 8px;
            width: 100%;
            height: 64px;
            padding: 0 24px;
            svg {
              min-width: 32px;
            }
          `}
        >
          <ExtensionIconMapper
            color={styles?.button?.backgroundColor}
            fileType={document?.FileType}
            className={css`
              pointer-events: none;
            `}
          />
          <Text
            label
            bold
            ellipsis
            css={`
              flex-grow: 1;
              margin: 0 16px;
            `}
          >
            {document?.Name}
          </Text>
          <DownloadSimple size={32} />
        </Div>
      )}
    </div>
  );
};

const EmptyDocument = ({ onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = (files) => {
    const file = files[0];
    onUpdate({ document: file });
  };

  const { getRootProps, getInputProps, isDragActive } = useUploadMedia({
    setLoading: setIsLoading,
    accept,
    onSuccess,
    noClick: true,
    maxFiles: 1,
  });

  return (
    <Div
      data-component={true}
      className={css`
        ${flex('jcc aic column')}
        background-color: ${colors.gray[100]};
        border-radius: 8px;
        width: 100%;
        min-height: 140px;
      `}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isLoading ? (
        <BriteLoader size={48} weight={8} />
      ) : isDragActive ? (
        <UploadSimple size={48} />
      ) : (
        <FileText
          size={48}
          className={css`
            pointer-events: none;
          `}
        />
      )}
      <Text
        bold
        label
        className={css`
          pointer-events: none;
        `}
      >
        Document
      </Text>
      <Text>Drop a file here, or click to edit</Text>
    </Div>
  );
};
