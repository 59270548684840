import { useState, useEffect, useMemo } from 'react';

import { colors } from '../shared/styles';
import { BriteLoader } from '../shared/components/brite-loader';

import { css } from '@emotion/css';
import { sortBy, orderBy } from 'lodash';
import { GuideAnalytics } from './GuideAnalytics';

import { IconButton } from '@material-ui/core';

import { groupBy } from 'lodash';
import { cloneDeep } from 'lodash';
import NoHeaderImage from '../Courses/no-header-image.png';
import posthog from 'posthog-js';

import 'react-dates/lib/css/_datepicker.css';

import { CustomAxios } from '../redux/axios/axios';
import { ViewsChart } from './ViewsChart';

import ExportModal from './ExportModal';

import { useStore } from '../store-provider/use-store';

import { createData, getDurationFromSeconds } from './helpers';

import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import 'chartkick/chart.js';
import { Text, Input, Div } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { MagnifyingGlass, Export, Eye, X } from '@phosphor-icons/react';
import { SortDropdown } from './SortDropdown';
import { FilterDropdown } from './FilterDropdown';

import { Tabs } from '../shared/components/tabs';
import { Box } from '../shared/components/box';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import Dates from '../shared/components/Dates';
import { Tooltip } from '../common/components/Tooltip';
import { fulfilledPromises } from '../react-query/promise-manager';
import { benefitsPackage, useResource } from '../react-query';

const COURSE_TAB = 'courses';
const ARCHIVED_TAB = 'archived';

Chart.register(ChartAnnotationsPlugin);

export const AnalyticsPage = () => {
  const [tab, setTab] = useState(COURSE_TAB);
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState([]);
  const [sortVal, setSortVal] = useState('mostviews');

  const [eligibleEmployee, setEligibleEmployee] = useState(0);
  const [editEmployeeCount, setEditEmployeeCount] = useState(false);
  const [pageDataRows, setPageDataRows] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [loadingPageViewsData, setLoadingPageViewsData] = useState(false);
  const [pageViewsStatistics, setPageViewsStatistics] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState({
    Name: 'Select Guide',
  });
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 30)));

  const [openEnrollmentCollection, setOpenEnrollmentCollection] = useState(false);
  const [timeFrame, _setTimeFrame] = useState('Last 30 days');
  const [courses, setCourses] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [analytics, setAnalytics] = useState({
    Feedback: [],
    Buyups: [],
    Activity: [],
    Questions: [],
    Recommendations: [],
    Time: {
      TotalTime: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [coursesLoading, setCoursesLoading] = useState(false);
  //this one has the Pages array. selectedCourse does not have Pages data
  const [selectedCourseData, setSelectedCourseData] = useState(null);

  const {
    data: { selectedBusiness },
  } = useStore();

  const pkgResource = useResource(benefitsPackage, {
    enabled:
      !!selectedCourseData?.BenefitsPackageID &&
      selectedCourseData?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000',
    params: {
      packageId: selectedCourseData?.BenefitsPackageID,
    },
  });

  const getGuides = async () => {
    setCoursesLoading(true);
    try {
      const response = await CustomAxios.get(`/v1/course`);
      let viewData = [];
      try {
        const viewResponse = await CustomAxios.get(`v1/analytics/courseviews`);
        if (viewResponse && viewResponse.data && viewResponse.data.Data) {
          viewData = viewResponse.data.Data;
        }
      } catch (e) {
        console.error('no views data');
      }

      let groupedViews = groupBy(viewData, function (view) {
        return view.CourseID;
      });

      let courses = response.data;

      courses = courses.filter((course) => course.Type !== 'survey');
      courses = courses.filter((course) => course.Type !== 'vendor');

      courses = sortBy(courses, (o) => o.Name.toLowerCase());
      for (let course of courses) {
        course.viewCountThisYear = 0;
        if (groupedViews[course.ID]) {
          for (let gView of groupedViews[course.ID]) {
            course.viewCountThisYear += gView.ViewsCount;
          }
        }
      }

      setViewData(viewData);
      setCourses(courses);
      setCoursesLoading(false);
    } catch (error) {
      setCourses([]);
      setCoursesLoading(false);
    }
  };

  const guides = useMemo(() => {
    let list = courses;

    if (tab === COURSE_TAB) {
      list = list?.filter((item) => {
        return !item?.ArchivedAt;
      });
    } else {
      list = list?.filter((item) => {
        return item?.ArchivedAt;
      });
    }
    if (!search && !sortVal && filterVal.length === 0) {
      return list;
    }

    if (search) {
      const term = search?.toLowerCase();
      list = list?.filter((item) => {
        return item?.Name?.toLowerCase().includes(term);
      });
    }
    if (filterVal.length > 0) {
      list = list?.filter((item) => {
        for (let valueOfFilter of filterVal) {
          if (item?.Type?.toLowerCase().includes(valueOfFilter)) {
            return true;
          }
        }
        return false;
      });
    }

    if (sortVal) {
      if (sortVal === 'mostviews') {
        list = orderBy(list, ['viewCountThisYear'], ['desc']);
      }
      if (sortVal === 'leastviews') {
        list = orderBy(list, ['viewCountThisYear']);
      }
      if (sortVal === 'atoz') {
        list = orderBy(list, ['Name'], ['asc']);
      }
      if (sortVal === 'ztoa') {
        list = orderBy(list, ['Name'], ['desc']);
      }
    }
    return list;
  }, [search, courses?.length, sortVal, filterVal.length, tab]);

  const totalViewsCount = useMemo(() => {
    let totalViewsCount = 0;
    for (let view of viewData) {
      const guide = guides?.find(({ ID }) => ID === view.CourseID);
      if (guide) {
        totalViewsCount += view.ViewsCount || 0;
      }
    }
    return totalViewsCount;
  }, [guides, viewData]);

  useEffect(() => {
    getGuides();
  }, [selectedBusiness.ID]);

  const fetchPageViewsStatistics = async (pages, id, start = startDate, end = endDate) => {
    setLoadingPageViewsData(true);
    // use CustomAxios to get page views statistics
    try {
      const engagementResponse = await CustomAxios.get(
        `/v1/analytics/engagement/${id}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      if (!engagementResponse.data) {
        engagementResponse.data = {
          PageSessions: [],
          Questions: [],
          AveragePagesPerVisitor: 0,
          AverageTimePerVisitorSeconds: 0,
          TotalPageVisits: 0,
          TotalVisitTimeSeconds: 0,
        };
      }
      if (!engagementResponse.data.Questions) {
        engagementResponse.data.Questions = [];
      }

      let groupedByPages = groupBy(engagementResponse.data.PageSessions, function (page) {
        return page.PageID;
      });

      let largestPageVisits = 0;

      let pagesWithData = pages.map((page) => {
        let pageStats = engagementResponse.data.PageSessions.find((pageViewStats) => pageViewStats.PageID === page.ID);
        if (!pageStats) {
          pageStats = {};
        }
        pageStats.visits = groupedByPages[pageStats.PageID]?.length || 0;

        let pageSeshes = groupedByPages[pageStats.PageID] || [];
        let groupedByUser = groupBy(pageSeshes, function (sesh) {
          return sesh.UserID;
        });

        let totalSeconds = pageSeshes.reduce((n, { PageSessionDurationSeconds }) => n + PageSessionDurationSeconds, 0);
        let vis = pageStats.visits || 0;

        let averageSeconds = 0;
        if (vis > 0) {
          averageSeconds = totalSeconds / vis;
        }

        if (vis > largestPageVisits) {
          largestPageVisits = vis;
        }

        pageStats.unique = Object.keys(groupedByUser).length;
        pageStats.pageData = page;
        pageStats.average = getDurationFromSeconds(averageSeconds);
        pageStats.total = getDurationFromSeconds(totalSeconds);
        pageStats.totalSeconds = totalSeconds;
        pageStats.averageSeconds = averageSeconds;
        return pageStats;
      });

      const rows = [];
      const columnRows = [];

      for (let pageData of pagesWithData) {
        if (pageData.PageOrder) {
          rows.push(
            createData(
              pageData.PageOrder,
              pageData.pageData.Name,
              pageData.visits || 0,
              pageData.unique || 0,
              pageData.average,
              pageData.total
            )
          );
          const label = `${pageData?.PageOrder} - ${pageData?.pageData?.Name}`;
          columnRows.push([label, pageData.visits || 0]);
        }
      }

      pagesWithData.columnRows = columnRows;

      setPageDataRows(rows);
      setPageViewsStatistics({
        courseID: id,
        startDate: start,
        data: pagesWithData,
        engagementOverview: engagementResponse.data,
        largestPageVisits,
      });
    } catch (e) {
      console.log('error getting page view statistics', e);
    } finally {
      setLoadingPageViewsData(false);
    }
  };

  const getAnalytics = async (guideID, start = startDate, end = endDate) => {
    setLoading(true);
    try {
      var response;
      let enrollmentConfidenceResponse;
      response = await CustomAxios.get(
        `/v1/analytics/traffic/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      try {
        enrollmentConfidenceResponse = await CustomAxios.get(
          `/v1/bp/confidence_survey?course_id=eq.${guideID}&created_at=gte.${start.toISOString()}&created_at=lte.${end.toISOString()}`,
          {
            headers: {
              'Accept-Profile': 'brite_public',
            },
          }
        );
      } catch (e) {
        enrollmentConfidenceResponse = { data: [], error: true };
      }

      const feedbackResponse = await CustomAxios.get(
        `/v1/analytics/${guideID}/feedback?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      const feedbackCommentsResponse = await CustomAxios.get(
        `/v1/analytics/${guideID}/feedback/comments?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );
      let feedbackComments = feedbackCommentsResponse.data;
      let feedbackData = feedbackResponse.data;
      let data = response.data;
      if (!data.Activity) {
        data.Activity = [];
      }
      if (!data.Questions) {
        data.Questions = [];
      }

      if (!data.Recommendations) {
        data.Recommendations = [];
      }
      if (!data.Buyups) {
        data.Buyups = [];
      }

      let comments = [];

      if (feedbackComments) {
        comments = groupBy(feedbackComments, function (comment) {
          return comment.NpsNumber;
        });
      }
      let index = 0;

      if (!feedbackData) {
        data.Feedback = [];
      } else {
        let newFeedback = [];
        feedbackData = feedbackData.sort((a, b) => a.NpsNumber < b.NpsNumber);
        for (let i = 1; i < 6; i++) {
          if (!feedbackData[index] || i !== feedbackData[index].NpsNumber) {
            newFeedback.push({
              NpsNumber: i,
              Count: 0,
              Comments: comments[i] || [],
            });
          } else {
            newFeedback.push({
              ...feedbackData[index],
              Comments: comments[i] || [],
            });
            index = index + 1;
          }
        }

        let total = newFeedback.reduce((partialSum, b) => {
          return partialSum + b.Count;
        }, 0);

        for (let feedbackObj of newFeedback) {
          feedbackObj.percentage = Math.floor((feedbackObj.Count / total) * 100);
          feedbackObj.width = (feedbackObj.percentage / 100) * 600;
        }
        let colors = ['#25282D', '#36414C', '#66737F', '#9AA7B5', '#D1DAE3'];
        let colorIndex = 0;
        newFeedback = newFeedback.sort((a, b) => b.percentage - a.percentage);
        newFeedback[0].color = colors[colorIndex];

        if (newFeedback[0].percentage > newFeedback[1].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[1].color = colors[colorIndex];
        if (newFeedback[1].percentage > newFeedback[2].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[2].color = colors[colorIndex];
        if (newFeedback[2].percentage > newFeedback[3].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[3].color = colors[colorIndex];
        if (newFeedback[3].percentage > newFeedback[4].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[4].color = colors[colorIndex];

        data.Feedback = newFeedback;
      }

      const guide = CustomAxios.get(`/v2/course/${guideID}`);
      const guidePages = CustomAxios.get(`/v2/course/${guideID}/pages`);
      const responses = await fulfilledPromises('allSettled', {
        guide,
        guidePages,
      });
      let pages = responses?.guidePages?.data || [];
      if (pages?.length > 0) {
        pages = sortBy(pages, ['Order']);
      } else {
        pages = [
          {
            Type: 'regular',
            Content: null,
            Name: 'Welcome',
            Order: 1,
            FrontendID: '_' + Math.random().toString(36).substr(2, 9),
            InsurancePlans: [],
            Question: null,
            IsLocked: false,
            TemplateID: '00000000-0000-0000-0000-000000000000',
          },
        ];
      }

      setSelectedCourseData(responses.guide?.data);
      setEligibleEmployee(responses.guide?.data?.EligibleEmployees || selectedBusiness?.EmployeeCount || 0);
      fetchPageViewsStatistics(pages, guideID, start, end);

      const conversionResponse = await CustomAxios.get(
        `/v1/analytics/conversion/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      data.conversion = conversionResponse.data;
      if (!data.conversion.MedicalRecommendations) {
        data.conversion.MedicalRecommendations = [];
      }

      if (enrollmentConfidenceResponse) {
        data.enrollmentConfidence = enrollmentConfidenceResponse.data || [];
      }
      setAnalytics({ ...data });
      setLoading(false);
      window?.ChurnZero?.push(['trackEvent', 'Analytics Accessed', '', 1]);
    } catch (error) {
      setLoading(false);
      setAnalytics({
        Feedback: [],
        Buyups: [],
        Activity: [],
        Questions: [],
        Recommendations: [],
        Time: {
          TotalTime: 0,
        },
      });
    }
  };

  const refresh = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    getAnalytics(selectedCourse.ID, start, end);
  };

  const getGuideType = (gType) => {
    if (gType === 'other') {
      return 'Other';
    }
    if (gType === 'openenrollment') {
      return 'Open Enrollment';
    }
    if (gType === 'recruiting') {
      return 'Recruiting';
    }
    if (gType === 'newhire') {
      return 'New Hire';
    }
    return 'Unknown';
  };

  const setTimeframe = (timeframe) => {
    let days = 7;
    _setTimeFrame(timeframe);
    if (timeframe === 'Last 7 days') {
      days = 7;
    }
    if (timeframe === 'Last 30 days') {
      days = 30;
    }
    if (timeframe === 'Last 90 days') {
      days = 90;
    }
    if (timeframe === 'Last Year') {
      days = 365;
    }
    if (timeframe === 'Open Enrollment') {
      if (pkgResource?.data?.EnrollmentStartDate && pkgResource?.data?.EnrollmentEndDate) {
        const start = startOfDay(Dates.getDateFromISO(pkgResource?.data?.EnrollmentStartDate));
        const end = endOfDay(Dates.getDateFromISO(pkgResource?.data?.EnrollmentEndDate));
        refresh(start, end);
      } else {
        setOpenEnrollmentCollection(true);
      }
      return;
    }
    setOpenEnrollmentCollection(false);
    if (timeframe !== 'Custom') {
      const start = startOfDay(subDays(new Date(), days));
      const end = endOfDay(new Date());
      refresh(start, end);
    }
  };

  const reset = () => {
    setSelectedCourseData(null);
    setTimeframe('Last 30 days');
    setAnalytics({
      Feedback: [],
      Buyups: [],
      Activity: [],
      Questions: [],
      Recommendations: [],
      Time: {
        TotalTime: 0,
      },
    });
    setSelectedCourse({
      Name: 'Select Guide',
    });
    setPageViewsStatistics(null);
  };

  useEffect(() => {
    reset();
  }, [selectedBusiness.ID]);

  const updateEligibleEmployees = async (employeeCount) => {
    try {
      let course = cloneDeep(selectedCourseData);
      delete course.Pages;
      course.EligibleEmployees = Number(employeeCount) || 0;
      await CustomAxios.put(`/v2/course/${selectedCourse.ID}`, course);
    } catch (e) {}
  };

  const updateOEDates = async () => {
    try {
      let course = cloneDeep(selectedCourseData);
      delete course.Pages;

      await CustomAxios.put(`/v2/course/${selectedCourse.ID}`, course);
    } catch (e) {}
  };

  const selectCourse = (option) => {
    setSelectedCourse(option);

    getAnalytics(option.ID);
  };

  const tabs = useMemo(() => {
    let tabs = { [COURSE_TAB]: 'Guides' };
    tabs[ARCHIVED_TAB] = 'Archived';
    return tabs;
  }, []);

  const countCommunicationByDay = () => {
    var groups = groupBy(analytics.GuideCommunications, function (LinkSent) {
      return format(startOfDay(new Date(LinkSent.created_at)), 'yyyy-MM-dd');
    });

    // Return an array of objects by day with a CommunicationMedium, and total count for that day
    let obj = [];
    const keys = Object.keys(groups);
    for (const key of keys) {
      let emailCount = 0;
      let textCount = 0;
      let slackCount = 0;
      let teamsCount = 0;
      let pdfQRCodeCount = 0;

      for (const item of groups[key]) {
        if (item.communication_medium === 'email') {
          emailCount += 1;
        }
        if (item.communication_medium === 'text') {
          textCount += 1;
        }
        if (item.communication_medium === 'slack') {
          slackCount += 1;
        }
        if (item.communication_medium === 'teams') {
          teamsCount += 1;
        }
      }

      obj.push({
        date: key,
        emailCount,
        textCount,
        slackCount,
        teamsCount,
        pdfQRCodeCount,
      });
    }

    return obj;
  };

  if (exportModalOpen) {
    return (
      <ExportModal
        onClose={() => {
          setExportModalOpen(false);
        }}
      />
    );
  }

  const guideLoaded = !loading && !coursesLoading && selectedCourse.Name !== 'Select Guide';

  let communicationAnnotations = {};
  let lineCount = 1;

  for (const item of countCommunicationByDay()) {
    let labelContent = ['Sent'];
    if (item.emailCount > 0) {
      labelContent.push(item.emailCount + ' emails');
    }
    if (item.textCount > 0) {
      labelContent.push(item.textCount + ' texts');
    }
    if (item.slackCount > 0) {
      labelContent.push(item.slackCount + ' slack messages');
    }
    if (item.teamsCount > 0) {
      labelContent.push(item.teamsCount + ' teams messages');
    }
    communicationAnnotations['line' + lineCount] = {
      type: 'line',
      xMin: item.date,
      xMax: item.date,
      borderColor: colors.purple,
      borderWidth: 2,
      borderDash: [8, 4],
      label: {
        content: labelContent,
        position: 'end',
      },
      enter({ element }, event) {
        element.label.options.display = true;
        return true; // force update
      },
      leave({ element }, event) {
        element.label.options.display = false;
        return true;
      },
    };
    lineCount++;
  }

  if (guideLoaded) {
    // This is an important event for us to track as part of overall adoption. It should always be called when the user sees the analytics page for a specific guide.
    // Make sure it is still used when we re-design the analytics page.
    posthog.capture('Guide Analytics View', {
      guideID: selectedCourse?.ID,
    });
  }

  if (selectedBusiness?.Type === 'multi' || selectedBusiness?.Type === 'carrier') {
    return (
      <Box page>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Text h1>Analytics</Text>

          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              className={css`
                display: flex;
                margin-left: auto;
              `}
            >
              <Tooltip label="Export All">
                <IconButton
                  onClick={() => {
                    setExportModalOpen(true);
                  }}
                >
                  <Export size={24} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className={css`
            text-align: center;
            margin: 16px auto;
          `}
        >
          Switch to a company you manage to see their analytics.
        </div>
        <ViewsChart type="company" />
      </Box>
    );
  }

  if (selectedCourse.ID) {
    return (
      <GuideAnalytics
        shareable={true}
        selectedBusiness={selectedBusiness}
        openEnrollmentCollection={openEnrollmentCollection}
        setOpenEnrollmentCollection={setOpenEnrollmentCollection}
        eligibleEmployee={eligibleEmployee}
        setEligibleEmployee={setEligibleEmployee}
        timeFrame={timeFrame}
        setTimeframe={setTimeframe}
        close={() => reset()}
        guide={selectedCourseData}
        setSelectedCourseData={setSelectedCourseData}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
        refresh={refresh}
        loading={loading}
        coursesLoading={coursesLoading}
        guideLoaded={guideLoaded}
        analytics={analytics}
        editEmployeeCount={editEmployeeCount}
        setEditEmployeeCount={setEditEmployeeCount}
        communicationAnnotations={communicationAnnotations}
        pageViewsStatistics={pageViewsStatistics}
        loadingPageViewsData={loadingPageViewsData}
        pageDataRows={pageDataRows}
        updateEligibleEmployees={updateEligibleEmployees}
        updateOEDates={updateOEDates}
        guides={courses || [selectedCourse]}
        selectCourse={selectCourse}
        pkgResource={pkgResource}
      />
    );
  }

  return (
    <Box page>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Text h1>Analytics</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <div
            className={css`
              display: flex;
              margin-left: auto;
            `}
          >
            <Tooltip label="Export All">
              <IconButton
                onClick={() => {
                  setExportModalOpen(true);
                }}
              >
                <Export size={24} weight={'bold'} color="#25282D" />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <FilterDropdown setFilterVal={setFilterVal} filterVal={filterVal} />
          </div>
          <div>
            <SortDropdown setSortVal={setSortVal} sortVal={sortVal} />
          </div>

          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Input
              styles="search"
              css={`
                margin-right: 16px;
                padding: 4px;
              `}
              placeholder="Search"
              startIcon={<MagnifyingGlass color={colors.gray[500]} />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Div>
        </div>
      </div>

      {(loading || coursesLoading) && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-top: 80px;
          `}
        >
          <BriteLoader />
        </div>
      )}
      {!loading && !coursesLoading && selectedCourse.Name === 'Select Guide' && (
        <div
          className={css`
            margin-top: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            {filterVal && filterVal.length > 0 && (
              <>
                {filterVal.map((filt) => {
                  return (
                    <div
                      className={css`
                        color: var(--Purple, #6d44fb);
                        text-align: center;
                        font-feature-settings: 'clig' off, 'liga' off;
                        /* Label */
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 171.429% */
                        letter-spacing: 0.15px;
                        border-radius: 24px;
                        background: #e2dafe;
                        padding: 4px 16px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                        &:hover {
                          background-color: #d7cef6;
                        }
                      `}
                      onClick={() => {
                        let cloned = cloneDeep(filterVal);
                        const index = cloned.indexOf(filt);
                        if (index > -1) {
                          // only splice array when item is found
                          cloned.splice(index, 1); // 2nd parameter means remove one item only
                        }
                        setFilterVal(cloned);
                      }}
                    >
                      <div>{getGuideType(filt)}</div>
                      <X color={'#6d44fb'} size={16} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div
            className={css`
              margin-top: 16px;
            `}
          >
            <Tabs tabs={tabs} current={tab} setCurrent={(tab) => setTab(tab)} />
          </div>
          <div
            className={css`
              margin-top: 24px;
              margin-left: 8px;
              display: flex;
              gap: 24px;
            `}
          >
            <div>
              {guides.length === 0 && (
                <div
                  className={css`
                    width: 764px;
                    height: 144px;
                    border-radius: 8px;
                    background-color: #fff;

                    /* Card */
                    box-shadow: 0px 2px 8px 0px rgba(37, 40, 45, 0.25);
                    display: flex;

                    margin-bottom: 24px;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  No guides
                </div>
              )}
              {guides.map((guide) => {
                return (
                  <div
                    className={css`
                      width: 764px;
                      height: 144px;
                      border-radius: 8px;
                      background-color: #fff;

                      /* Card */
                      box-shadow: 0px 2px 8px 0px rgba(37, 40, 45, 0.25);
                      border: 2px solid transparent;
                      display: flex;
                      &:hover {
                        cursor: pointer;
                        :hover {
                          border: 2px solid #66737f;
                        }
                      }
                      margin-bottom: 24px;
                    `}
                    onClick={() => {
                      selectCourse(guide);
                    }}
                  >
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 224px;
                        height: 100%;
                      `}
                    >
                      {!guide.LogoURL ? (
                        <img
                          alt="Carrier Logo"
                          className={css`
                            width: 150px;
                            height: 80px;
                          `}
                          src={NoHeaderImage}
                        />
                      ) : (
                        <img
                          alt="Guide Logo"
                          className={css`
                            max-height: 80px;
                            max-width: 150px;
                          `}
                          src={guide.LogoURL}
                        />
                      )}
                    </div>
                    <div
                      className={css`
                        border-left: 1px solid var(--Gray-300, #d1dae3);
                        width: 353px;
                        padding: 24px 32px;
                      `}
                    >
                      <Tooltip label={guide.Name}>
                        <div
                          className={css`
                            color: #25282d;

                            font-feature-settings: 'clig' off, 'liga' off;
                            /* H3 */
                            font-family: Roboto;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 32px;
                            width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          `}
                        >
                          {guide.Name}
                        </div>
                      </Tooltip>
                      <div
                        className={css`
                          color: #66737f;

                          font-feature-settings: 'clig' off, 'liga' off;
                          /* Body - Regular */
                          font-family: Roboto;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 150% */
                          letter-spacing: 0.15px;
                          margin-bottom: 16px;
                        `}
                      >
                        Created: {format(new Date(guide.CreatedAt), 'MMMM yyyy')}
                      </div>

                      <Tooltip label={'Total Lifetime Visits'}>
                        <div
                          className={css`
                            color: #25282d;

                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: Roboto;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 24px; /* 150% */
                            letter-spacing: 0.15px;
                          `}
                        >
                          Visits {guide.viewCountThisYear.toLocaleString()}
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      className={css`
                        margin: 24px;
                        margin-left: auto;
                      `}
                    >
                      <div
                        className={css`
                          color: var(--700, #25282d);
                          text-align: center;
                          font-feature-settings: 'clig' off, 'liga' off;
                          /* Label */
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 700;
                          line-height: 24px; /* 171.429% */
                          letter-spacing: 0.15px;
                          border-radius: 24px;
                          background: var(--100, #f5f7fa);
                          padding: 4px 16px;
                        `}
                      >
                        {getGuideType(guide.Type)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={css`
                border-radius: 16px;
                border: 1px solid var(--Gray-300, #d1dae3);
                background: var(--Purple, #6d44fb);
                display: flex;
                width: 312px;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                height: 118px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  gap: 24px;
                `}
              >
                <div
                  className={css`
                    border-radius: 16px;
                    background: #8966ff;
                    padding: 12px 24px;
                    padding-top: 15px;
                  `}
                >
                  <Eye color="white" />
                </div>
                <div>
                  <div
                    className={css`
                      color: #fff;

                      font-feature-settings: 'clig' off, 'liga' off;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px; /* 150% */
                      letter-spacing: 0.15px;
                    `}
                  >
                    Total Guide Visits
                  </div>
                  <div
                    className={css`
                      color: #fff;

                      font-feature-settings: 'clig' off, 'liga' off;
                      font-family: Roboto;
                      font-size: 34px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 40px; /* 117.647% */
                      letter-spacing: 0.25px;
                    `}
                  >
                    {totalViewsCount?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};
