import React, { forwardRef, useMemo } from 'react';
import { css, cx } from '@emotion/css';
import { buildStyles, colors } from '../styles';

const sharedHeaderStyle = `
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
`;

const sharedStyle = `
  ${sharedHeaderStyle}
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const textTheme = {
  h1: `
    ${sharedHeaderStyle}
    font-size: 34px;
    line-height: 48px;
    letter-spacing: 0.25px;
    color: #25282D; 
  `,
  h2: `
    ${sharedHeaderStyle}
    font-size: 24px;
    line-height: 32px;
    color: #25282D;
  `,
  h3: `
    ${sharedHeaderStyle}
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #25282D;
  `,
  h4: `
    ${sharedHeaderStyle}
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #25282D;
  `,
  label: `
    ${sharedStyle}
    font-size: 16px;
    color: #25282D;
  `,
  body: `      
    ${sharedStyle}
    font-size: 16px;
    color: #66737F;
  `,
  caption: `
    ${sharedStyle}
    font-size: 14px;
    color: #66737F;
  `,
  helper: `
    ${sharedStyle}
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.15px;
      color: #66737F;
  `,
  link: `
    ${sharedStyle}
    color: #6D44FB;
    cursor: pointer;
  `,
  textWrap: `
    white-space: wrap;
  `,
  bold: `font-weight: 600;`,
  normal: `font-weight: normal;`,
  uppercase: `text-transform: uppercase;`,
  underline: `text-decoration: underline;`,
  capitalize: `text-transform: capitalize`,
  ellipsis: `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  center: `text-align: center;`,
  right: `text-align: right;`,
  white: `color: white;`,
  red: `color: ${colors.red[100]};`,
};

const fallbackFromList = ['naked', 'body', 'label', 'caption', 'h1', 'h2', 'h3', 'h4'];
const allAttributes = Object.keys(textTheme);

const checkForFallback = (styles) => {
  const list = fallbackFromList.filter((attr) => styles.includes(attr));
  if (!list.length) {
    return `body ${styles}`;
  }
  return styles;
};

export const Text = forwardRef(
  ({ styles = '', className = '', css: cssStyles = '', as = 'p', children, color, bgColor, ...rest }, ref) => {
    const keys = Object.entries(rest || {});
    const { restProps, styleProps } = useMemo(() => {
      const filtered = keys.reduce(
        (prev, entry) => {
          const [attr, value] = entry;
          const property = allAttributes.includes(attr) && value ? 'styleProps' : 'restProps';
          return {
            ...prev,
            [property]: [...prev?.[property], entry],
          };
        },
        { restProps: [], styleProps: [] }
      );
      return {
        restProps: Object.fromEntries(filtered.restProps),
        styleProps: filtered.styleProps,
      };
    });

    const styling = useMemo(() => {
      const list = styleProps.map(([attr]) => attr);
      const extraStyles = styles + ' ' + list.join(' ');
      const taggedStyle = checkForFallback(extraStyles);
      return cx(
        css`
          margin: 0;
          ${buildStyles(textTheme, taggedStyle)}
          ${color ? `color: ${color};` : ''}
      ${bgColor ? `background-color: ${bgColor};` : ''}
        `,
        css`
          ${cssStyles}
        `,
        className
      );
    }, [styles, className, cssStyles, color, bgColor, styleProps]);

    const props = { className: styling, ref, ...restProps };

    if (as === 'p') {
      return <p {...props}>{children}</p>;
    } else if (as === 'span') {
      return <span {...props}>{children}</span>;
    } else if (as === 'h1') {
      return <h1 {...props}>{children}</h1>;
    }
  }
);
