import { css } from '@emotion/css';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { HandWaving, Question } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '../../../common/hooks/useModal';
import { BriteSearchableMultiSelect } from '../../../Courses/Courses';
import { Button, Modal, Text } from '../../../shared/components';
import { useQueryAPI } from '../../../react-query';
import { CustomAxios } from '../../../redux/axios/axios';
import { colors } from '../../../shared/styles';
import { MessageTemplatesModal } from './MessageTemplatesModal';
import { LaunchSuccessModal } from './LaunchSuccessModal';

const SLACK_INDIVIDUALS_TAB = 'Individuals';
const SLACK_CHANNELS_TAB = 'Channels';
const SLACK_SEND_METHOD_DM = 'Direct Message';
const SLACK_SEND_METHOD_POST = 'Post to Channels';

export const SlackModal = ({ hide = () => {}, guide = {}, initialMessage = '' }) => {
  const [slackTab, setSlackTab] = useState(SLACK_INDIVIDUALS_TAB);
  const [slackSendMethod, setSlackSendMethod] = useState(SLACK_SEND_METHOD_POST);

  const [slackUsers, setSlackUsers] = useState([]);
  const [slackUserMap, setSlackUserMap] = useState(new Map());
  const [slackUsersPlusAll, setSlackUsersPlusAll] = useState([]);
  const [allSlackUsersSelected, setAllSlackUsersSelected] = useState(false);

  const [slackChannels, setSlackChannels] = useState([]);
  const [slackChannelMembers, setSlackChannelMembers] = useState(new Set());
  const [slackChannelMembersMap, setSlackChannelMembersMap] = useState(new Map());
  const [slackLoadingChannelMembers, setSlackLoadingChannelMembers] = useState([]);

  const [slackContactsToMessage, setSlackContactsToMessage] = useState(new Set());
  const [slackChannelsToMessage, setSlackChannelsToMessage] = useState(new Set());

  const [loading, setLoading] = useState(false);

  const [messageBody, setMessageBody] = useState(initialMessage);

  const messageTemplatesModal = useModal();
  const launchSuccessModal = useModal();

  const messageMaxLength = 360;

  useQueryAPI(
    {
      url: `v1/slack/user`,
      enabled: true,
      select: (data) => (data?.Users?.length ? data?.Users : []),
      defaultValue: [],
      onMount: async (users) => {
        users.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setSlackUsers(users);
        setSlackUserMap(users.reduce((u, user) => u.set(user.id, user), new Map()));
        setSlackUsersPlusAll([{ id: 'all', name: 'Select All' }, ...users]);
        // Reset the selected users
        setSlackContactsToMessage(new Set());
        setAllSlackUsersSelected(false);
      },
    },
    []
  );

  useQueryAPI(
    {
      url: `v1/slack/channel`,
      enabled: true,
      select: (data) => (data?.Channels?.length ? data?.Channels : []),
      defaultValue: [],
      onMount: async (channels) => {
        // Order by channel name
        channels.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setSlackChannels(channels);
        // Reset the selected channels
        setSlackChannelsToMessage(new Set());
      },
    },
    []
  );

  const sendSlackMessage = async (channels, body, cId) => {
    if (messageBody.length > 4000) {
      toast.error(`Message body has too many characters.`);
      return;
    }
    setLoading(true);

    const ids = Array.from(channels).map((c) => c.id);
    const data = {
      ChannelIDs: ids,
      Message: body,
      CourseID: cId,
    };

    try {
      await CustomAxios.post(`v1/slack/message`, data);

      setLoading(false);
      launchSuccessModal.toggle();
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (err) {
      setLoading(false);
      toast.error(`Failed to send slack message.`);
      console.log(err);
    }
  };

  if (launchSuccessModal.isOpen) {
    return (
      <LaunchSuccessModal
        hide={() => {
          hide();
          launchSuccessModal.hide();
        }}
      />
    );
  }

  return (
    <Modal display full onClose={() => hide()}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <Text h1>Slack Guide Link</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button styles="icon" hoverLabel="Message Templates Help" onClick={() => messageTemplatesModal.toggle()}>
            <HandWaving />
          </Button>
          <Button
            secondary
            onClick={() => {
              hide();
            }}
          >
            Cancel
          </Button>
          <div>
            <Button
              disabled={!messageBody || slackContactsToMessage.length === 0 || loading}
              onClick={() => {
                if (slackTab === SLACK_CHANNELS_TAB && slackSendMethod === SLACK_SEND_METHOD_POST) {
                  sendSlackMessage(slackChannelsToMessage, messageBody, guide.ID);
                } else {
                  sendSlackMessage(slackContactsToMessage, messageBody, guide.ID);
                }
              }}
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
      <div
        id="send-via-slack"
        className={css`
          padding: 48px;
          max-width: 700px;
          margin: 0 auto;
          overflow: auto;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d1dae3;
            padding-bottom: 18px;
            width: 100%;
          `}
        >
          <div
            className={css`
              cursor: pointer;
              font-weight: 700;
              font-size: 18px;
              color: ${slackTab === SLACK_INDIVIDUALS_TAB ? colors.black : colors.gray[500]};
              border-bottom: ${slackTab === SLACK_INDIVIDUALS_TAB
                ? `4px solid ${colors.black}`
                : '4px solid transparent'};
              padding-bottom: 18px;
              margin-bottom: -19px;
              justify-content: center;
              width: 50%;
              display: flex;
            `}
            onClick={() => {
              setSlackTab(SLACK_INDIVIDUALS_TAB);
              // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
              setSlackContactsToMessage(new Set());
              setAllSlackUsersSelected(false);
              setSlackChannelsToMessage(new Set());
            }}
          >
            {SLACK_INDIVIDUALS_TAB}
          </div>
          <div
            className={css`
              cursor: pointer;
              font-weight: 700;
              font-size: 18px;
              color: ${slackTab === SLACK_CHANNELS_TAB ? colors.black : colors.gray[500]};
              border-bottom: ${slackTab === SLACK_CHANNELS_TAB ? `4px solid ${colors.black}` : '4px solid transparent'};
              padding-bottom: 18px;
              margin-bottom: -19px;
              justify-content: center;
              width: 50%;
              display: flex;
            `}
            onClick={() => {
              setSlackTab(SLACK_CHANNELS_TAB);
              // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
              setSlackContactsToMessage(new Set());
              setAllSlackUsersSelected(false);
              setSlackChannelsToMessage(new Set());
            }}
          >
            {SLACK_CHANNELS_TAB}
          </div>
        </div>
        <div
          className={css`
            min-width: 640px;
          `}
        >
          <div
            className={css`
              margin-bottom: 12px;
            `}
          >
            {slackTab === SLACK_CHANNELS_TAB && (
              <div id="slack-channels-tab">
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                  `}
                >
                  <div
                    className={css`
                      flex-grow: 1;
                      width: 100%;
                    `}
                  >
                    {
                      <BriteSearchableMultiSelect
                        label="Add Channels"
                        labelKey="name"
                        value={slackChannelsToMessage}
                        options={slackChannels}
                        onChange={(e, selectedChannels) => {
                          setSlackChannelsToMessage(new Set(selectedChannels));

                          // Even though the slackSendMethod may not be SLACK_SEND_METHOD_DM, we should still populate all the users to DM from the selected channels, in case the user switches to DMs.
                          // Each time the list of channels to message is modified, remove any existing members from the list, and re-populate members from added/remaining channels.
                          setSlackLoadingChannelMembers(true);
                          setSlackContactsToMessage(new Set());
                          setSlackChannelMembers(new Set());

                          let contactsToMessage = new Set();
                          let channelMembersSet = new Set();
                          let channelMembersMap = slackChannelMembersMap;

                          let channelMembersPromises = [];

                          // Iterate over all the currently selected channels
                          for (let i = 0; i < selectedChannels.length; i++) {
                            const channelID = selectedChannels[i].id;

                            // If we've already fetched the members for this channel, use the cached version.
                            if (channelMembersMap[channelID] !== undefined) {
                              channelMembersMap[channelID].forEach((memberID) => {
                                const slackUser = slackUserMap.get(memberID);
                                // It is possible that the user is a bot, or has been deactivated, so they won't be in the original users response from our backend.
                                if (slackUser !== undefined) {
                                  contactsToMessage.add(slackUser);
                                  channelMembersSet.add(slackUser);
                                }
                              });
                            } else {
                              // If don't have members for this channel, fetch them from the backend.

                              const f = async () => {
                                try {
                                  const response = await CustomAxios.get(`/v1/slack/channel/${channelID}/member`);
                                  const memberIDs = response.data.ChannelMemberIDs;

                                  memberIDs.forEach((memberID) => {
                                    const slackUser = slackUserMap.get(memberID);
                                    if (slackUser !== undefined) {
                                      contactsToMessage.add(slackUser);
                                      channelMembersSet.add(slackUser);
                                    }
                                  });
                                  channelMembersMap[channelID] = memberIDs;
                                } catch (err) {
                                  console.warn(err);
                                }
                              };
                              channelMembersPromises.push(f());
                            }
                          }

                          Promise.all(channelMembersPromises).then(() => {
                            setSlackContactsToMessage(contactsToMessage);
                            setSlackChannelMembers(channelMembersSet);
                            setSlackChannelMembersMap(channelMembersMap);
                            setSlackLoadingChannelMembers(false);
                          });
                        }}
                      />
                    }
                  </div>
                </div>
                <RadioGroup
                  aria-label="send method"
                  value={slackSendMethod}
                  onChange={(e) => {
                    setSlackSendMethod(e.target.value);
                  }}
                  row
                >
                  <FormControlLabel
                    value={SLACK_SEND_METHOD_POST}
                    control={<Radio color="default" />}
                    label={<div>Post to channels</div>}
                  />
                  <FormControlLabel
                    value={SLACK_SEND_METHOD_DM}
                    control={<Radio color="default" />}
                    label={
                      <div
                        className={css`
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          gap: 4px;
                        `}
                      >
                        Direct message members in channels
                        <Button
                          styles="icon"
                          hoverLabel="Prefer direct message for better insights and engagement when sending essential guides."
                        >
                          <Question />
                        </Button>
                      </div>
                    }
                  />
                </RadioGroup>
              </div>
            )}
            {slackTab === SLACK_INDIVIDUALS_TAB && (
              <div id="slack-dm-tab">
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                  `}
                >
                  <div
                    className={css`
                      flex-grow: 1;
                      width: 100%;
                    `}
                  >
                    {
                      <BriteSearchableMultiSelect
                        label="Add Individuals"
                        labelKey="name"
                        closeOnSelect={false}
                        value={slackContactsToMessage}
                        options={slackUsersPlusAll}
                        allAreSelected={allSlackUsersSelected}
                        onChange={(e, newValue) => {
                          let useAll = false;
                          newValue.forEach((member) => {
                            if (member.id === 'all') {
                              useAll = true;
                            }
                          });

                          if (useAll) {
                            if (allSlackUsersSelected) {
                              // If all users were already selected, and the "all" option is selected again, then unselect all users.
                              setSlackContactsToMessage(new Set());
                              setAllSlackUsersSelected(false);
                            } else {
                              setSlackContactsToMessage(new Set(slackUsers));
                              setAllSlackUsersSelected(true);
                            }
                          } else {
                            setSlackContactsToMessage(new Set(newValue));
                            if (newValue.length === slackUsers.length) {
                              // If all slack users have been selected (even though the "all" option was not used) need to set all to true.
                              setAllSlackUsersSelected(true);
                            } else {
                              // Keep AllSlackUsersSelected false if the "all" option was not used, and not all users have been manually added.
                              setAllSlackUsersSelected(false);
                            }
                          }
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            )}
            {slackTab === SLACK_CHANNELS_TAB && slackSendMethod === SLACK_SEND_METHOD_DM && (
              <div id="slack-dm-tab">
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      flex-grow: 1;
                      width: 100%;
                    `}
                  >
                    {!slackLoadingChannelMembers && (
                      <BriteSearchableMultiSelect
                        label="Filter Individuals (optional)"
                        labelKey="name"
                        closeOnSelect={false}
                        value={slackContactsToMessage}
                        options={slackChannelMembers}
                        defaultValues={slackContactsToMessage}
                        onChange={(e, newValue) => {
                          setSlackContactsToMessage(new Set(newValue));
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={css`
                padding-bottom: 8px;
                padding-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <Text label>Message</Text>
              <Text
                label
                css={css`
                  color: ${messageBody.length > messageMaxLength ? 'red' : 'inherit'};
                `}
              >
                {messageBody.length} / {messageMaxLength}
              </Text>
            </div>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              style={{ width: '100%' }}
              value={messageBody}
              onChange={(e) => {
                setMessageBody(e.target.value);
              }}
            />
            <div
              className={css`
                margin-top: 4px;
                margin-bottom: 16px;
              `}
            >
              <Text helper>The guide link will show up at the end of your message.</Text>
            </div>
          </div>
        </div>
      </div>
      {messageTemplatesModal.isOpen && (
        <MessageTemplatesModal hideMessageTemplates={() => messageTemplatesModal.hide()} guide={guide} />
      )}
    </Modal>
  );
};
