import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import SuccessText from '../../../Courses/success-text.svg';
import { Button, Modal } from '../../../shared/components';
import { colors } from '../../../shared/styles';

export const LaunchSuccessModal = ({ hide = () => {} }) => {
  return (
    <Modal
      display
      onClose={() => {
        hide();
      }}
      css={`
        overflow: auto;
        min-width: 800px;
      `}
    >
      <>
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 32px;
          `}
        >
          <Button
            styles="icon"
            onClick={() => {
              hide();
            }}
          >
            <X size={24} />
          </Button>
        </div>
        <div
          className={css`
            margin: 32px;
            margin-top: 0px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <img src={SuccessText} alt="Success" />
          </div>
          <div
            className={css`
              font-weight: 700;
              font-size: 24px;
              line-height: 133.4%;
              margin: 32px;
              text-align: center;
              color: ${colors.black};
            `}
          >
            Your message is sending successfully.
          </div>
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-bottom: 32px;
            `}
          >
            <Button onClick={() => hide()}>Close</Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
