import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useStore } from '../store-provider/use-store';
import { css } from '@emotion/css';
import { Div, Input, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { animation, container, flex, scrollbar, shadows } from '../shared/shared-styles';
import { CaretDown, CaretUp, Check, MagnifyingGlass } from '@phosphor-icons/react';
import { useOutsideClick } from '../shared/use-outside-click';
import { useQueryAPI } from '../react-query';
import { useKeyboard } from '../Router/use-keyboard';

export const CompanySelect = ({
  selectedBusiness: businessOverride = null,
  selectBusiness,
  defaultBusinessList = [],
  css: cssString = '',
  listHeight = '45vh',
}) => {
  const {
    data: { selectedBusiness: currentSelectedBusiness },
  } = useStore();
  const selectedBusiness = businessOverride || currentSelectedBusiness;

  const [search, setSearch] = useState('');
  const [searchValue] = useDebounceValue(search, 200);

  const { data } = useQueryAPI({
    url: `v2/search/business?search=${searchValue}&limit=40&offset=0`,
    enabled: !!searchValue,
  });

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const ref = useOutsideClick(() => setOpen(false));
  const inputRef = useRef();

  const defaultListDep = defaultBusinessList.map(({ ID }) => ID).toString();
  const handleSelect = (selected) => {
    setOpen(false);
    selectBusiness({ option: selected });
  };

  const list = useMemo(() => {
    if (!data?.data?.length) {
      const sortedParents = defaultBusinessList?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.ParentID]:
            item?.ParentID === item?.ID
              ? [item, ...(prev?.[item?.ParentID] || [])]
              : [...(prev?.[item?.ParentID] || []), item],
        }),
        {}
      );
      return Object.values(sortedParents)?.reduce((prev, item) => [...prev, ...item], []);
    }
    return data?.data;
  }, [data?.data?.length, defaultListDep]);

  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
    setSearch('');
    setIndex(0);
  }, [open]);

  const keydown = (captured, event) => {
    if (captured === 'meta+KeyE') {
      event?.stopPropagation();
      setOpen(true);
    } else if (captured === '+ArrowDown' && open) {
      setIndex((idx) => {
        let index = idx + 1;
        return index >= list?.length ? 0 : index;
      });
    } else if (captured === '+ArrowUp' && open) {
      setIndex((idx) => {
        let index = idx - 1;
        return index <= -1 ? list?.length - 1 : index;
      });
    } else if (captured === '+Enter' && open) {
      handleSelect(list?.[index]);
    }
  };

  useKeyboard({ keydown });

  return (
    <Div
      css={css`
        position: relative;
        border-radius: 8px;
        height: 56px;
        width: 300px;
        ${flex('space-between')}
        padding: 16px;
        svg {
          min-width: 32px;
        }
        background-color: white;
        cursor: pointer;
        border: 1px solid ${colors.gray[300]};
        ${open ? '' : container.hover}
        ${cssString}
      `}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      <Text
        h4
        ellipsis
        css={`
          flex-grow: 1;
        `}
      >
        {selectedBusiness?.Name}
      </Text>
      {open ? (
        <CaretUp className="hover-caret" size={24} color={colors.gray[400]} />
      ) : (
        <CaretDown className="hover-caret" size={24} color={colors.gray[400]} />
      )}
      {!open ? null : (
        <Div
          css={css`
            ${animation('fadeIn', '.15s ease')}
            ${shadows?.md}
            position: absolute;
            top: calc(100% + 8px);
            left: 0;
            right: 0;
            background-color: white;
            z-index: 100000000000000000;
            border-radius: 8px;
            cursor: default;
            padding: 8px 0;
          `}
          onClick={(e) => e.stopPropagation()}
        >
          <Input
            ref={inputRef}
            css={`
              position: sticky;
              top: 0;
              margin: 0 8px;
            `}
            placeholder="Search Businesses"
            startIcon={<MagnifyingGlass color={colors.gray[400]} />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Div
            css={css`
              max-height: ${listHeight};
              overflow-y: auto;
              padding: 8px;
              ${scrollbar.style}
            `}
          >
            {list?.map((business, idx) => {
              const isParent = business?.ID === business?.ParentID;
              return (
                <Div
                  css={css`
                    ${flex('space-between')}
                    ${container.hover}
                    ${idx === index ? `background-color: ${colors.gray[100]};` : ''}
                    border-radius: 8px;
                    ${isParent ? `padding: 8px` : `padding: 4px 8px;`};
                  `}
                  onClick={() => handleSelect(business)}
                  onMouseOver={() => setIndex(idx)}
                >
                  <Div
                    css={css`
                      flex-grow: 1;
                      padding: 8px;
                      max-width: calc(100% - 32px);
                    `}
                  >
                    <Text
                      label
                      ellipsis
                      bold={isParent}
                      css={`
                        flex-grow: 1;
                        max-width: 100%;
                        padding: 0;
                        margin: 0;
                      `}
                    >
                      {business?.Name}
                    </Text>
                    {!isParent ? (
                      <Text
                        css={`
                          padding: 0;
                          margin: 0;
                          font-size: 1.1em;
                        `}
                      >
                        {business?.ParentName}
                      </Text>
                    ) : null}
                  </Div>
                  {business?.ID === selectedBusiness?.ID ? <Check color={colors.purple} size={24} /> : null}
                </Div>
              );
            })}
          </Div>
        </Div>
      )}
    </Div>
  );
};

// <Input
//         css={`
//           border: none;
//           :hover {
//             border: none;
//           }
//         `}
//       />
