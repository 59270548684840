import { css } from '@emotion/css';
import { ReactComponent as DashedLine } from '../../images/svg/dashed-line.svg';
import { flex } from '../../shared/shared-styles';
import { useEditorResource } from '../use-editor-resource';
import { Tooltip } from '../../common/components/Tooltip';
import { Div, Text } from '../../shared/components';
import { colors } from '../../shared/styles';

export const Space = ({ isSelected, element }) => {
  const { component, container, attributes } = element;
  const { data: styles } = useEditorResource('styles');

  const combinedStyles = {
    ...styles?.[component],
    ...attributes?.style,
  };

  const containerStyle = container?.attributes?.style;

  const componentStyles =
    component === 'space'
      ? {
          ...combinedStyles,
          borderTopRightRadius: containerStyle?.borderTopRightRadius || '',
          borderBottomRightRadius: containerStyle?.borderBottomRightRadius || '',
          borderTopLeftRadius: containerStyle?.borderTopLeftRadius || '',
          borderBottomLeftRadius: containerStyle?.borderBottomLeftRadius || '',
        }
      : combinedStyles;

  const hasPageBreak = element?.container?.attributes?.style?.breakAfter === 'page';

  return (
    <div
      className={css`
        position: relative;
        overflow: hidden;
        ${flex('center')}
      `}
      style={componentStyles}
      data-component={true}
    >
      {hasPageBreak ? (
        <PageBreakSpace isSelected={isSelected} combinedStyles={combinedStyles} />
      ) : (
        <DefaultSpace isSelected={isSelected} combinedStyles={combinedStyles} />
      )}
    </div>
  );
};

const DefaultSpace = ({ isSelected = false, combinedStyles = {} }) =>
  isSelected ? (
    <Tooltip label={`Min Height ${combinedStyles?.minHeight}`}>
      <Div
        className={css`
          outline: 1px dotted rgba(200, 200, 200, 0.7);
          height: 100%;
          width: 100%;
          max-height: ${combinedStyles?.minHeight};
          :hover {
            background-color: rgba(150, 150, 150, 0.1);
          }
        `}
      />
    </Tooltip>
  ) : null;

const PageBreakSpace = ({ isSelected = false }) => {
  const Content = () => (
    <>
      <DashedLine />
      <Text
        helper
        bold
        color={colors.gray[400]}
        css={`
          white-space: nowrap;
        `}
      >
        Page Break
      </Text>
      <DashedLine />
    </>
  );

  return isSelected ? (
    <Tooltip label="Page breaks only affect exported guides.">
      <Div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          gap: 8px;
        `}
      >
        <Content />
      </Div>
    </Tooltip>
  ) : (
    <Div
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      `}
    >
      <Content />
    </Div>
  );
};
