import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/css';
import { useQueryAPI } from '../../../react-query';
import { Box, BriteLoader, Button, Div, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { Radio } from '@material-ui/core';
import { CaretRight, Trash, Warning } from '@phosphor-icons/react';
import { editorUtils, modifyContent, removeItem } from '../../provider/utils';
import { container, flex, px } from '../../../shared/shared-styles';
import { mutliNetworkCategoryMap } from '../../../benefit-package/utils';
import { ALL_TYPES, PRODUCT_HEADERS } from '../../../benefit-package/constants';
import { useEditorResource } from '../../use-editor-resource';
import { useStore } from '../../../store-provider/use-store';
import { CustomAxios } from '../../../redux/axios/axios';

const containerStyle = css`
  ${container.box}
  top: 32px;
  position: absolute;
  left: calc(50% - 200px);
  width: 400px;
  background-color: white;
  border-radius: 8px;
  padding: 0;
  z-index: 200;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  cursor: default;
`;

const displayMap = { ...PRODUCT_HEADERS, custom: 'Custom' };
const pricingTypes = ['insurance_plan', 'dental_buyup', 'vision_buyup', 'hsa_buyup'];
const networkTypes = ['insurance_plan', 'dental_buyup', 'vision_buyup'];

export const sortMap = {
  core: 'a',
  buyup: 'b',
  narrow: 'c',
};

export const AddBenefitsModal = ({
  editor,
  element,
  location,
  //
  products,
}) => {
  const { type: initialType = '', component } = element;
  const { businessId } = useParams();
  const [loading, setLoading] = useState(false);

  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const ref = useRef(null);
  const [type, setType] = useState(initialType);

  const {
    data: { devMode },
  } = useStore();

  const {
    state: { course, selection, layout },
  } = editor;

  const [product, setProduct] = useState({});

  const { data: pkg, isLoading } = useEditorResource('benefitsPackage', {
    enabled: !!course.BenefitsPackageID && course.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000',
  });

  const { data: programData } = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    // enabled: product?.ID && type === "hsa_buyup",
    defaultValue: [],
  });

  useEffect(() => {
    if (initialType) {
      const hasProductType = products?.find(({ Type }) => Type === initialType);
      if (!hasProductType) {
        setType('');
      }
    }
  }, [initialType, products?.length]);

  useEffect(() => {
    setProduct({});
  }, [type]);

  const data = useMemo(() => {
    return products?.reduce((prev, item) => {
      return { ...prev, [item?.Type]: [...(prev?.[item?.Type] || []), item] };
    }, {});
  }, [products?.length]);

  const handleDeleteComponent = () => {
    const rows = removeItem(layout, selection);
    modifyContent?.set(editor, [], rows);
    editorUtils?.setProperty(editor, 'selection', null);
  };

  const selectableProductTypes = useMemo(() => {
    const entries = Object.entries(displayMap);
    if (component === 'pricing') {
      return entries.filter(([type]) => pricingTypes.includes(type));
    } else if (component === 'network') {
      return entries.filter(([type]) => networkTypes.includes(type));
    }
    return entries;
  }, [component]);

  const productList = useMemo(() => {
    if (type) {
      let list = data?.[type];

      if (type === 'hsa_buyup') {
        const productIds = products?.map(({ ID }) => ID);
        list = programData?.filter(
          ({ ProgramType, PlanID }) => ProgramType === 'health_savings_account' && productIds.includes(PlanID)
        );
      }
      return list?.sort(
        (a, b) =>
          a?.MultiNetworkID?.localeCompare(a?.MultiNetworkID) ||
          sortMap[a?.MultiNetworkCategory]?.localeCompare(sortMap?.[b?.MultiNetworkCategory])
      );
    }
    return [];
  }, [type, product?.ID, data, programData?.length]);

  const getProductName = (item) => {
    if (type === 'hsa_buyup') {
      const product = products?.find(({ ID }) => ID === item?.PlanID);
      return product?.ProductName || displayMap[product?.Type];
    } else {
      return item.ProductName || displayMap[item?.Type];
    }
  };

  const handleSelectProduct = (item) => setProduct(item);

  const handleBack = () => {
    if (loading) return;
    if (!type) {
      editorUtils?.setProperty(editor, 'selection', null);
    } else {
      setType('');
    }
  };

  const loadProductTags = async (productId) => {
    if (!productId || !type || !course.IsPublished) {
      // return no product-tags, hardcoded values will be used and the smart component will not be portable to other benefits packages.
      return {};
    }
    try {
      setLoading(true);
      const prodType = type === 'hsa_buyup' ? 'insurance_plan' : type;
      const resp = await CustomAxios.get(
        `/v1/product/types/${prodType}/tags?productID=${productId}&benefitsPackageID=${course.BenefitsPackageID}&use-product-layout=true&direct-ref=true`
      );
      return resp.data;
    } catch (error) {
      console.warn('error loading product tags for smart component, will use hard-coded values instead', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const tagsDrillDown = (mergeTags, searchNames) => {
    if (!mergeTags || !searchNames.length) {
      return null;
    }

    for (let i = 0; i < mergeTags.length; i++) {
      if (mergeTags[i].name?.toLowerCase() !== searchNames[0].toLowerCase()) {
        continue;
      }

      if (mergeTags[i].mergeTags) {
        return tagsDrillDown(mergeTags[i].mergeTags, searchNames.slice(1));
      }

      return mergeTags[i].value;
    }

    return null;
  };

  const addBenefit = async () => {
    if (editor?.state?.editorType === 'page') {
      const productId = product?.PlanID ? product?.PlanID : product?.ID;
      const tags = await loadProductTags(productId);
      const idTag = tags?.DataSource?.IdentifierTag;

      if (type === 'hsa_buyup') {
        const relatedProductIdTag = tagsDrillDown(tags?.mergeTags, [
          'Included Plan Programs',
          'Health Savings Account',
          'Related Product Id',
        ]);

        onUpdate({
          data: {
            id: idTag ? idTag : product?.PlanID,
            relatedProductId: relatedProductIdTag ? relatedProductIdTag : product?.RelatedProductID,
            selectedId: product?.PlanID,
          },
          type,
        });
      } else {
        onUpdate({
          data: { id: idTag ? idTag : product?.ID, selectedId: product?.ID },
          type,
        });
      }
    } else {
      onUpdate({ type });
    }
  };

  return (
    <Div className={containerStyle} ref={ref}>
      {isLoading ? (
        <Box
          flex="center"
          css={`
            width: 100%;
            padding: 32px 0;
          `}
        >
          <BriteLoader overlay={false} weight={8} size={64} />
        </Box>
      ) : (
        <Div>
          <Div
            css={css`
              ${flex('space-between')} padding: ${px.xl};
            `}
          >
            {editor?.state?.editorType === 'page' && !devMode ? (
              pkg?.Name ? (
                <Text styles="h3 bold padr">{pkg?.Name}</Text>
              ) : (
                <Box
                  flex="left"
                  css={`
                    gap: 8px;
                    background-color: ${colors.gray[100]};
                    border-radius: 8px;
                    padding: 8px;
                    flex-grow: 1;
                    margin-right: 16px;
                  `}
                >
                  <Warning size={24} color={colors.yellow[100]} weight="bold" />
                  <Text label>No Benefit Package connected</Text>
                </Box>
              )
            ) : (
              <Text styles="h3 bold padr">Summary Type</Text>
            )}
            <Button
              styles="icon sm"
              hoverLabel="Delete Benefits"
              className={css`
                min-width: 32px;
              `}
              onClick={handleDeleteComponent}
            >
              <Trash />
            </Button>
          </Div>
          <Div
            className={css`
              width: 100%;
              border-top: 1px solid ${colors.gray[300]};
            `}
          />
          <Div
            className={css`
              padding-top: ${px.md};
              max-height: 250px;
              overflow-y: auto;
            `}
          >
            {editor?.state?.editorType === 'template' || devMode
              ? ALL_TYPES.map(
                  (item) =>
                    PRODUCT_HEADERS[item] && (
                      <Div
                        key={item}
                        onClick={() => setType(item)}
                        css={css`
                          ${flex('jcsb aic')} ${container.hover} padding: 8px 16px;
                        `}
                      >
                        <Text styles="label padh">{PRODUCT_HEADERS[item]}</Text>
                        <Radio checked={item === type} color="primary" />
                      </Div>
                    )
                )
              : !type
              ? selectableProductTypes.map(([key, display]) =>
                  data?.[key]?.length ? (
                    <Div
                      key={key}
                      onClick={() => setType(key)}
                      css={css`
                        ${flex('jcsb aic')} ${container.hover} padding: ${px.lg} ${px.lg};
                      `}
                    >
                      <Text styles="label padh">{display}</Text>
                      <CaretRight size={24} />
                    </Div>
                  ) : null
                )
              : productList?.map((item) => (
                  <Div
                    key={item.ID}
                    onClick={() => handleSelectProduct(item)}
                    css={css`
                      ${flex('aic')} padding: ${px.lg} ${px.lg};
                      ${container.hover}
                    `}
                  >
                    <Radio
                      style={{ width: '32px', height: '32px' }}
                      checked={product?.ID === item.ID}
                      color="primary"
                    />
                    <Div
                      css={css`
                        ${flex('space-between')} width: 100%;
                      `}
                    >
                      <Text styles="label capitalize padh ">{getProductName(item)}</Text>
                      {item?.MultiNetworkCategory && (
                        <Text>{mutliNetworkCategoryMap?.[item?.MultiNetworkCategory]}</Text>
                      )}
                    </Div>
                  </Div>
                ))}
          </Div>
          <Div
            css={css`
              ${flex('jcsb aic')} padding: ${px.md};
            `}
            onClick={(event) => event.stopPropagation()}
          >
            <Button
              disabled={loading}
              className={css`
                width: 50%;
              `}
              styles="secondary margin"
              onClick={handleBack}
            >
              {type ? 'Back' : 'Close'}
            </Button>
            <Button
              className={css`
                width: 50%;
              `}
              styles="primary margin"
              onClick={addBenefit}
              disabled={(editor?.state?.editorType === 'page' && !product?.ID && !devMode) || !type || loading}
            >
              Add {editor?.state?.editorType !== 'page' ? 'Type' : ''}
            </Button>
          </Div>
        </Div>
      )}
    </Div>
  );
};
