import { css } from '@emotion/css';
import { useContext, useMemo, useRef, useState } from 'react';
import { Div } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Column } from '.';
import { RowToolbar, RowIndicators, ColorPicker } from '../toolbar-menus';
import { EditorDetailsContext } from '../provider/editor-detail-provider';
import { editorUtils, iterateColumns } from '../provider/utils';
import { useStore } from '../../store-provider/use-store';

const rowStyles = ({ drag, isMobileStack, totalColumns, index, multiSelect, isRowInSelection }) => {
  const isMultiDragSource = drag?.action === 'multi-move' && index >= drag?.source?.start && index <= drag?.source?.end;

  return css`
    overflow-x: visible;
    position: relative;
    ${!isMobileStack && `display: flex;`}

    :hover {
      .tools {
        z-index: 100;
        opacity: 1 !important;
      }
    }

    ${isRowInSelection
      ? `
      ::before {
        content: '';
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        background-color: transparent;
        outline-offset: -2px;
        border-radius: 8px;
        pointer-events: none;
      }
    `
      : ``}

    .outline {
      object-fit: cover;
      border-radius: 8px;
      z-index: 10;
      position: absolute;
      outline: none;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
    }

    ${!drag?.isDragging
      ? `
      .outline { outline-offset: -1px; } 
      :hover {
        .tools { display: flex; opacity: 1; }
        .outline {
          z-index: 150;
          pointer-events: none;
          overflow: hidden;
        }
      }
      ${
        multiSelect?.isSelecting &&
        index >= multiSelect?.start &&
        index <= multiSelect?.end &&
        (!multiSelect?.hoverIndices?.length
          ? `
        .outline {
          outline: 2px solid ${colors.black};
          :hover {
            outline: 2px solid ${colors.black}; 
          }
        }
      `
          : `
        .outline {
          border-radius: 8px;
          outline: 1px solid ${colors.black};
          outline-offset: -1px;
        } 
      `)
      }
    `
      : `
      .drop {
        position: absolute;
        ${totalColumns === 1 ? `top: 0;` : `top: calc(100% - 16px);`}
        bottom: 0;
        right: 15%;
        left: 15%;
        ${drag?.action === 'multi-move' && `right: 0; left: 0;`}
        background: transparent;
        z-index: 150;
        :hover {
          + .outline.droppable {
            border: none;
            border-bottom: 12px solid ${colors.purple}DD;
            border-radius: 0;
            z-index: 149;
            margin-bottom: -5px;
          }
        }
      }
      ${
        isMultiDragSource &&
        `
        opacity: 60%;
        filter: saturate(0);
        border-radius: 8px;
        outline: 2px solid ${colors.black};
        outline-offset: -1px;
        z-index: 100;
        transition: none;
      `
      }
    `}
  `;
};

const rowBackground = ({ data, containerBounds }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  ${data?.fullScreenWidth
    ? `
    left: calc(0px - ${containerBounds?.editor?.left}px + ${containerBounds?.left}px);
    right: calc(0px - ${containerBounds?.right}px + ${containerBounds?.editor?.right}px + 8px);
  `
    : `
    left: 0;
    right: 0;
  `}

  z-index: -1;
`;

export const Row = ({ editor, row, index }) => {
  const rowRef = useRef(null);
  const {
    data: { devMode },
  } = useStore();

  const {
    editorDetails: { containerBounds = {} },
  } = useContext(EditorDetailsContext);

  const {
    state: { drag = {}, layout, viewMode, selection, multiSelect },
    shiftKeyIsPressed,
  } = editor;

  const [resizeIndex, setResizeIndex] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);

  const dragOver = (position = '') => {
    const destinationIsSource = drag?.source?.location?.length === 1 && index === drag?.source?.location?.[0];
    if (position && !destinationIsSource) {
      editorUtils?.setProperty(editor, 'drag', {
        ...drag,
        destination: { location: [index], position },
      });
    } else {
      editorUtils?.setProperty(editor, 'drag', {
        ...drag,
        destination: { location: [], position: '' },
      });
    }
  };

  const hasBenefitSummary = useMemo(() => {
    let hasBenefitSummary = false;
    iterateColumns(row?.columns, (_, item) => {
      if (item?.component === 'benefits') {
        hasBenefitSummary = true;
      }
    });
    return hasBenefitSummary;
  }, [row?.columns?.length]);

  const isMobileStack =
    (row.stackColumns || hasBenefitSummary) &&
    containerBounds?.editor?.width <= 420 &&
    containerBounds?.editor?.width > 0;

  const handleMultiSelect = (event) => {
    if (shiftKeyIsPressed) {
      event.stopPropagation();
      let endValue,
        startValue = multiSelect?.start;
      if (typeof startValue !== 'number') {
        if (selection?.length) {
          startValue = selection?.[0];
        } else {
          startValue = index;
        }
      }
      endValue = index;

      const start = Math.min(startValue, endValue);
      const end = Math.max(startValue, endValue);

      const rows = layout.rows.slice(start, end + 1);

      editorUtils?.setProperty(editor, 'multiSelect', {
        rows,
        start,
        end,
        isSelecting: true,
      });
      editorUtils?.setProperty(editor, 'selection', null);
    }
  };

  const isMultiDropDestination =
    drag?.action === 'multi-move' && index >= drag?.source?.start - 1 && index <= drag?.source?.end;

  return (
    <Div
      className={rowStyles({
        drag,
        index,
        multiSelect,
        isMobileStack,
        isRowInSelection: selection?.[0] === index,
        totalColumns: row?.columns?.length,
      })}
      onMouseEnter={() => setMouseEntered(!drag?.isDragging)}
      onMouseLeave={() => setMouseEntered(false)}
      onClick={handleMultiSelect}
      ref={rowRef}
    >
      <div
        className={rowBackground({ data: row, containerBounds })}
        style={{
          ...(row?.style || {}),
          backgroundColor: row?.style?.backgroundColor || ColorPicker.transparentColor,
        }}
      />

      {!drag?.isDragging ? (
        <>
          <RowIndicators data={row} index={index} viewMode={viewMode} devMode={devMode} />
          <RowToolbar data={row} index={index} editor={editor} isRowInSelection={selection?.[0] === index} />
        </>
      ) : (
        !isMultiDropDestination && <div className="drop" onMouseUp={() => dragOver('row')} />
      )}

      <div className="outline droppable" />

      {row?.columns?.map((element, idx) =>
        element ? (
          <Column
            key={`${editor?.state?.syncRenderState}-${row.rowId}-${index}-${idx}`}
            editor={editor}
            element={element}
            location={[index, idx]}
            // Put all other props into column:
            column={{
              mouseEntered,
              isMobileStack,
              resizeIndex,
              setResizeIndex,
              columns: row?.columns,
            }}
            rowRef={rowRef}
          />
        ) : null
      )}
    </Div>
  );
};
