import { css } from '@emotion/css';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { HandWaving } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '../../../common/hooks/useModal';
import { BriteSearchableMultiSelect } from '../../../Courses/Courses';
import { useQueryAPI } from '../../../react-query';
import { CustomAxios } from '../../../redux/axios/axios';
import { Button, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { MessageTemplatesModal } from './MessageTemplatesModal';
import { LaunchSuccessModal } from './LaunchSuccessModal';

const TEAMS_INDIVIDUALS_TAB = 'Individuals';
const TEAMS_CHANNELS_TAB = 'Channels';

export const TeamsModal = ({ hide = () => {}, guide = {}, initialMessage = '' }) => {
  const [teamsTab, setTeamsTab] = useState(TEAMS_INDIVIDUALS_TAB);

  const [teamsUsers, setTeamsUsers] = useState([]);
  const [teamsUsersToMessage, setTeamsUsersToMessage] = useState(new Set());
  const [teamsUsersPlusAll, setTeamsUsersPlusAll] = useState([]);
  const [allTeamsUsersSelected, setAllTeamsUsersSelected] = useState(false);

  const [teamsChannels, setTeamsChannels] = useState([]);
  const [teamsChannelsToMessage, setTeamsChannelsToMessage] = useState(new Set());

  const [teamsTeamOptions, setTeamsTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});

  const [loading, setLoading] = useState(false);

  const [messageBody, setMessageBody] = useState(initialMessage);

  const messageTemplatesModal = useModal();
  const launchSuccessModal = useModal();

  const messageMaxLength = 360;

  useQueryAPI(
    {
      url: `v1/teams`,
      enabled: true,
      select: (data) => (data?.length ? data : []),
      defaultValue: [],
      onMount: async (teamOptions) => {
        teamOptions.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : b.displayName.toLowerCase() > a.displayName.toLowerCase()
            ? -1
            : 0
        );

        setTeamsTeamOptions(teamOptions);
        if (teamOptions.length > 0) {
          setSelectedTeam(teamOptions[0]);
        }
      },
    },
    []
  );

  useEffect(() => {
    if (selectedTeam && selectedTeam.displayName) {
      const fetchTeamsData = async () => {
        try {
          setLoading(true);
          const channelResponse = await CustomAxios.get(`v1/teams/${selectedTeam.teamId}/channel`);
          const channels = channelResponse.data;

          channels.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
          setTeamsChannels(channels);
          // Reset the selected channels
          setTeamsChannelsToMessage(new Set());

          const usersResponse = await CustomAxios.get(`v1/teams/${selectedTeam.teamId}/user`);
          const users = usersResponse.data;

          users.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
          setTeamsUsers(users);

          setTeamsUsersPlusAll([{ id: 'all', displayName: 'Select All' }, ...users]);
          // Reset the selected users
          setTeamsUsersToMessage(new Set());
          setAllTeamsUsersSelected(false);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      };
      fetchTeamsData();
    }
  }, [selectedTeam]);

  const sendTeamsMessage = async (channels, body, cId) => {
    if (messageBody.length > 4000) {
      toast.error(`Message body has too many characters.`);
      return;
    }
    setLoading(true);

    const allIds = Array.from(channels).map((c) => c.userId);
    const ids = [...new Set(allIds)];

    const data = {
      UserIDs: ids,
      Message: body,
      CourseID: cId,
    };

    try {
      await CustomAxios.post(`v1/teams/message`, data);

      setLoading(false);
      launchSuccessModal.toggle();
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (err) {
      setLoading(false);
      toast.error(`Failed to send teams message.`);
      console.log(err);
    }
  };

  if (launchSuccessModal.isOpen) {
    return (
      <LaunchSuccessModal
        hide={() => {
          hide();
          launchSuccessModal.hide();
        }}
      />
    );
  }

  return (
    <Modal display full onClose={() => hide()}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <Text h1>Teams Guide Link</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button styles="icon" hoverLabel="Message Templates Help" onClick={() => messageTemplatesModal.toggle()}>
            <HandWaving />
          </Button>
          <div>
            <Button secondary onClick={() => hide()}>
              Cancel
            </Button>
          </div>
          <div>
            <Button
              disabled={!messageBody || teamsUsersToMessage.length === 0}
              onClick={() => {
                sendTeamsMessage(teamsUsersToMessage, messageBody, guide.ID);
              }}
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
      <div
        id="send-via-teams"
        className={css`
          padding: 48px;
          max-width: 700px;
          margin: 0 auto;
          overflow: auto;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d1dae3;
            padding-bottom: 18px;
            width: 100%;
          `}
        >
          <div
            className={css`
              cursor: pointer;
              font-weight: 700;
              font-size: 18px;
              color: ${teamsTab === TEAMS_INDIVIDUALS_TAB ? colors.black : colors.gray[500]};
              border-bottom: ${teamsTab === TEAMS_INDIVIDUALS_TAB
                ? `4px solid ${colors.black}`
                : '4px solid transparent'};
              padding-bottom: 18px;
              margin-bottom: -19px;
              justify-content: center;
              width: 50%;
              display: flex;
            `}
            onClick={() => {
              setTeamsTab(TEAMS_INDIVIDUALS_TAB);
              // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
              setTeamsUsersToMessage(new Set());
              setAllTeamsUsersSelected(false);
              setTeamsChannelsToMessage(new Set());
            }}
          >
            {TEAMS_INDIVIDUALS_TAB}
          </div>
          <div
            className={css`
              cursor: pointer;
              font-weight: 700;
              font-size: 18px;
              color: ${teamsTab === TEAMS_CHANNELS_TAB ? colors.black : colors.gray[500]};
              border-bottom: ${teamsTab === TEAMS_CHANNELS_TAB ? `4px solid ${colors.black}` : '4px solid transparent'};
              padding-bottom: 18px;
              margin-bottom: -19px;
              justify-content: center;
              width: 50%;
              display: flex;
            `}
            onClick={() => {
              setTeamsTab(TEAMS_CHANNELS_TAB);
              // Clear out selected recipients if the user switches tabs, to ensure no extra people/channels are messaged.
              setTeamsUsersToMessage(new Set());
              setAllTeamsUsersSelected(false);
              setTeamsChannelsToMessage(new Set());
            }}
          >
            {TEAMS_CHANNELS_TAB}
          </div>
        </div>
        <div
          className={css`
            min-width: 640px;
          `}
        >
          <div
            className={css`
              margin-bottom: 12px;
            `}
          >
            {teamsTab === TEAMS_CHANNELS_TAB && (
              <div id="teams-channels-tab">
                {teamsTeamOptions && teamsTeamOptions.length > 1 && (
                  <div id="teams-select-team-tab">
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 16px;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                          <InputLabel id="demo-simple-select-outlined-label">Select Team</InputLabel>
                          <Select
                            value={selectedTeam}
                            onChange={(e) => {
                              setSelectedTeam(e.target.value);
                            }}
                            variant={'outlined'}
                            label="Select Team"
                            fullWidth
                            style={{ width: '100%' }}
                          >
                            {teamsTeamOptions.map((opt) => {
                              return (
                                <MenuItem value={opt} key={opt.teamId}>
                                  {opt.displayName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}{' '}
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      flex-grow: 1;
                      width: 100%;
                    `}
                  >
                    {loading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <BriteSearchableMultiSelect
                        label="Add Channels"
                        disabled={!selectedTeam.displayName}
                        labelKey="displayName"
                        value={teamsChannelsToMessage}
                        options={teamsChannels}
                        onChange={(e, selectedChannels) => {
                          setTeamsChannelsToMessage(new Set(selectedChannels));
                          setTeamsUsersToMessage(new Set());

                          let contactsToMessage = new Set();

                          let channelMembersPromises = [];

                          // Iterate over all the currently selected channels
                          for (let i = 0; i < selectedChannels.length; i++) {
                            const channelID = selectedChannels[i].id;

                            const f = async () => {
                              try {
                                const response = await CustomAxios.get(
                                  `/v1/teams/${selectedTeam?.teamId || ''}/channel/${channelID}/member`
                                );
                                const members = response.data;

                                members.forEach((member) => {
                                  contactsToMessage.add(member);
                                });
                              } catch (err) {
                                console.warn(err);
                              }
                            };
                            channelMembersPromises.push(f());
                          }

                          Promise.all(channelMembersPromises).then(() => {
                            setTeamsUsersToMessage(contactsToMessage);
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={css`
                    margin-top: 4px;
                  `}
                >
                  <Text helper>All members of the channel(s) will receive an individual chat message.</Text>
                </div>
              </div>
            )}
            {teamsTab === TEAMS_INDIVIDUALS_TAB && (
              <div id="teams-dm-tab">
                {teamsTeamOptions && teamsTeamOptions.length > 1 && (
                  <div id="teams-select-team-tab">
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 8px;
                      `}
                    >
                      <div
                        className={css`
                          flex-grow: 1;
                          width: 100%;
                        `}
                      >
                        <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                          <InputLabel id="demo-simple-select-outlined-label">Select Team</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            value={selectedTeam}
                            onChange={(e) => {
                              setSelectedTeam(e.target.value);
                            }}
                            variant={'outlined'}
                            label="Select Team"
                            fullWidth
                            style={{ width: '100%' }}
                          >
                            {teamsTeamOptions.map((opt) => {
                              return (
                                <MenuItem value={opt} key={opt.teamId}>
                                  {opt.displayName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      flex-grow: 1;
                      width: 100%;
                    `}
                  >
                    {loading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <BriteSearchableMultiSelect
                        label="Add Individuals"
                        disabled={!selectedTeam.displayName}
                        labelKey="displayName"
                        closeOnSelect={false}
                        value={teamsUsersToMessage}
                        options={teamsUsersPlusAll}
                        allAreSelected={allTeamsUsersSelected}
                        onChange={(e, newValue) => {
                          let useAll = false;
                          newValue.forEach((member) => {
                            if (member.id === 'all') {
                              useAll = true;
                            }
                          });

                          if (useAll) {
                            if (allTeamsUsersSelected) {
                              // If all users were already selected, and the "all" option is selected again, then unselect all users.
                              setTeamsUsersToMessage(new Set());
                              setAllTeamsUsersSelected(false);
                            } else {
                              setTeamsUsersToMessage(new Set(teamsUsers));
                              setAllTeamsUsersSelected(true);
                            }
                          } else {
                            setTeamsUsersToMessage(new Set(newValue));
                            if (newValue.length === teamsUsers.length) {
                              // If all teams users have been selected (even though the "all" option was not used) need to set all to true.
                              setAllTeamsUsersSelected(true);
                            } else {
                              // Keep AllTeamsUsersSelected false if the "all" option was not used, and not all users have been manually added.
                              setAllTeamsUsersSelected(false);
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={css`
                padding-bottom: 8px;
                padding-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <Text label>Message</Text>
              <Text
                label
                css={css`
                  color: ${messageBody.length > messageMaxLength ? 'red' : 'inherit'};
                `}
              >
                {messageBody.length} / {messageMaxLength}
              </Text>
            </div>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              style={{ width: '100%' }}
              value={messageBody}
              onChange={(e) => {
                setMessageBody(e.target.value);
              }}
            />
            <div
              className={css`
                margin-top: 4px;
                margin-bottom: 16px;
              `}
            >
              <Text helper>The guide link will show up at the end of your message.</Text>
            </div>
          </div>
        </div>
      </div>
      {messageTemplatesModal.isOpen && (
        <MessageTemplatesModal hideMessageTemplates={() => messageTemplatesModal.hide()} guide={guide} />
      )}
    </Modal>
  );
};
