import { endOfDay } from 'date-fns';
import { useForm } from './use-form';
import { Button, Dates, Modal } from './components';

export const DateRangeModal = ({ title = '', modalProps, range, setRange, rangeOptions = {} }) => {
  // SOME PROP DEFINITIONS:
  // --> dateRange = { start, end }: native Date objects
  // --> rangeOptions = props to override <Dates.Range /> defaults

  const form = useForm(range, []);

  const submit = () => {
    setRange(form.values);
  };

  return (
    <Modal display={modalProps.isOpen} onClose={modalProps.hide}>
      <Modal.Paper width="700px">
        <Modal.Header title={title} onClose={modalProps.hide} />
        <Modal.Body>
          <Dates.Range
            maxDate={endOfDay(new Date())}
            start={form.values.start}
            end={form.values.end}
            setRange={form.setValues}
            {...rangeOptions}
          />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={modalProps.hide}>
            Cancel
          </Button>
          <Button onClick={submit}>Update</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
