import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { Button, IconButton, Typography } from '@material-ui/core';
import ButtonUI from '@material-ui/core/Button';
import { Plus, Trash, X } from '@phosphor-icons/react';
import { v4 as uuidv4 } from 'uuid';
import { history } from '../history';
import { Div, Input, Modal, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { useBackendConfig } from '../shared/use-backend-config';
import { Tooltip } from '../common/components/Tooltip';

// combineFields takes a new field, and an existing array of fields and either overwrites (based on id) or adds a new entry to existing array.
const combineFields = (newField, updateableFields) => {
  if (!updateableFields) {
    return [newField];
  }

  const idx = updateableFields.findIndex((field) => field.id === newField.id);
  if (idx > -1) {
    let copyFields = [...updateableFields];
    copyFields[idx] = newField;
    return copyFields;
  }

  if (!newField.id) {
    newField.id = uuidv4();
  }
  return [...updateableFields, newField];
};

const replaceField = (newField, updateableFields, idToReplace) => {
  // No fields to replace, just return the existing empty array.
  if (!updateableFields) {
    return updateableFields;
  }

  const idx = updateableFields.findIndex((field) => field.id === idToReplace);
  if (idx > -1) {
    // If the new field is null, we are deleting the field.
    if (newField === null) {
      return updateableFields.filter((field) => field.id !== idToReplace);
    }
    // Otherwise, we are replacing the field.
    updateableFields[idx] = newField;
    return updateableFields;
  }
};

const deleteField = async (fieldID, fieldList, setFieldList) => {
  try {
    // If current business owns the smart text, it can be updated (upserted).
    await CustomAxios.delete(`/v1/bp/company_smart_tag?id=eq.${fieldID}`, {
      headers: {
        'Content-Profile': 'brite',
        Prefer: 'return=representation',
      },
    });
    toast.success('Smart text successfully removed');
    setFieldList(replaceField(null, fieldList, fieldID));
  } catch (e) {
    toast.error('Error deleting smart text.');
  }
};

const AdditionalSmartFields = (selectedBusinessID) => {
  return (
    <>
      <hr
        className={css`
          border: 0;
          height: 1px;
          width: 100%;
          background: #d1dae3;
          margin-top: 45px;
          margin-bottom: 56px;
        `}
      />
      <div
        className={css`
          display: flex;
          margin-bottom: 40px;
        `}
      >
        <Typography
          color="primary"
          style={{
            fontWeight: '700',
            fontSize: '18px',
            minWidth: '240px',
            maxWidth: '240px',
            fontStyle: 'normal',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            marginRight: '23px',
          }}
        >
          Additional Smart Text
        </Typography>
        <div
          className={css`
            border-radius: 8px;
            background: var(--100, #f5f7fa);
            width: 700px;
            flex-shrink: 0;
            padding: 24px;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: left;
              gap: 16px;
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                fill="#6D44FB"
              />
              <path d="M15 15H16V22H17" fill="white" />
              <path
                d="M15 15H16V22H17"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.75 12C16.5784 12 17.25 11.3284 17.25 10.5C17.25 9.67157 16.5784 9 15.75 9C14.9216 9 14.25 9.67157 14.25 10.5C14.25 11.3284 14.9216 12 15.75 12Z"
                fill="white"
              />
            </svg>
            <span
              className={css`
                color: var(--700, #25282d);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px; /* 177.778% */
                letter-spacing: 0.15px;
                padding-bottom: 16px;
                align-items: top;
                justify-content: top;
              `}
            >
              Additional Smart Text
            </span>
          </div>
          <p
            className={css`
              color: #66737f;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin: 0px;
              margin-right: 8px;
              line-height: 24px; /* 150% */
              letter-spacing: 0.15px;
            `}
          >
            Most of the values you add to{' '}
            <span
              onClick={() => history.push(`/${selectedBusinessID}/packages`)}
              className={css`
                cursor: pointer;
                color: blue;
                text-decoration: underline;
              `}
            >
              Brite Benefit Packages
            </span>{' '}
            are ready to sync with a guide.
            <br />
            <br />
            <span
              onClick={() => history.push(`/${selectedBusinessID}/business-details`)}
              className={css`
                cursor: pointer;
                color: blue;
                text-decoration: underline;
              `}
            >
              Business Details
            </span>{' '}
            are also available as Smart Text.
          </p>
        </div>
      </div>
    </>
  );
};

const ChildSmartFieldDisplay = (
  childFieldsList,
  setChildFieldsList,
  inheritedFieldsList,
  currentBusinessID,
  isParent = false
) => {
  return (
    <>
      <div
        className={css`
          display: flex;
        `}
      >
        <Typography
          color="primary"
          style={{
            fontWeight: '700',
            fontSize: '18px',
            minWidth: '240px',
            maxWidth: '240px',
            fontStyle: 'normal',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            marginRight: '23px',
          }}
        >
          {!isParent ? 'Custom Smart Text' : 'Default Smart Text'}
        </Typography>
        <div
          className={css`
            width: 100%;
          `}
        >
          <p
            className={css`
              color: #66737f;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin: 0px;
              margin-right: 8px;
              line-height: 24px; /* 150% */
              letter-spacing: 0.15px;
            `}
          >
            You can add custom Smart Text to use throughout out all of your guides.{' '}
            {isParent ? 'Details may be customized at the client level.' : null}
          </p>
          {childFieldsList?.length ? (
            childFieldsList?.map((field, idx) => (
              <div
                key={idx}
                className={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    width: 100%;
                    margin-bottom: 11px;
                    margin-top: 8px;
                    margin-right: 16px;
                  `}
                >
                  <Typography
                    color="primary"
                    style={{
                      fontWeight: '700',
                      marginBottom: '4px',
                      fontSize: '14px',
                    }}
                  >
                    Label
                  </Typography>
                  <Input
                    type="text"
                    style={{ fontSize: '16px', width: 'calc(100% - 16px)' }}
                    value={field.key}
                    onChange={(e) => {
                      setChildFieldsList(
                        combineFields(
                          {
                            id: field.id,
                            business_id: currentBusinessID,
                            key: e.target.value,
                            value: field.value,
                          },
                          childFieldsList
                        )
                      );
                    }}
                    startIcon={
                      inheritedFieldsList.find((f) => {
                        return f.key === field.key;
                      }) ? (
                        <Tooltip
                          label={
                            <p>
                              Overrides a Default Smart Text. <br />
                              <span
                                className={css`
                                  font-weight: 400;
                                `}
                              >
                                Any Custom Smart Text added with the same label as a Default Smart Text will override
                                the default.
                              </span>
                            </p>
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M3.5 3L8.5 8L3.5 13"
                              stroke="#9AA7B5"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.5 3L13.5 8L8.5 13"
                              stroke="#25282D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Tooltip>
                      ) : null
                    }
                  />
                </div>
                <div
                  className={css`
                    width: 100%;
                    margin-bottom: 11px;
                    margin-top: 8px;
                  `}
                >
                  <Typography
                    color="primary"
                    style={{
                      fontWeight: '700',
                      marginBottom: '4px',
                      fontSize: '14px',
                    }}
                  >
                    Details
                  </Typography>
                  <Input
                    type="text"
                    style={{ fontSize: '16px', width: 'calc(100% - 16px)' }}
                    value={field.value}
                    onChange={(e) => {
                      setChildFieldsList(
                        combineFields(
                          {
                            id: field.id,
                            business_id: currentBusinessID,
                            key: field.key,
                            value: e.target.value,
                          },
                          childFieldsList
                        )
                      );
                    }}
                  />
                </div>
                <Tooltip label="Delete smart text">
                  <IconButton
                    style={{ marginTop: '16px' }}
                    onClick={() => {
                      // If the field is in child fields, delete from child fields list.
                      if (
                        childFieldsList.find((f) => {
                          return f.id === field.id;
                        })
                      ) {
                        deleteField(field.id, childFieldsList, setChildFieldsList, false);
                      }
                    }}
                  >
                    <Trash />
                  </IconButton>
                </Tooltip>
              </div>
            ))
          ) : (
            <Typography style={{ margin: '16px' }}>Click 'Add' to create a new smart text.</Typography>
          )}
          <ButtonUI
            style={{ marginTop: '13px' }}
            variant="outlined"
            onClick={() => {
              setChildFieldsList(
                combineFields(
                  {
                    id: uuidv4(),
                    business_id: currentBusinessID,
                    key: '',
                    value: '',
                  },
                  childFieldsList
                )
              );
            }}
            startIcon={<Plus />}
          >
            Add Field
          </ButtonUI>
        </div>
      </div>
      {inheritedFieldsList?.length ? (
        <div>
          <hr
            className={css`
              border: 0;
              height: 1px;
              background: #d1dae3;
              margin-top: 45px;
              margin-bottom: 56px;
            `}
          />

          <div
            className={css`
              display: flex;
              margin-bottom: 20px;
            `}
          >
            <Typography
              color="primary"
              style={{
                fontWeight: '700',
                fontSize: '18px',
                minWidth: '240px',
                maxWidth: '240px',
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                marginRight: '23px',
              }}
            >
              {!isParent ? 'Default Smart Text' : 'Global Smart Text'}
            </Typography>
            <div
              className={css`
                width: 100%;
              `}
            >
              <p
                className={css`
                  color: #66737f;
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  margin-bottom: 24px;
                  margin-top: 0px;
                  margin-right: 8px;
                  margin-left: 8px;
                  line-height: 24px; /* 150% */
                  letter-spacing: 0.15px;
                  justify-content: top;
                `}
              >
                Available on all guides. A matching custom Smart Text label will override these default details.
              </p>
              <div
                className={css`
                  display: flex;
                  width: 700px;
                  padding: 24px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 8px;
                  border-radius: 16px;
                  border: 1px solid var(--Gray-300, #d1dae3);
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    width: 100%;
                  `}
                >
                  <Typography
                    color="primary"
                    style={{
                      color: '#25282D',
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '24px' /* 150% */,
                      letterSpacing: '0.15px',
                      width: '50%',
                    }}
                  >
                    Label
                  </Typography>
                  <Typography
                    color="primary"
                    style={{
                      color: '#25282D',
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '24px' /* 150% */,
                      letterSpacing: '0.15px',
                      width: '50%',
                      textAlign: 'right',
                    }}
                  >
                    Details
                  </Typography>
                </div>
                {inheritedFieldsList?.map((inheritedField, idx) => (
                  <>
                    <hr
                      className={css`
                        border: 0;
                        height: 1px;
                        width: 100%;
                        background: #d1dae3;
                      `}
                    />
                    <div
                      key={idx}
                      className={css`
                        display: flex;
                        align-items: center;
                        width: 100%;
                      `}
                    >
                      {childFieldsList.find((f) => {
                        return f.key === inheritedField.key;
                      }) ? (
                        <div
                          className={css`
                            display: flex;
                            width: 700px;
                            flex-direction: column;
                            align-items: flex-start;
                          `}
                        >
                          <div
                            className={css`
                              display: flex;
                              align-items: center;
                              width: 100%;
                            `}
                          >
                            <div
                              className={css`
                                padding-right: 8px;
                              `}
                            >
                              <Tooltip
                                label={
                                  <p>
                                    This value has been overriden. <br />
                                    <span
                                      className={css`
                                        font-weight: 400;
                                      `}
                                    >
                                      Any Custom Smart Text added with the same label as a Default Smart Text will
                                      override the default.
                                    </span>
                                  </p>
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M3.5 3L8.5 8L3.5 13"
                                    stroke="#9AA7B5"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.5 3L13.5 8L8.5 13"
                                    stroke="#25282D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            <Typography
                              color="primary"
                              style={{
                                color: '#25282D',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px' /* 150% */,
                                letterSpacing: '0.15px',
                                width: '50%',
                              }}
                            >
                              {inheritedField.key}
                            </Typography>
                            <Typography
                              color="primary"
                              style={{
                                color: '#25282D',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px' /* 150% */,
                                letterSpacing: '0.15px',
                                width: '50%',
                                textAlign: 'right',
                              }}
                            >
                              {
                                childFieldsList.find((f) => {
                                  return f.key === inheritedField.key;
                                }).value
                              }
                            </Typography>
                          </div>
                          <div
                            className={css`
                              display: flex;
                              align-items: center;
                              width: 100%;
                            `}
                          >
                            <Typography
                              color="primary"
                              style={{
                                color: '#9AA7B5',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px' /* 150% */,
                                letterSpacing: '0.15px',
                                width: '100%',
                                textAlign: 'right',
                              }}
                            >
                              Initial value: {inheritedField.value}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={css`
                            display: flex;
                            align-items: center;
                            width: 100%;
                          `}
                        >
                          <Typography
                            color="primary"
                            style={{
                              color: childFieldsList.find((f) => {
                                return f.key === inheritedField.key;
                              })
                                ? '#9AA7B5'
                                : '#25282D',
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '24px' /* 150% */,
                              letterSpacing: '0.15px',
                              width: '50%',
                            }}
                          >
                            {inheritedField.key}
                          </Typography>
                          <Typography
                            color="primary"
                            style={{
                              color: childFieldsList.find((f) => {
                                return f.key === inheritedField.key;
                              })
                                ? '#9AA7B5'
                                : '#25282D',
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '24px' /* 150% */,
                              letterSpacing: '0.15px',
                              width: '50%',
                              textAlign: 'right',
                            }}
                          >
                            {inheritedField.value}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const updateSmartFields = async (smartFields, setSmartFields, isParent, isBriteMaster) => {
  // validate that no smartFields have matching names, and that all fields have labels.
  let invalidLabels = [];
  let uniqueLabels = new Set();
  let duplicatedLabels = new Set();

  smartFields.forEach((smartField) => {
    if (uniqueLabels.has(smartField.key)) {
      duplicatedLabels.add(smartField.key);
    } else {
      uniqueLabels.add(smartField.key);
    }

    if (smartField.key === '') {
      invalidLabels.push(smartField.id);
    }
  });

  if (duplicatedLabels.size > 0) {
    toast.error(
      'Smart text labels must be unique. Please edit/delete duplicate labels: ' + [...duplicatedLabels].join(', ') + '.'
    );
    return;
  }
  if (invalidLabels.length > 0) {
    toast.error('Smart text labels must not be empty. Please delete unused fields.');
    return;
  }

  try {
    // Persist the smart text owned by this business.
    smartFields.forEach(async (smartField) => {
      if (isParent) {
        // Parents are forced to share all fields for now.
        smartField.share_scope = 'inherited';
      } else if (isBriteMaster) {
        // Brite Master shares fields with everyone.
        smartField.share_scope = 'global';
      }
      let createdField = await CustomAxios.put(`/v1/bp/company_smart_tag?id=eq.${smartField.id}`, smartField, {
        headers: {
          'Content-Profile': 'brite',
          Prefer: 'return=representation',
        },
      });

      setSmartFields(combineFields(createdField.data[0], smartFields));
    });

    toast.success('Smart Text successfully updated');
    // Need to set the fields to the new fields, or else the IDs could be wrong.
  } catch (e) {
    toast.error('Error saving smart text.');
    console.error('Error saving smart text', e);
  }
};

export const CompanySmartFields = (selectedBusiness) => {
  const [loading, setLoading] = useState(false);
  const [businessFields, setBusinessFields] = useState([]);
  const [inheritedFields, setInheritedFields] = useState([]);
  const [globalFields, setGlobalFields] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isBriteMaster, setIsBriteMaster] = useState(false);

  const { CourseCatalogBusinessID } = useBackendConfig();

  useEffect(() => {
    setIsBriteMaster(CourseCatalogBusinessID === selectedBusiness.ID);
  }, [CourseCatalogBusinessID, selectedBusiness.ID]);

  const isParent =
    (selectedBusiness.Type === 'multi' || selectedBusiness.Type === 'carrier') &&
    selectedBusiness.ID === selectedBusiness.ParentID;

  useEffect(() => {
    const fetchSmartFields = async () => {
      try {
        setLoading(true);

        const bFields = await CustomAxios.get(`/v1/bp/company_smart_tag?order=key`, {
          headers: {
            'Accept-Profile': 'brite',
          },
        });
        if (bFields && bFields.data) {
          setBusinessFields(bFields.data);
        }

        const globalFields = await CustomAxios.get(`/v1/bp/company_smart_tag?with_share_scope=global&order=key`, {
          headers: {
            'Accept-Profile': 'brite',
          },
        });
        if (globalFields && globalFields.data) {
          // global fields do not return ids (because they are not owned by this business), so we add our own in order to track them uniquely in the UI.
          globalFields.data.forEach((field) => {
            field.id = uuidv4();
          });
          setGlobalFields(globalFields.data);
        }

        // If this is the parent, we already have all the inherited fields.
        if (!isParent) {
          // share_scope "inherited" will return both "global" and "inherited" fields from our parent.
          const iFields = await CustomAxios.get(`/v1/bp/company_smart_tag?with_share_scope=inherited&order=key`, {
            headers: {
              'Accept-Profile': 'brite',
            },
          });
          if (iFields && iFields.data) {
            // inherited fields do not return ids (because they are not owned by this business), so we add our own in order to track them uniquely in the UI.
            iFields.data.forEach((field) => {
              field.id = uuidv4();
            });
            setInheritedFields(iFields.data);
          }
        }
      } catch (e) {
        console.error('error loading company smart text.', e);
      } finally {
        setLoading(false);
      }
    };
    fetchSmartFields();
  }, [selectedBusiness.ID]);

  if (loading) {
    return (
      <div
        className={css`
          margin: 0 auto;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        margin-top: 32px;
        max-width: 1000px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 123.5%;
            letter-spacing: 0.25px;
            color: #25282d;
            margin-bottom: 40px;
          `}
        >
          Smart Text {isBriteMaster ? '(Global)' : null}
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              if (isParent) {
                setShowDeleteModal(true);
              } else {
                updateSmartFields(businessFields, setBusinessFields, isParent, isBriteMaster);
              }
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
      {isParent ? (
        <>
          <Modal display={showDeleteModal}>
            <Div
              css={css`
                width: 600px;
              `}
            >
              <Div
                css={css`
                  ${flex('space-between')} padding: 32px;
                `}
              >
                <Text styles="h2">Save Smart Text</Text>
                <Button styles="icon" onClick={() => setShowDeleteModal(false)}>
                  <X color="#66737F" />
                </Button>
              </Div>

              <Div
                css={css`
                  position: relative;
                  ${flex('space-between')}
                `}
              >
                <Text
                  css={`
                    padding: 0 32px;
                  `}
                >
                  Are you sure you want to update these smart text? It will affect all client accounts.
                </Text>
              </Div>

              <Div
                css={css`
                  ${flex('right')} padding: 32px;
                `}
              >
                <Div
                  className={css`
                    padding-left: 16px;
                    padding-right: 16px;
                  `}
                >
                  <Button color="secondary mt" variant="outlined" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                  </Button>
                </Div>
                <Div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      updateSmartFields(businessFields, setBusinessFields, true, isBriteMaster);
                      setShowDeleteModal(false);
                    }}
                  >
                    Save
                  </Button>
                </Div>
              </Div>
            </Div>
          </Modal>
          {ChildSmartFieldDisplay(businessFields, setBusinessFields, globalFields, selectedBusiness.ID, true)}
        </>
      ) : (
        ChildSmartFieldDisplay(
          businessFields,
          setBusinessFields,
          inheritedFields
            .concat(globalFields)
            .filter(
              (field) =>
                field.share_scope === 'inherited' || inheritedFields.findIndex((f) => f.key === field.key) === -1
            ),
          selectedBusiness.ID
        )
      )}
      {AdditionalSmartFields(selectedBusiness.ID)}
    </div>
  );
};
