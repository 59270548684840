import { css } from '@emotion/css';
import { EyeSlash, X } from '@phosphor-icons/react';
import { Button, Div, Modal, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { ComponentIconMap } from './component-icon-map';
import ReactJson from 'react-json-view';
import { useState } from 'react';
import { getLocationPath } from '../provider/utils';
import { Tooltip } from '../../common/components/Tooltip';

const rowIndicators = (hasIndicators) => css`
  ${flex('aic')}
  position: absolute;
  top: 8px;
  bottom: 8px;
  ${hasIndicators && `border-left: 1px solid ${colors.gray[300]}`};
  left: calc(100% + 32px);
  padding: 0;
  padding-left: 8px;
`;

export const RowIndicators = ({ data, index, viewMode, devMode = false }) => {
  const [modal, setModal] = useState([]);

  const cellData = modal?.length > 2 ? data?.columns?.[modal[1]]?.list?.[modal[2]] : data?.columns?.[modal[1]];

  return (
    <>
      {(data?.hideOnDesktop || data?.hideOnMobile || devMode) && (
        <Div css={rowIndicators(data?.hideOnDesktop || data?.hideOnMobile)} onClick={(e) => e.stopPropagation()}>
          {((viewMode === 'desktop' && data?.hideOnDesktop) || (viewMode === 'mobile' && data?.hideOnMobile)) && (
            <Tooltip label="Hidden Row">
              <EyeSlash size={24} color={colors.gray[300]} />
            </Tooltip>
          )}

          {devMode ? (
            <Div
              css={css`
                ${flex('left center')}
              `}
            >
              {data?.columns?.map(({ component, type, list }, idx) =>
                type === 'list' ? (
                  <Div>
                    {list.map(({ component }, listIdx) => (
                      <Tooltip label={component}>
                        <Div
                          css={css`
                            ${flex('left')}
                          `}
                        >
                          <Button styles="icon sm" onClick={() => setModal(['cell-data', idx, listIdx])}>
                            <ComponentIconMap type={component} size={24} />
                          </Button>
                        </Div>
                      </Tooltip>
                    ))}
                  </Div>
                ) : (
                  <Tooltip label={component}>
                    <Div
                      css={css`
                        ${flex('left')}
                      `}
                    >
                      <Button styles="icon sm" onClick={() => setModal(['cell-data', idx])}>
                        <ComponentIconMap type={component} size={24} />
                      </Button>
                    </Div>
                  </Tooltip>
                )
              )}
            </Div>
          ) : null}
        </Div>
      )}

      <Modal display={modal?.[0] === 'cell-data'} onClick={() => setModal([])}>
        <Div
          css={css`
            padding: 32px;
            padding-bottom: 8px;
            ${flex('space-between')}
            width: 100%;
          `}
        >
          <Text h4>{cellData?.component} data</Text>
          <Button styles="icon" onClick={() => setModal([])}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            margin: 16px 32px;
            ${flex('left')}
          `}
        >
          <Text
            css={`
              padding: 4px 8px;
              border-radius: 30px;
              margin-right: 8px;
              background-color: ${colors.gray[300]};
            `}
          >
            <strong>Location</strong> [{index}, {modal[1]}]
          </Text>
          <Text
            css={`
              padding: 4px 8px;
              margin-right: 8px;
              border-radius: 30px;
              background-color: ${colors.gray[300]};
            `}
          >
            <strong>Path</strong> {getLocationPath([index, modal[1]])}
          </Text>
        </Div>
        <Div
          css={css`
            margin: 16px;
            padding: 32px;
            width: 700px;
            min-height: 300px;
            max-height: 50vh;
            border-radius: 8px;
            background-color: ${colors.gray[100]};
            overflow: auto;
          `}
          onClick={(e) => e.stopPropagation()}
        >
          <ReactJson src={cellData} collapsed={true} />
        </Div>
      </Modal>
    </>
  );
};
