export const getDeviceData = (analytics) => {
  if (!analytics) {
    return [
      { label: 'Mobile', value: 0 },
      { label: 'Desktop', value: 0 },
      { label: 'Tablet', value: 0 },
    ];
  }

  const deviceTypes = [
    { label: 'Mobile', key: 'TotalMobileVisits' },
    { label: 'Desktop', key: 'TotalDesktopVisits' },
    { label: 'Tablet', key: 'TotalTabletVisits' },
  ];

  const uniqueUsers = analytics?.UniqueUsers || 0;

  return deviceTypes.map(({ label, key }) => ({
    label,
    value: uniqueUsers && analytics[key] ? Math.round((analytics[key] / uniqueUsers) * 100) : 0,
  }));
};
