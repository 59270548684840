import { isToday } from 'date-fns';
import { css, cx } from '@emotion/css';
import { X, Users } from '@phosphor-icons/react';
import { memo, useEffect, useMemo, useState } from 'react';
import { MEDIA, useQueryAPI } from '../../../react-query';
import { Button, Div, Input, Loader, Modal, NewTag, Text } from '../../../shared/components';
import { flex, px } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useDebounceValue } from '../../../shared/use-debounce-value';
import { useEditorResource } from '../../use-editor-resource';
import { Tooltip } from '../../../common/components/Tooltip';

const selectedTab = (isSelected) => css`
  width: 50%;
  ${isSelected ? `color: ${colors.black}; border-bottom: 2px solid black;` : `color: ${colors.gray[500]};`}
`;

const imageStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 8px;
  :hover {
    border: 1px solid ${colors.gray[300]};
    border-radius: 8px;
  }
`;

const creditStyle = css`
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 4px;
`;

const shareStyle = css`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 4px;
`;

const ImageList = ({ images, tab, changeImage, isLoading }) => {
  return (
    <Div
      className={css`
        ${flex('jcsb wrap')}
        width: 500px;
        height: 400px;
        align-items: stretch;
        overflow-y: auto;
        margin-left: 32px;
        margin-right: 8px;
        ::-webkit-scrollbar-track {
          background-color: none;
          background: blue;
          border: none;
        }
        a {
          color: ${colors.black};
        }
      `}
    >
      {isLoading && <Loader />}

      {images?.length ? (
        <>
          {tab === 'stock' && (
            <Text
              styles="center"
              className={css`
                width: 100%;
              `}
            >
              Photos from{' '}
              <a href="https://unsplash.com/?utm_source=brite&utm_medium=referral" target="_blank">
                Unsplash
              </a>
            </Text>
          )}
          {images.map(
            ({
              Name,
              ID,
              PublicURL = null,
              LogoURL = '',
              CreatedAt,
              ShareScope,
              ShowCredit = false,
              Attribution = {},
            }) => {
              const src = PublicURL || LogoURL;
              return (
                <Div key={ID} className={imageStyle} onClick={() => changeImage(ID, Name, src)}>
                  <img
                    alt="Modal"
                    src={src}
                    className={css`
                      max-width: 100%;
                      max-height: 100%;
                      border-radius: 8px;
                    `}
                  />
                  <NewTag
                    createdAt={CreatedAt}
                    className={css`
                      position: absolute;
                      top: 8px;
                      right: 8px;
                    `}
                  />
                  {ShowCredit ? (
                    <Text
                      color={colors.black}
                      className={cx(
                        creditStyle,
                        css`
                          font-size: 0.95em;
                        `
                      )}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Photo by{' '}
                      <a href={Attribution.link} target="_blank">
                        {Attribution.name}
                      </a>
                    </Text>
                  ) : (
                    <>
                      <Text color={colors.black} className={creditStyle}>
                        {Name}
                      </Text>
                      {ShareScope && ShareScope !== 'business' && (
                        <Tooltip label="Public" className={shareStyle}>
                          <Users size={24} />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Div>
              );
            }
          )}
        </>
      ) : (
        <Div
          css={css`
            ${flex('ais jcc')} margin-top: ${px.md};
            width: 100%;
          `}
        >
          <Text>Search for an image</Text>
        </Div>
      )}
    </Div>
  );
};

const ImageListComponent = memo(ImageList, (prevProps, nextProps) => {
  return (
    prevProps?.tab === nextProps?.tab &&
    prevProps?.images?.length === nextProps?.images?.length &&
    prevProps?.isLoading === nextProps?.isLoading
  );
});

export const ImagesModal = ({ updateImage, ...modalProps }) => {
  const [search, setSearch] = useState('');

  const { data: documents } = useQueryAPI({
    cacheKey: ['pre-fetch-editor-resource'],
    url: MEDIA + '?with_share_scope=business&with_share_scope=inherited',
    defaultValue: [],
    enabled: false,
  });

  const { data: logosData } = useEditorResource('carriers', {
    select: (data) => data.filter(({ Name, LogoURL }) => !!Name && !!LogoURL),
  });

  const [tab, setTab] = useState('images');
  const [searchValue] = useDebounceValue(search, 200);

  const { data: stockPhotos, isLoading } = useQueryAPI({
    cacheKey: ['pre-fetch-editor-resource'],
    url: `v1/content/images/search?query=${searchValue}&page=1`,
    enabled: !!searchValue,
    defaultValue: [],
    select: (data) => {
      return data?.Data?.Unsplash?.reduce((prev, item) => {
        return [
          ...prev,
          {
            Name: (item.description || item.alt_description || '').slice(0, 35),
            ID: item.id,
            PublicURL: item.urls.regular,
            CreatedAt: item.created_at,
            ShowCredit: true,
            Attribution: {
              name: `${item.user.first_name} ${item.user.last_name}`,
              link: `${item.user.links.html}?utm_source=brite&utm_medium=referral`,
            },
          },
        ];
      }, []);
    },
  });

  const imagesData = useMemo(
    () => documents?.filter(({ FileType }) => FileType.includes('image')) || [],
    [documents?.length]
  );

  const changeImage = (id, altText, url) => {
    const alt = altText || 'image';
    updateImage(tab, { src: url, alt, 'data-img-id': id });
    modalProps.onClose();
  };

  const images = useMemo(() => {
    if (tab === 'stock') {
      return stockPhotos;
    }
    let data = tab === 'images' ? imagesData : tab === 'logos' ? logosData : [];
    const { today, old } = data.reduce(
      (prev, item) => {
        const { Name, CreatedAt } = item;
        if (!search || Name.toUpperCase().includes(search.toUpperCase())) {
          const key = isToday(new Date(CreatedAt)) ? 'today' : 'old';
          return { ...prev, [key]: [...prev?.[key], item] };
        }
        return prev;
      },
      { today: [], old: [] }
    );

    return [...today.sort((a, b) => a.Name.localeCompare(b.Name)), ...old.sort((a, b) => a.Name.localeCompare(b.Name))];
  }, [tab, searchValue, imagesData?.length, logosData?.length, stockPhotos?.length]);

  useEffect(() => {
    if (!modalProps.display) {
      setSearch('');
    }
  }, [modalProps.display]);

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          margin: ${px.xl};
        `}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Div className={css`margin: 0 ${px.xl} width: 500px; padding: 0 16px; padding-bottom: 16px;`}>
          <Div
            css={css`
              ${flex('jcsb aic')} margin-bottom: ${px.md};
            `}
          >
            <Text styles="h2">Images</Text>
            <Button styles="icon" onClick={modalProps.onClose}>
              <X />
            </Button>
          </Div>

          <Div>
            <Div
              css={css`
                ${flex('aic')} border-bottom: 1px solid ${colors.gray[500]};
              `}
            >
              <Text
                styles="h4 center padb pointer"
                className={selectedTab(tab === 'images')}
                onClick={() => setTab('images')}
              >
                Images
              </Text>
              <Text
                styles="h4 center padb pointer"
                className={selectedTab(tab === 'stock')}
                onClick={() => setTab('stock')}
              >
                Stock Photos
              </Text>
              <Text
                styles="h4 center padb pointer"
                className={selectedTab(tab === 'logos')}
                onClick={() => setTab('logos')}
              >
                Logos
              </Text>
            </Div>

            <Text styles="label bold mt mb-sm">Search</Text>

            <Input
              className={css`
                width: 100%;
              `}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </Div>
        </Div>

        <ImageListComponent
          tab={tab}
          images={images}
          changeImage={changeImage}
          isLoading={tab === 'stock' ? isLoading : false}
        />
      </Div>
    </Modal>
  );
};
