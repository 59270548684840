import React, { useMemo, useState } from 'react';
import { CaretDown, CaretUp, Question, Sparkle, Trash } from '@phosphor-icons/react';
import { css } from '@emotion/css';
import { sectionContainer } from './product-section';
import { Button, Div, Input, Text, Toggle } from '../shared/components';
import { flex, px } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { SelectCarrier } from '../benefit-package/select-carrier';
import { productModifier } from './configs/product-utils';
import { useAcls } from '../shared/use-acls';
import { WRITE_BENEFITS_PACKAGE } from '../shared/acl-constants';
import { Collapse } from '../common/components/Collapse';
import { Tooltip } from '../common/components/Tooltip';

export const programs = {
  telemedicine: 'Telemedicine',
  wellness_differential: 'Wellness Differential',
  disease_management_program: 'Disease Management',
  specialty_pharmacy_program: 'Specialty Pharmacy',
  health_reimbursement_arrangement: 'Health Reimbursement Arrangement',
  custom: 'Custom',
};

export const programList = Object.entries(programs);

export const IncludedPrograms = ({ state }) => {
  const { includedPrograms } = state;

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);

  const [open, setOpen] = useState(false);

  const availablePrograms = useMemo(() => {
    return programList.filter(([key]) => key !== 'health_reimbursement_arrangement');
  }, []);

  const updateProgram = (type, updates) => {
    productModifier.setIncludedPrograms(state, (old) => ({
      ...old,
      [type]: {
        ...(old[type] || {}),
        ...updates,
      },
    }));
  };

  const updateName = (type, value) => {
    productModifier.setIncludedPrograms(state, (old) => {
      const item = old[type] || {};
      return {
        ...old,
        [type]: {
          ...item,
          RawData: {
            ...(item?.RawData || {}),
            Type: item?.ProgramType,
            Details: {
              ...(item?.RawData?.Details || {}),
              Name: value,
            },
          },
        },
      };
    });
  };

  const handleSelectCarrier = (type, value = null, _, name = null) => {
    updateProgram(type, {
      CarrierID: value,
      CarrierName: name,
    });
  };

  const handleCheck = (checked, type) => {
    if (checked) {
      updateProgram(type, {
        ProgramType: type,
        TitleDescriptions: [],
        CarrierID: null,
        CarrierName: null,
        deleted: false,
      });
    } else {
      productModifier.setIncludedPrograms(state, (programs) => {
        let next = { ...programs };
        next[type].deleted = true;
        return next;
      });
    }
  };

  const addDetail = (type) => {
    let details = [...includedPrograms?.[type]?.TitleDescriptions];
    details.push({ Title: '', Description: '' });
    updateProgram(type, { TitleDescriptions: details });
  };

  const updateDetail = (type, idx, property, value) => {
    let details = [...includedPrograms?.[type]?.TitleDescriptions];
    details.splice(idx, 1, {
      ...details[idx],
      [property]: value,
    });
    updateProgram(type, { TitleDescriptions: details });
  };

  const removeDetail = (type, idx) => {
    let details = [...includedPrograms?.[type]?.TitleDescriptions].filter((_, index) => index !== idx);
    updateProgram(type, { TitleDescriptions: details });
  };

  const includedProgramChecked = (type) => {
    return type in includedPrograms && !includedPrograms[type].deleted;
  };

  return (
    <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
      <Div
        css={css`
          ${flex('jcsb aic')} cursor: pointer;
          ${open && `padding-bottom: 16px;`}
        `}
        onClick={() => setOpen(!open)}
      >
        <Div
          css={css`
            ${flex('aic')}
          `}
        >
          <Div
            css={css`
              ${flex('aic')} min-width: 32px;
              margin-right: 8px;
            `}
          >
            {<Sparkle />}
          </Div>
          <Text styles="h3">Included Programs</Text>
          <Tooltip label="Highlight included features of this medical plan to the employee. These appear under the medical recommendation card when this plan is recommended. ">
            <Question />
          </Tooltip>
        </Div>
        {open ? <CaretUp /> : <CaretDown />}
      </Div>

      <Collapse isOpen={open}>
        {availablePrograms.map(([type]) => (
          <Div
            css={css`
              padding: 16px;
              border-radius: 16px;
              border: 1px solid ${colors.gray[200]};
              margin: 16px 0;
              ${canEdit && !includedProgramChecked(type) && 'cursor: pointer;'}
            `}
            onClick={() => canEdit && !includedProgramChecked(type) && handleCheck(true, type)}
          >
            <Div
              css={css`
                ${flex('space-between')}
              `}
            >
              <Text label>{programs?.[type]}</Text>
              <Toggle
                disabled={!canEdit}
                checked={includedProgramChecked(type)}
                onChange={(event) => canEdit && handleCheck(event.target.checked, type)}
              />
            </Div>
            <Collapse isOpen={includedProgramChecked(type)}>
              {type === 'custom' ? (
                <Div
                  css={css`
                    ${flex('space-between')}
                    margin: 16px 0;
                  `}
                >
                  <Text label>Name</Text>
                  <Input
                    css={`
                      width: 50%;
                    `}
                    disabled={!canEdit}
                    value={includedPrograms?.[type]?.RawData?.Details?.Name || ''}
                    onChange={(event) => updateName(type, event.target.value)}
                  />
                </Div>
              ) : null}

              <Div
                css={css`
                  ${flex('space-between')}
                `}
              >
                <Text label>Carrier</Text>
                <SelectCarrier
                  disabled={!canEdit}
                  selectProps={{ style: { width: '50%', height: '40px' } }}
                  fieldStyle={{ style: { margin: '1px', padding: '1px' } }}
                  carrierId={includedPrograms?.[type]?.CarrierID}
                  setCarrierId={(...args) => handleSelectCarrier(type, ...args)}
                />
              </Div>

              <Div
                css={css`
                  ${flex('left')}
                `}
              >
                <Text h4>Details</Text>
                <Tooltip label="Enter any details you want the employee to see. These will appear on the expanded view of each card.">
                  <Question />
                </Tooltip>
              </Div>
              <Div
                css={css`
                  ${flex('aic')} box-sizing: box-content;
                  margin-top: ${px.lg};
                `}
              >
                <Text
                  styles="label bold"
                  className={css`
                    width: 45%;
                  `}
                >
                  Title
                </Text>
                <Text
                  styles="label bold"
                  className={css`
                    width: 45%;
                    margin-left: ${px.md};
                  `}
                >
                  Description
                </Text>
              </Div>
              {!!includedPrograms?.[type]?.TitleDescriptions?.length &&
                includedPrograms?.[type]?.TitleDescriptions.map((data, idx) => (
                  <Div
                    css={css`
                      ${flex('aic')}
                    `}
                  >
                    <Input
                      disabled={data.Autogenerated || !canEdit}
                      className={css`
                        width: 45%;
                        margin-right: ${px.lg};
                      `}
                      value={data.Title}
                      onChange={(e) => updateDetail(type, idx, 'Title', e.target.value)}
                    />
                    <Input
                      disabled={data.Autogenerated || !canEdit}
                      className={css`
                        width: 45%;
                        margin-right: ${px.lg};
                      `}
                      value={data.Description}
                      onChange={(e) => updateDetail(type, idx, 'Description', e.target.value)}
                    />
                    <Button
                      styles="icon"
                      onClick={() => removeDetail(type, idx)}
                      disabled={data.Autogenerated || !canEdit}
                    >
                      <Trash size={24} />
                    </Button>
                  </Div>
                ))}
              <Div
                css={css`
                  ${flex('jcr aic')} margin-top: ${px.lg};
                `}
              >
                {!includedPrograms?.[type]?.TitleDescriptions?.length && (
                  <Text>Click 'Add Detail' to create a custom detail.</Text>
                )}
                <Button
                  styles="secondary"
                  className={css`
                    margin-left: auto;
                  `}
                  onClick={() => addDetail(type)}
                  disabled={!canEdit}
                >
                  Add Detail
                </Button>
              </Div>
            </Collapse>
          </Div>
        ))}
      </Collapse>
    </Div>
  );
};
