import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/css';
import { Button, Div } from '../../shared/components';
import {
  TextToolbar,
  ImageToolbar,
  SpaceToolbar,
  ButtonToolbar,
  VideoToolbar,
  WebsiteToolbar,
  DocumentToolbar,
  LineToolbar,
  AdvancedToolbar,
  settingsAvailable,
} from '.';
import { CaretDown, CaretUp, Trash } from '@phosphor-icons/react';
import { colors } from '../../shared/styles';
import { useEvent } from '../../shared/use-event';
import { modifyContent } from '../provider/utils';
import { container, flex } from '../../shared/shared-styles';
import { Tags } from './tags';
import { BenefitsToolbar } from './benefits-toolbar';
import { useSearchParams } from '../../shared/use-search-params';
import { TextV2Toolbar } from './textv2-toolbar';
import { addSmartFieldToCache, SmartFields } from '../utility-components/smart-fields';
import { useSlate } from 'slate-react';
import { useEditorResource } from '../use-editor-resource';
import { Editor, Transforms } from 'slate';
import { insertNode, isBlockActive } from '../editor-components/text-v2/slate-utils';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../../common/components/Tooltip';
import { usePopper } from 'react-popper';
import { Portal } from '../../shared/components/Portal';
import { useResize } from '../editor-components/use-resize';

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const menuContainer = ({ hideToolbar, hasShiftHover }) => {
  return css`
    ${flex('center')}
    margin: 8px 0;
    z-index: 1000;
    ${hideToolbar ? `opacity: 0;` : 'opacity: 1;'}
    ${hasShiftHover && `display: none;`}
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  `;
};

const defaultModals = {
  image: 'image-library',
  button: 'button-options',
  document: 'document-library',
  benefits: 'benefits-options',
};

const ComponentTypeMap = {
  textV2: 'text',
};

const componentsWithSettings = (item) => {
  let list = ['image', 'space', 'line', 'button', 'text', 'textV2', 'website', 'video', 'document'];
  if (item?.type && item?.data?.id) {
    list.push(item?.component);
  }
  return list;
};

const hideToolbar = (item) => {
  if ((item?.component === 'pricing' || item?.component === 'network') && (!item?.type || !item?.data?.id)) {
    return true;
  }
  return false;
};

export const Toolbar = (props) => {
  // textUtils is deprecated (only for text-v1)
  const { editor, element, location, flags = {}, textUtils = {} } = props;

  const ref = useRef();

  const popper = usePopper(props?.rowRef?.current, ref?.current);

  useResize(props?.rowRef?.current, () => popper?.update?.());

  const { styles = {}, attributes = {} } = ref?.current && props?.rowRef?.current ? popper : {};

  useEffect(() => {
    ref?.current?.scrollIntoView({ block: 'nearest' });
  }, [ref?.current]);

  const {
    params: { modalToolbar: modal },
    updateParams,
    removeParam,
  } = useSearchParams();

  const {
    state: { selection, recentUpdate, drag },
    shiftKeyIsPressed,
  } = editor;

  const setModal = (modalToolbar) => {
    if (!modalToolbar) {
      removeParam('modalToolbar');
    } else {
      updateParams({ modalToolbar });
    }
  };

  const shouldHideToolbar = hideToolbar(element);
  const [hasShiftHover, setHasShiftHover] = useState(false);
  const [additionalOptions, setAdditionalOptions] = useState('');

  useEffect(() => {
    if (!shiftKeyIsPressed) {
      setHasShiftHover(false);
    }
  }, [shiftKeyIsPressed]);

  const handleDeleteColumn = useCallback(() => {
    modifyContent.remove(editor, location, { syncRenderState: true });
  }, [location?.toString()]);

  const component = element?.component || '';
  const hasMoreSettings = settingsAvailable?.[component]?.length;

  const handleDoubleClick = useCallback(
    (event) => {
      if (event.target.hasAttribute('data-component') && component in defaultModals) {
        setModal(defaultModals[component]);
      }
    },
    [component]
  );

  useEvent('dblclick', handleDoubleClick);

  const setShiftHover = () => {
    if (shiftKeyIsPressed) {
      setHasShiftHover(true);
    }
  };

  useEvent('mousemove', setShiftHover);

  const hasSettings = componentsWithSettings(element).includes(component);

  const componentProps = {
    editor,
    element,
    location,
    //
    toolbar: {
      modal,
      setModal,
      additionalOptions,
      setAdditionalOptions,
    },
    // Deprecated Text-v1 prop:
    textUtils,
  };

  const AdditionalOptions = useMemo(() => {
    if (!additionalOptions) {
      return null;
    }
    return (
      <Div
        className={css`
          position: relative;
          box-sizing: border-box;
          width: 100%;
          padding: 2px 0;
          max-height: 340px;
          overflow-y: auto;
          cursor: default;
        `}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {additionalOptions === 'advanced-options' ? (
          <AdvancedToolbar {...componentProps} />
        ) : additionalOptions === 'merge-tags' ? (
          <Tags editor={editor} textUtils={flags?.textUtils} onClose={() => setAdditionalOptions('')} />
        ) : additionalOptions === 'smart-fields' ? (
          <SmartFieldWrapper />
        ) : null}
      </Div>
    );
  }, [additionalOptions, recentUpdate?.updatedAt]);

  if (drag?.isDragging || shouldHideToolbar) {
    return null;
  }

  const componentType = component in ComponentTypeMap ? ComponentTypeMap[component] : component;

  return (
    <Portal>
      <Div
        ref={ref}
        css={menuContainer({
          hasShiftHover,
          hideToolbar: drag?.isDragging || !selection?.length,
        })}
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
        style={styles.popper}
        {...attributes.popper}
      >
        <Div
          css={css`
            ${container.box}
          `}
          onClick={(e) => e.stopPropagation()}
          draggable
          onDragStart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {component && (
            <Div>
              <Div
                css={css`
                  ${flex('jcsb aic')}
                `}
              >
                {hasSettings && (
                  <Div
                    className={css`
                      width: 100%;
                      padding-right: 16px;
                      border-right: 1px solid ${colors.gray[200]};
                    `}
                  >
                    {component === 'image' ? (
                      <ImageToolbar {...componentProps} />
                    ) : component === 'pricing' ? (
                      <BenefitsToolbar {...componentProps} />
                    ) : component === 'network' ? (
                      <BenefitsToolbar {...componentProps} />
                    ) : component === 'benefits' ? (
                      <BenefitsToolbar {...componentProps} />
                    ) : component === 'space' ? (
                      <SpaceToolbar {...componentProps} />
                    ) : component === 'line' ? (
                      <LineToolbar {...componentProps} />
                    ) : component === 'button' ? (
                      <ButtonToolbar {...componentProps} />
                    ) : component === 'text' ? (
                      <TextToolbar {...componentProps} />
                    ) : component === 'textV2' && flags?.usingTextV2 ? (
                      <TextV2Toolbar {...componentProps} />
                    ) : component === 'website' ? (
                      <WebsiteToolbar {...componentProps} />
                    ) : component === 'document' ? (
                      <DocumentToolbar {...componentProps} />
                    ) : component === 'video' ? (
                      <VideoToolbar {...componentProps} />
                    ) : null}
                  </Div>
                )}
                <Div
                  css={css`
                    ${flex('aic')} ${hasSettings ? `padding-left: 6px;` : ''} margin: auto 0;
                  `}
                >
                  <Button
                    hoverLabel={`Delete ${toTitleCase(componentType)}`}
                    styles="icon sm"
                    onClick={handleDeleteColumn}
                  >
                    <Trash size={24} />
                  </Button>
                  {hasMoreSettings && (
                    <Tooltip label="Options">
                      <Button
                        styles="icon sm"
                        onClick={() =>
                          setAdditionalOptions((a) => (a === 'advanced-options' ? '' : 'advanced-options'))
                        }
                      >
                        {additionalOptions === 'advanced-options' ? <CaretUp size={24} /> : <CaretDown size={24} />}
                      </Button>
                    </Tooltip>
                  )}
                </Div>
              </Div>
              {AdditionalOptions}
            </Div>
          )}
        </Div>
      </Div>
    </Portal>
  );
};

const SmartFieldWrapper = () => {
  const slateEditor = useSlate();
  const { cache } = useEditorResource('liveSmartFields');

  const isSmartFieldActive = isBlockActive(slateEditor, 'smart-field');

  const handleRemove = () => {
    const [block] = Editor.nodes(slateEditor, {
      match: (n) => !Editor.isBlock(slateEditor, n) && n.type === 'smart-field',
    });
    const [, blockPath] = block || [];
    if (block?.[0]?.type === 'smart-field') {
      Transforms.delete(slateEditor, {
        at: blockPath,
      });
    }
  };

  const activeSmartField = useMemo(() => {
    if (isSmartFieldActive) {
      const [block] = Editor.nodes(slateEditor, {
        match: (n) => !Editor.isBlock(slateEditor, n) && n.type === 'smart-field',
      });
      const [v] = block || [];
      if (block?.[0]?.type === 'smart-field') {
        return v;
      }
    }
    return null;
  });

  const handleSmartField = ({ data, replace = false, dataSourceChain = [] }) => {
    if (replace) {
      handleRemove();
    }
    const [block] = Editor.nodes(slateEditor, {
      match: (n) => !Editor.isBlock(slateEditor, n) && n.type === 'smart-field',
    });
    if (!block) {
      insertNode(slateEditor, {
        ...data,
        dataSourceChain,
        type: 'smart-field',
        children: [{ text: '' }, { text: data.name }, { text: '' }],
        frontendId: uuidv4(),
      });
      addSmartFieldToCache(cache, data);
    }
  };

  return (
    <SmartFields handleSmartField={handleSmartField} handleRemove={handleRemove} activeSmartField={activeSmartField} />
  );
};
