import { css } from '@emotion/css';
import { MagnifyingGlass, Users } from '@phosphor-icons/react';
import { Button, Div, Input, Loader, Modal, Text } from '../../../shared/components';
import { flex, shadows } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useStore } from '../../../store-provider/use-store';
import { useTemplates } from '../../../templates/use-templates';
import { MemoizedIFrame } from '../../../templates/memoized-iframe';
import { thumbnailWidth } from '../../../templates/Templates';
import { UnlayerPreview } from '../../../templates/unlayer-preview';
import { TemplateFilters } from '../../../templates/template-filters';
import { useState } from 'react';
import { useSearchParams } from '../../../shared/use-search-params';
import { CustomAxios } from '../../../redux/axios/axios';
import { toast } from 'react-toastify';
import { useStateSync } from '../../../shared/use-state-sync';
import { BriteLoader } from '../../../shared/components/brite-loader';
import { Tooltip } from '../../../common/components/Tooltip';

export const NewTemplatePageModal = ({ courseId, refetch: refetchPages, ...modalProps }) => {
  const { updateParams } = useSearchParams();

  const {
    data: { parentBusiness, selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness?.ID;
  const parentBusinessId = selectedBusiness?.ParentID || parentBusiness?.ID;

  const templateProps = useTemplates({ businessId, parentBusinessId });
  const { paginatedQuery } = templateProps;

  const [selectedTemplates, setSelectedTemplates] = useStateSync([], [modalProps.display]);
  const [isSaving, setIsSaving] = useState(false);

  const { paginatedList: templates, isLoading, isFetching, hasMore, fetchNext } = paginatedQuery;

  const createPageFromTemplate = async () => {
    let pageId = '';
    try {
      setIsSaving(true);
      const templateRequests = await Promise.allSettled(
        selectedTemplates.map((template) => {
          return CustomAxios.post(`/v2/course/${courseId}/pages`, {
            Type: template?.Type || 'regular',
            Name: template?.Name,
            Content: template?.Content,
            FrontendID: '_' + Math.random().toString(36).substr(2, 9),
            IsLocked: false,
            TemplateID: '00000000-0000-0000-0000-000000000000',
            CourseID: courseId,
          });
        })
      );
      const firstPage = templateRequests.find(({ status }) => status === 'fulfilled');
      if (firstPage) {
        pageId = firstPage?.value?.data?.ID;
      }
    } catch (err) {
      console.warn(err);
      toast.error(`Failed to create new page.`);
    } finally {
      setIsSaving(false);
      refetchPages();
      if (pageId) {
        modalProps.onClose();
        updateParams({ pageId });
      }
    }
  };

  const detectBottomScroll = (event) => {
    const parentScrollHeight = event.target.parentElement.scrollHeight;
    const scrolledBottom = event.target.scrollTop + parentScrollHeight > event.target.scrollHeight - 150;
    if (scrolledBottom && hasMore && !isFetching) {
      fetchNext();
    }
  };

  const selectedIds = selectedTemplates.map(({ ID }) => ID);

  const selectTemplate = (item) => {
    if (selectedIds?.includes(item?.ID)) {
      const next = selectedTemplates.filter(({ ID }) => item?.ID !== ID);
      setSelectedTemplates(next);
    } else {
      setSelectedTemplates([...selectedTemplates, item]);
    }
  };

  return (
    <Modal full {...modalProps}>
      <Div
        css={css`
          height: 100vh;
          width: 100%;
          overflow-y: scroll;
        `}
        onScroll={detectBottomScroll}
      >
        <Div
          css={css`
            position: sticky;
            top: 0;
            z-index: 100;
            background-color: white;
            height: 100px;
            width: 100%;
            ${flex('space-between')} padding: 32px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h1>Select a template</Text>

          <Div
            css={css`
              ${flex('right')}
            `}
          >
            <Input
              styles="search"
              value={templateProps.search}
              onChange={(e) => templateProps.handlers.setSearch(e.target.value)}
              placeholder="Search"
              startIcon={<MagnifyingGlass color={colors.gray[500]} />}
              css={`
                padding-left: 8px;
                margin-right: 16px;
              `}
            />
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={modalProps.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedTemplates.length || isSaving}
              onClick={createPageFromTemplate}
              css={`
                position: relative;
              `}
            >
              <Loader
                isLoading={isSaving}
                type="three-dots"
                className={css`
                  height: calc(100% - 16px);
                  ${flex('center')} background-color: ${colors.gray[300]};
                `}
              />
              {selectedTemplates.length < 2 ? 'Add Page' : 'Add Pages'}
            </Button>
          </Div>
        </Div>

        <Div
          css={css`
            width: 100%;
            max-width: 1100px;
            margin: auto;
            padding-top: 32px;
          `}
        >
          <TemplateFilters {...templateProps} businessId={businessId} parentBusinessId={parentBusinessId} />

          <Div
            css={css`
              position: relative;
              ${flex('left start wrap')} width: 100%;
            `}
          >
            {(templateProps.tab !== 'carrier-templates' || !!templateProps.carrierId) &&
              templates?.map((item) => (
                <Div
                  css={css`
                    margin: 8px 0;
                    margin-right: 32px;
                    margin-left: 2px;
                  `}
                  key={item?.ID}
                >
                  <Div
                    css={css`
                      position: relative;
                      ${shadows.md} background-color: white;
                      margin-bottom: 8px;
                      border-radius: 8px;
                      width: 320px;
                      height: 200px;
                      overflow: hidden;
                      ${selectedIds?.includes(item?.ID)
                        ? `
                        outline: 2px solid ${colors.black};
                        .badge {
                          position: absolute;
                          top: 8px;
                          right: 8px;
                          border-radius: 50%;
                          background-color: ${colors.purple};
                          z-index: 10000;
                          height: 32px;
                          width: 32px;
                          ${flex('center')}
                        }
                      `
                        : `
                        .badge {
                          display: none;
                        }
                        :hover { outline: 1px solid ${colors.gray[400]}; }
                      `}
                    `}
                    onClick={() => selectTemplate(item)}
                  >
                    {item?.Type === 'regular' && <UnlayerPreview content={item?.Content} />}

                    {item.Type === 'brite-editor' && (
                      <Div
                        css={css`
                          ${flex('center start')}
                          position: relative;
                          width: 320px;
                          height: 200px;
                          overflow: hidden;
                        `}
                      >
                        <MemoizedIFrame
                          loading="lazy"
                          className={css`
                            border: none;
                            outline: none;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            width: 816px;
                            height: 100vh;
                            transform-origin: 0 0;
                            transform: scale(${320 / thumbnailWidth});
                            z-index: 10;
                          `}
                          src={`https://britehr.app/brite-template-preview/${item?.ID}?lt=preview`}
                        />
                        <Div
                          css={css`
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 5000;
                            cursor: pointer;
                            background-color: transparent;
                          `}
                        />
                      </Div>
                    )}
                    <Div className="badge">
                      <Text
                        css={`
                          color: white;
                        `}
                        bold
                      >
                        {selectedIds?.findIndex((ID) => item?.ID === ID) + 1}
                      </Text>
                    </Div>
                  </Div>
                  <Div
                    css={css`
                      ${flex('space-between')} height: 48px;
                      width: 320px;
                    `}
                  >
                    <Text
                      h4
                      ellipsis
                      className={css`
                        flex-grow: 1;
                      `}
                    >
                      {item.Name}
                    </Text>
                    <Div
                      css={css`
                        ${flex('right')}
                      `}
                    >
                      {item?.ShareScope !== 'business' && (
                        <Tooltip label="Public">
                          <Users size={24} />
                        </Tooltip>
                      )}
                    </Div>
                  </Div>
                </Div>
              ))}

            <BriteLoader
              type="icon"
              isLoading={isLoading}
              css={`
                height: 100%;
                max-height: calc(100vh - 200px);
              `}
            />

            {!isLoading && !isFetching && !templates.length ? (
              <Div
                css={css`
                  width: 100%;
                  ${flex('center')}
                `}
              >
                <Text h4>Looks like we couldn't find any templates.</Text>
              </Div>
            ) : null}
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
