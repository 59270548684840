import React from 'react';
import { css } from '@emotion/css';
import {
  Activity,
  Bank,
  Bed,
  EggCrack,
  Eyeglasses,
  FaceMask,
  FirstAid,
  FirstAidKit,
  Heartbeat,
  Selection,
  ShieldWarning,
  Wheelchair,
} from '@phosphor-icons/react';

export const PlanTypeIcons = ({ type, ...rest }) => {
  const props = {
    className: css`
      margin-right: 8px;
    `,
    ...rest,
  };
  if (type === 'accident_plan') {
    return <EggCrack {...props} />;
  } else if (type === 'cancer_buyup') {
    return <ShieldWarning {...props} />;
  } else if (type === 'critical_illness') {
    return <Activity {...props} />;
  } else if (type === 'custom') {
    return <Selection {...props} />;
  } else if (type === 'dental_buyup') {
    return <FaceMask {...props} />;
  } else if (type === 'dental_ortho_buyup') {
    return <FaceMask {...props} />;
  } else if (type === 'hospital_indemnity') {
    return <Bed {...props} />;
  } else if (type === 'healthcaregov') {
    return <Bank {...props} />;
  } else if (type === 'medicare_recommendation') {
    return <Bank {...props} />;
  } else if (type === 'reference_based_pricing') {
    return <Bank {...props} />;
  } else if (type === 'long_term_disability') {
    return <Wheelchair {...props} />;
  } else if (type === 'short_term_disability') {
    return <Wheelchair {...props} />;
  } else if (type === 'supplimental_life') {
    return <Heartbeat {...props} />;
  } else if (type === 'vision_buyup') {
    return <Eyeglasses {...props} />;
  } else if (type === 'insurance_plan' || type === 'medical' || type === 'default' || type === 'core') {
    return <FirstAid {...props} />;
  } else {
    return <FirstAidKit {...props} />;
  }
};
