import { getParentBusiness, useBusinessAuth } from '../Dashboard/use-business-auth';
import { history } from '../history';
import { CustomAxios } from '../redux/axios/axios';
import { useStore } from '../store-provider/use-store';
import { handleUser } from './login-utils';

const regexValidateUuid = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

export const useLogin = () => {
  const { setStore, resetStore } = useStore();
  const { setBusiness, fetchBusinesses } = useBusinessAuth();

  const handleLogin = async ({
    accessToken,
    auth0FromHook,
    email,
    setShowTermsPopup,
    setShowBusinessPopup,
    setShowWalkthrough,
  }) => {
    try {
      CustomAxios.setHeader('Authorization', `Bearer ${accessToken}`);
      localStorage.setItem('authHeader', `Bearer ${accessToken}`);
      CustomAxios.setInterceptors(resetStore, auth0FromHook);

      const user = await handleUser(email);
      if (user && !user.MostRecentPreviousLogin) {
        setShowWalkthrough(true);
      } else {
        setShowWalkthrough(false);
      }
      setStore('user', user);

      let business;
      if (user && user.Business) {
        business = user.Business;
        CustomAxios.setHeader('BusinessID', business.ID);
        CustomAxios.setHeader('ParentID', business.ID);
      }

      if (user.Type === 'singleAdmin') {
        //check to see if they have signed terms yet
        try {
          await CustomAxios.get(`v1/terms`);
          setShowTermsPopup(false);
        } catch {
          setShowTermsPopup(true);
        }
      }

      // Get user companies list
      const { companies, acls } = await fetchBusinesses();

      // *** START BUSINESS CHECKS ***
      let redirectTo = '/dashboard';
      const splitPath = history.location.pathname.split('/');
      if (companies.length === 1) {
        // - If they only have access to 1 business, select that business
        business = companies[0];
        setBusiness(business, { acls, companies });
        redirectTo = `/${business.ID}/courses`;
      } else if (splitPath.length > 1) {
        // - Check if pathname already has a businessID in it
        const uuid = splitPath[1];
        const isUuid = regexValidateUuid.test(uuid);

        // - Check if that businessID exists within the users company list
        let businessAccess = companies.find(({ ID }) => uuid === ID) || {};

        if (
          isUuid &&
          !Object.keys(businessAccess).length &&
          (user.Email.includes('@be-brite.com') || user.Email.includes('@britebenefits.com'))
        ) {
          // - If business isn't within users company list,
          //   check if user is BRITE-USER, if they are get that business & select it.
          redirectTo = window.location.pathname;
          try {
            const response = await CustomAxios.get(`/v2/business/${uuid}`);
            businessAccess = response.data;
            business = businessAccess;
          } catch (err) {
            console.warn(err);
          }
        }

        if (isUuid && businessAccess.ID) {
          // - If the businessID is within the company list, select the business
          business = businessAccess;
          setBusiness(business, { acls, companies });
          redirectTo = window.location.pathname;
        }
      }
      // ***END BUSINESS CHECKS***

      // Get parent business and throw it in storage & selectedAcl for good measure
      const parent = await getParentBusiness(companies, business, user);
      setStore('parentBusiness', parent);
      setStore('selectedAcl', acls[parent.ID] || {});

      setShowBusinessPopup(false);

      if (window.location.pathname !== redirectTo) {
        history.push(redirectTo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handleLogin };
};
