export const getDevicePercentage = (device, analytics) => {
  const { TotalDesktopVisits, TotalTabletVisits, TotalMobileVisits } = analytics || {};

  const totalVisits = (TotalDesktopVisits || 0) + (TotalTabletVisits || 0) + (TotalMobileVisits || 0);

  if (!totalVisits) return '0%';

  const deviceVisits = {
    desktop: TotalDesktopVisits || 0,
    mobile: TotalMobileVisits || 0,
    tablet: TotalTabletVisits || 0,
  };

  return `${Math.round((deviceVisits[device] / totalVisits) * 100)}%` || '0%';
};
