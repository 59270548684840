import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { useEffect } from 'react';
import {
  useDisplaySettings,
  contributionLabelDefaults,
} from '../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings';
import { Button, Div, Input, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useDebounce } from '../shared/use-debounce';
import { useForm } from '../shared/use-form';

export const CompanyLanguage = ({ businessId }) => {
  const { displaySettings, saveDisplaySettings, dataUpdatedAt } = useDisplaySettings(businessId);

  const { values, merge, getProperty, hasChanges, updateAt } = useForm(displaySettings, [dataUpdatedAt]);

  const { values: editingTier, merge: mergeEditingTier } = useForm(() => {
    const entries = Object.entries(contributionLabelDefaults);
    return entries?.reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: values?.[key] !== value,
      }),
      {}
    );
  }, [values, dataUpdatedAt]);

  const saveSettings = useDebounce(() => saveDisplaySettings(values), 500);
  useEffect(() => {
    if (updateAt && hasChanges) {
      saveSettings();
    }
  }, [updateAt]);

  const resetField = (tier) => {
    mergeEditingTier({ [tier]: false });
    merge({ [tier]: contributionLabelDefaults?.[tier] });
  };

  return (
    <div
      className={css`
        display: flex;
        gap: 56px;
        padding-top: 56px;
        padding-bottom: 100px;
        margin-top: 56px;
        border-top: 1px solid #d1dae3;
      `}
    >
      <Text
        h4
        css={`
          width: 385px;
        `}
      >
        Company Language
      </Text>
      <div>
        <Text>
          You can customize labels in Brite to match your company culture. For example, change "employee" to "worker".
        </Text>
        <Text
          css={`
            margin: 16px 0;
          `}
        >
          Changes here will be reflected everywhere in Brite
        </Text>
        {Object.keys(contributionLabelDefaults)?.map((tier) => (
          <Div
            css={css`
              border-radius: 8px;
              border: 1px solid ${colors.gray[300]};
              padding: 16px 24px;
              margin-bottom: 24px;
              p {
                width: 100%;
              }
            `}
          >
            <Text
              h3
              css={`
                margin-bottom: 16px;
              `}
            >
              {contributionLabelDefaults?.[tier]}
            </Text>
            {!editingTier?.[tier] ? (
              <Button link onClick={() => mergeEditingTier({ [tier]: true })}>
                Customize Label
              </Button>
            ) : (
              <>
                <Text label bold>
                  Custom Label
                </Text>
                <Div
                  css={css`
                    ${flex('space-between')}
                  `}
                >
                  <Input
                    css={`
                      flex-grow: 1;
                      margin-right: 8px;
                    `}
                    {...getProperty(tier, {
                      characterLimit: 50,
                    })}
                  />
                  <Button styles="icon" onClick={() => resetField(tier)}>
                    <X color={colors.gray[400]} />
                  </Button>
                </Div>
                <Text>50 character max</Text>
              </>
            )}
          </Div>
        ))}
      </div>
    </div>
  );
};
