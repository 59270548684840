import { css } from '@emotion/css';
import {
  ChatCircle,
  Check,
  DotsThreeVertical,
  EnvelopeSimple,
  LinkSimple,
  MicrosoftTeamsLogo,
  QrCode,
  SlackLogo,
} from '@phosphor-icons/react';
import { useState } from 'react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useModal } from '../../../common/hooks/useModal';
import { Box, Button, Div, DropMenu, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { useStore } from '../../../store-provider/use-store';
import { Handout } from '../../Handout';
import HandoutImage from '../../handout-icon-editor.png';
import { LaunchTip } from '../LaunchTip';
import { LaunchType } from '../LaunchType';
import { LinkSettingsModal } from './LinkSettingsModal';
import { RemoveLinkModal } from './RemoveLinkModal';
import { EmailMessageModal } from './EmailMessageModal';
import { TextMessageModal } from './TextMessageModal';
import { EnableTextingModal } from './EnableTextingModal';
import { SlackModal } from './SlackModal';
import { TeamsModal } from './TeamsModal';
import { EnableTeamsModal } from './EnableTeamsModal';
import { QRCodeModal } from './QRCodeModal';

export const LaunchGuideModal = ({ hide = () => {}, guide = {} }) => {
  const [copied, setCopied] = useState(false);

  const {
    data: { kolla, selectedBusiness },
  } = useStore();

  const canEmailMessage = useFeatureFlagPayload('email-messaging');
  const canTextMessage = useFeatureFlagPayload('text-messaging-new');
  const canSlack = kolla?.slack ? kolla['slack'] : false;
  const canTeams = kolla?.['microsoft-teams-47756'] ? kolla['microsoft-teams-47756'] : false;

  const guideUrl = `https://britehr.app/${guide?.VanityURL}`;
  const initialMessage = `${selectedBusiness?.Name} employees! Check out the best benefit options for this upcoming year, click the link we’ve provided for you below!`;

  const handleCopy = () => {
    if (copied) return;
    try {
      navigator.clipboard.writeText(guideUrl);
      // Show user that the link has been copied via a button state change.
      setCopied(true);
    } catch (err) {
      console.log(err);
    } finally {
      // Reset the button state after 1 second.
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  /** Modals */

  const linkSettingsModal = useModal();
  const removeLinkModal = useModal();
  const emailMessageModal = useModal();
  const textMessageModal = useModal();
  const enableTextingModal = useModal();
  const slackModal = useModal();
  const teamsModal = useModal();
  const enableTeamsModal = useModal();
  const handoutModal = useModal();
  const qrCodeModal = useModal();

  const modals = [
    {
      isOpen: linkSettingsModal.isOpen,
      component: <LinkSettingsModal hide={linkSettingsModal.hide} guide={guide} />,
    },
    {
      isOpen: removeLinkModal.isOpen,
      component: <RemoveLinkModal hide={removeLinkModal.hide} hideLaunchGuide={hide} guide={guide} />,
    },
    {
      isOpen: emailMessageModal.isOpen,
      component: <EmailMessageModal hide={emailMessageModal.hide} guide={guide} />,
    },
    {
      isOpen: textMessageModal.isOpen,
      component: <TextMessageModal hide={textMessageModal.hide} guide={guide} initialMessage={initialMessage} />,
    },
    { isOpen: enableTextingModal.isOpen, component: <EnableTextingModal hide={enableTextingModal.hide} /> },
    {
      isOpen: slackModal.isOpen,
      component: <SlackModal hide={slackModal.hide} guide={guide} initialMessage={initialMessage} />,
    },
    {
      isOpen: teamsModal.isOpen,
      component: <TeamsModal hide={teamsModal.hide} guide={guide} initialMessage={initialMessage} />,
    },
    { isOpen: enableTeamsModal.isOpen, component: <EnableTeamsModal hide={enableTeamsModal.hide} /> },
    {
      isOpen: handoutModal.isOpen,
      component: (
        <Handout
          setShowBriteGuideHandout={handoutModal.hide}
          guideURL={guideUrl}
          guideID={guide.ID}
          selectedCourse={guide}
        />
      ),
    },
    { isOpen: qrCodeModal.isOpen, component: <QRCodeModal hide={qrCodeModal.hide} guide={guide} /> },
  ];

  const openModal = modals.find((modal) => modal.isOpen);

  if (openModal) {
    return openModal.component;
  }

  return (
    <Modal display onClose={() => hide()}>
      <Modal.Paper width="600px">
        <Modal.Header title="Launch Guide" onClose={() => hide()} />
        <Modal.Body>
          <Div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `}
          >
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <Div
                css={css`
                  display: flex;
                  padding: 8px 16px;
                  justify-content: space-between;
                  align-items: center;
                  align-self: stretch;
                  border-radius: 8px;
                  background: ${colors.gray[100]};
                `}
              >
                <Div
                  css={css`
                    display: flex;
                    justify-content: start;
                    align-items: center;
                  `}
                >
                  <Text body color={colors.black}>
                    https://britehr.app/
                  </Text>
                  <Text
                    h4
                    css={css`
                      font-size: 16px;
                    `}
                  >
                    {guide.VanityURL}
                  </Text>
                </Div>
                <DropMenu
                  button={
                    <Button
                      styles="icon"
                      css={css`
                        padding: 0px;
                        margin-right: -8px;
                      `}
                      onClick={() => {}}
                    >
                      <DotsThreeVertical size={24} weight="bold" />
                    </Button>
                  }
                >
                  <Div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      width: 200px;
                    `}
                  >
                    <DropMenuItem text="Link Settings" onClick={() => linkSettingsModal.toggle()} />
                    <DropMenuItem text="Remove Link" onClick={() => removeLinkModal.toggle()} />
                  </Div>
                </DropMenu>
              </Div>
              <Button
                css={css`
                  padding: 12px 24px;
                  gap: 8px;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  align-self: stretch;
                  background: ${copied ? colors.briteGradient : colors.purple};
                  transition: background-color 0.5s ease;
                `}
                onClick={() => handleCopy()}
              >
                {copied ? (
                  <Check size={24} color="currentColor" weight="bold" />
                ) : (
                  <LinkSimple size={24} color="currentColor" weight="bold" />
                )}
                {copied ? 'Copied!' : 'Copy Link'}
              </Button>
            </Div>
            <Div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;
              `}
            >
              {canEmailMessage?.value && (
                <LaunchType
                  iconComponent={<EnvelopeSimple size={40} />}
                  iconLabel="Email"
                  onClick={() => emailMessageModal.toggle()}
                />
              )}
              <LaunchType
                iconComponent={<ChatCircle size={40} />}
                iconLabel="Text"
                onClick={() => (canTextMessage?.value ? textMessageModal.toggle() : enableTextingModal.toggle())}
              />
              <LaunchType
                iconComponent={<SlackLogo size={40} />}
                iconLabel="Slack"
                onClick={() => {
                  if (canSlack) {
                    slackModal.toggle();
                  } else {
                    try {
                      window.kolla.openConnector('slack');
                      window?.ChurnZero?.push(['setAttribute', 'account', 'integration-slack', true]);
                      hide();
                    } catch (e) {
                      console.error('Kolla error opening slack connector.');
                    }
                  }
                }}
              />
              <LaunchType
                iconComponent={<MicrosoftTeamsLogo size={40} />}
                iconLabel="Teams"
                onClick={() => (canTeams ? teamsModal.toggle() : enableTeamsModal.toggle())}
              />
              <LaunchType
                iconComponent={<img src={HandoutImage} alt="Handout" width="40px" height="40px" />}
                iconLabel="Handout"
                onClick={() => handoutModal.toggle()}
              />
              <LaunchType
                iconComponent={<QrCode size={40} />}
                iconLabel="QR Code"
                onClick={() => qrCodeModal.toggle()}
              />
            </Div>
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <Div
                css={css`
                  display: flex;
                  padding: 4px 16px;
                  align-items: center;
                  gap: 8px;
                  align-self: stretch;
                  border-left: 4px solid var(--Purple, ${colors.purple});
                `}
              >
                <Text
                  h4
                  css={css`
                    font-size: 16px;
                  `}
                >
                  Tips for launching your guide
                </Text>
              </Div>
              <Div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                `}
              >
                <LaunchTip
                  title="Share more."
                  text="Brite recommends sharing a guide at least 3 times during open enrollment."
                />
                <LaunchTip
                  title="Share everywhere."
                  text="Use a combination of email, QR codes, and texts to reach employees."
                />
                <LaunchTip
                  title="Analyze."
                  text="Use guide analytics to monitor engagement and improve your content."
                />
              </Div>
            </Div>
          </Div>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};

const DropMenuItem = ({ text = '', onClick = () => {} }) => (
  <Box
    css={css`
      padding: 8px;
      border-radius: 8px;
      width: 100%;

      :hover {
        cursor: pointer;
        background: var(--100, ${colors.gray[100]});
      }
    `}
    onClick={() => onClick()}
  >
    <Text label>{text}</Text>
  </Box>
);
