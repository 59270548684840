import { colors } from '../../../shared/styles';

export const getDeviceColor = (device) => {
  const colorMapping = {
    desktop: colors.black,
    mobile: colors.gray[500],
    tablet: colors.gray[300],
  };

  return colorMapping[device];
};
