import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { states, industries } from '../constants';
import { cloneDeep } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import { Input as BriteInput } from '../shared/components';
import { CustomAxios } from '../redux/axios/axios';
import { useAcls } from '../shared/use-acls';
import { WRITE_BUSINESS_ACCESS } from '../shared/acl-constants';
import { useForm } from '../shared/use-form';
import { handleUpdateBusiness } from '../redux/replace-redux-actions';
import { useLifecycle } from '../shared/useLifecycle';
import { useStore } from '../store-provider/use-store';
import { Button, Box, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { ACLWrapper } from '../shared/components/acl-wrapper';

const validate = (values) => {
  const errors = {};

  if (!values.EmployeeCount) {
    errors.EmployeeCount = 'Required';
  }
  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (!values.Industry) {
    errors.Industry = 'Required';
  }

  if (!values.State) {
    errors.State = 'Required';
  }

  if (!values.Domain) {
    errors.Domain = 'Required';
  }

  return errors;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 8,
  borderColor: '#25282D',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#25282D',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#2196f3',
};

const rejectStyle = {
  borderColor: '#2196f3',
};

export const CompanySettings = () => {
  const theme = useTheme();

  const {
    data: { selectedBusiness },
    setStore,
  } = useStore();

  const form = useForm(
    {
      Categories: [],
      ...selectedBusiness,
    },
    [selectedBusiness?.ID]
  );

  const { values } = form;

  const errors = validate(values);
  const handleChange = (e) => form?.merge({ [e.target.name]: e.target.value });

  const isParent = selectedBusiness?.ID === selectedBusiness?.ParentID;
  const plansightImportFF = useFeatureFlagPayload('plansight-import');

  const handleSubmit = (event) => {
    event?.preventDefault();
    window?.ChurnZero?.push([
      'trackEvent',
      'Business Details',
      'This page was clicked on and any amount of information was input',
      1,
    ]);
    let cloned = cloneDeep(values);

    cloned.Type = selectedBusiness?.Type;

    setStore('selectedBusiness', { PaymentSource: 'none', ...cloned });
    handleUpdateBusiness({ PaymentSource: 'none', ...cloned });
  };

  const [imageUploading, setImageUploading] = useState(false);
  const [categories, setCategories] = useState([]);
  const canAdminBusiness = useAcls(WRITE_BUSINESS_ACCESS);

  useLifecycle(
    {
      onSubsequent: () => {
        if (form?.hasChanges) {
          handleSubmit();
        }
      },
    },
    [values?.LogoURL]
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setImageUploading(true);
      const url = `/v2/media/${acceptedFiles[0].name ? encodeURIComponent(acceptedFiles[0].name) : 'no-name'}/${
        acceptedFiles[0].type ? encodeURIComponent(acceptedFiles[0].type.replace('/', '.')) : 'image.png'
      }`;
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      try {
        const res = await CustomAxios.post(url, formData, config);
        setImageUploading(false);
        form?.merge({ LogoURL: res.data.PublicURL });
      } catch {
        setImageUploading(false);
        toast.error('Error uploading logo. Try again.');
      }
    } else {
      setImageUploading(false);
      toast.error('Invalid file or too many files');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/*',
    noDragEventsBubbling: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchAllCategories = async () => {
    try {
      const response = await CustomAxios.get(`/v1/categories`);
      setCategories(response.data.sort());
    } catch (e) {}
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme?.typography?.fontWeightRegular : theme?.typography?.fontWeightMedium,
    };
  }

  return (
    <div
      className={css`
        width: 800px;
        .MuiOutlinedInput-root {
          border-radius: 8px;
        }
        .MuiOutlinedInput-input {
          height: 0;
          padding: 24px 16px;
        }
      `}
    >
      <form onSubmit={handleSubmit}>
        <Box
          flex="space-between"
          css={`
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h1>Business Details</Text>
          <Button disabled={!form?.hasChanges} type="submit">
            Save
          </Button>
        </Box>
        <div
          className={css`
            display: flex;
          `}
        >
          <Text
            h4
            css={`
              width: 250px;
            `}
          >
            Company Logo
          </Text>
          <div
            className={css`
              margin-left: 32px;
              margin-bottom: 32px;
            `}
          >
            {selectedBusiness.Type === 'carrier' && (
              <div
                className={css`
                  margin-bottom: 8px;
                `}
              >
                As a carrier, this logo is shown to brokers on Brite and on the employee recommendations page. Check out
                the Preview tab
              </div>
            )}

            {imageUploading && (
              <div
                className={css`
                  margin-bottom: 18px;
                `}
              >
                Uploading logo...
              </div>
            )}

            {!imageUploading && (
              <div
                className={css`
                  margin-bottom: 18px;
                  display: flex;
                  align-items: center;
                `}
              >
                <section>
                  <div
                    className={css`
                      &:hover {
                        border-color: blue !important;
                      }
                    `}
                    {...getRootProps({ style })}
                  >
                    <input {...getInputProps()} />
                    {values.LogoURL && (
                      <div
                        className={css`
                          min-width: 200px;
                        `}
                      >
                        <img
                          alt="Carrier Logo"
                          src={
                            values.LogoURL ||
                            'https://storage.googleapis.com/brite-carrier-logos/logos/logo-placeholder.png'
                          }
                          className={css`
                            max-height: 80px;
                            max-width: 200px;
                          `}
                        />
                      </div>
                    )}

                    {!values.LogoURL && <p>Drag 'n' drop your logo here</p>}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>

        <div
          className={css`
            gap: 56px;
          `}
        >
          <Box>
            <Box
              flex="left start"
              css={`
                margin-bottom: 32px;
              `}
            >
              <Text
                h4
                css={`
                  width: 250px;
                `}
              >
                Company Information
              </Text>
              <Box
                css={`
                  width: 400px;
                  margin-left: 32px;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Company name
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.Name}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.Name}
                    name="Name"
                  />
                </div>

                {selectedBusiness.Type === 'carrier' && (
                  <div
                    className={css`
                      margin-bottom: 18px;
                    `}
                  >
                    <div
                      className={css`
                        margin-bottom: 18px;
                      `}
                    >
                      <div
                        className={css`
                          margin-bottom: 8px;
                        `}
                      >
                        Your company description will be shown to brokers as they browse carriers and products through
                        Brite.
                      </div>
                      <Text
                        label
                        css={`
                          margin-bottom: 4px;
                        `}
                      >
                        Company Description
                      </Text>
                      <TextField
                        fullWidth
                        error={errors.Description}
                        variant="outlined"
                        onChange={handleChange}
                        value={values.Description}
                        name="Description"
                        multiline
                        rowsMax={4}
                      />
                    </div>

                    <div
                      className={css`
                        margin-bottom: 8px;
                      `}
                    >
                      What type of benefit products does your company offer? You can select more than one.
                    </div>

                    <div
                      className={css`
                        border: 1px solid #ccc;
                        border-radius: 8px;
                      `}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          labelId="categories-label"
                          id="select-category"
                          multiple
                          variant="outlined"
                          value={values.Categories}
                          onChange={(e) => {
                            if (e.target.value && Array.isArray(e.target.value)) {
                              const ids = e.target.value.map((o) => o.ID);
                              const filtered = e.target.value.filter(({ ID }, index) => !ids.includes(ID, index + 1));
                              form?.setValues({ ...values, Categories: filtered });
                            } else {
                              form?.setValues({ ...values, Categories: [] });
                            }
                          }}
                          input={
                            <Input
                              id="select-multiple-chip"
                              variant="outlined"
                              className={css`
                                padding: 8px 0;
                              `}
                            />
                          }
                          renderValue={(selected) => (
                            <div
                              className={css`
                                margin: 2px;
                              `}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value.ID}
                                  label={value.Name}
                                  style={{ margin: '2px' }}
                                  onDelete={() => {
                                    let filtered = values.Categories.filter(function (el) {
                                      return el.ID !== value.ID;
                                    });
                                    form?.setValues({ ...values, Categories: filtered });
                                  }}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {categories.map((category) => (
                            <MenuItem
                              key={category.ID}
                              value={category}
                              style={getStyles(category.Name, values.Categories, theme)}
                            >
                              {category.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}

                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    # of employees
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.EmployeeCount}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.EmployeeCount}
                    type="number"
                    name="EmployeeCount"
                    disabled={!canAdminBusiness}
                  />
                </div>

                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Website URL
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.Domain}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.Domain}
                    name="Domain"
                  />
                </div>

                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    State
                  </Text>
                  <FormControl variant="outlined" error={errors.State} fullWidth>
                    <Select
                      value={values.State}
                      onChange={(e) => {
                        form?.merge({ State: e.target.value });
                      }}
                      name="State"
                      input={<OutlinedInput />}
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Industry
                  </Text>
                  <FormControl variant="outlined" error={errors.Industry} fullWidth>
                    <Select
                      value={values.Industry}
                      name="Industry"
                      onChange={(e) => {
                        form?.merge({ Industry: e.target.value });
                      }}
                      input={<OutlinedInput />}
                    >
                      {industries.map((industry) => (
                        <MenuItem key={industry.name} value={industry.name}>
                          {industry.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Box>
            <Box
              flex="left start"
              css={`
                margin-bottom: 32px;
              `}
            >
              <Text
                h4
                css={`
                  width: 250px;
                `}
              >
                Broker Contact Information
              </Text>
              <Box
                css={`
                  width: 400px;
                  margin-left: 32px;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Broker Contact Name
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.BrokerContactName}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.BrokerContactName}
                    name="BrokerContactName"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Broker Contact Email
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.BrokerContactEmail}
                    variant="outlined"
                    type="email"
                    onChange={handleChange}
                    value={values.BrokerContactEmail}
                    name="BrokerContactEmail"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Broker Contact Phone
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.BrokerContactPhone}
                    variant="outlined"
                    type="tel"
                    onChange={handleChange}
                    value={values.BrokerContactPhone}
                    name="BrokerContactPhone"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Broker Contact Title
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.BrokerContactTitle}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.BrokerContactTitle}
                    name="BrokerContactTitle"
                  />
                </div>
              </Box>
            </Box>
            <Box
              flex="left start"
              css={`
                margin-bottom: 32px;
              `}
            >
              <Text
                h4
                css={`
                  width: 250px;
                `}
              >
                HR Contact Information
              </Text>
              <Box
                css={`
                  width: 400px;
                  margin-left: 32px;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    HR Contact Name
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.HRContactName}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.HRContactName}
                    name="HRContactName"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    HR Contact Email
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.HRContactEmail}
                    variant="outlined"
                    type="email"
                    onChange={handleChange}
                    value={values.HRContactEmail}
                    name="HRContactEmail"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    HR Contact Phone
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.HRContactPhone}
                    variant="outlined"
                    type="tel"
                    onChange={handleChange}
                    value={values.HRContactPhone}
                    name="HRContactPhone"
                  />
                </div>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    HR Contact Title
                  </Text>
                  <TextField
                    fullWidth
                    error={errors.HRContactTitle}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.HRContactTitle}
                    name="HRContactTitle"
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </div>
        {isParent && plansightImportFF?.value ? (
          <ACLWrapper acls={[WRITE_BUSINESS_ACCESS]}>
            <Box
              flex="left start"
              css={`
                margin-bottom: 32px;
              `}
            >
              <Text
                h4
                css={`
                  width: 250px;
                `}
              >
                Integrations
              </Text>
              <Box
                css={`
                  width: 400px;
                  margin-left: 32px;
                `}
              >
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <Text
                    label
                    css={`
                      margin-bottom: 4px;
                    `}
                  >
                    Plansight API Key
                  </Text>
                  <BriteInput
                    value={form?.values?.Metadata?.APIKeys?.plansight}
                    onChange={(e) => {
                      form?.merge({
                        Metadata: {
                          APIKeys: {
                            plansight: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Box>
            </Box>
          </ACLWrapper>
        ) : null}
      </form>
    </div>
  );
};
