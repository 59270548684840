import { css } from '@emotion/css';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { X } from '@phosphor-icons/react';
import { Button, Modal, Text } from '../../../shared/components';

export const ImportPhoneNumbersModal = ({ hideImportPhoneNumbers, errorMessages, phoneNumbers, setPhoneNumbers }) => {
  const [numImportedPhoneRows, setNumImportedPhoneRows] = useState(0);
  const [badPhoneNumbersFromUpload, setBadPhoneNumbersFromUpload] = useState([]);
  return (
    <Modal
      onClose={() => {
        hideImportPhoneNumbers();
      }}
      display
      css={`
        overflow: auto;
        min-width: 800px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 32px;
        `}
      >
        <div
          className={css`
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #25282d;
          `}
        >
          Import Phone Numbers
        </div>
        <Button
          styles="icon"
          onClick={() => {
            hideImportPhoneNumbers();
          }}
        >
          <X size={24} />
        </Button>
      </div>
      <div
        className={css`
          margin-left: 32px;
          margin-right: 32px;
        `}
      >
        <div
          className={css`
            margin-bottom: 16px;
          `}
        >
          <Text label>
            Import a CSV with mobile phone numbers in one column. Numbers can be in local or international format. The
            following examples are all valid:
          </Text>
        </div>
        <Text label>(415) 555-2671</Text>
        <Text label>4154442671</Text>
        <Text label>+14155552671</Text>
      </div>
      <div
        className={css`
          margin: 32px;
        `}
      >
        {
          <Importer
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            restartable={false} // optional, lets user choose to upload another file when import is complete
            delimiter={','} // this struggles to autodetect if the delimiters are not consistent, so we force it to be a comma and it will read better whether or not there are commas
            processChunk={async (rows, { startIndex }) => {
              try {
                new Promise((resolve) => {
                  let nums = new Set(phoneNumbers);
                  let badNums = [];
                  rows.forEach((row, index) => {
                    // Check that the number is a valid mobile phone number.
                    if (isValidPhoneNumber(row.PhoneNumber) || isValidPhoneNumber('+1' + row.PhoneNumber)) {
                      // The backend will handle adding the US country code, if it is missing.
                      nums.add(row.PhoneNumber);
                    } else if (row.PhoneNumber === '') {
                      badNums.push({
                        PhoneNumber: row.PhoneNumber,
                        Error: 'Empty phone number.',
                        Row: index + 2 + startIndex,
                      });
                    } else if (isValidPhoneNumber('+' + row.PhoneNumber)) {
                      badNums.push({
                        PhoneNumber: row.PhoneNumber,
                        Error: errorMessages.InvalidCountryCode,
                        Row: index + 2 + startIndex,
                      });
                    } else {
                      badNums.push({
                        PhoneNumber: row.PhoneNumber,
                        Error: errorMessages.InvalidPhoneNumber,
                        Row: index + 2 + startIndex,
                      });
                    }
                  });
                  setNumImportedPhoneRows((numImportedPhoneRows) => numImportedPhoneRows + rows.length);
                  setBadPhoneNumbersFromUpload((badPhoneNumbersFromUpload) => [
                    ...badPhoneNumbersFromUpload,
                    ...badNums,
                  ]);
                  setPhoneNumbers((phoneNumbers) => new Set([...phoneNumbers, ...nums]));
                  resolve();
                });
              } catch (err) {
                console.log(err);
              }
            }}
            onClose={() => {
              hideImportPhoneNumbers();
            }}
          >
            <ImporterField name="PhoneNumber" label="Phone Number" />
          </Importer>
        }
        {badPhoneNumbersFromUpload.length > 0 && (
          <div>
            <Text
              h4
              css={css`
                font-size: 16px;
                margin-top: 16px;
                margin-bottom: 32px;
                text-align: center;
              `}
            >
              {badPhoneNumbersFromUpload.length} of {numImportedPhoneRows} rows were skipped with errors
            </Text>
            <div
              className={css`
                margin-bottom: 24px;
                overflow-y: scroll;
                max-height: 250px;
              `}
            ></div>
            <div
              className={css`
                display: flex;
                justify-content: center;
                margin-bottom: 32px;
              `}
            >
              <Button
                onClick={() => {
                  const csvOfImportErrors =
                    'data:text/csv;charset=utf-8,Row,PhoneNumber,Error\r\n' +
                    badPhoneNumbersFromUpload
                      .map((e) => {
                        return [e.Row, e.PhoneNumber, e.Error];
                      })
                      .join('\n');
                  const encodedUri = encodeURI(csvOfImportErrors);
                  const link = document.createElement('a');
                  link.setAttribute('href', encodedUri);
                  link.setAttribute('download', 'brite_text_import_errors.csv');
                  document.body.appendChild(link);
                  link.click();
                }}
              >
                Download Errors
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
