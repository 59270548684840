import { useEffect, useRef } from 'react';

export const useResize = (element, callback) => {
  const refCallback = useRef(callback);
  refCallback.current = callback;
  useEffect(() => {
    if (!element || typeof refCallback.current !== 'function') {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        refCallback.current(entry.contentRect);
      }
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);
};
