import { Check, Warning, X } from '@phosphor-icons/react';
import { colors } from '../styles';
import { Box } from './box';
import { ReactComponent as InfoI } from '../../images/svg/info-i.svg';
import { Icon } from './icon';
import { Text } from './text';

export const Alert = ({ type, title, description }) => {
  return (
    <Box
      css={`
        background-color: ${colors.gray[100]};
        border-radius: 8px;
        padding: 16px;
      `}
      flex="space-between start"
    >
      <Box
        flex="center"
        css={`
          border-radius: 50%;
          background-color: ${
            type === 'success'
              ? colors.purple
              : type === 'info'
              ? colors.purple
              : type === 'error'
              ? colors.red[500]
              : type === 'warning'
              ? colors.yellow[500]
              : colors.gray[300]
          };
        }  
      `}
      >
        {type === 'success' ? (
          <Check color="white" />
        ) : type === 'info' ? (
          <Icon SVG={InfoI} color="white" />
        ) : type === 'warning' ? (
          <Warning color="white" />
        ) : type === 'error' ? (
          <X color="white" />
        ) : null}
      </Box>
      <Box
        css={`
          margin-left: 16px;
          flex-grow: 1;
        `}
      >
        <Text label bold>
          {title}
        </Text>
        <Text label>{description}</Text>
      </Box>
    </Box>
  );
};
