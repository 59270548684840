import { css } from '@emotion/css';
import { MenuItem } from '@material-ui/core';
import { FolderSimple, Textbox, UploadSimple } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { Button, Div, DropMenu, Input, Text } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { modifyContent } from '../provider/utils';
import { DocumentsLibrary, fileTypesMap } from './menu-components/documents-library';
import { useUploadMedia } from '../../shared/useUploadMedia';

export const DocumentToolbar = ({ editor, element, location, toolbar }) => {
  const [editName, setEditName] = useState('');
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const updateDocuments = (updates) => {
    onUpdate(updates);
  };

  const toggleEditName = (e) => {
    e.preventDefault();
    if (!editName) {
      setEditName(element?.document?.Name);
    } else {
      onUpdate({
        document: {
          Name: editName,
        },
      });
      setEditName('');
    }
  };

  useEffect(() => {
    return () => toolbar.setModal('');
  }, []);

  return (
    <Div
      css={css`
        ${flex('jcl aic')}
      `}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Div>
        <DropMenu button={<Button styles="secondary">{element?.document?.ID ? 'Replace' : 'Add'} Document</Button>}>
          <MenuItem onClick={() => toolbar.setModal('document-uploader')}>
            <UploadSimple size={24} />
            <Text styles="label padl-sm">Upload Document</Text>
          </MenuItem>
          <MenuItem onClick={() => toolbar.setModal('document-library')}>
            <FolderSimple size={24} />
            <Text styles="label padl-sm">Select from Documents</Text>
          </MenuItem>
        </DropMenu>
      </Div>
      {!document ? null : (
        <form
          onSubmit={toggleEditName}
          className={css`
            ${flex('right')}
          `}
        >
          {editName ? (
            <Input
              css={`
                width: 300px;
                margin: 0 16px;
              `}
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            />
          ) : (
            <a target="_blank" href={element?.document?.PublicURL}>
              <Text
                styles="link padh-lg ellipsis uppercase"
                className={css`
                  max-width: 300px;
                `}
              >
                {element?.document?.Name}
              </Text>
            </a>
          )}
          <Button styles="icon sm" type="submit" onClick={toggleEditName}>
            <Textbox />
          </Button>
        </form>
      )}

      {toolbar?.modal === 'document-uploader' ? (
        <UploadMedia onUpdate={updateDocuments} onClose={() => toolbar.setModal('')} />
      ) : null}

      {toolbar?.modal === 'document-library' ? (
        <DocumentsLibrary
          element={element}
          display={toolbar?.modal === 'document-library'}
          onClose={() => toolbar.setModal('')}
          onUpdate={updateDocuments}
        />
      ) : null}
    </Div>
  );
};

const accept = Object.values(fileTypesMap).join(', ');

const UploadMedia = ({ onUpdate, onClose }) => {
  const onSuccess = (files) => {
    const file = files[0];
    onUpdate({ document: file });
    onClose();
  };

  const { open } = useUploadMedia({
    accept,
    onSuccess,
    noClick: true,
    maxFiles: 1,
    onFileDialogCancel: onClose,
  });

  useEffect(() => {
    open();
  }, []);

  return null;
};
