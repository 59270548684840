import React from 'react';
import { Text } from '../shared/components';
import { css } from '@emotion/css';
import { chain, sumBy } from 'lodash';

export const LocationCityCount = ({ locations }) => {
  const groupedCityLocations = chain(locations)
    .groupBy('CityName')
    .map((locs, cityName) => ({
      CityName: cityName,
      Count: sumBy(locs, 'Count'),
    }))
    .orderBy('Count', 'desc')
    .value();

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
      `}
    >
      {groupedCityLocations.map((l, index) => {
        if (index > 2) {
          return null;
        }
        return (
          <div
            key={index}
            className={css`
              padding: 16px;
              padding-left: 24px;
              padding-right: 24px;
              margin-right: 24px;
              margin-bottom: 24px;
              width: 230px;
            `}
          >
            <Text body>{l.CityName || 'N/A'}</Text>
            <Text h1>{l.Count}</Text>
          </div>
        );
      })}
    </div>
  );
};
