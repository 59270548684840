import Axios from 'axios';
import { css } from '@emotion/css';
import { Image } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { Div } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';

export const getUnlayerPreview = async (content) => {
  if (content) {
    try {
      const { data } = await Axios.post(
        'https://api.unlayer.com/v2/export/image',
        { displayMode: 'web', design: content },
        {
          headers: {
            Authorization:
              'Basic THZyN3VQNGpmTzhxMWhZS1dRN2oyVnVrc3pZckl5NmFNYWJ3UTEyZ1JIVEk0MmJNNndOQndLZWZCb1A0eWlEbzo=',
          },
        }
      );
      return data?.data?.url;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }
};

export const UnlayerPreview = ({ content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState('');

  const getUnlayer = async () => {
    try {
      setIsLoading(true);
      const url = await getUnlayerPreview(content);
      setImgSrc(url);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUnlayer();
  }, []);

  return (
    <Div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
      `}
    >
      <BriteLoader
        isLoading={isLoading}
        className={css`
          ${flex('center')} height: 100%;
        `}
      />
      {imgSrc ? (
        <img
          src={imgSrc}
          alt="Unlayer Preview"
          className={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top left;
          `}
        />
      ) : (
        <Div
          css={css`
            ${flex('center')} height: 100%;
          `}
        >
          <Image size={64} color={colors.gray[400]} />
        </Div>
      )}
    </Div>
  );
};
