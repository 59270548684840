import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, CircularProgress, Select, Typography, MenuItem, Button } from '@material-ui/core';
import { css } from '@emotion/css';
import { ArrowLeft, DotsThree, Plus } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import { history } from '../history';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { DropMenu } from '../shared/components/dropdown-menu';
import { useMemoRef } from '../shared/use-memo-ref';
import { useStore } from '../store-provider/use-store';
import { toast } from 'react-toastify';
import { AssignCompany } from './assign-company';
import { useMembers } from './use-members';
import { Div } from '../shared/components/div';
import { container } from '../shared/shared-styles';

const fetchBusinesses = async (userId) => {
  let list = [];
  const response = await CustomAxios.get(`v1/users/${userId}/businesses`);
  const resources = response.data.Data;
  if (resources) {
    const promises = await Promise.allSettled(
      resources.map(({ ResourceID }) => CustomAxios.get(`/v2/business/${ResourceID}`))
    );
    const businesses = promises.filter(({ status }) => status === 'fulfilled').map(({ value }) => value.data);
    for (let index = 0; index < resources.length; index++) {
      const business = businesses[index];
      if (business) {
        const resource = resources.find(
          ({ ResourceID }) => ResourceID === business.ID || ResourceID === business.ParentID
        );
        list.push({ resource, business });
      }
    }
  }
  return list;
};

export const MemberAccess = () => {
  const { userId } = useParams();

  const {
    data: memberList,
    isLoading: isLoadingMembers,
    refetch: refetchMemberList,
    cache: cacheMemberList,
  } = useMembers();
  const member = memberList.find(({ UserID }) => UserID === userId) || {};

  const [loadingText, setLoadingText] = useState('Fetching member access...');

  useEffect(() => {
    refetchMemberList();
    setTimeout(() => {
      setLoadingText('making some calculations...');
    }, 3000);
    setTimeout(() => {
      setLoadingText('almost done...');
    }, 6000);
  }, []);

  const {
    data: { acls },
  } = useStore();
  const resourceIds = Object.keys(acls);
  const [assignCompany, setAssignCompany] = useState(false);

  const { data, isLoading, cache, refetch } = useQueryAPI({
    urlKey: `v1/users/${userId}/businesses`,
    queryFn: () => fetchBusinesses(userId),
    enabled: !!member.Roles && !!member.Roles.length,
    defaultValue: [],
    retry: false,
  });

  const username =
    member.user &&
    'FirstName' in member.user &&
    'LastName' in member.user &&
    `${member.user.FirstName} ${member.user.LastName}`;

  const userEmail = useMemoRef(
    (prev) => {
      if (member.user && member.user.Email) {
        return member.user.Email;
      }
      return prev;
    },
    [member.user && member.user.Email]
  );

  const writeableAccess = useMemo(
    () =>
      data.reduce((prev, item) => {
        const resourceId = item.business.ID in acls ? item.business.ID : item.business.ParentID;
        let hasAccess = false;
        if (resourceId in acls) {
          hasAccess = resourceId && acls[resourceId].Acls.includes('write:business_access');
        }
        return { ...prev, [item.business.ID]: hasAccess };
      }, {}),
    [data.length, resourceIds.length]
  );

  const handlePermission = async (e, children) => {
    const roleId = e.target.value;
    const userId = member.UserID;
    const businessId = children.props.item.business.ID;

    const old = [...data];
    const updatedData = old.reduce((prev, item) => {
      if (item.business.ID === businessId) {
        return [...prev, { ...item, resource: { ...item.resource, RoleID: roleId } }];
      }
      return [...prev, item];
    }, []);

    try {
      cache.setData(updatedData);
      CustomAxios.setHeader('BusinessID', businessId);
      await CustomAxios.delete(
        `v1/user/${userId}/access/${businessId}`,
        {},
        {
          headers: { BusinessID: businessId },
        }
      );
      await CustomAxios.post(
        `v1/user/${userId}/access/${businessId}/role/${roleId}`,
        {},
        {
          headers: { BusinessID: businessId },
        }
      );
    } catch (err) {
      toast.error('Failed to update the users permission');
      cache.setData(old);
      console.warn(err);
    }
  };

  const removeAccess = async (business) => {
    const userId = member.UserID;
    const businessId = business.ID;
    const old = [...data];
    const oldList = [...memberList];
    const updatedData = old.filter((item) => item.business.ID !== businessId);
    try {
      cache.setData(updatedData);
      if (!updatedData.length) {
        const removeUser = oldList.filter(({ UserID }) => UserID !== userId);
        cacheMemberList.setData(removeUser);
      }
      CustomAxios.setHeader('BusinessID', businessId);
      await CustomAxios.delete(`v1/user/${userId}/access/${businessId}`);
    } catch (err) {
      toast.error('Failed to remove users access.');
      cache.setData(old);
      cacheMemberList.setData(oldList);
      console.warn(err);
    }
  };

  if (isLoadingMembers || !userEmail) {
    return (
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100vh;
        `}
      >
        <CircularProgress />
        <Typography style={{ margin: '32px' }}>{loadingText}</Typography>
      </div>
    );
  }

  return (
    <div>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <div
          className={css`
            display: flex;
          `}
        >
          <IconButton onClick={() => history.push('/members')}>
            <ArrowLeft />
          </IconButton>
          <div
            className={css`
              display: flex;
              align-items: center;
            `}
          >
            {username && <h1>{username}</h1>}
            {username ? (
              <Typography>{userEmail}</Typography>
            ) : (
              <Typography style={{ fontWeight: 'bold' }}>{userEmail}</Typography>
            )}
          </div>
        </div>
        <Button startIcon={<Plus />} variant="contained" color="primary" onClick={() => setAssignCompany(true)}>
          Assign Company
        </Button>
      </Div>

      <div
        className={css`
          display: flex;
          border-bottom: 1px solid #d1dae3;
          padding: 8px 16px;
          width: 1000px;
        `}
      >
        <Typography style={{ fontSize: '16px', color: '#66737F', width: '50%' }}>Company</Typography>
        <Typography
          style={{
            fontSize: '16px',
            color: '#66737F',
            flexGrow: '1',
            marginLeft: '125px',
          }}
        >
          Permissions
        </Typography>
        <Typography style={{ fontSize: '16px', color: '#66737F' }}>Options</Typography>
      </div>

      {isLoading ? (
        <div
          className={css`
            width: 100%;
            display: flex;
            justify-content: center;
            max-width: 1000px;
            padding: 32px;
          `}
        >
          <CircularProgress />
        </div>
      ) : !data.length ? (
        <Typography style={{ margin: '32px' }}>There aren't any permissions to display for this user.</Typography>
      ) : (
        data.map((item) => (
          <div
            key={item.resource.ResourceID}
            className={css`
              display: flex;
              border-bottom: 1px solid #d1dae3;
              padding: 16px 0;
              width: 1000px;
            `}
          >
            <div
              className={css`
                display: flex;
                width: 50%;
                padding: 16px;
                align-items: center;
              `}
            >
              <Typography style={{ flexGrow: 1 }}>{item.business.Name}</Typography>
              {item.business.LogoURL && (
                <img
                  alt="Carrier Logo"
                  src={item.business.LogoURL}
                  className={css`
                    max-width: 100px;
                    max-height: 40px;
                  `}
                />
              )}
            </div>
            <div
              className={css`
                display: flex;
                justify-content: center;
                flex-grow: 1;
                align-items: center;
              `}
            >
              <Select
                disabled={!writeableAccess[item.business.ID]}
                variant="outlined"
                style={{ width: '200px', border: 'none', height: '36px' }}
                value={item.resource.RoleID}
                onChange={handlePermission}
              >
                <MenuItem value="admin" item={item}>
                  Admin
                </MenuItem>
                <MenuItem value="editor" item={item}>
                  Can Edit
                </MenuItem>
                <MenuItem value="viewer" item={item}>
                  View Only
                </MenuItem>
              </Select>
            </div>
            <div>
              <DropMenu
                disabled={!writeableAccess[item.business.ID]}
                button={
                  <IconButton>
                    <DotsThree />
                  </IconButton>
                }
                title="Options"
              >
                <MenuItem onClick={() => removeAccess(item.business)}>Remove Access</MenuItem>
              </DropMenu>
            </div>
          </div>
        ))
      )}

      <AssignCompany
        member={member}
        display={assignCompany}
        onClose={(isSuccessful) => {
          setAssignCompany(false);
          refetch();
        }}
      />
    </div>
  );
};
