import { css } from '@emotion/css';
import QRCode from 'react-qr-code';
import { Button, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';

export const QRCodeModal = ({ hide, guide = {} }) => {
  const onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  return (
    <Modal display full onClose={() => hide()}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid ${colors.gray[300]};
        `}
      >
        <Text h1>QR Code</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <div>
            <Button secondary onClick={() => hide()}>
              Cancel
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                onImageDownload();
              }}
            >
              Download QR Code
            </Button>
          </div>
        </div>
      </div>

      <div
        className={css`
          border-radius: 8px;
          padding: 32px;
          display: flex;
          justify-content: center;
          max-width: 700px;
          min-width: 600px;
          margin: 32px auto;
          overflow: auto;
        `}
      >
        <QRCode id="QRCode" value={`https://britehr.app/${guide.VanityURL}?lt=pdf-qr-code-manual`} />
      </div>
    </Modal>
  );
};
