import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import { DotsThree, Info, Trash, Users, X } from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../redux/axios/axios';
import { Button, ConfirmationModal, Div, DropMenu, Modal, Text } from '../../shared/components';
import { Icon } from '../../shared/components/icon';
import { colors } from '../../shared/styles';
import { removeItem, getRowId, modifyContent, editorUtils, iterateColumns } from '../provider/utils';
import { flex, px, container as containerStyles } from '../../shared/shared-styles';
import { ReactComponent as ListPlus } from '../../images/svg/list-plus.svg';
import { useEditorResource } from '../use-editor-resource';
import { MenuItem } from '@material-ui/core';
import { useParams } from 'react-router';
import { WRITE_COURSE } from '../../shared/acl-constants';
import { useModalWithData } from '../../common/hooks/useModalWithData';
import { ShareResourceModal } from '../../shared/components/ShareResourceModal';
import { useStore } from '../../store-provider/use-store';
import { Tooltip } from '../../common/components/Tooltip';

const emptyContainer = css`
  position: relative;
  width: 100%;
  background-color: ${colors.gray[100]};
  min-height: 300px;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const container = css`
  position: absolute;
  top: 50%;
  left: calc(50% - 200px);
  width: 400px;
  background-color: white;
  border-radius: 8px;
  padding: 0;
  z-index: 200;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  cursor: default;
`;

export const ContentBlock = (props) => {
  const { businessId } = useParams();

  const [modalBlock, setModalBlock] = useState(['', '']);
  const shareModal = useModalWithData();

  const {
    data: { isBriteUser, acls, parentBusiness },
  } = useStore();
  const canEditParent = acls?.[parentBusiness?.ID]?.Acls?.includes(WRITE_COURSE);

  const { isSelected, editor } = props;
  const ref = useRef(null);
  const { layout, selection } = editor?.state;

  const {
    data: contentBlocks,
    cache,
    refetch,
  } = useEditorResource('blocks', {
    select: (data) => {
      return data.filter((item) => 'id' in item || item.business_id !== businessId);
    },
  });

  const addContent = (contentBlock) => {
    const selected = [...selection];
    setTimeout(() => editorUtils?.setProperty(editor, 'selection', null), 0);

    const content = contentBlock?.layout?.rows?.map((item) => {
      const rowId = getRowId();
      let newItem = cloneDeep(item);
      iterateColumns(item?.columns, (location, item) => {
        if (location.length === 1) {
          newItem.columns[location[0]] = {
            ...item,
            id: getRowId(),
          };
        } else if (location.length === 2) {
          newItem.columns[location[0]].list[location[1]] = {
            ...item,
            id: getRowId(),
          };
        }
      });
      return { ...newItem, rowId };
    });
    let rows = [...layout?.rows];
    rows.splice(selected?.[0], 1, ...content);
    modifyContent?.set(editor, [], rows);
  };

  const deleteContentBlock = async () => {
    const oldContentBlocks = [...contentBlocks];
    try {
      const updatedContentBlock = contentBlocks?.filter(({ id }) => id !== modalBlock[1]);
      cache.setData(updatedContentBlock);
      await CustomAxios.delete(`v1/bp/course_editor_block?id=eq.${modalBlock[1]}`, {
        headers: { 'Content-Profile': 'brite_public' },
      });
      refetch();
    } catch (err) {
      cache.setData(oldContentBlocks);
      console.error(err);
      toast.error(`Failed to delete content block`);
    }
  };

  const shareContentBlock = async (changes) => {
    try {
      const body = {
        business_id: changes?.businessId,
        share_scope: changes?.shareScope,
      };
      await CustomAxios.patch(`v1/bp/course_editor_block?id=eq.${changes?.id}`, body, {
        headers: {
          'Content-Profile': 'brite_public',
        },
      });
      refetch();
      editorUtils?.setProperty(editor, 'selection', null);
    } catch (err) {
      console.warn(err);
      toast.error('Failed to update acccess for this block.');
    }
  };

  const handleDeleteComponent = () => {
    const rows = removeItem(layout, selection);
    modifyContent?.set(editor, [], rows);
    editorUtils?.setProperty(editor, 'selection', null);
  };

  const getShareValues = (item) => ({
    id: item?.id,
    name: item?.name,
    shareScope: item.share_scope,
    businessId: item.business_id,
  });

  return (
    <>
      <Div className={emptyContainer}>
        <Icon SVG={ListPlus} size={48} color={colors.black} weight="light" />
        <Text styles="bold label padt-sm">Add Saved Content Block</Text>
      </Div>
      {isSelected && (
        <Div className={container} ref={ref}>
          <Div>
            <Div
              css={css`
                ${flex('space-between')} padding: 32px;
              `}
            >
              <Text h4 bold>
                Add Saved Content Block
              </Text>
              <Div
                css={css`
                  ${flex('right')}
                `}
              >
                <Button styles="icon sm" onClick={() => setModalBlock(['info', null])}>
                  <Info />
                </Button>
                <Button styles="icon sm" hoverLabel="Delete Content Block" onClick={handleDeleteComponent}>
                  <Trash />
                </Button>
              </Div>
            </Div>
            <Div
              css={css`
                width: 100%;
                border-top: 1px solid ${colors.gray[300]};
              `}
            />
          </Div>
          <Div
            css={css`
              padding: ${px.md} 0;
              max-height: 250px;
              overflow-y: auto;
            `}
          >
            {contentBlocks?.length ? (
              contentBlocks?.map((item, idx) => (
                <Div
                  key={item.id + idx}
                  css={css`
                    ${flex('jcsb aic')} ${containerStyles.hover} padding: ${px.md};
                    :not(:hover) {
                      button,
                      svg {
                        visibility: hidden;
                      }
                    }
                  `}
                  onClick={() => addContent(item)}
                >
                  <Text styles="label padh">{item.name}</Text>
                  {item?.business_id === businessId ? (
                    <DropMenu
                      button={
                        <Button styles="icon sm">
                          <DotsThree />
                        </Button>
                      }
                    >
                      {isBriteUser || canEditParent ? (
                        <MenuItem onClick={() => shareModal?.show(item)}>Share</MenuItem>
                      ) : null}
                      <MenuItem onClick={() => setModalBlock(['delete', item.id])}>Delete</MenuItem>
                    </DropMenu>
                  ) : (
                    <Tooltip label="Shared Block">
                      <Users
                        size={24}
                        className={css`
                          margin-right: 8px;
                        `}
                      />
                    </Tooltip>
                  )}
                </Div>
              ))
            ) : (
              <Text styles="label pad">You have no saved content blocks.</Text>
            )}
          </Div>
        </Div>
      )}
      <ConfirmationModal
        display={modalBlock[0] === 'delete'}
        onClose={() => setModalBlock(['', ''])}
        title="Are you sure you want to permanently delete this content block?"
        onConfirm={deleteContentBlock}
        isDeleteAlert
        confirmText="Delete Content Block"
      />
      <Modal display={modalBlock[0] === 'info'} onClose={() => setModalBlock(['', ''])}>
        <Div
          css={css`
            width: 500px;
            padding: 32px;
            border-radius: 16px;
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Div
              css={css`
                ${flex('left')}
                p {
                  margin-left: 8px;
                }
              `}
            >
              <Tooltip title="Add Blocks Icon">
                <Icon SVG={ListPlus} />
              </Tooltip>
              <Text h2>About Layout Blocks</Text>
            </Div>
            <Button styles="icon" onClick={() => setModalBlock(['', ''])}>
              <X />
            </Button>
          </Div>
          <Text
            css={css`
              margin-top: 16px;
            `}
          >
            Layouts are a great tool for saving multiple components to use later. Brite has also added a for components
            that we think will be helpful.
          </Text>
          <Div
            css={css`
              ${flex('left')}
            `}
          >
            <Text
              label
              css={`
                margin: 16px 0;
              `}
            >
              How it works
            </Text>
          </Div>
          <Text
            css={`
              ${flex('left start')}
              span {
                margin-right: 8px;
              }
              strong {
                margin: 0 4px;
              }
            `}
          >
            <Text as="span">1.</Text> Hold <strong>shift</strong> to select multiple rows
          </Text>
          <Text
            css={`
              ${flex('left start')}
              span {
                margin-right: 8px;
              }
            `}
          >
            <Text as="span">2.</Text> Click the add blocks icon to the left of the rows to name and save your new layout
          </Text>
          <Div
            css={css`
              ${flex('right')}
              margin-top: 16px;
            `}
          >
            <Button onClick={() => setModalBlock(['', ''])}>Done</Button>
          </Div>
        </Div>
      </Modal>
      {shareModal?.isOpen ? (
        <ShareResourceModal
          title={`Share "${shareModal?.data?.name}"`}
          modalProps={shareModal}
          getFormValues={getShareValues}
          onSubmit={shareContentBlock}
        />
      ) : null}
    </>
  );
};
